import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import {useHistory} from 'react-router-dom';
import {
    Card, CardHeader,
    Button, 
    Row,
    Col,
    ButtonDropdown,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import DetailTabBar from './DetailTabBar'
import {getIconColorTable, primaryColor} from '../../common/tableFormats';

const DetailHeader = ({id, cancelChanges, saveChanges, saveAndClose, saveAndPrev, saveAndNext, cancelDisabled, 
    saveDisabled, prevCompany, prevButtonEnabled, nextCompany, nextButtonEnabled, handleTabClick, setShowSimpleModal, 
    setModalType })=> {

    const {org, orgId, currentUser, setOrgAfterLogin, //env
    } = useAuth();

    const { api,
        activesNavTable, importsNavTable,
        currentActiveFilter, currentImportsFilter,
        lastItems, setLastItems,
        filters, setFilters,
        activeFilterCard,
        tableOrigin, insertMode,
    } = useContext(CompanyContext);        

    // useEffect(async ()=>{
    //     if (!org && currentUser) {
    //         await setOrgAfterLogin(currentUser.uid);
    //     }
    // },[org]);
              
    const history = useHistory();
    const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);
    const [currentRecordNo, setCurrentRecordNo] = useState(1);
    const [currentNavLength, setCurrentNavLength] = useState(-1);
    const [currentFilterCard, setCurrentFilterCard] = useState(-1);
    const [saveIsOpen, setSaveIsOpen] = useState(false);
    const saveToggle = ()=> setSaveIsOpen(prev=>!prev);

    useEffect(()=>{
        if (!lastItems.filteredIds || !lastItems.filteredIds.length) {
            const filteredIdsJSON = localStorage.getItem(`${api.getOrgId()}_filteredIds`);
            let filteredIds = [];
            if (filteredIdsJSON) filteredIds = JSON.parse(filteredIdsJSON)
            setLastItems(prev=>({...prev, filteredIds, filteredCount: filteredIds.length}));
            setCurrentRecordNo(filteredIds.findIndex(item=>item === id)+1);
        } else 
            setCurrentRecordNo(lastItems.filteredIds.findIndex(item=>item === id)+1);
        if (tableOrigin === "Active") {
            setCurrentNavLength(Object.keys(activesNavTable).length);
            setCurrentFilterCard(currentActiveFilter);
        }
        else if (tableOrigin === 'Imports') {
            setCurrentNavLength(Object.keys(importsNavTable).length);
            setCurrentFilterCard(currentImportsFilter);
        }
    },[id]);

    const getCurrentRecNo = () => {
        return lastItems.filteredIds.findIndex(item=>item === lastItems._id)+1;
    }

    //if (!activeFilterCard) return <span></span>;
    let ndx = Object.entries(iconColorTable).findIndex(item=>item[1].display && activeFilterCard === item[1].label);
    let color='', icon='', key='';
    if (ndx > -1) {
        key = Object.entries(iconColorTable)[ndx][0];
        color = primaryColor; //iconColorTable[key].color;
        icon = iconColorTable[key].icon;
    }
    const recordNumber = () => { 
        return (<Fragment>
        <p className="m-0 p-0" style={{
            whiteSpace: 'nowrap',
            fontSize: '.9rem',
            }}
            ><small><strong>Record: </strong>{currentRecordNo} of {lastItems.filteredCount}</small>
            {filters.length > 1
                ? <small className="d-inline ml-3"><strong>Filters:  </strong>{filters.filter(item=>item.id !== 'Actions').map(item=>(`${item.id}: ${item.value}`)).join(', ')}</small> 
                : ''}
        </p>
        </Fragment>);
    };

    const singleRecord = () => { 
        const singleFilter = [{id: id}]
        return (<Fragment>
        <p className="m-0 p-0" style={{
            whiteSpace: 'nowrap',
            fontSize: '.9rem',
            }}
            ><small><strong>Record: </strong>1 of 1</small>
            {singleFilter.length > 1
                ? <small className="d-inline ml-3"><strong>Filters:  </strong>{singleFilter.filter(item=>item.id !== 'Actions').map(item=>(`${item.id}: ${item.value}`)).join(', ')}</small> 
                : ''}
        </p>
        </Fragment>);
    };

    const returnToResults = () => {
        const saveFilters = [...filters];
        const table = tableOrigin === 'Active' ? 'active' : 'imports';
        const card = tableOrigin === 'Active' ? currentActiveFilter : currentImportsFilter;
        setTimeout(()=>{
            //alert(saveFilters)
            setFilters([...saveFilters])
        },1000);
        history.push(`/admin/${table}/${currentFilterCard}`)
    }

    const handleMoveToClick = (origin, id, del=false) => {
        if (del) setModalType('moveToDeleted')
        else setModalType(origin==='Active'?'moveToImports':'moveToActive');
        setShowSimpleModal(true);
    }

    return (
        <div  className="sticky-top ml-0 mr-0 mt-1 pt-1 w-100">
            <Card className="border ml-0 mr-0 pl-0 pr-0 mb-0 pb-0 mt-0"><CardHeader>
                <Row>
                    <Col sm="8">
                        <h5 className={`hover-pointer py-0 text-left mb-0 pb-0 icon-big icon-${color}`} onClick={returnToResults}>  
                            <span className="text-primary ml-0 pl-0">{`${tableOrigin}->`}
                            {/* <FontAwesomeIcon 
                                icon={icon} transform="grow-1" 
                                className={`ml-2 mr-1 pb-1`} style={{color:color, opacity: 0.7}} 
                            />  */}
                            {' '}<u>{currentFilterCard}</u></span>
                            <FontAwesomeIcon 
                                icon={'arrow-left'} transform="grow-1" 
                                className={`ml-2 mr-0`} style={{color:color, opacity: 0.7}} 
                            />
                        </h5>
                        {currentNavLength > 1 ? recordNumber() : singleRecord()}
                    </Col>
                    {/* <Col sm="2">
                        <h5 className={`py-0 icon-big text-center icon-${color}`}>
                        </h5>
                    </Col>         */}
                    <Col lg="4" className="pull-right">
                        <Row className="p-0 m-0 mb-1 text-right">

                        {/* <Col><Button onClick={testScroll}>Check out scrolling</Button></Col> */}
                            <Col lg="4" className="p-0 m-0 text-center">
                                <Button className="m-0" size="sm" color="primary" onClick={prevCompany} disabled={!prevButtonEnabled}>{'  <  '}</Button>
                                    &nbsp;&nbsp;
                                <Button className="m-0" size="sm" color="primary" onClick={nextCompany} disabled={!nextButtonEnabled}>{'  >  '}</Button>
                            </Col>
                        {/* </Row>
                        <Row className="p-0 m-0 mt-4 mb-1"> */}
                            <Col lg="8" className="text-right p-0 m-0">
                                <Row>
                                    <Col sm="2" className="text-center pr-4">
                                        <UncontrolledDropdown 
                                            size="sm"
                                            //color="primary"
                                        >
                                            <DropdownToggle caret
                                                size="sm"
                                                color="primary" 
                                                className="m-0 text-center align-middle"
                                            > Actions
                                            </DropdownToggle>
                                            <DropdownMenu className="mt-1 pt-0">
                                                <DropdownItem className="mt-0 mb-1 pt-0 pb-0">
                                                    <div className="m-0" color="primary" size="sm"
                                                        onClick={()=>{
                                                            handleMoveToClick(tableOrigin, id) }}
                                                            disabled={!saveDisabled}>
                                                        Move To {tableOrigin === 'Active' ? 'Imports' : 'Active'}
                                                    </div>
                                                </DropdownItem>
                                                <DropdownItem className="mt-0 mb-1 pt-0 pb-0">
                                                    <div className="m-0" color="primary" size="sm"
                                                        onClick={()=>{
                                                            handleMoveToClick(tableOrigin, id, true)
                                                        }}                                                            
                                                        disabled={!saveDisabled}>
                                                        Remove from PureBlue
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Col>
                                    <Col sm="2"><span>&nbsp;</span></Col>
                                    <Col sm="4" className="text-right pr-0 pl-2">
                                        <Button className="m-0" color="primary" size="sm"
                                            onClick={cancelChanges} disabled={cancelDisabled}>
                                                Cancel</Button>
                                    </Col>
                                    <Col sm="4" className="text-left pl-2 pr-0 mr-0">
                                        <UncontrolledDropdown 
                                            size="sm"
                                            //color="primary"
                                            direction="down"
                                        >
                                            <DropdownToggle caret
                                                size="sm"
//                                                tag="button"
                                                color="primary" 
                                                className="m-0 text-center align-middle"
                                                disabled={saveDisabled}
                                            >
                                                Save
                                            </DropdownToggle>
                                            <DropdownMenu className="mt-1 pt-0">
                                                {!insertMode && 
                                                <DropdownItem className="mt-0 mb-1 pt-0 pb-0">
                                                    <div className="m-0" size="sm" color="primary" 
                                                        //style={{backgroundColor: primaryColor, color: 'white'}}
                                                        onClick={saveChanges} 
                                                        disabled={saveDisabled}
                                                    >Save</div>
                                                </DropdownItem>}
                                                {/* <DropdownItem className="mt-0 mb-1 pt-0 pb-0">
                                                    <Button className="m-0" size="sm" color="primary" 
                                                        onClick={saveAndPrev} disabled={saveDisabled || !prevButtonEnabled}>Save and Prev</Button>
                                                </DropdownItem>
                                                <DropdownItem className="mt-0 mb-1 pt-0 pb-0">
                                                    <Button className="m-0" size="sm" color="primary" 
                                                        onClick={saveAndNext} disabled={saveDisabled || !nextButtonEnabled}>Save and Next</Button></DropdownItem> */}
                                                <DropdownItem divider/>
                                                <DropdownItem className="mt-0 mb-1 pt-0 pb-0">
                                                    <div className="m-0" size="sm" color="primary" 
                                                        onClick={saveAndClose} disabled={saveDisabled}>Save and Close</div></DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown> 
                                    </Col>
                                </Row>
                            </Col>            
                        </Row>
                    </Col>
                        
                </Row>
                <Row><Col>
                <DetailTabBar
                    handleTabClick={handleTabClick}
                />   
                </Col></Row>
            </CardHeader></Card>
        </div>
    );
}

export default DetailHeader;