import React, {useState, useEffect} from 'react';
import {Row, Col, FormGroup, InputGroup, Label, Input} from 'reactstrap';
import {OPCImg} from '../../common/tableFormats';

const SyncForm = ({data, mode, setUpdateObject}) => {
  //first we must see if there is a CRMSync object in the data.  If so, get values.
  let sync, exists, handle;
  if (data && 'CRMSync' in data) {
    sync = data.CRMSync.syncToAILead ? data.CRMSync.syncToAILead : "No";
    exists = data.CRMCompanyId ? "Yes" : "No";
    handle = data.CRMSync.handleConflict ? data.CRMSync.handleConflict : "crmWins";
  }

  const isFirstRender = React.useRef(true);
  const [syncToAILead, setSyncToAILead] = useState(sync || "No");
  const existsInCRM = exists || "No";
  const [handleConflict, setHandleConflict] = useState(handle || "mostRecentWins");
  const [changed, setChanged] = useState({});

  const changeFieldValue = (e, field, stateValue, setFunction) => {
    e.stopPropagation();
    const newValue = e.target.value;
    setFunction(newValue);
    const newChanged = {...changed};
    newChanged[field] = e.target.value;
    newChanged.UpdateDate = new Date();
    setChanged({...newChanged});
  }

  useEffect(()=>{
    if (!isFirstRender.current) {
      const outObj = {CRMSync: {...data.CRMSync, ...changed}};
      //const historyLine = {date: new Date(), desc: `CRMSync set to ${syncToAILead} with ${handleConflict}`};
      //outObj.History = data.History ? [historyLine, ...data.History] : [historyLine];
      setUpdateObject(outObj);
    }
  },[syncToAILead, handleConflict, data, changed, setUpdateObject])

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

return (
      <Row className="mt-0 pt-0">
        <Col sm="12">
              <FormGroup>
                <Row>
                  <Col md="3" className="w-100 text-right pr-0 h-100 align-middle">
                    <Label className="justify-content-end form-control-sm pr-3 mr-2" for="whichCRM">CRM For Sync</Label>
                  </Col>
                  <Col md="9" className="pl-0">
                    <div className="text-left d-inline pl-0">
                      <img className="pr-0" alt="OnePageCRM" width={40} height={40} src={OPCImg} />OnePageCRM</div>
                  </Col>
                </Row>
                <Row>
                  <Col md="3" className="w-100 text-right pr-0 h-100 align-middle">
                    <Label className="justify-content-end form-control-sm pr-3 mr-2" for="syncToAILead">Enable Data Sync?</Label>
                  </Col>
                  <Col md="9" className="pl-1">
                    <InputGroup> 
                        <Label>
                            <Input type="radio" 
                              name="syncToAILead" 
                              checked={syncToAILead === 'Yes' ? true : false}
                              onChange={(e)=>changeFieldValue(e, 'syncToAILead', syncToAILead, setSyncToAILead)}
                              value={"Yes"}
                            />{' '}
                            Yes
                        </Label>
                      </InputGroup>
                      <InputGroup>
                        <Label>
                            <Input type="radio" 
                              name="syncToAILead" 
                              checked={syncToAILead === 'No' ? true : false}
                              onChange={(e)=>changeFieldValue(e, 'syncToAILead', syncToAILead, setSyncToAILead)}
                              value={"No"}
                            />{' '}
                            No
                        </Label>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3" className="w-100 text-right pr-0 h-100 align-middle">
                    <Label className="justify-content-end form-control-sm pr-3 mr-2" for="existsInCRM">Exists in CRM?</Label>
                  </Col>
                  <Col md="9" className="pl-1">
                  <InputGroup> 
                        <Label>
                            <Input type="radio" 
                              name="existsInCRM" 
                              disabled={true}
                              checked={existsInCRM === 'Yes' ? true : false}
                              value={"Yes"}
                            />{' '}
                            Yes
                        </Label>
                        </InputGroup>
                        <InputGroup>
                        <Label>
                            <Input type="radio" 
                              name="existsInCRM" 
                              disabled={true}
                              checked={existsInCRM === 'No' ? true : false}
                              value={"No"}
                            />{' '}
                            No
                        </Label>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3" className="w-100 text-right pr-0 h-100 align-middle">
                    <Label className="justify-content-end form-control-sm pr-3 mr-2" for="handleConflict">Handling Conflicts</Label>
                  </Col>
                  <Col md="9" className="pl-1">
                    <InputGroup> 
                        <Label>
                            <Input type="radio" name="handleConflict" value="aiLeadWins" 
                              checked={handleConflict === 'aiLeadWins' ? true : false}
                              onChange={(e)=>changeFieldValue(e, 'handleConflict', handleConflict, setHandleConflict)}
                            />{' '}
                            PureBlue Wins - PureBlue data overwrites CRM
                        </Label>
                      </InputGroup>
                      <InputGroup>
                        <Label>
                            <Input type="radio" name="handleConflict" value="crmWins" 
                              checked={handleConflict === 'crmWins' ? true : false}
                              onChange={(e)=>changeFieldValue(e, 'handleConflict', handleConflict, setHandleConflict)}
                            />{' '}
                            CRM Wins - CRM data overwrites PureBlue
                        </Label>
                      </InputGroup>
                      <InputGroup>
                        <Label>
                            <Input type="radio" name="handleConflict" value="mostRecentWins" 
                              checked={handleConflict === 'mostRecentWins' ? true : false}
                              onChange={(e)=>changeFieldValue(e, 'handleConflict', handleConflict, setHandleConflict)}
                            />{' '}
                            Most Recent Wins - most recent overwrites the other
                        </Label>
                    </InputGroup> 
                  </Col>
                </Row>
                </FormGroup>
        </Col>
      </Row>
    );
}

export default SyncForm;