
import React, {Fragment} from "react";
// react plugin used to create charts
// import { Line, Pie } from "react-chartjs-2";
// // reactstrap components
import {
  // Card,
  // CardHeader,
  // CardBody,
  // CardFooter,
  // CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
// import {
//   dashboard24HoursPerformanceChart,
//   dashboardEmailStatisticsChart,
//   dashboardNASDAQChart,
// } from "variables/charts.js";

import ContactsTable from './ActiveTable';

class Dashboard extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="content p-0 pt-0">
          <Row>
            <Col md="12">
              <ContactsTable />
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
