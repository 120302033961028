import React, {useMemo, useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {CompanyContext} from '../../context/CompanyContext';
import TableDropDownActions from './TableDropDownActions';
import GenericDropDown from '../../components/dropdowns/GenericDropdown';
import {toDateString, getIconColorTable, formatWebsite} from '../../common/tableFormats';
//import {updateCompany as updateCompanyGeneric} from '../../common/ApiFunctions';
import StatusDropDown from './StatusDropDown';
import CRMSyncDropDown from './CRMSyncDropDown.jsx';

const TableActions = ({ classFromParent, row, setId, _id, origin, setMessage, setErrorLevel, setFetchRecord, 
    setReadTable, setShowActionModal, setShowSimpleModal, setModal, setModalType, setModalTitle, setModalSubtitle, setModalContent, 
    setButtonsType, setTitleIcon, setIconColor, setIsOpenMode, setRowCell, setSimpleRowData, }) =>
    {

    const { api, setPatchArray, setCurrentCompany, setCurrentContact,
    } = useContext(CompanyContext);

    const history = useHistory();

    const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);

    const rowIndex = row.cell.row.index;
    const [rowData, setRowData] = useState(row.data[rowIndex]);

    const contactCount = rowData.numContacts;
    const Name = rowData.Name;
    const City = rowData.City;
    const State = rowData.State;
    const Phone = rowData.Phone;
    const Website = rowData.Website;
    const WebText = ''; //rowData.WebsiteInfo ? rowData.WebsiteInfo.webText : null;
    const UpdateDate = rowData.UpdateDate;

    const bullet = '\u2022';
    const subTitleLine = `${Name} 
      \n${(City && State ? City + ', ' + State : City)} ${((City || State) ? bullet + ' ' : '')} ${Phone}
      \n${Website ? formatWebsite(row, Website, Name, 50, 'textOnly') + ' ' + bullet : ''}\nLast Modified: ${toDateString(UpdateDate)}`;

    const updateCompany = async (_id, doc, msg=null) => {
        console.log('in table actions update company. ')
        setMessage(`Updating Database for ${Name}`);
        await api.updateCompany(_id, doc, rowData, msg, setMessage, null, null, null, null, setFetchRecord, null, syncContact, origin, setMessage);
    } //end updateCompany()  

    const syncContact = async(rowData)=> {
        //call the method to sync this contact because it has been updated
        await api.syncAction(_id, {}, {...rowData}, setMessage, Name, 'OnePageCRM', setReadTable, origin);
    }
        
    const actionIconClick = (which, show, icon, color) => {
        // setRowIndex(rowIndex);  //this is for the ActionModal
        // setRowData({...rowData});
        setModalSubtitle(subTitleLine)
        setModalType(which);
        setTitleIcon(icon);
        setIconColor(color);
        setId(_id);

        if (which === 'contacts') {
            setModalTitle('Company Contacts');
            setModalContent("Company Contacts");
            setButtonsType('Cancel:OK');
            setShowActionModal(true);
        }
        else if (which === 'edit') {
            setPatchArray([]);
            setCurrentCompany({});
            setCurrentContact({});
            history.push('/admin/detail/' + _id);
        }
        else if (which === 'history') {
            setModalTitle('View History' );
            setModalContent("View History For This Record");
            setButtonsType('Cancel:OK')
            setShowActionModal(true);
        }
        else if (which === 'notes') {
            setModalTitle('View/Edit Notes' );
            setModalContent("View/Edit Nodes");
            setButtonsType('Cancel:OK')
            setShowActionModal(true);
        } 
        else if (which === 'moveToActive') {
            setSimpleRowData(rowData);
            setModalTitle('Move Record to Active' );
            setModalContent("Move Record to Active");
            setShowSimpleModal(true);
        }
        else if (which === 'moveToImport') {
            setSimpleRowData(rowData);
            setModalTitle('Move Record to Active' );
            setModalContent("Move Record to Active");
            setShowSimpleModal(true);
        }
        else if (which === 'sync') {
            setModalTitle('Sync With CRM');
            setModalContent("Sync With CRM");
            setButtonsType('Cancel:OK')
            setShowActionModal(true);
        }
        else if (which === 'moveToDeleted') {
            alert(row.cell.row.index);
            setModalTitle('Remove from PureBlue' );
            setModalContent("Remove from PureBlue");
            setShowSimpleModal(true);
        }
    }

    const contact = <span className="d-inline notification-indicator notification-indicator-warning notification-indicator-fill" key={`contact_div_${_id}`}>
          <span className="notification-indicator-number">
              {contactCount}
          </span>
          <FontAwesomeIcon 
            key={`contact_icon_action_${_id}`}
            id={`contact_${rowIndex}`} 
            icon={iconColorTable.contacts.icon} transform="grow-2" 
            className="ml-0 mr-2" style={{color:iconColorTable.contacts.color}} 
            onClick={()=>actionIconClick('contacts', true, iconColorTable.contacts.icon, iconColorTable.contacts.color)}
        /></span>
        
//     const edit = <FontAwesomeIcon 
//         key={`edit_icon_action_${_id}`}
// //        id={`edit_${rowIndex}`} 
//         icon={iconColorTable.edit.icon} transform="grow-2" 
//         className="ml-2 mr-2" style={{color:iconColorTable.edit.color}} 
//         onClick={()=>actionIconClick('edit', true, iconColorTable.edit.icon, iconColorTable.edit.color)}
//     />

    const sync = <GenericDropDown 
        //origin={origin}
        key={`crmtoggle_icon_action_${_id}`}
        mouseover={false}
        mode={'CRMSyncToggle'}
        //isOpen={ddOpen.CRMSyncToggle[rowIndex]}
        //setIsOpen={(mode, val)=>{setIsOpenMode(mode, val, rowIndex)}}
        updateCompany={updateCompany}
    >
        <CRMSyncDropDown 
            origin={'Table'}
            mode={'CRMSyncToggle'}
            currentCompany={rowData}
            setRowData={(field, newVal)=>setRowCell(row, rowIndex, field, newVal)}
            setMessage={setMessage}
            // isOpen={ddOpen.CRMSyncToggle[rowIndex]}
            // setIsOpen={(mode, val)=>{setIsOpenMode(mode, val, rowIndex)}}
            handleToggle={(mode, val)=>{setIsOpenMode(mode, false, rowIndex)}}
            updateCompany={updateCompany}
            setChangeMade={()=>{}}
        />        
    </GenericDropDown>;

    const statusSection = 

        (<GenericDropDown 
            //origin={origin}
            key={`aistatus_icon_action_${_id}`}
            mouseover={false}
            mode={'AIStatus'}
            // isOpen={ddOpen.AIStatus[rowIndex]}
            // setIsOpen={(mode, val)=>{setIsOpenMode(mode, val, rowIndex)}}
            updateCompany={updateCompany}
        >
            <StatusDropDown 
                origin={'Table'}
                mode={'AIStatus'}
                currentCompany={rowData}
                setRowData={(field, newVal)=>setRowCell(row, rowIndex, field, newVal)}
                setMessage={setMessage}
                // isOpen={ddOpen.AIStatus[rowIndex]}
                // setIsOpen={(mode, val)=>{setIsOpenMode(mode, val, rowIndex)}}
                handleToggle={(mode, val)=>{setIsOpenMode(mode, false, rowIndex)}}
                updateCompany={updateCompany}
                setChangeMade={()=>{}}
            />        
        </GenericDropDown>);


    const more = 
        (<TableDropDownActions 
                    key={`more_icon_action_${_id}`}
                    classFromParent={"d-inline"} 
                    row={rowData}
                    setSimpleRowData={setSimpleRowData}
                    origin={origin}
                    subTitle={subTitleLine}
                    setSubtitle={setModalSubtitle} 
                    setModal={setModal}
                    setModalType={setModalType} 
                    setModalTitle={setModalTitle} 
                    setModalContent={setModalContent} 
                    setButtonsType={setButtonsType} 
                    setIconColor={setIconColor} 
                    setShowActionModal={setShowActionModal} 
                    setShowSimpleModal={setShowSimpleModal} 
                    setTitleIcon={setTitleIcon} 
                    updateCompany={updateCompany}
                    _id={_id}
                    setId={setId}   
        />);

    return (
        <React.Fragment key={`action_icons_fragment_${_id}`}>
            {/* <div></div> */}
            <span key={`actions_span_${_id}`} className="text-center" style={{whiteSpace: 'nowrap' }}>
                <span key={`contacts_span_${_id}`} className="text-center pl-1 pr-0" data-html={true} data-toggle="popover" title={'Contacts for: ' + Name} data-content="content">{contact}</span>
                {/* <span key={`edit_span_${_id}`} className="text-center pl-1 pr-0" data-html={true} data-toggle="popover" title={'Edit: ' + Name} data-content="content">{edit}</span> */}
                <span key={`sync_span_${_id}`} className="text-center pl-0 pr-0" data-html={true} data-toggle="popover" title={'Sync: ' + Name} data-content="content">{sync}</span>
                <span key={`status_span_${_id}`} className="text-center pl-1 pr-2" data-html={true} data-toggle="popover" title={'Change Status: ' + Name} data-content="content">{statusSection}</span>
                <span key={`more_span_${_id}`} className="text-center pl-2 pr-0" data-html={true} data-toggle="popover" title={'Show More Actions'} data-content="content">{more}</span>
            </span>
        </React.Fragment>
    )
}

export default TableActions;
