import React, {useContext, useState, useEffect} from 'react';
import {Row,Col,Label,Input,FormGroup} from 'reactstrap';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from "react-switch";
import {CompanyContext} from '../../context/CompanyContext';
import {toDateString, primaryColor, infoColor, parseUrl} from '../../common/tableFormats';   //dangerColor, infoColor, warningColor

const EditFieldRow = ({numCols=2, dbfield, rowKey='', label, placeholderText, fieldwidth="15", type="text", 
                        selectData=[], selectDisplay='text', selectValue='id', renderOption=null,
                        rows=null, cols=null, readonly=false, displayType=null, fixed=null, commas=false, arrayName=null, 
                        calendarIcon=false, weblink=false, weblinkAsIs=false, fieldIsArray=false,displayIcon=null,
                        onChangeHook=null, onBlurHook=null}) => {

    const {
        currentContact, 
        currentCompany, 
        setSingleField
    } = useContext(CompanyContext);

    const [originalValue, setOriginalValue ] = useState(fieldValue);

    useEffect(()=>{    
        setOriginalValue(fieldValue);
    },[]);

    function numberWithCommas(x) {
        if (x === "" || x === null) return "";
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleOnBlur = async (dbfield, value, dontPatch, arrayName, fieldIsArray, originalValue) => {
        if (onBlurHook && value !== null && value !== undefined) {
            const {valid, newValue} = await onBlurHook(value, dbfield, originalValue);
            if (valid && newValue) value = newValue;
        }
        setSingleField(dbfield, value, dontPatch, arrayName, fieldIsArray);
        return true;
    }
    const handleChange = async (dbfield, value, dontPatch, arrayName, fieldIsArray, originalValue)=> {
        if (onChangeHook && value !== null && value !== undefined) {
            const {valid, newValue} = await onChangeHook(value, dbfield, originalValue);
            if (valid && newValue) value = newValue;
        }
        setSingleField(dbfield, value, dontPatch, arrayName, fieldIsArray);
        return true;
    }

    const handleOnKeyDown = e => {
        if (e.key === "Escape") handleChange(dbfield, originalValue, false, arrayName, fieldIsArray);
    }

    const insertDate = e=> {
        const insertedDate = toDateString(new Date(), 2, true);
        const newNotes = `${insertedDate}: ${currentContact.Notes && currentContact.Notes.length ? '\n\n' + currentContact.Notes[0] : ''}`;
        handleChange(dbfield, newNotes, false, arrayName);
        // ref.current.selectionStart = ref.current.selectionEnd = insertedDate.length+2;
        // ref.current.focus();
    }

    const createHttpLink = (website) => {
        const myUrl = website && website.search(/^http/i) === 0 ? website : 'https://' + website;
        let {httpUrl} = parseUrl(myUrl);
        return httpUrl;
    }

    let labelCols = "4";
    let inputCols = "8";
    if (numCols === 1) {
        labelCols = "2";
        inputCols = "10";
    }
    let fieldValue, keyField;
    if (arrayName && arrayName === "Contacts") {
        fieldValue = currentContact[dbfield] ? currentContact[dbfield] : '';
        keyField = `contact_${dbfield}`;
        console.log('keyfield',keyField);
    }
    else {
        fieldValue = currentCompany[dbfield] ? currentCompany[dbfield] : '';
    }
    if (fieldValue && displayType==="date") fieldValue = toDateString(fieldValue, 4);
    else if (fieldValue && displayType==="decimal" && commas) fieldValue = fieldValue ? numberWithCommas(parseInt(fieldValue.toString().replace(/,/g, ""))) : null;
    else if (fieldValue && displayType==="decimal" && fixed) fieldValue = fieldValue ? fieldValue.toFixed(fixed) : null;

    return (
        <Row className="mt-0 pt-0 mb-1">
            <Col key={`labelcol_${keyField}`} lg={labelCols} className="text-right mr-0 pr-1 pt-1">
            {calendarIcon ? 
                <FontAwesomeIcon 
                    id={`contact_calendar_icon`} 
                    icon={'calendar-day'} transform="grow-4" 
                    className="ml-1 mr-2 pr-0" style={{color:primaryColor}} 
                    onClick={insertDate}
                />            
            : weblink && fieldValue ? 
                <a target='_blank' href={!weblinkAsIs ? createHttpLink(fieldValue) : (fieldValue.toString().toLowerCase().substr(0,4) !== 'http') ? 'https://' + fieldValue : fieldValue} alt={fieldValue} rel="noopener noreferrer">
                    <FontAwesomeIcon 
                    id={`contact_weblink_icon`} 
                    icon={'globe-americas'} transform="grow-4" 
                    className="ml-1 mr-2 pr-0" style={{color:primaryColor}} 
                /></a>         
            : displayIcon ? 
                    <span>{displayIcon ? 
                        <img id={`contact_display_icon`} 
                            src={displayIcon}
                            alt={`${fieldValue} Flag`}
                            className="ml-1 mr-2 pr-0" 
                        />
                        : null}
                        </span> 
            : null}
                <Label for={dbfield}><strong>{label}:</strong>
            </Label></Col>
            <Col key={`inputcol_${keyField}`} lg={inputCols} className="text-left ml-0 pl-0">
                <div style={{fontSize: '.8rem',}} key={`inputdiv_${keyField}`}>
                    {type === "text" || type==="number" ?      //this is just a text input field
                        <Input className={`pl-1 ${type==="textarea" ? "pt-0" : null}`} 
                            type={type} 
                            placeholder={!readonly ? `<${placeholderText}>` : ''} 
                            size={cols === 1 ? "80" : fieldwidth} 
                            //value={type==="number" ? numberWithCommas(fieldValue) : fieldValue}
                            value={fieldValue}
                            id={dbfield} name={dbfield}
                            //key={`input_${keyField}`}
                            onChange={(e)=>handleChange(dbfield, e.target.value, false, arrayName, fieldIsArray, originalValue)}
                            onBlur={(e)=>handleOnBlur(dbfield, e.target.value, false, arrayName, fieldIsArray, originalValue)}
                            disabled={readonly}
                    />
                    : type==="select" ?
                        <div //class="form-group"
                        >
                        <Input className={` pl-1 ${type==="textarea" ? "pt-0" : null}`} 
                            id={dbfield} name={dbfield}
                            placeholder={readonly === false ? `<${placeholderText}>` : ''} 
                            size={cols === 1 ? "80" : fieldwidth} 
                            value={fieldValue}
                            //key={`input_${keyField}`}
                            onChange={async (e)=>handleChange(dbfield, e.target.value, false, arrayName, fieldIsArray)}
                            onBlur={(e)=>handleOnBlur(dbfield, e.target.value, false, arrayName, fieldIsArray)}
                            disabled={readonly}
                            list={`${dbfield}_list`}
                            onKeyDown={handleOnKeyDown}

                        ></Input>                        
                        <datalist id={`${dbfield}_list`}>
                            {selectData.map((item, i)=>{
                                if (renderOption) return renderOption(item, i);
                                else return (<option key={`inputcol_${keyField}_opt_${i}`}>{item[selectDisplay]}</option>);
                        })}
                        </datalist>
                        </div>
                    : type==="textarea" ?
                        <Input className={`pl-1 pt-0`} placeholder={`<${placeholderText}>`} 
                            size={cols === 1 ? "80" : fieldwidth} 
                            value={fieldValue}
                            //key={`input_${keyField}`}
                            type="textarea"
                            id={dbfield} name={dbfield}
                            onChange={(e)=>handleChange(dbfield, e.target.value, false, arrayName, fieldIsArray)}
                            rows={rows}
                            cols={cols}
                            disabled={readonly}
                        />
                    : type==="switch" ?
                        <Switch 
                            key={`${dbfield}_switch`}
                            onChange={(e)=>handleChange(dbfield, !fieldValue, false, arrayName, fieldIsArray)}
                            checked={fieldValue}
                            className="react-switch"
                        />
                    : null
                    }
                </div>
            </Col>
        </Row>
    )
}

export default EditFieldRow;
