import React from 'react';
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';

const ConfirmationModal = ({confirmationModal, showConfirmationModal, toggle, navFunction, abandonChanges, title="Data Has Changed", okMessage="Click OK to abandon changes", next=null, prev=null}) => {

    const toggleConfirmation = ()=>{
        showConfirmationModal(!confirmationModal);
    }

    const className = '';

    return (
        <Modal
            backdrop={"static"}
            isOpen={confirmationModal}
            //toggle={toggleConfirmation}
            className={`modal-md mt-2 ${className ? className : ''}`}
            contentClassName="border-0"
            modalClassName="theme-modal"
            size="md"
      >
            <ModalHeader>
                <strong className="text-center text-danger">{title}</strong>
            </ModalHeader>
            <ModalBody>
                <div className="text-center text-danger">{okMessage}</div>
                <div className="text-center">
                <Button id="abandon"
                    key="modal_abandon_button"
                    color="danger"
                    className="btn btn-sm m-1"
                    onClick={() => {
                        if (navFunction === 'cancelsave') {
                            toggle();
                            abandonChanges();
                        } 
                        else if (navFunction==="next" && next) {
                            next(true);         //true tells the next() function to abandon changes
                        }
                        else if (navFunction==="prev" && prev) {
                            prev(true);
                        }
                    }
                }
                >OK</Button>
                <Button id="cancelAbandon"
                    key="modal_cancel_abandon_button"
                    color="secondary"
                    className="btn btn-sm m-1"
                    onClick={() => {
                        toggleConfirmation();
                    }
                }
                >Cancel</Button>
                </div>
            </ModalBody>
        </Modal>
    );
} //end component

export default ConfirmationModal