import React, { useContext, useState, useEffect } from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';
//import {useHistory} from 'react-router-dom';

//import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import EditFieldRow from './EditFieldRow';
import GenericDropDown from '../../components/dropdowns/GenericDropdown';
import StatusDropDown from '../../components/dropdowns/StatusDropDown';
import ProspectStageDropDown from '../../components/dropdowns/ProspectStageDropDown';
import CRMStatusDropDown from '../../components/dropdowns/CRMStatusDropDown.jsx';
import CRMSyncDropDown from '../../components/dropdowns/CRMSyncDropDown.jsx';
//import {getPostalCodeRecord, env} from '../../common/ApiFunctions';
  
const CompanyPane = ({setValidationError}) =>{
    const {org, orgId, currentUser, setOrgAfterLogin, //env
    } = useAuth();

    const { api, 
        currentCompany, resetCurrentCompany, leadSources, setLeadSources, naicsCodes, countries, states, 
        getUsStates, getMexicanStates, getCanadianStates, setSingleField,
      } = useContext(CompanyContext);

      //const history = useHistory();
      
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [countryFlag, setCountryFlag] = useState('../../assets/img/flags/US.png')

    useEffect(()=>{
        if ((!leadSources || !leadSources.length) && orgId) 
            getLeadSources(leadSources, setLeadSources, 'crmLeadSources', api.getLeadSources, 'item', 1, orgId);
    }, [leadSources, orgId])

    const setUpdateObject = obj => {
        //alert('update');
    }

    const setChangeMade = obj => {
        //alert('change made');
    }

    const updateCompany = obj => {
        alert('update company');
    }

    const setMessage = msg => {
        alert(msg);
    }

    const getLeadSources = async (items, setItems, lsKeyName, getItems, itemName='items', hours=1, useOrg=false, orgId) => {
        //need to make sure that we have the lead sources, country codes, naics codes, etc.
        //first check localStorage.  Requery if date older than # hours in param -- 1 - Lead Sources, 30 days - Countries
        //alert('getting lead sources ' + orgId);
        let lsObj = {};
        if (useOrg) lsKeyName = `${useOrg}_${lsKeyName}`;
        const itemLS = localStorage.getItem(lsKeyName);
        if (itemLS) {
          lsObj = JSON.parse(itemLS);
        } else {      //otherwise we need to get the data from the API
          lsObj = await api.getLeadSources(lsKeyName); 
        }
        //alert(JSON.stringify(lsObj));
        // if (lsObj && lsObj.data) alert(lsKeyName + ' ' + new Date(lsObj.data.timestamp).toLocaleString());
        // if (lsObj && lsObj.data) alert(lsKeyName + ' ' + (Date.now() - lsObj.data.timestamp) + " " + 1000 * 60 * 60 * hours);
        if (lsObj && lsObj.data && (Date.now() - lsObj.data.timestamp) > 1000 * 60 * 60 * hours) {
            //need to fetch data again
            lsObj = await api.getLeadSources(lsKeyName); 
        } 
        if (lsObj && lsObj.data && lsObj.data.items && lsObj.data.items.length) {
          //alert('setting leadSources');
          setLeadSources([...lsObj.data.items]);
        }
    } //end getLeadSources
    
    function numberWithCommas(x) {
        if (x === "" || x === null) return "";
//        alert(x.toString());
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //this is called on onBlurHook
    const validatePhone = (value, field, showErrors=true) => {
        if (value && value.length >= 10) {
            const regex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
            const match = regex.test(value);
            if (match) {
                return {valid: true, newValue: null};
            }
            showErrors && setValidationError(`Invalid ${field} number.  Please use format +NN NNN-NNN-NNNN (country code is optional)`);
            return {valid: false, newValue: null};
        } else if (value && value.length >= 1)
            showErrors && setValidationError(`Invalid ${field} number.  Please use format +NN NNN-NNN-NNNN (country code is optional)`);
        return {valid: true, newValue: null};
    }

    const validateYearFounded = async (value, showErrors=true) => {
        if (!value) return {valid: true, newValue: null}
        if (isNaN(value)) {
            showErrors && setValidationError(`${value} not a valid year.  Please enter a 4-digit year between 1750 and ${new Date().getFullYear()}`);
            return {valid: false, newValue: null}
        }
        value = parseInt(value);
        if (value >= 1750 && value <= (new Date().getFullYear())) return {valid: true, newValue: null}
        showErrors && setValidationError(`${value} not a valid year.  Please enter a 4-digit year between 1750 and ${new Date().getFullYear()}`);
        return {valid: false, newValue: null}
    }
    const validatePostal = async (value, showErrors=true) => {
        //validate zipcode if US
        if (value && value.length >= 5 && value.length <= 10 && (!currentCompany.Country || currentCompany.Country.toUpperCase() === 'USA')) {
            const regex = new RegExp(/^\d{5}([\-]?\d{4})?$/);
            const match = regex.test(value);
            if (match) {
                if (await setStateAndCountry(value) === false) {
                    showErrors && setValidationError(`US postal code ${value} not found`);
                    return {valid: false, newValue: null};
                } else return {valid: true, newValue: null};
            }
            showErrors && setValidationError('Invalid format.  Enter zipcode as nnnnn or nnnnn-nnnn or another country');
            return {valid: false, newValue: null};
        } else if (value && value.length >= 5)
            showErrors && setValidationError('Invalid format.  Enter zipcode as nnnnn or nnnnn-nnnn or another country');
        return {valid: false, newValue: null};
    }

    const processWSChange = async (value, dbfield, orig) => {
        if (value === '' && orig !== null) {
            if (currentCompany.WebsiteInfo && currentCompany.WebsiteInfo.domain) {
                const newWSI = {prevDomain: currentCompany.WebsiteInfo.domain, updated: new Date()}
                setSingleField('WebsiteInfo', newWSI);
            }
            // if (currentCompany.Supplemental && currentCompany.Supplemental.SerpApi) {
            //     const suppl = {...currentCompany.Supplemental};
            //     delete suppl.SerpApi;
            //     setSingleField('Supplemental', suppl);
            // }
        }
        return {valid: true, newValue: null}
    }

    const removeDecimals = (value, showErrors=true) => {
        if (!value) return {valid: true, newValue: null};

        if (isNaN(parseInt(value.replace(/,/g, "")))) {
            showErrors && setValidationError(`${value} is not a number.  Please enter a number`);
            return {valid: false, newValue: null};
        }

        value = Number.parseFloat(value).toFixed(0);
        return {valid: true, newValue: value};
    }

    const validateCountry = async (value, showErrors=true) => {
        //validate state code if us
        if (value && value.length >= 2) {
            const ndx = countries.findIndex(item=>[item.Country.toUpperCase(),item['Alpha-2 code'].toUpperCase(),item['Alpha-3 code'].toUpperCase()].includes(value.toUpperCase()));
            let found = (ndx > -1);
            if (found) {
                return {valid: true, newValue: countries[ndx]['Alpha-3 code'].toUpperCase()};
            }
            showErrors && setValidationError(`${value} not a valid Country.  Please enter country name or country code`);
            return {valid: false, newValue: null};
        }
        if (!value) return {valid: true, newValue: null};
        showErrors && setValidationError(`${value} not a valid country.  Please enter country name or country code`);
        return {valid: false, newValue: null};
    }

    const validateState = async (value, showErrors=true) => {
        //validate state code if us
        if (value && value.length >= 2 && !currentCompany.Country) {
            const ndx = states.findIndex(item=>item.Code === value.toUpperCase());
            let found = (ndx > -1);
            if (found) {
                setSingleField('Country', states[ndx].Country);
                return {valid: true, newValue: value.toUpperCase()};
            }
        }
        else if (value && value.length >= 2 && (currentCompany.Country.toUpperCase() === 'USA')) {
            const ndx = getUsStates().findIndex(item=>item.Code === value.toUpperCase());
            let found = (ndx > -1);
            if (!found) {
                showErrors && setValidationError(`${value.toUpperCase()} not a valid US State - Enter 2-char US state code or choose another country`);
                return {valid: false, newValue: null};
            }
            if (!currentCompany.Country) {
                setSingleField('Country', 'USA');
            }
            return {valid: true, newValue: value.toUpperCase()};
        } else if (value && value.length >= 2 && currentCompany.Country.toUpperCase() === 'CAN') {
            const ndx = getCanadianStates().findIndex(item=>item.Code === value.toUpperCase());
            let found = (ndx > -1);
            if (!found) {
                showErrors && setValidationError(`${value.toUpperCase()} not a valid Canadian State - Enter 2-char US state code or choose another country`);
                return {valid: false, newValue: null};
            }
            return {valid: true, newValue: value.toUpperCase()};
        } else if (value && value.length >= 2 && currentCompany.Country.toUpperCase() === 'MEX') {
            const ndx = getMexicanStates().findIndex(item=>item.Code === value.toUpperCase());
            let found = (ndx > -1);
            if (!found) {
                showErrors && setValidationError(`${value.toUpperCase()} not a valid Mexican State - Enter 2-char US state code or choose another country`);
                return {valid: false, newValue: null};
            }
            return {valid: true, newValue: value.toUpperCase()};
        }  
        return {valid: true, newValue: null};
    }

    const setStateAndCountry = async (postal) => {
        if (postal.length && postal.length >= 5) {
            //alert('retrieving ' + postal);
            const postalRecord = await api.getPostalCodeRecord(postal.slice(0,5));
            //alert(JSON.stringify(postalRecord, null, 2))
            const rec = postalRecord.data;
            if (rec && rec.zip) {
                if (rec.primary_city && rec.primary_city !== currentCompany.City) {
                    setSingleField('City', rec.primary_city);
                }
                if (rec.state && rec.state !== currentCompany.State) {
                    setSingleField('State', rec.state);
                }
                if (rec.country && rec.country !== currentCompany.Country) {
                    setSingleField('Country', get3CharCountry(rec.country));
                }
                return true;
            }
            return false;
            //alert(JSON.stringify(postalRecord, null, 2));
        }
    }

    const get3CharCountry = (country) => {
        const ndx = countries.findIndex(item=>item['Alpha-2 code'] === country);
        if (ndx > -1) {
            return countries[ndx]['Alpha-3 code'];
        }
        return null;
    }

    // const setCountryFlagIcon = (country) => {
    //     const ndx = countries.findIndex(item=>item['Alpha-3 code'] === country);
    //     if (ndx > -1) {
    //         const code = countries[ndx]['Alpha-2 code'];
    //         setCountryFlag(code);
    //     }
    // }

    useEffect(()=>{
        if (currentCompany.Country) {
            //alert('country changed ' + currentCompany.Country);
            setSelectedCountry(currentCompany.Country);
            if (!currentCompany.Country || ['USA', 'CAN', 'MEX'].includes(currentCompany.Country)) {
                if (currentCompany.Country === 'USA') {
                    setCountryFlag(require("../../assets/img/flags/US.png"));
                    setStateList(getUsStates());
                }
                else if (currentCompany.Country === 'CAN') {
                    setCountryFlag(require("../../assets/img/flags/CA.png"));
                    setStateList(getCanadianStates());
                }
                else if (currentCompany.Country === 'MEX') {
                    setCountryFlag(require("../../assets/img/flags/MX.png"));
                    setStateList(getMexicanStates());
                }
            } else {
                setCountryFlag('');
            }
            //setCountryFlagIcon(currentCompany.Country);
        }
    }, [currentCompany.Country]);

    //const setIsOpen = setFn => setFn(prev=>!prev);
    const initDropdownOpen = {
        CRMStatus: false,
        AIStatus: false,
        ProspectStage: false,
        CRMSyncToggle: false,
    }
    const [isDropdownOpen, setIsDropdownOpen] = useState(initDropdownOpen);
    const setIsOpenMode = (mode, val) => {
        setIsDropdownOpen(prev=>({...prev, [mode]: val}))
    }

    return (
        <div style={{height: 500}} className="text-center p-0 m-0">
            <Card className="border p-0 m-0 mt-0 pt-0" style={{height: 500}}>
                <CardBody className="mt-0 pt-0 px-1">
                    <Row className="m-0 p-0 mb-0 pb-0 rounded-soft-top pl-0 pr-2 py-0 pt-0 mt-0">
                    <Col className="text-center ml-0 mr-1 p-1 pt-2 pr-0 pl-0 mb-0 pb-0">
                            <GenericDropDown 
                                mouseover={false}
                                mode={'CRMStatus'}
                                isOpen={isDropdownOpen.CRMStatus}
                                setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                            >
                                <CRMStatusDropDown  
                                    origin={'Modal'}
                                    mode={'CRMStatus'}
                                    currentCompany={currentCompany}
                                    //setRowData={setCurrentCompany}
                                    setMessage={setMessage}
                                    isOpen={isDropdownOpen.CRMStatus}
                                    setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                                    handleToggle={()=>setIsOpenMode('CRMStatus', false)}
                                    setUpdateObject={setUpdateObject}
                                    updateCompany={updateCompany}
                                    setChangeMade={setChangeMade}
                                />
                            </GenericDropDown>
                            {/* ) : null } */}
                        </Col>
                        <Col className="text-center ml-0 mr-1 p-1 pt-2 pr-0 pl-0 mb-0 pb-0">
                            {currentCompany.Label ? (
                            <GenericDropDown 
                                mouseover={false}
                                mode={'AIStatus'}
                                isOpen={isDropdownOpen.AIStatus}
                                setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                            >
                                <StatusDropDown  
                                    origin={'Modal'}
                                    mode={'AIStatus'}
                                    currentCompany={currentCompany}
                                    //setRowData={setCurrentCompany}
                                    setMessage={setMessage}
                                    isOpen={isDropdownOpen.CRMStatus}
                                    setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                                    handleToggle={()=>setIsOpenMode('AIStatus', false)}
                                    setUpdateObject={setUpdateObject}
                                    updateCompany={updateCompany}
                                    setChangeMade={setChangeMade}
                                />
                            </GenericDropDown>
                            ) : null }
                        </Col>
                        <Col className="text-center ml-0 mr-1 p-1 pt-2 pr-0 pl-0 mb-0 pb-0">
                            {currentCompany.ProspectStage ? (
                            <GenericDropDown 
                                mouseover={false}
                                mode={'ProspectStage'}
                                isOpen={isDropdownOpen.ProspectStage}
                                setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                            >
                                <ProspectStageDropDown  
                                    origin={'Modal'}
                                    mode={'ProspectStage'}
                                    currentCompany={currentCompany}
                                    //setRowData={setCurrentCompany}
                                    setMessage={setMessage}
                                    isOpen={isDropdownOpen.ProspectStage}
                                    setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                                    handleToggle={()=>setIsOpenMode('ProspectStage', false)}
                                    setUpdateObject={setUpdateObject}
                                    updateCompany={updateCompany}
                                    setChangeMade={setChangeMade}
                                />
                            </GenericDropDown>
                        ) : null }
                        </Col>
                        <Col className="text-center ml-0 mr-1 p-1 pt-2 pr-0 pl-0 mb-0 pb-0">
                            {org && org.crmActive ?
                                <GenericDropDown 
                                    mouseover={false}
                                    mode={'CRMSyncToggle'}
                                    isOpen={isDropdownOpen.CRMSyncToggle}
                                    setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                                >
                                    <CRMSyncDropDown  
                                        origin={'Modal'}
                                        mode={'CRMSyncToggle'}
                                        currentCompany={currentCompany}
                                        //setRowData={setCurrentCompany}
                                        setMessage={setMessage}
                                        isOpen={isDropdownOpen.CRMSyncToggle}
                                        setIsOpen={(mode, val)=>setIsOpenMode(mode, val)}
                                        handleToggle={()=>setIsOpenMode('CRMSyncToggle', false)}
                                        setUpdateObject={setUpdateObject}
                                        updateCompany={updateCompany}
                                        setChangeMade={setChangeMade}
                                    />
                                </GenericDropDown>
                            : null}
                        </Col>
                    </Row>

                    {/* const EditFieldRow = ({cols=2, dbfield, label, placeholderText, fieldwidth="15"}) => { */}

                    <div style={{height: 380, overflowX: 'hidden'}} className="px-1">
                    <EditFieldRow
                        key={'co_name'}
                        numCols={1}
                        dbfield="Name"
                        label="Company Name"
                        placeholderText="Enter Company Name"
                        fieldwidth="80"
                    />
                    
                    <EditFieldRow
                        key={'co_address'}
                        numCols={1}
                        dbfield="Address"
                        label="Address"
                        placeholderText="Enter Address"
                        fieldwidth="80"
                    />

                    <Row>
                        <Col className="px-3 py-0 my-0">
                            <EditFieldRow
                                key={'co_city'}
                                numCols={2}
                                dbfield="City"
                                label="City"
                                placeholderText="Enter City"
                                fieldwidth="15"
                            />                    
                            <EditFieldRow
                                key={'co_state'}
                                numCols={2}
                                dbfield="State"
                                label="State"
                                placeholderText="Enter State"
                                fieldwidth="15"
                                type="select"
                                selectData={stateList}
                                selectDisplay={'Name'}
                                selectValue={'Code'}
                                onChangeHook={async (value)=>await validateState(value)}
                                renderOption={(item, i)=>{
                                    return (<option key={`opt_state_${item['Code']}`} value={item['Code']}>{item['Name']}</option>);
                                }}
                            />
                            <EditFieldRow
                                key={'co_zipcode'}
                                numCols={2}
                                dbfield="Zipcode"
                                label="Postal/Zip"
                                placeholderText="Enter Postal/Zip"
                                fieldwidth="15"
                                onChangeHook={async (value)=>await validatePostal(value)}
                            />
                            <EditFieldRow
                                key={'co_phone'}
                                numCols={2}
                                dbfield="Phone"
                                label="Main Phone"
                                placeholderText="Enter Main Phone"
                                fieldwidth="15"
                                onBlurHook={(value)=>validatePhone(value, "phone")}
                            />
                            <EditFieldRow
                                key={'co_country'}
                                numCols={2}
                                dbfield="Country"
                                label="Country"
                                placeholderText="Enter Country"
                                fieldwidth="15"
                                displayIcon={countryFlag}
                                type="select"
                                selectData={countries}
                                selectDisplay={'Alpha-3 code'}
                                selectValue={'Country'}
                                onBlurHook={(value)=>validateCountry(value)}
                                renderOption={(item, i)=>{
                                    return (<option key={`opt_cntry_${item['Alpha-3 code']}`} value={item['Alpha-3 code']}>{item['Country']}</option>);
                                }}
                            />
                        </Col>
                        <Col>
                            <EditFieldRow
                                key={'co_founded'}
                                numCols={2}
                                dbfield="Founded"
                                label="Founded"
                                placeholderText="Enter Year Founded"
                                fieldwidth="15"
                                type="number"
                                onBlurHook={(value)=>validateYearFounded(value)}
                            />                    

                            <EditFieldRow
                                key={'co_emps'}
                                numCols={2}
                                dbfield="Employees"
                                label="Employees"
                                placeholderText="Enter Employees"
                                fieldwidth="15"
                                type="number"
                                onBlurHook={(value)=>removeDecimals(value)}
                            />                    
                            <EditFieldRow
                                key={'co_revenue'}
                                numCols={2}
                                dbfield="Revenue"
                                label="Revenue"
                                placeholderText="Enter Revenue"
                                fieldwidth="15"
                                type="text"
                                displayType="decimal"
                                fixed={0}
                                commas={true}
                                //onBlurHook={(value)=>removeDecimals(value)}
                            />                    
                            <EditFieldRow
                                key={'co_source'}
                                numCols={2}
                                dbfield="Source"
                                label="Lead Source"
                                placeholderText="Enter Lead Source"
                                fieldwidth="15"
                                type="select"
                                selectData={[...leadSources]}
                            />                    
                            <EditFieldRow
                                key={'co_naics'}
                                numCols={2}
                                dbfield="NAICS"
                                label="NAICS"
                                placeholderText="Enter NAICS Code"
                                fieldwidth="15"
                                type="select"
                                selectData={naicsCodes}
                                selectDisplay={'display'}
                                selectValue={'value'}
                                renderOption={(item, i)=>{
                                    //return (<option value={item.value}>{item.value} - {item.display}</option>);
                                    return (<option key={`opt_naics_${item.value}`} value={item.value}>{item.display}</option>);
                                }}
                            />                    
                </Col>
                </Row>
                <EditFieldRow
                    key={'co_industry'}
                    numCols={1}
                    dbfield="Industry"
                    label="Industry"
                    placeholderText="Enter Industry"
                    fieldwidth="80"
                />      
                <EditFieldRow
                    key={'co_website'}
                    numCols={1}
                    dbfield="Website"
                    label="Website"
                    placeholderText="Enter Website"
                    fieldwidth="80"
                    weblink={true}
                    onChangeHook={(value)=>processWSChange(value)}
                />      
                <EditFieldRow
                    key={'co_desc'}
                    numCols={1}
                    dbfield="Description"
                    label="Description"
                    placeholderText="Enter Description"
                    fieldwidth="80"
                    type="textarea"
                    rows="3" cols="61" 
                />      
                <EditFieldRow
                    key={'co_linkedin'}
                    numCols={1}
                    dbfield="LinkedInUrl"
                    label="LinkedIn"
                    placeholderText="Enter Linked In URL"
                    fieldwidth="80"
                    weblink={true}
                    weblinkAsIs={true}
                />      
                <Row>
                    <Col>
                        <EditFieldRow
                            key={'co_fax'}
                            numCols={2}
                            dbfield="Fax"
                            label="Fax Number"
                            placeholderText="Enter Fax Number"
                            fieldwidth="15"
                            onBlurHook={(value)=>validatePhone(value, "fax")}
                            />      
                        <EditFieldRow
                            key={'co_duns'}
                            numCols={2}
                            dbfield="DUNSNumber"
                            label="DUNS Number"
                            placeholderText="Enter Duns Number"
                            fieldwidth="15"
                        />      
                        <EditFieldRow
                            key={'co_sic'}
                            numCols={2}
                            dbfield="SIC"
                            label="SIC Code"
                            placeholderText="Enter SIC Code"
                            fieldwidth="15"
                        />      
                        <EditFieldRow
                            key={'co_risk'}
                            numCols={2}
                            dbfield="RiskRating"
                            label="Risk Rating"
                            placeholderText="Select Risk Rating"
                            fieldwidth="15"
                        />      
                        <EditFieldRow
                            key={'co_conf'}
                            numCols={2}
                            dbfield="Confidence"
                            label="Confidence"
                            placeholderText="Enter Confidence"
                            fieldwidth="15"
                            readonly={true}
                            displayType="decimal"
                            fixed={4}
                        />      
                      </Col>                   
                      <Col>
                        <EditFieldRow
                                key={'co_cdate'}
                                numCols={2}
                                dbfield="CreateDate"
                                label="Create Date"
                                placeholderText=""
                                fieldwidth="15"
                                readonly={true}
                                displayType="date"
                        />      
                        <EditFieldRow
                                key={'co_idate'}
                                numCols={2}
                                dbfield="ImportedDate"
                                label="Import Date"
                                placeholderText=""
                                fieldwidth="15"
                                readonly={true}
                                displayType="date"
                        />      
                        <EditFieldRow
                                key={'co_ailsource'}
                                numCols={2}
                                dbfield="aiLeadSource"
                                label="aiLead Source"
                                placeholderText=""
                                fieldwidth="15"
                                readonly={true}
                        />      
                        <EditFieldRow
                                key={'co_enriched'}
                                numCols={2}
                                dbfield="Enriched"
                                label="Enriched"
                                placeholderText=""
                                fieldwidth="15"
                                readonly={true}
                        />      
                        <EditFieldRow
                                key={'co_ready'}
                                numCols={2}
                                dbfield="Ready"
                                label="Ready For Active"
                                placeholderText=""
                                fieldwidth="15"
                                readonly={true}
                        />      
                      </Col>
                    </Row>
                </div>
                </CardBody>
            </Card>
        </div>
    )

} //end functional component

export default CompanyPane;