import React, {useState, useEffect, useMemo, useContext} from 'react';
import {Button, Row, Col, DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import {getIconColorTable, secondaryColor, syncAction }  //, dangerColor, primaryColor, warningColor, infoColor, successColor } 
    from '../../common/tableFormats';
//import {patchCompany, syncFromDB} from '../../common/ApiFunctions';
import '../../assets/css/cursorPointer.css';
    
const CRMSyncDropDown = ({origin, currentCompany, mode, setIsOpen, isOpen, handleToggle, setChangeMade, setRowData, setMessage}) => {
    const {org, //orgId, currentUser, setOrgAfterLogin, env
    } = useAuth();
    const { api,
        addChangeToPatch, setSingleField, //        patchArray
    } = useContext(CompanyContext);

    //const crmName = 'OnePageCRM';  //??? this will need to change for multi-tenant
    const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);

    const [company, setCompany] = useState(currentCompany.Name);
    const [companyId, setCompanyId] = useState(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);
    const [CRMSync, setCRMSync] = useState({})

    useEffect(()=>{
        if (currentCompany) {
            if (currentCompany.CRMSync)
                setCRMSync({...currentCompany.CRMSync});
            else
                setCRMSync({
                    syncToAILead: "No",
                    existsInCRM: "No",
                    updateDate: new Date(),
                    handleConflict: "mostRecentWins"
            });
            setCompany(currentCompany.Name);
            setCompanyId(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);
        }
    }, [currentCompany]);

//    const editMode = false;


//    const [enableTooltipOpen, setEnableTooltipOpen] = useState(false);
//    const [disableTooltipOpen, setDisableTooltipOpen] = useState(false);

    const buildUpdateDoc = newStatus => {
        //if (origin === 'Modal') setRowData({...currentCompany, CRMSync: {...CRMSync, syncToAILead:newStatus}});
        return {
            ...currentCompany.CRMSync, updateDate: new Date(), syncToAILead:newStatus
        }
    }

    return (
        <UncontrolledDropdown className="d-inline">
            <DropdownToggle
                tag="span"
                key={`crmsync_dd_${companyId}`}
                aria-haspopup={true}
                data-toggle="dropdown"
                id="CRMSyncDropdown"
                className={'p-0 m-0'}
                style={{valign: 'top', border: 1, 
                    //backgroundColor: 'transparent'
                }}
            >{origin === "Modal" 
                ? 
                <div className="p-2 pb-0 mb-0 card border" 
                style={{width: 138,  
                    backgroundColor: 'transparent', 
                    //zIndex: 10000, 
                    "&:hover": {backgroundColor: 'transparent'}}}
            >
                    <h6 className="ml-1 mr-1 mb-0 pb-0">
                        <div className="pb-2">CRM Sync</div>
                            <FontAwesomeIcon 
                                id={`sync`} 
                                icon={CRMSync && CRMSync.syncToAILead === "Yes" ? iconColorTable.sync.icon : 'ban'} 
                                transform="grow-6" 
                                style={{color:CRMSync && CRMSync.syncToAILead === "Yes" ? iconColorTable.sync.color : secondaryColor}} 
                            />            
                            <div className="text-center mt-1 pt-2"
                                style={{
                                    whiteSpace: 'nowrap',
                                    fontSize: '.85rem',
                                }}
                            >
                                {CRMSync && CRMSync.syncToAILead === "Yes" ? 'Disable' : 'Enable'}
                            </div>
                    </h6> 
                </div>
                :   <FontAwesomeIcon key={`tableIcon_${companyId}`} id={`crmSync_${companyId}`} icon={CRMSync && CRMSync.syncToAILead === "Yes" ? iconColorTable.sync.icon : 'ban'} transform="grow-2" 
                        className="hover-pointer ml-2 mr-2" style={{color:CRMSync && CRMSync.syncToAILead === "Yes" ? iconColorTable.sync.color : secondaryColor}} 
            />           
        }
        </DropdownToggle>
        <DropdownMenu 
            persist
            //left={origin === 'Modal' ? true : false} 
            right={origin !== 'Modal' ? true : false} 
            aria-labelledby="AIStatusDropdown"
            className="mt-2"
        >
            <DropdownItem className="m-0 p-0 pt-2 mt-0 pt-0 bg-white" tag="div">
            <div className={"p-0 pt-1 mt-1 pb-0 mb-0 card"} 
                style={{ minWidth: 350, maxHeight: 250, 
                    // backgroundColor: 'white', 
                    zIndex: 10001, 
                    // "&:hover": {backgroundColor: 'white'}
                }}
                >
                <div className="d-inline card-header p-0 mx-2 px-2" titletag="h6" light="false">
                    <button type="button" className="close d-inline" aria-label="Close" 
                        //onClick={()=>{handleToggle(mode, false)}}
                        >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>{`CRM Sync is ${CRMSync && CRMSync.syncToAILead === "Yes" ? 'Enabled' : 'Disabled'} for: `}</div>
                    <div><strong>{`${company}`}</strong></div>
                </div>

            
            {/* <div className={"p-0 pt-1 mt-1 pb-0 mb-0 card"} 
                style={{ minWidth: 375, 
                    // backgroundColor: 'white', 
                    zIndex: 10001, 
                    // "&:hover": {backgroundColor: 'white'}
                }}
            >
                <div className="d-inline card-header p-0 mx-2 px-2" titletag="h6" light="false">
                    <Button type="button" className="close d-inline" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </Button>
                    <div>{`CRM Sync is ${CRMSync && CRMSync.syncToAILead === "Yes" ? 'Enabled' : 'Disabled'} for: `}</div>
                    <div><strong>{`${company}`}</strong></div>
                </div> */}
                {/* end of card header */}
                <div className="card-body text-center p-0 mx-2 px-2 py-3 mb-0 pb-0">
                    {CRMSync && CRMSync.syncToAILead === "Yes" ?
                        <React.Fragment>
                        <Button id={`crmToggle_${companyId}`}
                            color="warning"
                            className="btn btn-sm m-2"
                            onClick={async (e) => {
                                if (origin === 'Modal') { 
                                    setChangeMade(true);
                                    setSingleField('CRMSync', buildUpdateDoc("No"));
                                    //setUpdateObject(buildUpdateDoc("No")); 
                                }
                                else { 
                                    const msg = `Enable Sync for ${company} Has Been Updated to No`;
                                    const patch = addChangeToPatch('CRMSync', buildUpdateDoc("No"));
                                    await api.patchCompany(companyId, patch);
                                    setRowData('CRMSync', buildUpdateDoc("No"))
                                    setMessage(msg);
                                }
                                // e.stopPropagation();
                                // handleToggle(mode, false);  //now close the dropdown after we are done
                            }  }
                        ><FontAwesomeIcon icon={'ban'} transform="grow-2" 
                            className="ml-2 mr-2" style={{color:'white'}} 
                         />           
                            Disable Sync</Button>

                        </React.Fragment>
                    : <React.Fragment>

                    <Button id={`crmToggle_${companyId}`}
                        color="primary"
                        className="btn btn-sm m-2"
                        onClick={async (e) => {
                            if (origin === 'Modal') { 
                                setChangeMade(true);
                                setSingleField('CRMSync', buildUpdateDoc("Yes"));
                                //setUpdateObject(buildUpdateDoc("Yes")); 
                            }
                            else { 
                                const msg = `Enable Sync for ${company} Has Been Updated to No`;
                                const patch = addChangeToPatch('CRMSync', buildUpdateDoc("Yes"));
                                await api.patchCompany(companyId, patch);
                                setRowData('CRMSync', buildUpdateDoc("Yes"))
                                setMessage(msg);
                                setMessage(`Now Syncing ${currentCompany.Name} to ${'OnePageCRM'}...`);
                                if (org.crmActive) api.syncFromDB(companyId, currentCompany, 'OnePageCRM');
                                setMessage(`Successfully Synced ${currentCompany.Name} to ${'OnePageCRM'} in ${origin}`);
                            }   
                            // e.stopPropagation();
                            // handleToggle(mode, false);  //now close the dropdown after we are done
                        } }
                    ><FontAwesomeIcon icon={iconColorTable.sync.icon} transform="grow-2" 
                        className="ml-2 mr-2" style={{color:'white'}} 
                    />
                        Enable Sync</Button>

                        </React.Fragment>
                }
                <React.Fragment>
                    <button id={`cancel_${companyId}`}
                        color="secondary"
                        className="btn btn-sm m-2"
                        // onClick={async () => {
                        //     setIsOpen(!isOpen);
                        // } }
                    >
                        Cancel</button>
                        {/* <Tooltip
                            placement="top"
                            isOpen={enableTooltipOpen}
                            target={`crmToggle_${companyId}`}
                            toggle={() => setEnableTooltipOpen(!enableTooltipOpen)}
                            >
                            This will turn ON CRM Sync for {company}
                        </Tooltip> */}
                </React.Fragment>
                </div>
            </div></DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default CRMSyncDropDown;