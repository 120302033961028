import React , { Fragment, useEffect, useState, useContext } // , {useMemo} 
from 'react';
//import NotificationAlert from "react-notification-alert";
import {
  Modal, 
  ModalBody, Alert, //Spinner, 
  Row, //Input,  //FormGroup, Label, 
  Col, Card, CardBody,
} from 'reactstrap';
//import { nanoid } from 'nanoid'

import SimpleModalHeader from './SimpleModalHeader';
import SimpleModalButtons from './SimpleModalButtons';
import GenericContent from '../../components/GenericContent';
import FetchErrorDisplay from '../../components/fetchError/FetchErrorDisplay';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {getCRMStatusValueArray, getCountryValueArray, getStateValueArray, syncAction, useNotification} from '../../common/tableFormats';
import {CompanyContext} from '../../context/CompanyContext';
// import { setOriginalNode } from 'typescript';
// import { importsOrigin } from '../../common/tableFormats';

const SimpleModal = ({modal, setModal, id, filteredIds=[], rowData, modalContent, modalType, title, subtitle, titleIcon, 
  iconColor, setMessage, setReadTable, setRefetchCounts}) => {

    const { api, env,
      setMessage: setErrorMessage,
      setErrorLevel,
      tableOrigin,
  } = useContext(CompanyContext);   

//  const {api} = useContext(CompanyContext);        
  const notifyElem = React.useRef(null);
  const isFirstRender = React.useRef(true);
  const [fetchError, setFetchError] = useState(false);
  const [fetchErrorDetail, setFetchErrorDetail] = useState(false);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const toggle = (save) => {
    setModal(prev=>!prev);
  }

  const renderGenericContent = (data, content, mode) => {
    return (
      <GenericContent 
        data={data}
        content={content}
        mode={mode}
      />
    )
  }

    let contentToRender;
    if (modalType === 'moveToActive') {
      contentToRender = renderGenericContent(rowData, modalContent, 'Active');
    } else if (modalType === 'moveToImports') {
      contentToRender = renderGenericContent(rowData, modalContent, 'Imports');
    } else if (modalType === 'moveToDeleted') {
      contentToRender = renderGenericContent(rowData, modalContent, 'Deleted');
    } else if (modalType === 'moveAllToActive') {
      contentToRender = renderGenericContent(rowData, modalContent, 'Move All');
    } else if (modalType === 'remove') {
      contentToRender = modalContent;
    }    

    let subtitleArray = subtitle;
    if (subtitleArray && subtitleArray.search('\n')>0)
      subtitleArray = subtitleArray.split('\n');
    else
      subtitleArray = [subtitleArray];

    return (
    fetchError 
    ? <FetchErrorDisplay 
        fetchError={fetchError}
        fetchErrorDetail={fetchErrorDetail}
      /> 
    // : !isLoaded 
    // ? <span><Spinner color="primary" /> Loading...</span>
    : <Fragment>
      <Modal
        backdrop={"static"}
        isOpen={modal}
        toggle={toggle}
//        className={`modal-lg mt-3 ${className ? className : ''}`}
        className={`modal-lg mt-3 mb-0`}
        contentClassName="border-0"
        modalClassName="theme-modal"
        size="lg"
      >
      <SimpleModalHeader 
          rowData={rowData}
          titleIcon={titleIcon}
          iconColor={iconColor}
          title={title}
          toggle={toggle}
          moveAll={modalType === 'moveAllToActive' ? true : false}
      /> 
      <ModalBody className="p-0">
        <div className="p-1 pb-2 pt-0 mt-3 ml-3 mr-3 pl-2">
          <Row>
            <Col sm="12" className="ml-2 pl-3"><strong>
                {contentToRender}
                </strong>
            </Col>
          </Row>
          <Row>
              <Col sm="12" className="text-right">
                <SimpleModalButtons 
                    id={id}
                    filteredIds={filteredIds}
                    setMessage={setMessage}
                    setReadTable={setReadTable}
                    setRefetchCounts={setRefetchCounts}
                    modalType={modalType}
                    toggle={toggle}
                /> 
              </Col>
          </Row>

        </div>
      </ModalBody>
    </Modal>
    <Alert ref={notifyElem} zIndex={9999} />
    </Fragment>
  );  //end render()

} //end component

export default SimpleModal;