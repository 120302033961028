/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-time.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://pureblue.ai)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Fragment} from "react";
// react plugin used to create charts
// import { Line, Pie } from "react-chartjs-2";
// // reactstrap components
import {
  // Card,
  // CardHeader,
  // CardBody,
  // CardFooter,
  // CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
// import {
//   dashboard24HoursPerformanceChart,
//   dashboardEmailStatisticsChart,
//   dashboardNASDAQChart,
// } from "variables/charts.js";

import ImportsTable from './ImportsTable';

class Imports extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="content">
          <Row>
            <Col md="12">
              <ImportsTable />
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default Imports;
