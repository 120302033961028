import React, {Fragment} from 'react';

const FetchErrorDisplay = ({fetchError, fetchErrorDetail}) => {

    const [errorDetailClass, setErrorDetailClass] = React.useState("collapse")
    const toggleErrorDetails = ()=> {
        setErrorDetailClass(errorDetailClass === "collapse" ? "collapse.show" : "collapse");
    }

    return (
        <Fragment>
            <div  className="main-content w-100">
                {fetchError} 
                {fetchErrorDetail 
                    ? <span className="text-primary underline" onClick={toggleErrorDetails}>
                        <small>{'  '}  [{errorDetailClass === "collapse" ? 'show' : 'hide'} details]</small></span> 
                    : ''}</div> 
            <div className={"w-100 " + errorDetailClass}>
                {fetchErrorDetail ? <pre style={{maxWidth: Math.round(window.innerWidth * .8, 0)}} className="w-100" id="json">{fetchErrorDetail}</pre> : 'no details available'}
            </div>
        </Fragment>
    );  //end return (render)
} //end App()

export default FetchErrorDisplay;
