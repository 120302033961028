import React, { useMemo, useContext, useEffect } from 'react';
import {Row, Col, Card, CardBody, CardTitle, CardFooter} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {CompanyContext} from '../../context/CompanyContext';
import {getIconColorTable, secondaryColor} from '../../common/tableFormats';

const shadowClass = 'shadow-lg';

const FilterCards = ({origin, filterCardArray })=> { 

    const {
        activeFilterCard, setActiveFilterCard,
        setRefetchCounts,
        currentActiveFilter,
        currentImportsFilter,
        setStartTime,
    } = useContext(CompanyContext);

    useEffect(()=>{
        //alert(currentActiveFilter)
        if (origin === 'Active') {
            setActiveFilterCard(currentActiveFilter);
            const newStart = new Date();
            setStartTime(newStart);
            console.log(JSON.stringify(['render complete', currentActiveFilter, newStart]));
            }
        else setActiveFilterCard(currentImportsFilter);
        //alert(JSON.stringify(['setting', currentActiveFilter, currentImportsFilter], null, 2));
    }, [currentActiveFilter, currentImportsFilter]);

    const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);
    const history = useHistory();

    return (
    <React.Fragment>
        <Row id="FilterCards" className="mt-0 pt-0">
            {filterCardArray.map(card=> { 
                let key = card.iconColorTableKey;
                let color = iconColorTable[key].color;
                let icon = iconColorTable[key].icon;
                // let color, icon;
                return (
                <Col lg="2" md="4" sm="6" key={'bigcol_' + card.Label}>
                    <Card className={"hover-pointer card-stats border p-2 mt-0 " + (activeFilterCard === card.Label ? shadowClass : '')}
                        key={'card_' + card.Label}
                        id={card}
                        onClick={()=>{
                            setRefetchCounts(true);
                            history.push(`/admin/${origin}/${card.Label}`);
                        }}
                        data-html={true} data-toggle="popover" title={card.mouseOverText}
                    >
                        <CardBody className="pt-0 mt-0">
                            <Row className="my-0">
                                <Col sm="12">
                                    <div key={'card.Name.' + card.Label} className={`card-category text-black text-center`}>{card.Name}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="3" xs="5">
                                    <div className={`py-0 icon-big text-center icon-${color}`}>
                                    <FontAwesomeIcon 
                                        key={'icon_' + card.Label}
                                        icon={icon} transform="grow-1" 
                                        className="ml-0 mr-3" style={{color:color, opacity: 0.7}} 
                                    /> 
                                    </div>
                                </Col>
                                <Col sm="9" xs="7">
                                    <div className="numbers pt-3" key={'numbers_' + card.Label}>
                                            <CardTitle tag="p">{card.Number}</CardTitle>
                                        <p />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>                    
                </Col>
            ) }
        )}
    </Row>
    </React.Fragment>
)}; //end FilterCards()

export default FilterCards;