import React, {useMemo, useContext, useState} from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CompanyContext} from '../../context/CompanyContext';
import {getIconColorTable} from '../../common/tableFormats';

const TableDropDownActions = ({classFromParent, origin, row, setModalType, 
    setModalTitle, subTitle, setSubtitle, setModalContent, setButtonsType, setIconColor, setSimpleRowData, 
    setShowActionModal, setShowSimpleModal, setTitleIcon, _id, setId }) => { 

  const { actives, imports,
  } = useContext(CompanyContext);

  let rowData = row;
  
  const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);

  const setupModal = (type)=> {
    //let's set the _id and look up the data in filtered rows.
    setId(_id);
    const arr = origin === 'Active' ? [...actives] : [...imports];
    const ndx = arr.findIndex(item=>item._id.toString() === _id);
    if (ndx === -1) throw new Error(`Cannot find _id ${_id}`);
    rowData = arr[ndx];

    setSubtitle(subTitle)
    setModalType(type);
    setTitleIcon(iconColorTable[type].icon);
    setIconColor(iconColorTable[type].color);

    if (type === 'moveToActive') {
      setSimpleRowData(rowData);
      setModalTitle(`Move ${rowData.Name} To Active`);
      setModalContent(`Are you sure you want to move ${rowData.Name} to Active?`);
      setButtonsType('No:Yes');
      setShowSimpleModal(true);
    }
    else if (type === 'moveToImports') {
      setSimpleRowData(rowData);
      setModalTitle(`Move ${rowData.Name} To Imports`);
      setModalContent(`Are you sure you want to move ${rowData.Name} to Imports?`);
      setButtonsType('No:Yes');
      setShowSimpleModal(true);
    }    
    else if (type === 'moveToDeleted') {
      setSimpleRowData(rowData);
      setModalTitle(`Remove ${rowData.Name} From PureBlue`);
      setModalContent(`Are you sure you want to remove ${rowData.Name}?`);
      setButtonsType('No:Yes');
      setShowSimpleModal(true);
    }
    else if (type === 'notes') {
      setModalTitle('View/Edit Notes');
      setModalContent('Comment Goes Here');
      setButtonsType('Cancel:Save');
      setShowActionModal(true);
      // setRecordId(recordId);
    }
    else if (type === 'history') {
      setModalTitle('View History');
      setModalContent('Record History Goes Here');
      setButtonsType('Cancel:OK');
      setShowActionModal(true);
      // setRecordId(recordId);
    }
    else if (type === 'sync') {
      setModalTitle('CRM Sync Properties');
      setModalContent('Index File Info Goes Here');
      setButtonsType('Cancel:OK');
      setShowActionModal(true);
      // setRecordId(recordId);
    }
  }

  return (
  <UncontrolledDropdown className={"d-inline " + classFromParent}>
    <DropdownToggle tag="span" color="link" size="sm" className="text-600 btn-reveal">
      <FontAwesomeIcon icon="bars" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right={true} className="border py-2">
      <DropdownItem onClick={()=>setupModal('sync')}>CRM Sync Properties</DropdownItem>
      <DropdownItem onClick={()=>setupModal('history')}>View History</DropdownItem>
      <DropdownItem onClick={()=>setupModal('notes')}>View/Edit Notes</DropdownItem>
      <DropdownItem divider />
      <DropdownItem className="text-warning"
        onClick={()=>{
          setupModal(origin === 'Active' ? 'moveToImports' : 'moveToActive')
        }}
      >Move to {origin === 'Active' ? 'Imports' : 'Active'}
      </DropdownItem>
      <DropdownItem onClick={()=>setupModal('moveToDeleted')} className="text-danger">Remove From PureBlue</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
)};

export default TableDropDownActions;