import React, { useContext, useState, useEffect } from 'react';
import { auth } from './firebase';
import axios from 'axios';
//import {useHistory} from 'react-router-dom';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    //const history = useHistory();
    const [org, setOrg] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [uid, setUid] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshOrg, setRefreshOrg] = useState(false);
    //const [thisIsLogin, setThisIsLogin] = useState(true);
    const [env, setEnv] = useState();
    //const [uidOrgs, setUidOrgs] = useState(null);

    //first let's fetch all of the uid->org pairs and populate a dictionary
    const fullUrl = window.location.href;
    const parsed = new URL(fullUrl);
    const urlDomain = encodeURIComponent(parsed.host.toLowerCase());

    const axiosGet = async (url)=> {
        try {
            const headers = {
              'Accept': 'application/json',
            }
            return await axios.get(url, {headers,
            validateStatus: function (status) {
                return status >= 200 && status < 300; // default
            } 
        });
      } catch(err) {
        console.error(err);
        return {error: err.toString()}
      }
    }
    
    // const setUpOrg = async(user) => {
    //     let orgUrl = `https://api.pureblue.ai/aiLead/api/org/${urlDomain}/uidorgs`;
    //     if (fullUrl.indexOf('localhost') > 0)
    //         orgUrl = `http://localhost:5001/aiLead/api/org/${urlDomain}/uidorgs`;
    //     const uo = await axiosGet(orgUrl);
    //     if (uo && uo.data) {
    //         const orgData = [...uo.data.results.data];
    //         const uids = {}
    //         orgData.forEach(rec=>{
    //             const uidrec = {uid: rec.uid, orgId: rec.orgId, name: rec.name};
    //             uids[rec.uid] = uidrec;
    //         });
    //         setUidOrgs({...uids});
    //         //setOrgId(uids[user.uid].orgId);
    //         //setEnv(uids[user.uid].env);
    //     }
    // }
    // if (!uidOrgs && currentUser) setUpOrg(currentUser);

    const setOrgAfterLogin = async (uid)=>{
        const fetchOrg = async ()=>{
            const res = await getOrg(window.location.href, uid);
            return res;
        }
        //alert(JSON.stringify(['setOrgAfterLogin', uid]))
        if (uid) {
            const tempOrg = await fetchOrg(uid);
            if (tempOrg && tempOrg.data) {
                const orgData = {...tempOrg.data.results.data};
                setOrg(orgData);
                setOrgId(orgData._id.toString());
                setEnv(orgData.env);
                //alert('org set ' + orgData.env)
            }
        }
    }
    
    const getOrg = async (fullUrl, uid) => {
        const parsed = new URL(fullUrl);
        const urlDomain = encodeURIComponent(parsed.host.toLowerCase());
        let orgUrl = `https://api.pureblue.ai/aiLead/api/org/${urlDomain}/uid/${uid}`;
        if (fullUrl.indexOf('localhost') > 0)
            orgUrl = `http://localhost:5001/aiLead/api/org/${urlDomain}/uid/${uid}`;
        return await axiosGet(orgUrl);
    }

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }
    
    function login(email, password) {
        //alert(JSON.stringify(auth.settings));
        setLoading(true);
        return auth.signInWithEmailAndPassword(email, password);
    }
    
    function logout() {
        return auth.signOut();
    }

    function resetPassword(email=null) {
        return auth.sendPasswordResetEmail(email ? email : currentUser.email);
    }
    
    // const changeCurrentUser = async (user) => {
    //     //alert(JSON.stringify(['changeCurrentUser', uidOrgs]));
    //     if (user && user.uid && uidOrgs && user.uid in uidOrgs) {
    //         //alert('setting up user ' + user.id);
    //         setOrgId(uidOrgs[user.uid].orgId);
    //         setEnv(uidOrgs[user.uid].env);
    //         setCurrentUser(user);
    //         setUid(user.uid);
    //         setLoading(false);
    //     }
    // }  
    
    useEffect(()=> {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            setCurrentUser(user);
            setUid(user ? user.uid : null);
            setLoading(false);
            if (user && user.uid) await setOrgAfterLogin(user.uid);
        });
        return unsubscribe;
    },[]);

    useEffect(()=> {
        async function fetchOrg() {
         await getOrg(window.location.href, currentUser.uid);
        }
        if (currentUser) fetchOrg();
        //need to fetch this organization's org record
    },[refreshOrg]);

    const value = {
        currentUser, 
        uid,
        env,
        org,
        orgId,
        setRefreshOrg,
        signup,
        login,
        logout,
        resetPassword,
        setOrgAfterLogin,
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}