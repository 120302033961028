import React, {useState, useEffect, useAsyncDebounce} from 'react';
import { Row, Col, InputGroup, Input, Form } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import '../assets/css/opacity.css';
//import '../assets/css/materialUI.css';
import matchSorter from 'match-sorter';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import url from 'url';
import InfoDropDown from '../components/dropdowns/InfoDropDown';

// import {getBaseUrl} from './ApiFunctions';
// import axios from 'axios';

//export const env = "https://api.pureblue.ai";
export const primaryColor = '#032b43';
export const secondaryColor = 'lightGray';
export const successColor = '#136f63';
export const infoColor = '#3f88c5';
export const warningColor = '#ffba08';
export const dangerColor = '#D00000';

export const activeOrigin = 'Active';
export const importsOrigin = 'Imports';

export const getIconColorTable = {
    'contacts': {
        icon: 'user',
        color: infoColor,
        mouseover: `Click to Access Contacts`
    },
    'edit': {
        icon: 'edit',
        color: 'black',
        mouseover: `Click to Edit`
    },
    'sync': {
        icon: 'sync-alt',
        color: primaryColor,
        mouseover: `Click to Toggle Sync On/Off`
    },
    'notes': {
        icon: 'comment',
        color: infoColor,
        mouseover: `Click to Toggle Sync On/Off`
    },
    'history': {
        icon: 'clipboard-list',
        color: 'black',
        mouseover: `Click to View History`
    },
    'moveToDeleted': {
        icon: 'trash-alt',
        color: 'danger',
        mouseover: `Click to Remove`
    },
    'moveToActive': {
        icon: 'atom',
        color: 'warning',
        mouseover: `Click to Move Record To Active`
    },
    'moveToImports': {
        icon: 'cloud-upload-alt',
        color: 'warning',
        mouseover: `Click to Move Record To Imports`
    },
    'thumbsUp': {
        icon: 'thumbs-up',
        color: primaryColor,
        label: 'ThumbsUp',
        display: 'Thumbs Up'
    },
    'thumbsDown': {
        icon: 'thumbs-down',
        color: primaryColor,
        label: 'ThumbsDown',
        display: 'Thumbs Down' //'#d00000'
    },
    'recommend': {
        icon: 'exclamation',
        color: primaryColor,
        label: 'Recommend',
        display: 'Recommended'            //crimson
    },
    'aiq': {
        icon: 'question',
        color: primaryColor,
        label: 'AIQueue',
        display: 'AI Queue'
    },
    'other': {
        //icon: 'ellipsis-h',
        icon: 'plus',
        color: primaryColor,
        label: 'Other',
        display: 'Other'
    },
    'inactive': {
      //icon: 'ellipsis-h',
      icon: 'store-alt-slash',
      color: primaryColor,
      label: 'Inactive',
      display: 'Inactive'
    },
    'recent': {
        icon: 'clock',
        color: infoColor,
        label: 'Recent',
        display: 'Recently Added'
    },
    'ready': {
        icon: 'check',
        color: infoColor,
        label: 'Ready',
        display: 'Ready To Move'
    },
    'needsInfo': {
        icon: 'ban',
        color: infoColor,
        label: 'NeedsInfo',
        display: 'Needs Info'
    },
    'hasContacts': {
        icon: 'users',
        color: infoColor,
        label: 'Other',
        display: 'Has Contacts'
    },
    'noContacts': {
        icon: 'user-slash',
        color: infoColor,
        label: 'Other',
        display: 'No Contacts'
    },
    'all': {
        icon: 'globe-americas',
        color: primaryColor,
        label: undefined,
        display: 'All'
    },
}; //end iconColorTable

export const getActiveFilterCardArray = [
    {
        Name: 'Recommended',
        Label: 'Recommend',
        // Number: nr,
        iconColorTableKey: 'recommend',
        mouseOverText: 'These are recommended by the AI'
    },
    {
        Name: 'Thumbs Up',
        Label: 'ThumbsUp',
        // Number: ntu,
        iconColorTableKey: 'thumbsUp',
        mouseOverText: 'These are companies you like -- Customers, Leads, or Near Misses -- you want the AI to find more like these'
    },
    {
        Name: 'Thumbs Down',
        Label: 'ThumbsDown',
        // Number: ntd,
        iconColorTableKey: 'thumbsDown',
        mouseOverText: "These are companies you don't like -- you want the AI to avoid companies like these"
    },
    {   
        Name: 'AI Queue',
        Label: 'AIQueue',
        // Number: naiq,
        iconColorTableKey: 'aiq',
        mouseOverText: "These are companies you don't know about -- you want the AI to process these as input to see if they are recommended"
    },
    {   Name: 'Other',
        Label: 'Other',
        // Number: no,
        iconColorTableKey: 'other',
        mouseOverText: "These are companies you don't want the AI to consider"
    },
    {
        Name: 'All Companies',
        Label: 'All',
        // Number: master.length,
        iconColorTableKey: 'all',
        mouseOverText: "All Active companies in your PureBlue database"
    },
];

export const getImportsFilterCardArray = [
    {
        Name: 'Recently Added',
        Label: 'Recent',
        // Number: nrecent,
        FilterColumn: 'Recent', 
        FilterValue: 'Yes',
        iconColorTableKey: 'recent',
        mouseOverText: 'These companies/contacts have been added recently'
    },
    {   Name: 'Ready for Active',
        Label: 'Ready',
        FilterColumn: 'Ready', 
        FilterValue: 'Yes',
        // Number: nready,
        iconColorTableKey: 'ready',
        mouseOverText: "These companies are ready to move to Active"
    },
    {
        Name: 'Need More Info',
        Label: 'NeedsInfo',
        FilterColumn: 'Ready', //ReadyToMove
        FilterValue: 'No',
        // Number: nneeds,
        iconColorTableKey: 'needsInfo',
        mouseOverText: 'These companies need more info'
    },
    {
        Name: 'Has Contacts',
        Label: 'HasContacts',
        // Number: ncontacts,
        FilterColumn: 'Contacts', //ReadyToMove
        FilterValue: 'Yes',
        iconColorTableKey: 'hasContacts',
        mouseOverText: "These are companies that have at least one or more contacts"
    },
    {   
        Name: 'No Contacts',
        Label: 'NoContacts',
        // Number: nnocontacts,
        FilterColumn: 'Contacts', //ReadyToMove
        FilterValue: 'No',
        iconColorTableKey: 'noContacts',
        mouseOverText: "These are companies with no contacts"
    },
    {
        Name: 'All Companies',
        Label: 'All',
        // Number: staged.length,
        iconColorTableKey: 'all',
        mouseOverText: "All Import/Staged companies in your PureBlue database"
    },
];

export const OPCImg = require("../assets/img/opcLogo.png");

export const useFilterCards = ((urlFilter, visibleColumns, type, filterCardArray, counts, coll, activeFilterCard, setActiveFilterCard, 
  filters, setFilters)=> {

    useEffect(()=>{             // this replaces selectContacts()
      const complexFilterCards = () => {
        if (filters && filters.length) {
          filters.length=0;
          setFilters([]);
        }
        const accessorIndex = filterCardArray.findIndex(elem=>elem.Label === activeFilterCard);
        if (accessorIndex < 0) {
            console.error('Accessor index not found for ' + activeFilterCard);
            alert('Accessor index not found for ' + activeFilterCard);
        } else {
            visibleColumns.forEach(item=> {
              item.setFilter && item.setFilter(null);
            });
            if (activeFilterCard !== 'All') {
              const accessor = filterCardArray[accessorIndex].FilterColumn;
              const filterValue = filterCardArray[accessorIndex].FilterValue;
              const filterIndex = visibleColumns.findIndex(item=>item.Header === accessor);
              //console.dir([accessorIndex, accessor, filterValue, filterIndex]);
              if (filterIndex > -1) {
                  visibleColumns[filterIndex].setFilter(filterValue);
              }
              else console.error('Could not find table column for filter ' + activeFilterCard + ' accessor ');
          }
        }
      } //end complexFilterCards()
      const columnFilterCards = ()=> {
        if (activeFilterCard) {
            //alert('useFilterCards column is firing ' + visibleColumns.length);
            if (filters && filters.length) {
              visibleColumns.forEach(item=> {
                item.setFilter && item.setFilter(null);
              });
            }
            const labelColIndex = visibleColumns.findIndex(elem=>elem.Header === 'Actions');
            //alert(labelColIndex);
            if (labelColIndex > -1) {
              visibleColumns[labelColIndex].setFilter(activeFilterCard === 'All' ? undefined : activeFilterCard);
              //alert('is set filter for vis column ' + labelColIndex + ', ' + JSON.stringify(filters, null, 2));
              setFilters([...filters]);
            }
        } //end if
      }  //end columnFilterCards()
      if (visibleColumns) {
        // if (type==='column') columnFilterCards();
        // else complexFilterCards();
      }
  },[activeFilterCard, visibleColumns]);

  useEffect(()=>{
    if (urlFilter) {
          setActiveFilterCard(urlFilter);
    }
  },[urlFilter]);

  return {filters, setFilters, activeFilterCard, setActiveFilterCard};
});

export const useNotification = ((isFirstRender, notifyElem, position='tc')=> {
  const [notificationMsg, setNotificationMsg] = useState(null);
  
  useEffect(() => {
    const showNotification = (notificationAlertOptions)=> {
      notifyElem.current && notifyElem.current.notificationAlert(notificationAlertOptions);
    }
    if (!isFirstRender.current && notificationMsg) {
      const notificationAlertOptions = {
        place: position,
        message: (
            <div className="opacity-3">
                <div>
                    {`${notificationMsg}`}
                </div>
            </div>
        ),
        type: 'info',
        icon: "nc-icon nc-check-2",
        // animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        // animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied                
        autoDismiss: 7,
    };            
    showNotification(notificationAlertOptions);
    }
  }, [notificationMsg, isFirstRender, notifyElem, position]);

  return {setNotificationMsg}
})

export const setHistory = (historyLine, history) => {
  const newObj = {date: new Date(), desc: historyLine}
  return history ? [{...newObj}, ...history] : [{...newObj}]
}

export const nameCapitalized = name=> name.charAt(0).toUpperCase() + name.slice(1);

export const getLabelInfo = (label, iconColorTable) => {
  const iconColorArray = Object.keys(iconColorTable).map(key=>iconColorTable[key]);
    let ndx = iconColorArray.findIndex(item=>item.label === label);
    if (ndx > -1) return iconColorArray[ndx];
    return null;
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getTotalLocalStorage = ()=> {
  let _lsTotal = 0,_xLen, _x;
  for (_x in localStorage) {
  if (!localStorage.hasOwnProperty(_x)) {
      continue;
  }
  _xLen = ((localStorage[_x].length + _x.length) * 2);
  _lsTotal += _xLen;
  console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
  };
  console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

export const formatObj = obj=>console.log(JSON.stringify(obj, null, '\t'));

export const datetimeFormatter = props => { 
    return(<span>{moment.utc(props.value).local().format('MM-DD-YY hh:mma')}</span>)
}

export const toDateString = (dateIn, yyyy=2, slash=false) => { 
    if (!dateIn) return null;
    let format = (slash === true) ? (yyyy===4 ? 'MM/DD/YYYY hh:mma' : 'MM/DD/YY hh:mma') : (yyyy===4 ? 'MM-DD-YYYY hh:mma' : 'MM-DD-YY hh:mma');
    if (yyyy === 2) return(moment.utc(dateIn).local().format(format));
    else return(moment.utc(dateIn).local().format(format));
}

export const checkMarkDisplay = row => {
  // const rowIndex = row.cell.row.index;
  // const fieldval = row.data[rowIndex].Ready;  
  // if (rowIndex === 0) alert(typeof fieldval);
    if (typeof row.value === 'boolean' && row.value === true) return <div className="text-center"><FontAwesomeIcon icon="check" transform="shrink-2" className='text-center text-primary' /></div>
    if (typeof row.value === 'string' && row.value === 'Yes') return <div className="text-center"><FontAwesomeIcon icon="check" transform="shrink-2" className='text-center text-primary' /></div>
    return <div>&nbsp;</div>
}

export const parseUrl = urlIn => {
    let domain = url.parse(urlIn.toLowerCase());
    //if (!domain.protocol || !urlIn.search(/^http/i) === 0)
    const httpUrl = 'http://' + domain.hostname;
    const displayUrl = domain.hostname;
    return {displayUrl, httpUrl};
}

export const companyFormatter = (row, handleCompanyClick, getDescription)=> {
  if (!row.value) return <div></div>;
  const rowIndex = row.cell.row.index;
  const id = row.data[rowIndex]._id.toString();
//        const description = row.data[rowIndex].Description;
  let companyName = row.value.length <= 30 ? row.value : row.value.substring(0, 30) + '...';
  const cellContent = 
  <span>
       <InfoDropDown 
           type={'description'}
           cellContent={<FontAwesomeIcon icon={'info-circle'} transform="grow-5" className="ml-1" color={primaryColor} />}
           rowData={row.data[rowIndex]}
           getDescription={getDescription}
      />
      &nbsp;&nbsp;
      <span className="text-left text-primary hover-pointer" style={{textDecoration: 'underline', textDecorationColor: primaryColor, fontWeight: 'bold'}}
          onClick={()=>handleCompanyClick(row)}>
          {companyName}
      </span>
  </span>;
  return cellContent;
}

export const stateFormatter = row=> {
  if (!row.value) return <div></div>;
  let companyName = row.value.length <= 30 ? row.value : row.value.substring(0, 28) + '...';
  return (<div className="ml-4 mr-4 text-center">{companyName}</div>)
}


export const formatWebsite = (row, website, company, widthToShow=35, mode='divWithLink', getWebtext) => {
    if (!row.value) return <div></div>;
    const rowIndex = row.cell.row.index;
  
    const myUrl = website && website.search(/^http/i) === 0 ? website : 'https://' + website;
    let {displayUrl, httpUrl} = parseUrl(myUrl);
    if (!displayUrl) displayUrl = '';
    displayUrl = displayUrl.replace('www.', '');
    displayUrl = (displayUrl.length <= widthToShow) ? displayUrl : displayUrl.substring(0, widthToShow) + '...';
    if (mode === 'divWithLink') {
        return (
            displayUrl && httpUrl ?
            // <div className="d-inline text-left" data-html={true} data-toggle="popover" title={company + ' Website Text:\n\n' + webText}>
            <div className="d-inline text-left">
                <a target='_blank' href={httpUrl} alt={displayUrl} rel="noopener noreferrer">
                  <strong className="text-left">{displayUrl ? displayUrl : ''}</strong>
                </a>&nbsp;
                <InfoDropDown 
                    type={'website'}
                    cellContent={<FontAwesomeIcon icon={'comment-alt'} transform="grow-5" className="ml-1" color={primaryColor} />}
                    rowData={row.data[rowIndex]}
                    getWebtext={getWebtext}
                />
            </div>
            : ''
        );
    }
    return displayUrl;

}
export const datetimeFormatter2 = (row) => {
    const inpDate = row.value;
    const date = new Date(inpDate);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    const strTime = hours + ':' + minutes + ampm;
    const formattedDate = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
    return <div className="text-left">{formattedDate}</div>
}

export const LabelFilter = ({
    column: { filterValue, preFilteredRows, setFilter, rows },
    }) => {
//    const count = preFilteredRows ? preFilteredRows.length : 0;
    return (
        <InputGroup className="d-none">
        <Input className="d-none"
            bsSize="sm"
            value={filterValue || ''}
            onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            e.stopPropagation();
            }}
            //placeholder={`Search ${count}`}
            placeholder={``}
            />
        </InputGroup>
    );
}


// Define a default UI for filtering
export const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
    }) => {
//        const count = preFilteredRows.length;
    return (
        // <InputGroup className="">
        <div className="form-field"><div className="control">
          <input className="control"
            size="sm"
            value={filterValue || ''}
            onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
//                alert(e.target.value);
            //filterTagRef.current.addTag({id: 'test', name: e.target.value });
            e.stopPropagation();
            }}
            //placeholder={`Search ${count}`}
            placeholder={``}
            style={{
                fontSize: '.8rem',
                width: '110px'
    //            lineHeight: '90%'
            }}
          /></div></div>
    );
}


// This is a custom filter UI for selecting a unique option from a list
export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id, columnRef },
}) => {
    // Calculate the options for filtering
    // using the preFilteredRows

    const [, setHasFocus] = useState(false);      //hasFocus
    const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
        options.add(row.values[id])
    })
    return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <div className="form-field"><div className="control">
      <select className={`input-sm {hasFocus ? "is-focused" : ""}`}
            // bsSize="sm"
            // type="select"
        ref={columnRef}
        value={filterValue}
        onChange={e => {
            setFilter(e.target.value || undefined);
            // e.preventDefault && e.preventDefault();
            // e.stopPropagation && e.stopPropagation();
        }}
        onFocus={()=>setHasFocus(true)}
        onBlur={()=>setHasFocus(false)}
        style={{
          fontSize: '.8rem',
        }}
    >
        <option value="">All</option>
        {options.map((option, i) => (
        <option key={i} value={option}>
            {option}
        </option>
        ))}
    </select></div></div>
    )
};

// This is a custom filter UI for a date column
export const DateColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
    }) => {
    const [startDate, setStartDate] = useState();

    return (
        <InputGroup>
        <DatePicker className="input input-sm text-muted text-center"
            bsSize="sm"
            selected={startDate} 
            onChange={date => setStartDate(date)} 
            isClearable
            customInput={<Input className="input input-sm" />}
        />
        </InputGroup>
    );
}

// Define a default UI for filtering
export function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
    
    return (
        <Row className="mt-0 mb-0">
            <Col xs={12}><span>
            <Form.Group className="form-inline">
                <Form.Label><strong>
                {'Search: '}</strong>
                </Form.Label>
            <Form.Control type="input"
                className="form-control-sm text-muted ml-2"
                value={value || ""}
                onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                style={{
                width: '91%',
                fontSize: '.875rem',
                // border: '0',
                }}
            />
            </Form.Group></span></Col>
        </Row>
    )
}; //end GlobalFilter()
    
export const calculatePageSizeArray = numPages => {
    const retArr = [];
    console.log('calling calculatePageSizeArray.  numPages = ' + numPages);
    if (numPages >= 10) retArr.push(10); 
    if (numPages >= 25) retArr.push(25); 
    if (numPages >= 50) retArr.push(50); 
    if (numPages >= 100) retArr.push(100); 
    if (numPages > 10 && numPages < 1000) retArr.push(numPages);
    return retArr;
}

export const currencyFormatter = row=> {
    const amount = !isNaN(parseInt(row.value)) ? '$' + parseFloat(row.value/1000).toFixed(0).toLocaleString() : null;
    return (<div className="text-right">
    {amount}</div>)
}

export const alignRight = row => <div className="text-right">{row.value}</div>;

export const alignCenter = row => <div className="text-center">{row.value}</div>;

export const alignLeft = (row, limit=0, pl=0, style={whiteSpace: 'nowrap'}) => {
    let cellValue = row.value;
    if (limit && cellValue && cellValue.toString().length > limit)
        cellValue = cellValue.substring(0, limit) + '...';
    return (<div style={style} className={`text-left pl-${pl}`}>{cellValue}</div>);
}

export const timeDifference = (current, previous, units="minutes") => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  switch (units) {
    case "seconds":
      return Math.round(elapsed/1000);
    case "minutes":
      return Math.round(elapsed/msPerMinute);
    case "hours":
      return Math.round(elapsed/msPerHour);
    case "days":
      return Math.round(elapsed/msPerDay);
    case "months":
      return Math.round(elapsed/msPerMonth);
    case "years":
      return Math.round(elapsed/msPerYear);
    default:
      return -1;
  } //end switch()

  // if (elapsed < msPerMinute) {
  //      return Math.round(elapsed/1000) + ' seconds ago';   
  // }
  // else if (elapsed < msPerHour) {
  //      return Math.round(elapsed/msPerMinute) + ' minutes ago';   
  // }
  // else if (elapsed < msPerDay ) {
  //      return Math.round(elapsed/msPerHour ) + ' hours ago';   
  // }
  // else if (elapsed < msPerMonth) {
  //     return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
  // }
  // else if (elapsed < msPerYear) {
  //     return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
  // }
  // else {
  //     return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
  // }
} //end function timeDifference()

//const actionHeading = row => <span className="border text-center" style={{width:'25px'}}>Actions</span>;

export const getCRMStatusValueArray = ()=>{
    return [
        { value: 'Avoid', display: 'Avoid'},
        { value: 'Customer', display: 'Customer'},
        { value: 'General', display: 'General'},
        { value: 'Lead', display: 'Lead'},
        { value: 'NearMiss', display: 'NearMiss'},
        { value: 'Prospect', display: 'Prospect'},
    ];
}

export const getCountryValueArray = ()=>{
    return [
            { value: 'USA', display: 'United States'},
            { value: 'CAN', display: 'Canada'},
            { value: 'MEX', display: 'Mexico'},
    ];
}

//export const getStateValueArray = useMemo(
  export const getStateValueArray = ()=>{ return [
    {
      value: "AK",
      display: "Alaska"
    },
    {
      value: "AL",
      display: "Alabama"
    },
    {
      value: "AR",
      display: "Arkansas"
    },
    {
      value: "AZ",
      display: "Arizona"
    },
    {
      value: "CA",
      display: "California"
    },
    {
      value: "CO",
      display: "Colorado"
    },
    {
      value: "CT",
      display: "Connecticut"
    },
    {
      value: "DE",
      display: "Delaware"
    },
    {
      value: "DC",
      display: "D.C."
    },
    {
      value: "FL",
      display: "Florida"
    },
    {
      value: "GA",
      display: "Georgia"
    },
    {
      value: "HI",
      display: "Hawaii"
    },
    {
      value: "IA",
      display: "Iowa"
    },
    {
      value: "ID",
      display: "Idaho"
    },
    {
      value: "IL",
      display: "Illinois"
    },
    {
      value: "IN",
      display: "Indiana"
    },
    {
      value: "KS",
      display: "Kansas"
    },
    {
      value: "KY",
      display: "Kentucky"
    },
    {
      value: "LA",
      display: "Louisiana"
    },
    {
      value: "MA",
      display: "Massachusetts"
    },
    {
      value: "MD",
      display: "Maryland"
    },
    {
      value: "ME",
      display: "Maine"
    },
    {
      value: "MI",
      display: "Michigan"
    },
    {
      value: "MN",
      display: "Minnesota"
    },
    {
      value: "MO",
      display: "Missouri"
    },
    {
      value: "MS",
      display: "Mississippi"
    },
    {
      value: "MT",
      display: "Montana"
    },
    {
      value: "NC",
      display: "North Carolina"
    },
    {
      value: "ND",
      display: "North Dakota"
    },
    {
      value: "NE",
      display: "Nebraska"
    },
    {
      value: "NH",
      display: "New Hampshire"
    },
    {
      value: "NJ",
      display: "New Jersey"
    },
    {
      value: "NM",
      display: "New Mexico"
    },
    {
      value: "NV",
      display: "Nevada"
    },
    {
      value: "NY",
      display: "New York"
    },
    {
      value: "OH",
      display: "Ohio"
    },
    {
      value: "OK",
      display: "Oklahoma"
    },
    {
      value: "OR",
      display: "Oregon"
    },
    {
      value: "PA",
      display: "Pennsylvania"
    },
    {
      value: "RI",
      display: "Rhode Island"
    },
    {
      value: "SC",
      display: "South Carolina"
    },
    {
      value: "SD",
      display: "South Dakota"
    },
    {
      value: "TN",
      display: "Tennessee"
    },
    {
      value: "TX",
      display: "Texas"
    },
    {
      value: "UT",
      display: "Utah"
    },
    {
      value: "VA",
      display: "Virginia"
    },
    {
      value: "VT",
      display: "Vermont"
    },
    {
      value: "WA",
      display: "Washington"
    },
    {
      value: "WI",
      display: "Wisconsin"
    },
    {
      value: "WV",
      display: "West Virginia"
    },
    {
      value: "WY",
      display: "Wyoming"
    },
  ]}; //end useMemo()

export default function() {}