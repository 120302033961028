import React, {useState, useEffect, useMemo, useContext} from 'react';
import {Row, Col, DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {CompanyContext} from '../../context/CompanyContext';
import {getIconColorTable, getLabelInfo } from '../../common/tableFormats';
//import {patchCompany} from '../../common/ApiFunctions';
import './dropdowns.css';

const StatusDropDown = ({origin, currentCompany, mode, setIsOpen, isOpen, handleToggle, setChangeMade, setRowData, setMessage}) => {
    const { api,
        addChangeToPatch, setSingleField, //        patchArray
    } = useContext(CompanyContext);

    const [currentStatus, setCurrentStatus] = useState(currentCompany.Label);
    const [company, setCompany] = useState(currentCompany.Name);
    const [companyId, setCompanyId] = useState(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);

    const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);

    useEffect(()=>{
        if (currentCompany) {
            setCurrentStatus(currentCompany.Label);
            setCompany(currentCompany.Name);
            setCompanyId(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);
//            setHistory(currentCompany.History);
        } //end if
    }, [currentCompany]);    
//    console.log(company, currentStatus);

    // const dropdownStyle = dateColumn ? {minHeight:375, minWidth: 280} : {};
    // const justifyStyle = dateColumn ? "justify-content-left" : "justify-content-center";

    const thumbsUpIcon = iconColorTable.thumbsUp.icon;
    const thumbsUpColor = iconColorTable.thumbsUp.color;
    const thumbsDownIcon = iconColorTable.thumbsDown.icon;
    const thumbsDownColor = iconColorTable.thumbsDown.color;
    const recommendIcon = iconColorTable.recommend.icon;
    const recommendColor = iconColorTable.recommend.color;
    const otherIcon = iconColorTable.other.icon;
    const otherColor = iconColorTable.other.color;
    const newIcon = iconColorTable.aiq.icon;
    const newColor = iconColorTable.aiq.color;

    const getStatusColor = currentStatus => {
        return currentStatus === "ThumbsUp" ? thumbsUpColor 
            : currentStatus === "ThumbsDown" ? thumbsDownColor 
            : currentStatus === "Recommend" ? recommendColor
            : currentStatus === "Other" ? otherColor
            : newColor;
    }

    const getStatusIcon = currentStatus => {
        return currentStatus === "ThumbsUp" ? thumbsUpIcon 
            : currentStatus === "ThumbsDown" ? thumbsDownIcon 
            : currentStatus === "Recommend" ? recommendIcon
            : currentStatus === "Other" ? otherIcon
            : newIcon;
    }

    const statusArray = ["ThumbsUp", "ThumbsDown", "Other", "AIQueue"].filter(item=>item !== currentStatus);

    const buildUpdateDoc = newLabel => {
        let newCrmStatus = '';
        setCurrentStatus(newLabel);
//        if (newLabel === 'ThumbsUp')
//            newCrmStatus = 'Lead';
        if (newLabel === 'ThumbsDown')
            newCrmStatus = 'Avoid';
        else if (newLabel === 'Other')
            newCrmStatus = 'General'; 
        else if (newLabel === 'AIQueue')
            newCrmStatus = 'Prospect'; 
        // let crmStatusChgMsg = newCrmStatus ? `, CRMStatus from ${CRMStatus} to ${newCrmStatus}` : '';
        // let historyLine = `Changed AIStatus from ${currentStatus} to ${newLabel} ${crmStatusChgMsg}`;
        const retObj =  {
            // History: setHistory(historyLine, history),
            Label: newLabel
        }
        if (newCrmStatus) retObj.CRMStatus = newCrmStatus;
        //if (origin === 'Modal') setRowData({...currentCompany, ...retObj });
        return retObj;
    }

    return (
        <UncontrolledDropdown className="d-inline">
            <DropdownToggle
                tag="span"
                key={`status_dd_${companyId}`}
                aria-haspopup={true}
                data-toggle="dropdown"
                id="AIStatusDropdown"
                className={'p-0 m-0'}
                style={{valign: 'top', border: 1, 
                    //backgroundColor: 'transparent'
                }}
            >{origin === 'Modal' && currentStatus ? 
            <div className="p-2 pb-0 mb-0 card border" 
            style={{width: 138,  
                backgroundColor: 'transparent', 
                //zIndex: 10000, 
                "&:hover": {backgroundColor: 'transparent'}}}
        >
                <h6 className="ml-1 mr-1 mb-0 pb-0">
                    <div className="pb-2">AI Status</div>
                    <FontAwesomeIcon 
                            id="status_icon" icon={getLabelInfo(currentStatus, iconColorTable).icon} 
                            transform="grow-6"   //getStatusIcon(status)
                            style={{color:getLabelInfo(currentStatus, iconColorTable).color}}
                        /> 
                            <div className="text-center mt-1 pt-2"
                                style={{
                                    whiteSpace: 'nowrap',
                                    fontSize: '.85rem',
                                }}
                            >
                            {currentStatus}
                        </div>
                    </h6> 
                </div>
                : //else this is from the detail line
                <span key={`stage_div_${companyId}`} 
                    className={"d-inline text-center ml-0 mr-0 bg-transparent"} 
                    data-html={true} data-toggle="popover" 
                    title={getStatusColor(currentStatus).popover} data-content="content"
                    style={{backgroundColor: 'transparent', valign: 'top'}}
                >
                    <FontAwesomeIcon 
                        id="view" icon={getStatusIcon(currentStatus)} transform="grow-3" 
                        className="d-inline mt-0 pt-0 ml-1 mr-1 bg-transparent" 
                        style={{valign: 'top', color:getStatusColor(currentStatus), opacity: 0.7, backgroundColor: 'transparent'}} 
                        //onClick={()=>actionIconClick(row, 'view', true, viewIcon, viewColor)}
                    />    
                </span>        
                }
            </DropdownToggle>
            <DropdownMenu 
                //persist
                //left={origin === 'Modal' ? true : false} 
                right={origin !== 'Modal' ? true : false} 
                aria-labelledby="AIStatusDropdown"
                className="mt-2"
            >
            <DropdownItem className="m-0 p-0 pt-2 mt-0 pt-0 bg-white" tag="div">
            <div className={"p-0 pt-1 mt-1 pb-0 mb-0 card"} 
                style={{ minWidth: 350, maxHeight: 150, 
                    // backgroundColor: 'white', 
                    zIndex: 10001, 
                    // "&:hover": {backgroundColor: 'white'}
                }}
                >
                <div className="d-inline card-header p-0 mx-2 px-2" titletag="h6" light="false">
                    <button type="button" className="close d-inline" aria-label="Close" 
                        //onClick={()=>{handleToggle(mode, false)}}
                        >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>{`Change AI Status of ${currentStatus} for: `}</div>
                    <div><strong>{`${company}`}</strong></div>
                </div>
                <div className="card-body p-0 mx-2 px-2 mb-0 pb-0">
                    <Row>
                    {statusArray.map(status => (
                    <Col key={`Col_${status}`}><span key={`span_${status}`} className="justify-content-center ml-2 mr-2">
                        <div key={`div_${status}`} className="w-100 text-center">
                            <FontAwesomeIcon 
                                key={`icon_${status}`}
                                id="edit" icon={getStatusIcon(status)} transform="grow-4" 
                                className="ml-1 mr-1" style={{color:getStatusColor(status)}}
                                onClick={async e=>{
                                    //console.log('in onClick in StatusDropdown ' + Object.keys(currentCompany));
                                    //alert(companyId + ' - ' + JSON.stringify(buildUpdateDoc(status), null, 2));
                                    if (origin === 'Modal') { 
                                        setChangeMade(true);
                                        setSingleField('Label', status);
                                        //setUpdateObject(buildUpdateDoc(status)); 
                                    }
                                    else {
                                        const msg = `${company} Has Been Updated to PureBlue Status: ${status}`;
                                        let patch = addChangeToPatch('Label', status);
                                        await api.patchCompany(companyId, patch);
                                        setRowData('Label', status)
                                        setMessage(msg);
                                    }
                                    //e.stopPropagation();
                                    //handleToggle(mode, false);  //now close the dropdown after we are done
                                }}
                            />
                        </div>
                        <div key={`div2_${status}`} className="text-center">
                            <small>{status}</small>
                        </div>
                    </span></Col>)
                )}
                    </Row>
                </div>
            </div></DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default StatusDropDown;