import React, {useState} from 'react';
import UpdatedClock from '../../components/UpdatedClock';

import { Container, 
    // Dropdown, DropdownItem 
} from "react-bootstrap";

import { 
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col, 
    // InputGroup, Input, Form, Button, Tooltip, Alert 
  } from "reactstrap";
  
import '../../assets/css/pagination.css';

//import ReactBSAlert from "react-bootstrap-sweetalert";

import FilterTable from './ActiveFilterTable';
//import contactsFormat, {tableContent} from './contactsFormat';

const ActiveTable = ()=> {

    const [fetchTime, setFetchTime] = useState(new Date());
    const [reload, setReload] = useState(false);

    return (
        <div className="main-content p-0 m-0 mr-1 pr-2">
        <Container className="m-0 p-0" fluid>
            <Row className="ml-0 pl-0">
            <Col md={12}>
            <Card >
                <CardHeader className="pt-0 mb-0">
                  <CardTitle tag="h5">Active Companies    <strong><small className="text-primary hover-pointer"
                    onClick={()=>setReload(true)}><u>reload</u></small></strong></CardTitle>
                </CardHeader>
                <CardBody className="mt-0 pt-0 ml-0 pl-2">
                  <FilterTable 
                      setFetchTime={setFetchTime}
                      reload={reload}
                      setReload={setReload}
                      //env={env}
                    />
                </CardBody>
                <CardFooter>
                  <hr />
                  <UpdatedClock 
                    fetchTime={fetchTime} 
                  />
                </CardFooter>
              </Card>
            </Col>
            </Row>
        </Container>
        </div>
    );

//    return (<div>{data.length}</div>);
}

export default ActiveTable;


