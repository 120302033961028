import React, {useEffect} from 'react';
//import {Row, Col, FormGroup, InputGroup, Card, CardBody, Label, Input} from 'reactstrap';
//import {toDateString, OPCImg} from './tableFormats';

const GenericContent = ({data, content, mode, setUpdateObject}) => {

  //let's just set up the Update doc for each supported mode
    const outObj = {};
    if (mode === 'Active')
        outObj.MoveToMaster = true;
    else if (mode === 'Imports')
        outObj.MoveToStaged = true;
    else if (mode === 'Deleted')
        outObj.MoveToDeleted = true;
    else if (mode === 'Move All')
        outObj.MoveAllToActive = true;

    // useEffect(()=> {
    //     // const historyLine = {date: new Date(), desc: `Set Record to Move to ${mode}`};
    //     // outObj.History = data.History ? [{...historyLine}, ...data.History] : [{...historyLine}];
    //     setUpdateObject({...outObj});
    // },[setUpdateObject, mode, outObj]);

    return (
      <div>{content}</div>
    );
}

export default GenericContent;