import React, {useContext} from 'react';
import {Table} from 'reactstrap';
import is from 'is_js';

import {toDateString} from '../../common/tableFormats';
import {CompanyContext} from '../../context/CompanyContext';

const HistoryForm = ({data, mode}) => {
  const {
    currentCompany, 
  } = useContext(CompanyContext);

  //first we must see if there is a CRMSync object in the data.  If so, get values.
  let historyArray = [], sortedArray=[];

  if (currentCompany && 'History' in currentCompany) {
    historyArray = [...currentCompany.History];
    sortedArray = historyArray.sort((a,b)=> b.date - a.date);
  }
  else if (data && 'History' in data) {
    historyArray = [...data.History];
    sortedArray = historyArray.sort((a,b)=> b.date - a.date);
  }

  if (!sortedArray || (sortedArray && !sortedArray.length))
    return <div className="text-center">No History</div>
    else return (
  <div style={{height:400, overflowY: 'scroll'}}>
  <Table striped={true} size="sm">
    <thead>
      <tr>
          <th width="21%">Date</th>
          <th scope="row">Data Activity</th>
      </tr>
    </thead>
    <tbody>
      {sortedArray.map((row, i)=>{ 
        return (
        <tr key={`row_${i}`}>
            {/* <td>Date</td>
            <td>{row}</td> */}
        {Object.keys(row).map((cell,j)=>{
            let cellToRender = row[cell];
            if (cell === 'date' && is.date(new Date(cellToRender)))
                cellToRender = toDateString(new Date(cellToRender), 4);
            return (<td className="text-left" key={`row_${i}_td_${j}`}><div style={{ //whiteSpace: 'nowrap',
              fontSize: '.8rem',}}>{cellToRender}</div></td>);
          })}
        </tr>
        )     
      })}
    </tbody>          
  </Table>
  </div>
)

}

export default HistoryForm;