import React from 'react';
import {Row, Col, Input, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PaginationControls = ({pageSizeArray, pageSize, setPageSize, pageCount, pageIndex, previousPage, nextPage, gotoPage})=> {

    function RoundedPagination({pageCount, currentPage, previousPage, nextPage, gotoPage}) {
        const canNextPage = ()=> pageCount > currentPage;
        const canPreviousPage = ()=> pageCount > 1 && currentPage > 1;
        const pages = [];
        for (let i=0; i<pageCount; i++) pages.push(i+1);
//        let cPage = parseInt(currentPage);
        let pageLi = [];
        for (let i=1; i<pages.length+1; i++) {
            if (i===currentPage) {
                // console.log ('found ' + i);
                pageLi.push(<li key={`pageLi_${i}`} disabled={true} className="page-item active"><button key={`pageButton_${i}`} className="page-link" data-abc="true"><span>{i}</span></button></li>)
            } else if ((currentPage === 1 && i<=5) || (currentPage === 2 && i<=5) 
                    || (currentPage === pageCount && i>= pageCount-4) || (currentPage === pageCount-1 && i>= pageCount-4)
                    || (i === currentPage+1 || i === currentPage+2 || i === currentPage-1 || i === currentPage-2)) {
                pageLi.push(<li key={`pageLi_${i}`} className="page-item" ><button key={`pageButton_${i}`} className="page-link" onClick={() => {gotoPage(i);window.location.hash='#FilterCards';}} data-abc="true">{i}</button></li>)
            }
        } //end for 
        //console.log('canNextPage = ' + canNextPage);
        return (
            <div className="container text-right">
                {/* {pageCount}-{currentPage}{canNextPage() ? 'canNextPage' : ''}&nbsp;{canPreviousPage() ? 'canPreviousPage':''} */}
                <ul className="pagination d-flex justify-content-end flex-wrap pagination-rounded-flat pagination-primary">
                    {canPreviousPage 
                    ?   <li className="page-item" disabled={false}><button className="page-link" onClick={() => previousPage()} data-abc="true">
                            <FontAwesomeIcon icon="angle-left" transform="down-1.5" className="" /></button>
                        </li>
                    :   <li className="page-item" disabled={true}><button className="page-link" data-abc="true">
                            <FontAwesomeIcon icon="angle-left" transform="down-1.5" className="" /></button>
                        </li>
                    }
                        {pageLi}
                    {canNextPage 
                    ?   <li className="page-item" disabled={false}><button className="page-link" onClick={() => nextPage()} data-abc="true">
                            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="" /></button>
                        </li>
                    :   <li className="page-item" disabled={true}><button className="page-link"data-abc="true">
                            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="" /></button>
                        </li>
                    }
                </ul>
            </div>            
        )
    }

    return (
    <Row className="pagination noGutters px-1 py-3">
        <Col xs={6} className="pr-1 text-left">
            <Input type="select"
                bsSize="sm"
                className="btn btn-primary btn-sm w-25" 
                value={pageSize}
                onChange={e => {
                setPageSize(Number(e.target.value))
                }}
            >
                {pageSizeArray.map(pageSize => ( 
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize}&nbsp;
                </option>
                ))}
            </Input>
        </Col>
        <Col xs={6} className="justify-content-end">
            <RoundedPagination 
                pageCount={pageCount}
                currentPage={pageIndex+1}
                previousPage={previousPage} 
                nextPage={nextPage} 
                pageSize={pageSize}
                gotoPage={gotoPage}
            ></RoundedPagination>
        </Col>
    </Row>
    )
} //end PaginationControls()

export default PaginationControls;