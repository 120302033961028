import React, {useState} from 'react';
import {Tooltip, Button} from 'reactstrap';
//import ConfirmationModal from './ConfirmationModal';

const ModalButtons = ({origin, no, yes, setYes, toggle, rowData, changed, showConfirmationModal, //syncContact, //modalType, editing,
                        setRenderSaveCancel, renderSaveCancel, setEnableSync, updateCompany, setNavFunction
                        }) => 
{
    const [yesTooltipOpen, setYesTooltipOpen] = useState(false);
    const [yesAndSaveTooltipOpen, setYesAndSaveTooltipOpen] = useState(false);
    const [noTooltipOpen, setNoTooltipOpen] = useState(false);
    // const [areYouSure, setAreYouSure] = useState(false);
    const id = rowData && rowData._id ? rowData._id.toString() : '';
//    const company = rowData.Name;

    return (
        <React.Fragment>

            <div className="text-right pr-3">
            <Button id="no" outline={true} size="sm" 
                key="modal_no_cancel_button"
                color="primary"
                className="btn btn-sm m-2"
                onClick={() => {
                    if (no === 'Cancel') {
                        if (renderSaveCancel && changed) {
                            setNavFunction('cancelsave');
                            showConfirmationModal(true);
                        } else toggle();
                    } else {
                        toggle();
                    }
                    }
                }
            >{no}</Button>
                <Tooltip
                    placement="top"
                    key="no_button_tooltip"
                    isOpen={noTooltipOpen}
                    target="no"
                    toggle={() => {setNoTooltipOpen(!noTooltipOpen)}}
                >
                {no} and Close Window
                </Tooltip>
            <Button id="yes" outline={true} size="sm" 
                key="modal_ok_yes_button"
                color="primary"
                className="btn btn-sm m-1"
                onClick={async () => {
                if (renderSaveCancel && changed) { 
                    //console.log('in modalButtons event before call to update Company ' + JSON.stringify(changed));
                    setEnableSync(true);
                    showConfirmationModal(false);
                    // await updateCompany(id, changed); 
                    setRenderSaveCancel(false);
                    setYes("OK");
                }
                else toggle();
                } }
            >{yes}</Button>
                <Tooltip
                    key="modal_ok_yes_button_tooltip"
                    placement="top"
                    isOpen={yesTooltipOpen}
                    target="yes"
                    toggle={() => setYesTooltipOpen(!yesTooltipOpen)}
                    >
                    {yes} Changes to the Database
                </Tooltip>
                {renderSaveCancel 
                ? <React.Fragment>
                    <Button id="yesAndSave" outline={true} size="sm" 
                        key="modal_save_n_close_button"
                        color="primary"
                        className="btn btn-sm m-1"
                        onClick={async () => {
                        if (renderSaveCancel && changed) {
                            await updateCompany(id, changed); 
                            showConfirmationModal(false);
                            setEnableSync(true);
                            toggle();
                        }
                    } }
                    >Save and Close</Button>
                        <Tooltip
                            key="modal_save_n_close_button_tooltip"
                            placement="top"
                            isOpen={yesAndSaveTooltipOpen}
                            target="yesAndSave"
                            toggle={() => setYesAndSaveTooltipOpen(!yesAndSaveTooltipOpen)}
                            >
                            {yes} and Close Window
                        </Tooltip>
                    </React.Fragment>
                : null}
            </div>
        </React.Fragment>
    ) //end return

} //end function

export default ModalButtons;
