import React, {useState, useRef, useEffect} from 'react'; 
import { Card, CardHeader, CardFooter, CardBody, Col, Form, FormGroup, Label, Button, Input, Row, Spinner, Table } from 'reactstrap';
import ImportTable from './ImportTable';
import ButtonIcon from '../components/ButtonIcon';
import "./stickyTableHeader.css"

const DisplayFileSample = (props) => { 
    
    let columns;
    useEffect(() => {
        if (props.fileData && props.fileData.length) {
            columns = Object.keys(props.fileData[0]).map(col=>{
                return {Header: col, accessor: col}});
        }
    }, [props.fileData.length]);

    useEffect(() => {
        columns = Object.keys(props.fileData[0]).map(col=>{
            return {Header: col, accessor: col}});
        //clear the announcement from the previous tab
        props.announcement('Review the sample data (10 rows) and proceed to Map Fields if correct.');
    }, [props.step]);

    // columns = Object.keys(props.fileData[0]).map(col=>{
    //                             return {Header: col, accessor: col}});
    return ( 
        <Card className="w-100 border p-0 m-0 mb-2">
            <CardHeader className="pb-0 pt-1 mt-0 mb-0">
                <h5 className="text-left mb-0 pb-0">
                    {`Sample Data For: ${props.fileName}`}
                </h5>
            </CardHeader>
            <CardBody tag={Form} className="bg-light m-0 p-0 pl-2 pr-2 mt-1">
                {(props.fileData && props.fileData.length) ?
                    <Card className="w-100" style={{maxHeight: 325, overflowX: 'scroll', overflowY: 'scroll'}}>
                        {/* <ImportTable 
                            height={"325px"}
                            //tableData={[...props.fileData]}
                            tableData={[{name: 'Rick', age: 57}, {name: 'Claudia', age: 60}]}
                            tableColumns={[{Header: 'name', accessor: 'name'}, {Header: 'age', accessor: 'age'}]}
                        /> */}
                        <Table striped size="sm">
                            <thead><tr className="primaryRow">
                                {props.columns.map(col=>{
                                    return (<th className="sticky" style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>{col}</th>)
                                })}
                                </tr>
                            </thead>
                            <tbody>
                                {props.fileData.map(row=>{
                                    return (
                                        <tr>
                                            {Object.entries(row).map((entry, i)=>{
                                                return (
                                                    <td style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>
                                                        {props.formatCell(entry[1], entry[0])}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Card>
                : null }
            </CardBody>
            <CardFooter className="mb-0">
                <Row>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="arrow-left"
                            onClick={()=>props.setStep('upload')}
                        >
                            Go Back
                        </ButtonIcon>
                    </Col>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="columns"
                            onClick={()=>props.setStep('map')}
                        >
                            Map Fields
                        </ButtonIcon>
                        {/* <Spinner className={"ml-2 " + spinnerClass} size="sm" color="primary" /> */}
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    ); 
} //end component

export default DisplayFileSample;
 
