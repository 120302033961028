import React, {useState, useRef, useEffect} from 'react'; 
import { Card, CardHeader, CardFooter, CardBody, Col, Form, FormGroup, Label, Button, Input, Row, Spinner, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIcon from '../components/ButtonIcon';
import { primaryColor, infoColor, toDateString } from '../../common/tableFormats';
import "./stickyTableHeader.css"
//import StateManager from 'react-select';

const PreviewUpload = (props) => { 

    useEffect(() => {
        //clear the announcement from the previous tab
        //props.announcement('');
        //alert(props.templateModified);
        //alert(JSON.stringify(props.columnMap.filter(item=>item !== '-- IGNORE --'), null, 2))
    }, []);

    return ( 
        <Card className="w-100 border p-0 m-0 mb-2">
            <CardHeader className="pb-0 pt-1 mt-0 mb-0">
                <h5 className="text-left mb-0 pb-0">
                    {`Preview Import For: ${props.fileName}`}
                </h5>
            </CardHeader>
            <CardBody tag={Form} className="bg-light m-0 p-0 pl-2 pr-2 mt-1 pt-2 border">
                <Card className="w-100 border pt-2 mb-2 pb-0">
                    <Table striped size="sm" className="mb-0">
                        <thead className="sticky-top">
                            <tr className="mt-0 pt-0 mb-0 pb-0">
                                <th className="mt-0 pt-0 mb-0 pb-1" style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>Import Template Name</th>
                                <th className="text-right mt-0 pt-0 mb-0 pb-1" style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>Save</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="mt-0 pt-0 mb-0 pb-0">
                                <td className="mt-0 pt-1 mb-0 pb-1 pl-1">
                                    <strong>
                                        {props.saveTemplate 
                                        ? <Input type="text" className="text-left mb-0 pb-0 pl-1" 
                                            style={{fontWeight: 'bold'}} 
                                            onChange={(e)=>props.setTemplateName(e.target.value)}
                                            value={`${props.mappingInfo.template}`} />
                                        : props.mappingInfo.template
                                        }
                                    </strong>
                                </td>
                                <td className="text-right pr-3 mt-0 pt-1 mb-0 pb-1">
                                    {/* <span className="text-right mb-0 pb-0 mr-2 pr-0">Save</span> */}
                                    <FontAwesomeIcon icon={(props.saveTemplate && props.templateModified === true) ? 'check-square' : 'square'} 
                                        color={primaryColor} transform="grow-10" className="m-0 p-0"
                                        //disabled={!props.templateModified}
                                        onClick={()=>{props.setSaveTemplate(prev=>!prev)}}
                                    />

                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card>
                <Row><Col>
                {(props.previewData && props.previewData.length) ?
                    <Card className="w-100" style={{maxHeight: 325, overflowX: 'scroll', overflowY: 'scroll'}}>
                    <Table striped size="sm">
                        <thead><tr className="primaryRow">
                            {props.columnMap.filter(item=>item !== '-- IGNORE --').map(col=>{
                                return (<th className="sticky" style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>{col}</th>)
                            })}
                            </tr>
                        </thead>
                        <tbody>
                            {props.previewData.map(row=>{   
                                return (
                                    <tr>
                                        {props.columnMap.filter(item=>item !== '-- IGNORE --').map((field, i)=>{
                                            return (
                                                <td style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>
                                                    {props.formatCell(row[field], field)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table></Card>
                : null }
                </Col></Row>
            </CardBody>
            <CardFooter className="mb-0">
                {props.uploading
                ? <Spinner color="primary">...Uploading</Spinner>
                : <Row>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="arrow-left"
                            onClick={()=>props.setStep('map')}
                        >
                            Go Back
                        </ButtonIcon>
                    </Col>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="file-import"
                            onClick={()=>props.importData()}
                        >
                            Stage Data {props.templateModified}
                        </ButtonIcon>
                        {/* <Spinner className={"ml-2 " + spinnerClass} size="sm" color="primary" /> */}
                    </Col>
                </Row>
                }
                </CardFooter>
        </Card>
    ); 
} //end component

export default PreviewUpload;
 
