import firebase from "firebase/app"
import "firebase/auth"

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID

    //********************************************************* LOCAL ********************************************************************** */
    // apiKey: "AIzaSyBVNf4EvirWJYcjeMKNwmeNEOTblC0o2G8",
    // authDomain: "ailead-production.firebaseapp.com",
    // projectId: "ailead-production",
    // storageBucket: "ailead-production.appspot.com",
    // messagingSenderId: "312936599659",
    // appId: "1:312936599659:web:1e322e6c21410da21f612e"

    //********************************************************* LOCAL ********************************************************************** */
    // apiKey: "AIzaSyCVhPXv7NZTwur5Qn6ENGLzLE8AYRPqd3I",
    // authDomain: "ailead-development.firebaseapp.com",
    // projectId: "ailead-development",
    // storageBucket: "ailead-development.appspot.com",
    // messagingSenderId: "740342788142",
    // appId: "1:740342788142:web:5bcd47948117bc604f2fbf"
});

export const auth = app.auth();
export default app;