import React, {Fragment, useState, useContext, useEffect} from 'react';
import {Row, Col, Table, Card} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CompanyContext} from '../../context/CompanyContext';
import { primaryColor} from '../../common/tableFormats';     //dangerColor, infoColor, , warningColor
//import '../../assets/css/tableHover.css';

const NotesPane = () =>{

    const {
        currentCompany, setCurrentContact, setLastItems, //currentContact, 
      } = useContext(CompanyContext);

    const [editModeArray, setEditModeArray] = useState([]);
    
    useEffect(()=>{
        if (currentCompany) {
            const current = currentCompany && currentCompany.Contacts && currentCompany.Contacts.length ? 0 : null;
            if (current !== null) {
                //need to reset the edit mode for each of the contacts
                const newEditModeArray = currentCompany.Contacts.map(item=>false);
                setEditModeArray([...newEditModeArray]);
                // const newRec = {}
                // Object.keys(currentCompany.Contacts[current]).forEach(key =>{
                //     newRec[key] = currentCompany.Contacts[current][key] ? currentCompany.Contacts[current][key] : '';
                // });
                // setCurrentContact(newRec);
            }
        }
    }, [currentCompany]);
    
    const handleNoteClick = (i, value=true) => {
        const newEditModeArray = [...editModeArray];
        newEditModeArray[i] = value;
        setEditModeArray([...newEditModeArray]);
    }

    const renderNotes = (notes, i, limit=180) => {
        if (!notes || !notes.length) 
            return <div>-- no notes for contact --</div>;
        console.dir(notes);
        notes = notes && notes.length && notes.join ? notes.join('\n') : "";
        let more = '';
        if (notes.length > limit) {
            notes = notes.substring(0,limit);
            more = " [see more]";
        }
        return (<Fragment>
            <div>{notes}</div>  
            {more ? <div className="text-primary">
                <strong>{more}</strong></div>: null}
            </Fragment>
        )
    }
    return (
        <div style={{height: 250}} className="text-center p-0 m-0">
            <Card className="p-0 border">
                <Row className="m-0 p-0" style={{height: 240, overflowY: 'auto', overflowX: 'hidden'}}>
                    <Col>
                        <Table size="sm"
                            hover
                            striped
                            className="ml-0 mr-0 pl-0 pr-0 table-hover"
                        >
                            <thead>
                            <tr>
                                {/* <th key='th_blank'>{' '}</th> */}
                                <th key='th_contact' className="text-left">Contact</th>
                                <th key='th_last' className="text-left">Notes</th>
                                <th key='th_actions' className="text-Center">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentCompany.Contacts ?
                                currentCompany.Contacts.map((row, i)=>{ 
                                return ( 
                                <tr key={row.key + '_' + i + "_tr"} onClick={()=>{
                                    setCurrentContact({...currentCompany.Contacts[i]});
                                    setLastItems(prev=>({...prev, contactIndex: i}));
                                }}>
                                    <td key={`${row.key + '_' + i}_first_last_disp_td`}><div className="text-left" style={{ //whiteSpace: 'nowrap',
                                        fontSize: '.8rem'}}>{row.First} {row.Last}</div>
                                    </td>
                                    <td key={`${row.key + '_' + i}_notes_disp_td`}
                                        onClick={()=>handleNoteClick(i)}
                                    >
                                        <div className="text-left" style={{fontSize: '.8rem'}}>
                                        {editModeArray[i] ? 
                                        <textarea className="pl-1 pt-0" type="textarea"
                                            key={`${row._id}_notespane_notes_textarea`}
                                            //ref={ref} 
                                            rows="4" cols="180" placeholder="<Enter Notes>" value={(row.Notes && row.Notes.length) ? row.Notes.join('\n') : row.Notes}
                                            //onChange={(e)=>fieldChanged(e, i, 'Notes', Notes, setNotes)}
                                            //onBlur={fieldBlur}
                                        />
                                        : <div>{renderNotes(row.Notes, i)}</div>}
                                        </div>
                                    </td>
                                    <td key={`${row.key + '_' + i}_edit_icon_td`}>
                                        <FontAwesomeIcon 
                                            key={`${row.key + '_' + i}_edit_icon`} 
                                            icon={(editModeArray[i] ? 'check' : 'pencil-alt')} transform="grow-2" 
                                            //icon={'pencil-alt'} transform="grow-2" 
                                            className="ml-2 mr-2" 
    //                                        style={{color:editIndex === i ? warningColor : infoColor}} 
                                            style={{color:primaryColor}}
                                            onClick={(e)=>handleNoteClick(i, false)}
                                        />
                                        </td>
                                        <td key={`${row.key + '_' + i}_trash_icon_td`}>
                                        <FontAwesomeIcon 
                                            key={`${row.key + '_' + i}_trash_icon`} 
                                            icon={'trash-alt'} transform="grow-2" 
                                            className="ml-2 mr-2" style={{color:primaryColor}} 
                                            //onClick={(e)=>deleteContactEvent(e, i)}
                                        />
                                    </td>
                                </tr>)
                            }) :
                            <tr><td colSpan="3">no notes</td></tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card>

        </div>
    )

} //end functional component

export default NotesPane;