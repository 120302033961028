import React, {useState, useRef, useEffect, useMemo, Fragment} from 'react'; 
import { Card, CardHeader, CardBody, CardFooter, Col, Form, FormGroup, Label, Button, Input, Row, Spinner, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { primaryColor, infoColor, toDateString } from '../../common/tableFormats';
import ButtonIcon from '../components/ButtonIcon';
import "./stickyTableHeader.css"

const MapColumns = (props) => { 

    const normalizeFileName = props.normalizeFileName;
    
    const aiLEADColumns = useMemo(()=>{return ["-- IGNORE --", "Owner", "Address", "Address2", "CRMStatus","CRMCompanyId", "CRMContactId", "City","Contact_First", "Contact_Last", "Contact_JobTitle", 
        "Contact_Phone","Contact_Cellphone", "Contact_Email", "Contact_LinkedInUrl","Contact_Notes", "Country","CreateDate", "DUNSNumber","Description",
        "Employees","EnteredBy","Fax","Founded","Industry","Label","LinkedInUrl","MSA","MSAName","NAICS","Name","Notes","NumDBContacts","Phone","ProspectStage","Revenue",
        "RiskRating","SIC","Source","State", "Timezone", "Website","Zipcode","aiLeadSource","syncToAILead",
        "MantaUrl", "BuzzfileUrl", "DNBUrl", "TwitterUrl"]});

    const aiLColsLC = aiLEADColumns.map(item=>item.toLowerCase());
    const [showTemplatesClass, setShowTemplatesClass] = useState("collapse.show");

    useEffect(() => {
        //clear the announcement from the previous tab
        props.announcement('Choose a saved template and/or map each import column');
        //restore the props.selectedTemplate & props.columnMap if set previously
        //alert(JSON.stringify(props.columnMap));
        if (props.selectedTemplate && props.selectedTemplate.FormatName) {selectTemplate(props.selectedTemplate);}
    }, [props.step]);

    useEffect(()=>{
        if (!props.selectedTemplate.FormatName && props.columns && props.columns.length) {
            props.columns.map((item, i)=>{
                if (aiLColsLC.includes(item.toLowerCase())) {
                    mapColumn(item, i);
                } else mapColumn('-- IGNORE --', i);
            });

            //now we need to buzz through the saved templates to see if we have a match
            if (props.importTemplates && props.importTemplates.length) {
                let matchFound;
                for (let i=0; i<props.importTemplates.length; i++) {
                    matchFound = true;
                    const item=props.importTemplates[i];
                    const importColumns = item.Columns.map(col=>col.importField.toLowerCase());
                    props.columns.map(impCol=>{
                        if (!importColumns.includes(impCol.toLowerCase())) {
                            matchFound = false;
                        }
                    });
                    if (matchFound) {
                        props.announcement('PureBlue has found a matching template: ' + item.Name);
                        selectTemplate(item);
                        break;
                    }
                }
            }
        }
    }, [props.columns]);

    const selectTemplate = (template) => {
        props.setTemplateModified(false);
        props.setSelectedTemplate(template);
        //now we need to set all the columns per the template.  if one is already set, then leave it.
        props.columns.map((item, i)=>{
            const ndx = template.Columns.findIndex(template=>item.toLowerCase() === template.importField.toLowerCase());
            if (ndx > -1) {
                mapColumn(template.Columns[ndx].masterField, i)
            }
        });
    }

    const mapColumn = (value, i, set=false) => {
        props.setColumnMap(prev=>{
            const newMap = [...prev];
            newMap[i] = value
            return [...newMap];
        });
        if (set) props.setTemplateModified(true);
        return true;
    }
    
    const goToPreview = ()=>{
        //first let's make sure that every column is mapped
        let everyFieldMapped = true;
        props.columnMap.map(item=>{
            if (!item) everyFieldMapped = false;

        });
        //here is where we call the routine to actually import the test data (10 lines or whatever)
        // if (!everyFieldMapped) {
        //     props.announcement('Every field must be mapped with a column or -- IGNORE -- in order to Preview')
        //     return false;
        // }

        const fullColumnMap = {};
        props.columns.map((importCol, i)=>{
            fullColumnMap[importCol] = props.columnMap[i];
        });
        const mapping = {
            template: (props.selectedTemplate && props.selectedTemplate.FormatName) ? normalizeFileName(props.selectedTemplate.FormatName) : normalizeFileName(props.fileName),
            columnMap: props.columnMap,
            fullColumnMap
        }
        
        //alert('in mapping ' + JSON.stringify(fullColumnMap, null, 2));
        props.createPreview(mapping);
    }

    return (<Fragment>
        <Card className="w-100 border p-0 m-0 mb-2">
        <CardHeader>
                <Row>
                    <Col sm="auto">
                        <h5 className="text-left mb-0 pb-0">{`Map Fields for: ${props.fileName}`}</h5>
                    </Col>
                </Row>
        </CardHeader>
        <CardBody tag={Form} className="bg-light m-0 p-0 pl-2 pr-2 mt-1 border">
            <Row>
                <Col sm="12" className="text-center mt-2">
                    <h6 className="text-center d-inline pt-2 mb-0 pb-0">
                        {`Saved Import Data Templates`}
                    </h6>
                {/* </Col>
                <Col sm="1" className="pull-right"> */}
                    {'  '}<u><strong><small 
                        onClick={()=>setShowTemplatesClass(prev=>prev === "collapse" ? "collapse.show": "collapse")}
                        className="d-inline underlined" style={{fontSize: '.7rem',}}>{showTemplatesClass === "collapse" ? 'show' : 'hide'}</small></strong></u>
                </Col>
            </Row>            
            <Card className={`${showTemplatesClass} w-100 border mt-2 mb-1`} style={{maxHeight: 165, overflowX: 'scroll', overflowY: 'scroll'}}>
                <Table striped size="sm"  style={{}}>
                    <thead>
                        <tr className="primaryRow">
                            <th style={{fontSize: '.8rem',}} className="text-center sticky">Sel</th>
                            <th className="sticky" style={{fontSize: '.8rem',}}>Template Name</th>
                            <th className="sticky" style={{fontSize: '.8rem',}}>Last Imported</th>
                            <th className="sticky" style={{fontSize: '.8rem',}}>Import Columns</th>
                        </tr>
                    </thead>
                    <tbody>{props.importTemplates.map(row=>(
                        <tr className="pt-0 pb-0 mb-0 border">
                            <td className="text-center">      
                                <FontAwesomeIcon icon={(props.selectedTemplate && props.selectedTemplate.FormatName === row.FormatName) ? 'check-square' : 'square'} color={primaryColor} transform="grow-10" className="m-0 p-0" 
                                    onClick={()=>selectTemplate(row)}
                                />
                            </td>
                            <th style={{fontSize: '.8rem',whiteSpace: 'nowrap'}}>{row.Name.length < 35 ? row.Name : row.FormatName.substr(0, 35) + '...' }</th>
                            <td style={{fontSize: '.8rem',whiteSpace: 'nowrap'}}>{toDateString(row.UpdateDate, 2)}</td>
                            <td style={{fontSize: '.8rem',whiteSpace: 'nowrap'}}>{row.Columns.map(item=>item.importField).join(',')}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                </Card>
                    <Row>
                        <Col sm="12" className="text-center mt-2">
                            <h6 className="text-center d-inline pt-2 mb-0 pb-0">
                                {`Field Mappings`}
                            </h6>
                        </Col>
                    </Row>            

                <Card className="w-100 border mt-1" style={{maxHeight: showTemplatesClass === "collapse.show" ? 231 : 231+176, overflowY: 'scroll'}}>
                    <Table striped size="sm" className="border mt-0 pt-0">
                        <thead>
                            <tr className="primaryRow">
                                <th className="sticky" style={{fontSize: '.8rem',}}>Import Field</th>
                                <th className="sticky" style={{fontSize: '.8rem',}}>PureBlue Field</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.columns.map((row, i)=>{
                                return (
                                    <tr key={`tr_${row}`} style={{height: '20px'}} className="mt-0 mb-0 pt-0 pb-0">
                                        <th style={{fontSize: '.8rem',}}>
                                            {row}
                                        </th>
                                        <td style={{fontSize: '.8rem'}} className="mt-0 pt-0 mb-0 pb-0">
                                            <Input className="ml-0 pl-1" type="select" 
                                                placeholder="--Select--" value={props.columnMap[i]?props.columnMap[i]:'-- IGNORE --'}
                                                onChange={(e)=>mapColumn(e.target.value, i, true)}
                                                style={{fontSize: '.8rem',}}
                                            >
                                                <option selected value="">[--Select Field--]</option>
                                                {aiLEADColumns.map(option=>{
                                                    if (option.toLowerCase() === row.toLowerCase())
                                                        return (<option selected style={{fontSize: '.8rem',}}>{option}</option>);
                                                    return (<option style={{fontSize: '.8rem',}}>{option}</option>);
                                                })}
                                            </Input>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        </Table>
                    </Card>
                </CardBody>
            <CardFooter>
                <Row>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="arrow-left"
                            onClick={()=>props.setStep('display')}
                        >
                            Go Back
                        </ButtonIcon>
                    </Col>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="eye"
                            onClick={goToPreview}
                        >
                            Preview
                        </ButtonIcon>
                    </Col>
                </Row>
            </CardFooter>
        </Card></Fragment>
    ); 
} //end component

export default MapColumns;
 
