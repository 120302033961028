import React, {useState, useRef, useEffect, Fragment} from 'react'; 
import { Card, CardHeader, CardFooter, CardBody, Col, Form,  Row, Spinner, Table } from 'reactstrap';
import ButtonIcon from '../components/ButtonIcon';
import "./stickyTableHeader.css"

const ConfirmImport = (props) => { 

    const [importedColumns, setImportedColumns] = useState([])
    const fieldSet = new Set();
    useEffect(() => {
        if (props.importedData && props.importedData.length) {
            props.importedData.map(row=>{
                const fields = Object.keys(row);
                fields.map(item=>fieldSet.add(item));
            });
            setImportedColumns(Array.from(fieldSet));
        }
    }, [props.importedData]);
    
    return ( 
        <Card className="w-100 border p-0 m-0 mb-2">
            <CardHeader className="pb-0 pt-1 mt-0 mb-0">
                <Row className='w-100'>
                    <Col sm="8">
                        <h5 className="text-left mb-0 pb-0">
                            {`Confirm Import For: ${props.fileName}`}
                        </h5>
                    </Col>
                    <Col sm="4" className='text-right'>
                        {'  '}<strong><small 
                                onClick={()=>props.getMoreImportRecords()}
                                className="d-inline underlined" style={{fontSize: '.7rem',}}>{`Records 1-${props.importedData.length} - `}{props.importedData.length < props.totalRecords ? <u>Get 100 More</u> : null}</small></strong>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody tag={Form} className="bg-light m-0 p-0 pl-2 pr-2 mt-1 pt-2 border">
                 {props.uploading 
                ? <span><Spinner color="primary" />Loading...</span>
                : <Row><Col>
                {(props.importedData && props.importedData.length) ?
                    <Card className="w-100" style={{maxHeight: 325, overflowX: 'scroll', overflowY: 'scroll'}}>
                    <Table striped size="sm">
                        <thead><tr className="primaryRow">
                            {importedColumns.map(col=>{
                                return (<th className="sticky" style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>{col}</th>)
                            })}
                            </tr>
                        </thead>
                        <tbody>
                            {props.importedData.map(row=>{
                                return (
                                    <tr>
                                        {importedColumns.map((field, i)=>{
                                                return (
                                                    <td style={{whiteSpace: 'nowrap', fontSize: '.8rem',}}>
                                                        {props.formatCell(row[field], field)}
                                                    </td>
                                                );
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table></Card>
                : null }
                </Col></Row>}
            </CardBody>
            <CardFooter className="mb-0">
                <Row>{!props.uploadComplete 
                ? <Fragment><Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="arrow-left"
                            onClick={()=>props.setStep('preview')}
                        >
                            Go Back
                        </ButtonIcon>
                    </Col>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="ban"
                            onClick={()=>props.cancelImport()}
                        >
                            Cancel Import
                        </ButtonIcon>
                        {/* <Spinner className={"ml-2 " + spinnerClass} size="sm" color="primary" /> */}
                    </Col>
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="check"
                            onClick={()=>props.confirmImport()}
                        >
                            Confirm Import
                        </ButtonIcon>
                        {/* <Spinner className={"ml-2 " + spinnerClass} size="sm" color="primary" /> */}
                    </Col></Fragment>
                    : 
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
                            transform={'grow-2'}
                            disabled={props.uploading}
                            icon="check"
                            onClick={()=>props.closeModal(false)}
                        >
                            Done
                        </ButtonIcon>
                        {/* <Spinner className={"ml-2 " + spinnerClass} size="sm" color="primary" /> */}
                    </Col>
                }
                </Row>
            </CardFooter>
        </Card>
    ); 
} //end component

export default ConfirmImport;
 
