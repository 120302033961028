import React, {useState, useRef, useEffect} from 'react'; 
import { Card, CardHeader, CardBody, Col, Form, FormGroup, Label, Button, Input, Row, Spinner, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIcon from '../components/ButtonIcon';

const FILE_SIZE_LIMIT = 20000000;

const UploadFile = (props) => { 
    const ChooseFile = ({text, display=true}) => {
        if (!display) return null;
        return (
            <FormGroup>
            <Label className="btn btn-round btn-sm text-white bg-primary" color="primary"
                disabled={props.uploading}
            >
            <FontAwesomeIcon
                icon={'folder-open'}
                className={'ml-0 pl-0 mr-2'}
                transform={'grow-2'}
            />
                {text}
            <Input hidden name="fileName" type="file"
            
    //                        value={title}
                className="btn btn-round btn-sm text-center" color="primary"
                accept=".csv"
                onChange={fileChange}
                bsSize="sm"
                id="fileName"
                placeholder="Choose File"
            />
            </Label>
        </FormGroup>
        );
    }
    
    // const countySelectRef = React.createRef();
    const initialState = { 
        // Initially, no file is selected 
        fileSelected: props.selectedFile ? true : false,
        compVisible: props.compVisible,
        uploading: false
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (props.selectedFile) {
            const fileName = props.selectedFile.name.toLowerCase();
            props.announcement(`${fileName} is ready to upload to server`, 'info');
        }
    }, [props.selectedFile, props.step]);

    useEffect(() => {
        props.announcement('');
    }, []);

        // On file select (from the pop up) 
	const fileChange = (event) => { 
        // Update the state 
        event.persist();
        const value = event.target.files[0];
        if (value.size > FILE_SIZE_LIMIT) {
            props.announcement(`${value.name} is ${props.numberWithCommas(value.size)} bytes -- too large. Break it up into smaller pieces or select a file under ${props.numberWithCommas(FILE_SIZE_LIMIT)}.`)
            return false;
        }
        props.setSelectedFile(value);
        setState(prev=>({...prev, fileSelected: true}));
    }; 
	
    let visibilityStyle = props.compVisible ? "collapse.show" : "collapse";
    let uploadButtonVisibilityStyle = state.fileSelected ? "collapse.show" : "collapse";
    let fileInfo = null;
    if (state.fileSelected && props.selectedFile) { 
        //first store the county selected
        fileInfo =  ( 
            <Row className="mt-0">
            <Col xs={12}>
                {/* <small className="form-text mt-0">County: {state.countySelectedLabel}</small>  */}
                <Card className="border">
                <Table striped size="sm">
                    <thead>
                        <tr>
                            <th colSpan="2" className="text-center">
                                File Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>File Name</th>
                            <td>{props.selectedFile.name}</td>
                        </tr>
                        <tr>
                            <th>File Type</th>
                            <td>{props.selectedFile.type}</td>
                        </tr>
                        <tr>
                            <th>File Size</th>
                            <td>{props.numberWithCommas(props.selectedFile.size)}</td>
                        </tr>
                        <tr>
                            <th>Last Modified</th>
                            <td>{props.selectedFile.lastModifiedDate.toDateString() + " " + props.selectedFile.lastModifiedDate.toLocaleTimeString('en-US')}</td>
                        </tr>
                    </tbody> 
                </Table></Card>
            </Col>
            </Row>
        ); 
    }         
    let spinnerClass = state.uploading ? "collapse.show" : "collapse";
    return ( 
        state.uploading 
        ? <span><Spinner color="primary" />Loading...</span>
        : <Card className={"border mb-1 " + visibilityStyle}>
            {/* <CardHeader title="Upload CSV File" light={false} /> */}
            <CardBody tag={Form} className="bg-light"
            //  onSubmit={handleSubmit}
                >
                <Row form>
                    <Col xs={4}>
                        <ChooseFile 
                            text="Choose File"
                            display={!state.fileSelected}
                        />
                    </Col>
                    </Row>
                    <Row className="mt-0">
                    <Col>
                        {fileInfo}
                    </Col>
                </Row>
                <Row className={uploadButtonVisibilityStyle}>
                    {state.fileSelected && 
                            <Col sm={'auto'}>
                                <ChooseFile 
                                    text="Choose Different File"
                                />
                            </Col>
                    }
                    <Col sm={'auto'}>
                        <ButtonIcon color="primary" className="btn-round btn-sm text-center" color="primary" 
//                                color="falcon-default"
//                                size="sm"
                            transform={'grow-2'}
                            disabled={state.uploading}
                            icon="plus"
                            onClick={props.fileUpload}
                        >
                        Upload File
                        </ButtonIcon><Spinner className={"ml-2 " + spinnerClass} size="sm" color="primary" />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    ); 
} //end component

export default UploadFile;
 
