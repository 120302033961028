import React, {useState, useContext} from 'react';
import {UncontrolledTooltip , Button} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {CompanyContext} from '../../context/CompanyContext';

//import ConfirmationModal from './ConfirmationModal';

const SimpleModalButtons = ({modalType, toggle, id, filteredIds=[], description='', setMessage, setReadTable, setRefetchCounts}) => 
{
    //const id = rowData && rowData._id ? rowData._id.toString() : '';
//    const company = rowData.Name;

    const { api, tableOrigin, setErrorLevel, setCurrentCompany, setCurrentContact, setPatchArray
    } = useContext(CompanyContext);

    const history = useHistory();
    const actionDescription = description || 'Move Company';

    const functionRouter = async ()=> {
        if (modalType === 'moveAllToActive') await api.moveAllToActive(tableOrigin, filteredIds, setMessage, setErrorLevel);
        else if (modalType === 'moveToActive' || modalType === 'moveToImports')
            await api.moveToActiveImports(tableOrigin, id, setMessage, setErrorLevel);
        else if (modalType === 'moveToDeleted') await api.removeCompanyFromColl(tableOrigin, id, setMessage, setErrorLevel);
        setCurrentCompany({});
        setCurrentContact({});
        setPatchArray([]);
        setReadTable(true);
        setRefetchCounts(true);
        if (tableOrigin === 'Active' && modalType === 'moveToImports') history.push('/admin/active/ThumbsUp');
        else if (modalType === 'moveToActive' || modalType === 'moveAllToActive') history.push('/admin/imports/Recent');
        if (tableOrigin === 'Active') history.push('/admin/active/ThumbsUp');
        else history.push('/admin/imports/Recent');
    }
    
    return (
        <React.Fragment>

            <div className="text-right pr-3">
            <Button id="no" outline={true} size="sm" 
                key="modal_no_cancel_button"
                color="primary"
                className="btn btn-sm m-2"
                onClick={() => { 
                    toggle(); }
                }
            >No</Button>
                <UncontrolledTooltip 
                    placement="top"
                    key="no_button_tooltip"
                    target="no"
                >Don't {actionDescription}</UncontrolledTooltip>
            <Button id="yes" outline={true} size="sm" 
                key="modal_ok_yes_button"
                color="primary"
                className="btn btn-sm m-1"
                onClick={async () => { 
                    await functionRouter();
                    toggle();
                }}
            >Yes</Button>
                <UncontrolledTooltip
                    key="modal_ok_yes_button_tooltip"
                    placement="top"
                    target="yes"
                >{actionDescription}</UncontrolledTooltip>
            </div>
        </React.Fragment>
    ) //end return

} //end function

export default SimpleModalButtons;
