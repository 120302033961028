import React from 'react';
import {Button} from 'reactstrap';

const navButtons = ({nextContact, prevContact, prevButtonEnabled, nextButtonEnabled, setRenderSaveCancel, confirmationModal, showConfirmationModal, navFunction}) => {

    return (
        <div className="fs--1 text-left pl-4">
            {/* {confirmationModal ?  */}
            {/* : null
            } */}
            {/* Navigation buttons here */}
            <Button size="sm" outline color="primary" onClick={(e)=>prevContact(false)} disabled={!prevButtonEnabled}>{`<`}</Button>
            &nbsp;
            <Button size="sm" outline color="primary" onClick={(e)=>nextContact(false)} disabled={!nextButtonEnabled}>{`>`}</Button>
        </div>

    );
}

export default navButtons;