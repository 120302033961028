import React //, {useState} 
    from 'react';
import {Row, Col} from 'reactstrap';
import TagsInput from 'react-tagsinput';

const FilterSection = ({filters, ref, visibleColumns, columns}) => {
    let tempTags = filters.map(item=>`${item.id}: ${item.value}`);
    // console.log('tempTags');
    // console.dir(tempTags);
    //const [tags, setTags] = useState(tempTags);

    const deleteFilter = column=>{
        //need to gain access to the setFilter function for the deleted filter column
        console.log('column = ' + column);
        let ndx = columns.findIndex(elem=>elem.id === column || elem.accessor === column);
        if (ndx !== -1) {
            visibleColumns[ndx].filterValue = undefined;
            visibleColumns[ndx].setFilter(undefined);
//            console.log('filterValue', visibleColumns[ndx].filterValue);
            //visibleColumns[ndx].columnRef.current.props.onChange({target: {value: ""}})
            //visibleColumns[ndx].columnRef.current.state.selectedIndex = -1;
//            console.log('selected index', visibleColumns[ndx].columnRef.current.selectedIndex);
            if (visibleColumns[ndx].columnRef) visibleColumns[ndx].columnRef.current.selectedIndex = 0;
//            console.log('filterValue', visibleColumns[ndx].filterValue);
        }
        console.log(ndx);
    }

    const handleChange = tags => {
        // console.log('here are the tags: ' + tags);
        // console.log('here are the filters: ' + JSON.stringify(filters, null, '\t'));
        // alert('in handleChange ' + filters.length);
//        setTags([...tags]);
        let tempFilters = [...filters];
        for (let i=0; i<tempFilters.length; i++) {
            console.log(tempFilters[i].id + ': ' + tempFilters[i].value)
            if (!tags.includes(tempFilters[i].id + ': ' + tempFilters[i].value)) {
                deleteFilter(tempFilters[i].id);
                break;
            }
        }
    }

    // console.log('tags.length = ' + tags.length);
    // console.log('filters.length = ' + filters.length);
    return (
        <Row className="mt-2 mb-0">
            <Col xs={12}>
                <div className="form-inline">
                    <span className="ml-1 mr-2"><strong>Filters:</strong></span>
                    <TagsInput 
                        value={tempTags} onChange={handleChange} 
                        tagProps={{ className: "ml-1 react-tagsinput-tag info", style: {opacity:.7}}}
                    />
                </div>
            </Col>
        </Row>
    )
} //end FilterSection

export default FilterSection;