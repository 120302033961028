import React, {useState, useEffect, useContext} from 'react';
import {Card, CardHeader, CardBody, Row, Col, Dropdown, DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {CompanyContext} from '../../context/CompanyContext';
import {dangerColor, warningColor, infoColor, primaryColor } from '../../common/tableFormats';
//import {patchCompany} from '../../common/ApiFunctions';
import './dropdowns.css';

const ProspectStageDropDown = ({origin, currentCompany, setRowData, mode, setIsOpen, isOpen, handleToggle, setMessage, setChangeMade}) => {
    const { api,
        addChangeToPatch, setSingleField,
//        patchArray
    } = useContext(CompanyContext);
    
    const [currentStage, setCurrentStage] = useState(currentCompany.ProspectStage);
    const [company, setCompany] = useState(currentCompany.Name);
    const [companyId, setCompanyId] = useState(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);
    const [, setHistory] = useState(currentCompany.History);

    useEffect(()=>{
        if (currentCompany) {
            setCurrentStage(currentCompany.ProspectStage);
            setCompany(currentCompany.Name);
            setCompanyId(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);
            setHistory(currentCompany.History);
        } //end if
    }, [currentCompany]);

    //console.log('Stage', origin, company, currentStage);

    // const dropdownStyle = dateColumn ? {minHeight:375, minWidth: 280} : {};
    // const justifyStyle = dateColumn ? "justify-content-left" : "justify-content-center";

    const getStageInfo = stage => {
        let retObj = {};
        switch (stage) {
            case '0-No Contact':
                retObj = {
                    color: primaryColor,
                    badgeColor: 'primary',
                    icon: 'phone-slash',
                    text: 'No Contact',
                    popover: 'No Contact Attempted',
                }
                break;
            case '1-1st Contact':
                retObj = {
                    color: primaryColor,
                    badgeColor: 'primary',
                    icon: 'phone',
                    text: '1st Contact',
                    popover: 'First Contact Attempted',
                }
                break;
            case '2-2nd Contact':
                retObj = {
                    color: primaryColor,
                    badgeColor: 'primary',
                    icon: 'comments-dollar',
                    text: '2nd Contact',
                    popover: 'Second Contact Attempted',
                }
                break;
            default:
                retObj = {
                    color: primaryColor,
                    badgeColor: 'primary',
                    icon: 'question',
                    text: stage,
                    popover: `${stage} is not a valid Stage.  This is an error`,
                }
        } //end switch()
        return retObj;
    }

    const stageArray = ["0-No Contact", "1-1st Contact", "2-2nd Contact"].filter(item=>!currentStage || item !== currentStage);

    const buildUpdateDoc = newStage => {
        setCurrentStage(newStage);
        //if (origin === 'Modal') setRowData({...currentCompany, ProspectStage: newStage });
        return {
            // History: setHistory(`Changed ProspectStage from ${currentStage} to ${newStage}`, history),
            ProspectStage: newStage
        }
    }
    return (
        <UncontrolledDropdown className="d-inline">
            <DropdownToggle
                tag="span"
                key={`stage_dd_${companyId}`}
                aria-haspopup={true}
                data-toggle="dropdown"
                id="StageDropdown"
                className={'p-0 m-0'}
                style={{valign: 'top', border: 1, 
                    //backgroundColor: 'transparent'
                }}
            >{origin === 'Modal' ? 
                <div className="p-2 pb-0 mb-0 card border" 
                    style={{width: 138,  
                    backgroundColor: 'transparent', 
                    //zIndex: 10000, 
                    "&:hover": {backgroundColor: 'transparent'}}}
                >
                    <h6 className="ml-1 mr-1 mb-0 pb-0">
                        <div className="pb-2">Stage</div>
                        {currentStage !== undefined ? <FontAwesomeIcon 
                            id="stage_icon" icon={getStageInfo(currentStage).icon} 
                            transform="grow-6"   //getStatusIcon(status)
                            //className="ml-1 mr-1" 
                            style={{color:getStageInfo(currentStage).color}}
                        /> : <span></span>
                        }
                        <div className="text-center mt-1 pt-2"
                            style={{
                                whiteSpace: 'nowrap',
                                fontSize: '.85rem',
                            }}
                        >
                        {getStageInfo(currentStage).text}                    
                        </div>
                    </h6> 
                </div>
                : currentStage !== undefined 
                ? //else this is from the detail line
                <div key={`stage_div_${companyId}`} 
                    className={"hover-pointer text-left badge badge-pill m-0 p-1 rounded-capsule badge-" + getStageInfo(currentStage).badgeColor} 
                    data-html={true} data-toggle="popover" title={getStageInfo(currentStage).popover} data-content="content"
                    style={{opacity: 0.7, width: '98px'}}
                >
                    <FontAwesomeIcon key={`tableIcon_${companyId}`} icon={getStageInfo(currentStage).icon} transform="grow-2" 
                        className="ml-1 mr-1" 
                    />
                    {getStageInfo(currentStage).text}
                    </div>
                : null}

            </DropdownToggle>
            <DropdownMenu 
                persist
                right={origin !== 'Modal' ? true : false} 
                aria-labelledby="CRMStatusDropdown"
                className={`m-0 p-0 ${origin==='Modal' ? "mt-1" : "mt-2"}`}
                tag="div" 
            >
            <DropdownItem tag="div" className="m-0 p-0 pt-0 mt-0 pt-0 card">
            <div className={"p-0 pt-3 mt-0 pb-0 mb-0 card"} 
                style={{ minWidth: 375, maxHeight: 150, 
                    // backgroundColor: 'white', 
                    zIndex: 10001, 
                    // "&:hover": {backgroundColor: 'white'}
                }}
                >
                <div className="d-inline card-header p-0 mx-2 px-2" titletag="h6" light="false">
                    <button type="button" className="close d-inline" aria-label="Close" 
                        //onClick={()=>{handleToggle(mode, false)}}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>{`Change Prospect Stage of ${currentStage} for: `}</div>
                    <div><strong>{`${company}`}</strong></div>
                </div>
                <div className="card-body p-0 mx-2 px-2 mb-0 pb-0">
                    <Row>
                    {stageArray.map(stage => (
                    <Col key={`Col_${stage}_${companyId}`} className="justify-content-center"><span key={`span_${stage}_${companyId}`} className="justify-content-center ml-2 mr-2">
                        <div key={`div_${stage}_${companyId}`} className="w-100 text-center">
                            <FontAwesomeIcon 
                                key={`icon_${stage}_${companyId}`}
                                id={`${stage}_${companyId}`} icon={getStageInfo(stage).icon} transform="grow-4" 
                                className="ml-1 mr-1" style={{color:getStageInfo(stage).color}}
                                onClick={async e=>{
                                    if (origin === 'Modal') { 
                                        setChangeMade(true);
                                        setSingleField('ProspectStage', stage);
                                        //setUpdateObject(buildUpdateDoc(stage)); 
                                    }
                                    else { 
                                        const msg = company + ' Has Been Updated to Prospect Stage: ' + stage;
                                        const patch = addChangeToPatch('ProspectStage', stage);
                                        await api.patchCompany(companyId, patch);
                                        setRowData('ProspectStage', stage)
                                        setMessage(msg);
                                    } 
                                    //e.stopPropagation();
                                    //handleToggle(mode, false);  //now close the dropdown after we are done
                                }}
                            />
                        </div>
                        <div key={`div2_${stage}_${companyId}`} className="text-center">
                            <small>{getStageInfo(stage).text}</small>
                        </div>
                    </span></Col>)
                )}
                    </Row>
                </div>
            </div></DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default ProspectStageDropDown;