import React //, {useState} 
  from 'react';
import {Table, Row, Col} from 'reactstrap';
import {toDateString} from '../../common/tableFormats';
// import is from 'is_js';

const NotesModal = ({data, mode}) => {
  //first we must see if there are any notes in the data.  If so, get values.
  const notesArray = data && data.Notes ? [...data.Notes] : [];

  if (!notesArray || (notesArray && !notesArray.length))
    return <div className="text-center">No Notes</div>
    else return (
  <div style={{minHeight:300, maxHeight:600, overflowX: 'auto'}}>
  <Table striped>
    <thead>
      <tr>
          <th>
              <Row>
                <Col md="2">Date</Col>
                <Col md="10">Note Text</Col>
              </Row>
          </th>
      </tr>
    </thead>
    <tbody>
      {notesArray.map((row, i)=>{ 
        return (
        <tr key={i} >
            <td>
              <Row style={{minHeight: 150}}>
                <Col md="2">{toDateString(new Date(row.noteDate))}</Col>
                <Col md="10"><textarea rows="6" cols="80" name={`note_${i}`} value={row.noteText}></textarea></Col>
              </Row>
            </td>
        </tr>
        )     
      })}
    </tbody>          
  </Table>
  </div>
)

}

export default NotesModal;