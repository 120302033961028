import React, {Fragment, useState, useEffect, useRef} from 'react';
import {Card, Row, Col, Input,} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toDateString, primaryColor} from '../../common/tableFormats';

const ContactDetail = ({contact, i, fieldChanged, editMode, fieldBlur}) => {
    const [Cellphone, setCellphone] = useState(contact.Cellphone || '');
    const [LinkedInUrl, setLinkedInUrl] = useState(contact.LinkedInUrl || '');
    const [Notes, setNotes] = useState(contact.Notes ? contact.Notes[0] : '');
    const [rowKey, setRowKey] = useState(contact.key);

    const ref = useRef();

    const insertDate = e=> {
        const insertedDate = toDateString(new Date(), 2, true);
        const newNotes = insertedDate + ': \n' + (Notes ? Notes : '');
        fieldChanged(e, i, 'Notes', Notes, setNotes, newNotes);
        ref.current.selectionStart = ref.current.selectionEnd = insertedDate.length+2;
        ref.current.focus();
    }

    useEffect(()=>{
        setCellphone(contact.Cellphone || '');
        setLinkedInUrl(contact.LinkedInUrl || '');
        setNotes(contact.Notes ? contact.Notes[0] : '');
        setRowKey(contact.key);
    }, [contact]);

    return (
        <Card>
            <Row>
            <Col>
                {/* <Row>
                    <Col className="text-right mr-1 pr-0"><strong>Job Title:</strong></Col>
                    <Col className="text-left ml-1 pl-0">{contact.JobTitle}</Col>
                </Row> */}
                {(editMode === true) 
                ? <Fragment>
                    <Row>
                        <Col sm="4" className="text-right mr-1 pr-0"><strong>Cell Phone:</strong></Col>
                        <Col className="text-left ml-1 pl-0">
                            <div style={{fontSize: '.8rem',}}>
                                <Input type="text" placeholder="999-999-9999" size="12" value={Cellphone}
                                    key={`${rowKey}_contact_detail_cellphone_input`}
                                    onChange={(e)=>fieldChanged(e, i, 'Cellphone', Cellphone, setCellphone)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" className="text-right mr-1 pr-0"><strong>LinkedIn URL:</strong></Col>
                        <Col className="text-left ml-1 pl-0">
                            <div style={{fontSize: '.8rem',}}>
                                <Input type="text" placeholder="LinkedIn URL" size="20" value={LinkedInUrl}
                                    key={`${rowKey}_contact_detail_linkedin_input`}
                                    onChange={(e)=>fieldChanged(e, i, 'LinkedInUrl', LinkedInUrl, setLinkedInUrl)}
                                />
                            </div>
                        </Col>
                    </Row>
                </Fragment>                
                : <Fragment>
                    <Row>
                        <Col sm="4" className="text-right mr-1 pr-0"><strong>Cell Phone:</strong></Col>
                        <Col className="text-left ml-1 pl-0">{Cellphone}</Col>
                    </Row>
                    <Row>
                        <Col sm="4" className="text-right mr-1 pr-0"><strong>LinkedIn URL:</strong></Col>
                        <Col className="text-left ml-1 pl-0">{LinkedInUrl}</Col>
                    </Row>
                </Fragment>
                }
                </Col>

            <Col className="d-inline">
                <Row>
                    <Col className="">
                        <strong>Notes:</strong>
                    </Col>
                    {(editMode === true) ?
                    <Col className="text-right mr-3">
                        <FontAwesomeIcon 
                            id={`contact_${i}`} 
                            icon={'calendar-day'} transform="grow-4" 
                            className="ml-1 mr-0" style={{color:primaryColor}} 
                            onClick={insertDate}
                        />
                    </Col>
                    : ''
                    }
                </Row>
                <Row key={`${rowKey}_contact_detail_notes_row`}><Col>
                {(editMode === true) ? 
                (<textarea type="textarea" 
                    key={`${rowKey}_contact_detail_notes_textarea`}
                    ref={ref} rows="4" cols="46" placeholder="enter notes here..." value={Notes}
                    onChange={(e)=>fieldChanged(e, i, 'Notes', Notes, setNotes)}
                    onBlur={fieldBlur}
                >
                </textarea>)
                : <div
                    key={`${rowKey}_contact_detail_notes_display`}
                >{Notes}</div>
                }</Col></Row>
            </Col></Row>
        </Card>
    )
}

export default ContactDetail;