import React, {Fragment, useState, useContext, useEffect} from 'react';
import {Row, Col, Table, Card, CardBody, CardHeader} from 'reactstrap';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CompanyContext} from '../../context/CompanyContext';
import EditFieldRow from './EditFieldRow';
import {primaryColor, toDateString} from '../../common/tableFormats';     //, infoColor, primaryColor, warningColor

import '../../assets/css/tableHover.css';

const ContactMasterDetail = ()=>{

//    const [selectedIndex, setSelectedIndex] = useState(0);
    const {
        currentCompany, setCurrentCompany, currentContact, setCurrentContact, lastItems, setLastItems,
        setErrorMessage, setAlertVisible, setErrorLevel, addChangeToPatch, setSingleField
      } = useContext(CompanyContext);

    const [selectedRowKey, setSelectedRowKey] = useState(currentContact ? currentContact.key : '');
    const [prevContact, setPrevContact] = useState((currentContact !== null && currentContact !== undefined) ? {...currentContact} : {});
    
    useEffect(()=>{
        if (currentCompany) {
            let current = -1;
            if (currentCompany.Contacts && currentCompany.Contacts.length) current = currentCompany.Contacts.findIndex(item=>item.defaultContact === true);
            if (current === -1 && ((currentCompany.Contacts && currentCompany.Contacts.length) === true)) {
                current = 0;
            }
            //alert(currentCompany.Name + ' ' + JSON.stringify(currentCompany.Contacts[current], null, 2));
            if (current !== null) {
                const newRec = {}
                if (currentCompany !== null && currentCompany !== undefined && currentCompany.Contacts && currentCompany.Contacts[current]) {
                    Object.keys(currentCompany.Contacts[current]).forEach(key =>{
                        newRec[key] = currentCompany.Contacts[current][key] ? currentCompany.Contacts[current][key] : '';
                    });
                    setCurrentContact(newRec);
                    setLastItems(prev=>({...prev, contactIndex: current}));
                } 
            }
            else setCurrentContact({});
        }
        else {
            setCurrentContact({});
        }
    }, []);

    useEffect(()=>{
        //setPrevContact({...currentContact});
        if (currentCompany && currentCompany.Contacts && prevContact) {
            //alert(Object.keys(currentCompany).join(','));
            const newContacts = [...currentCompany.Contacts];
            const ndx = newContacts.findIndex(item=>item.key === prevContact.key);
            if (JSON.stringify(prevContact) !== JSON.stringify(newContacts[ndx])) {
                newContacts[ndx] = {...prevContact};
                setCurrentCompany(prev=>({...prev, Contacts: [...newContacts]}));
                //alert('setting');
            }
        }
    }, [lastItems.contactIndex]);
    
    const modifiedDateLine = (
        <p className="m-0 p-0" style={{
          whiteSpace: 'nowrap',
          fontSize: '.9rem',
        }}
        ><small>Last Modified: {toDateString(currentContact.UpdateDate, 4)}</small></p>
      );

    const deleteContactEvent = (e, i) => {
        //e.stopPropogation();
        const contactToDelete = currentCompany.Contacts[i];

        const contactName = `${contactToDelete.First} ${contactToDelete.Last}`;
        addChangeToPatch(`Contacts/${contactToDelete.origIndex}`, null, "remove", contactToDelete.key);
        const contacts = [...currentCompany.Contacts];
        contacts.splice(i,1);
        setAlertVisible(true);
        setErrorMessage(`${contactName} will be deleted when you Save. To cancel this delete, simply press Cancel instead`);
        setErrorLevel("dark");
        setTimeout(()=>{
            setAlertVisible(false);
        }, 5000);
        setCurrentCompany(prev=>({...prev, Contacts: contacts}));
        setLastItems(prev=>({...prev, contactIndex: 0}))
    }

    const setDefaultContact = value => {
        if (!currentCompany.Contacts || currentCompany.Contacts.length <= 1) return {valid: true, newValue: null};
        if (value === true) {
            //if this has been set to true and there is more than 1 contact, we need to set the other contact's field to false
            let defaultFound = false;
            const contacts = currentCompany.Contacts;
            const newContacts = contacts.map(item=>{
                console.dir(['default', item])
                if (item.defaultContact === true && currentContact.key !== item.key) {
                    item.defaultContact = false;
                    defaultFound = true;
                    const modifiedContact = {...item};
                    delete modifiedContact.origIndex;
                    delete modifiedContact.newContact;
                    addChangeToPatch(`Contacts/${item.origIndex}`, {...modifiedContact, defaultContact: false}, item.newContact === true ? "add" : "replace", item.key);
                }
                return item;
            })
            if (defaultFound) {
                setCurrentCompany(prev=>({...prev, Contacts: [...newContacts]}));
            }
            return {valid: true, newValue: null}
        }
    }
    return (
        <div style={{height: 520}} className="text-center p-0 m-0">
            <Card className="p-0 border">
                <Row className="m-0 p-0" style={{height: 200, overflowY: 'auto', overflowX: 'hidden'}}>
                    <Col>
                        <Table size="sm"
                            hover
                            striped
                            className="ml-0 mr-0 pl-0 pr-0 table-hover"
                        >
                            <thead>
                            <tr>
                                {/* <th key='th_blank'>{' '}</th> */}
                                {/* <th key='th_ndx' className="text-left">ndx</th> */}
                                <th key='th_first' className="text-left">First</th>
                                <th key='th_last' className="text-left">Last</th>
                                <th key='th_title' className="text-left">Job Title</th>
                                <th key='th_phone' className="text-left">Phone</th>
                                <th key='th_email' className="text-left">Email</th>
                                <th key='th_actions'>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentCompany.Contacts ?
                                currentCompany.Contacts.map((row, i)=>{ 
                                //okay we need to either display from the currentContact or the data that is in the currentCompany.Contacts array
                                return ( 
                                <tr key={row.key + '_' + i + "_tr"} onClick={()=>{
                                        setPrevContact({...currentContact});
                                        setCurrentContact({...currentCompany.Contacts[i]});
                                        setSelectedRowKey(currentCompany.Contacts[i].key);
                                        setLastItems(prev=>({...prev, contactIndex: i}));
                                    }}
                                    style={{backgroundColor: (i===lastItems.contactIndex) ? primaryColor : 'transparent',
                                            color: (i===lastItems.contactIndex) ? 'white' : 'black'}}
                                >
                                    {/* <td key={`${row.key + '_' + i}_ndx_disp_td`}><div className="text-left" style={{ //whiteSpace: 'nowrap',
                                        fontSize: '.8rem'}}>{(currentContact && currentContact.key === row.key) ? currentContact.origIndex :row.origIndex}</div>
                                    </td> */}
                                    <td key={`${row.key + '_' + i}_first_disp_td`}><div className="text-left" style={{ //whiteSpace: 'nowrap',
                                        fontSize: '.8rem'}}>{(currentContact && currentContact.key === row.key) ? currentContact.First : row.First}</div>
                                    </td>
                                    <td key={`${row.key + '_' + i}_last_disp_td`}><div className="text-left" style={{ //whiteSpace: 'nowrap',
                                        fontSize: '.8rem'}}>{(currentContact && currentContact.key === row.key) ? currentContact.Last : row.Last}</div>
                                    </td>
                                    <td key={`${row.key + '_' + i}_jobtitle_disp_td`}><div className="text-left" style={{ //whiteSpace: 'nowrap',
                                        fontSize: '.8rem'}}>{(currentContact && currentContact.key === row.key) ? currentContact.JobTitle : row.JobTitle}</div>
                                    </td>
                                    <td key={`${row.key + '_' + i}_phone_disp_td`}><div className="text-left" style={{ whiteSpace: 'nowrap',
                                        fontSize: '.8rem'}}>{(currentContact && currentContact.key === row.key) ? currentContact.Phone : row.Phone}</div>
                                    </td>
                                    <td key={`${row.key + '_' + i}_email_disp_td`}><div className="text-left" style={{ //whiteSpace: 'nowrap',
                                        fontSize: '.8rem'}}>{(currentContact && currentContact.key === row.key) ? currentContact.Email : row.Email}</div>
                                    </td>

                                        <td key={`${row.key + '_' + i}_trash_icon_td`}>
                                        <FontAwesomeIcon 
                                            key={`${row.key + '_' + i}_trash_icon`} 
                                            icon={'trash-alt'} transform="grow-2" 
                                            className="ml-2 mr-2" style={{color: (i===lastItems.contactIndex) ? 'white' : 'black'}} 
                                            onClick={(e)=>deleteContactEvent(e, i)}
                                        />
                                    </td>
                                </tr>)
                            }) :
                            <tr></tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card>
            <Card className="border p-1 mt-0 pt-0" style={{height: 298}}>
                <CardHeader className="pt-0 pb-2">
                {(currentContact && currentCompany.Contacts && currentCompany.Contacts.length) ?
                    // <h6 className="text-center">
                    //     Selected Contact:  {`${currentContact.First} ${currentContact.Last}`}
                    // </h6>            
                    <Row>
                        <Col sm={"4"} className="text-left mb-0 pb-0">
                            {/* <h5 className="text-left mb-0 pb-0">{`${currentContact.First} ${currentContact.Last} ${(currentContact.JobTitle ? '- ' + currentContact.JobTitle : '')}`}</h5> */}
                            <h5 className="text-left mb-0 pb-0">{`${currentContact.First} ${currentContact.Last}`}</h5>
                            <div className="mt-0 pt-0 pb-1">{modifiedDateLine ? modifiedDateLine : null}</div>
                        </Col>          
                        <Col sm="4" className="pt-1">
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={3}
                                    dbfield="defaultContact"
                                    rowKey={selectedRowKey}
                                    label="Default Contact"
                                    type="switch"
                                    onChangeHook={(value)=>setDefaultContact(value)}
                            />
                        </Col>                                      
                        <Col sm="4" className="pt-1 ml-0 pl-0">
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={3}
                                    dbfield="syncContact"
                                    rowKey={selectedRowKey}
                                    label="Sync Contact"
                                    type="switch"
                            />
                        </Col>                                      
                    </Row>                    
                :   <h6 className="text-center">
                        There are no contacts for this company
                    </h6>         
                }   
                </CardHeader>
                <CardBody className="mt-0 pt-0" style={{overflowX: 'hidden'}}>
                {currentContact && currentCompany.Contacts && currentCompany.Contacts.length ?
                <Fragment>
                    <Row>
                        <Col className="px-3 py-0 my-0">
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={2}
                                    dbfield="First"
                                    rowKey={selectedRowKey}
                                    label="First Name"
                                    placeholderText="Enter First Name"
                                    fieldwidth="15"
                            />
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={2}
                                    dbfield="Last"
                                    rowKey={selectedRowKey}
                                    label="Last Name"
                                    placeholderText="Enter Last Name"
                                    fieldwidth="15"
                            />
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={2}
                                    dbfield="JobTitle"
                                    rowKey={selectedRowKey}
                                    label="Job Title"
                                    placeholderText="Enter Job Title"
                                    fieldwidth="15"
                            />
                        </Col>
                        <Col>
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={2}
                                    dbfield="Email"
                                    rowKey={selectedRowKey}
                                    label="Email"
                                    placeholderText="Email"
                                    fieldwidth="15"
                            />
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={2}
                                    dbfield="Phone"
                                    rowKey={selectedRowKey}
                                    label="Direct Phone"
                                    placeholderText="Enter Direct Phone"
                                    fieldwidth="15"
                            />
                            <EditFieldRow
                                    arrayName={"Contacts"}
                                    numCols={2}
                                    dbfield="Cellphone"
                                    rowKey={selectedRowKey}
                                    label="Cell Phone"
                                    placeholderText="Enter Cell Phone"
                                    fieldwidth="15"
                            />
                        </Col>
                    </Row>
                <Row><Col>
                <EditFieldRow
                    arrayName={"Contacts"}
                    numCols={1}
                    dbfield="LinkedInUrl"
                    rowKey={selectedRowKey}
                    label="LinkedIn"
                    placeholderText="Enter LinkedIn URL"
                    fieldwidth="80"
                    weblink={true}
                    weblinkAsIs={true}
                />

                <EditFieldRow
                    arrayName={"Contacts"}
                    numCols={1}
                    dbfield="Notes"
                    rowKey={selectedRowKey}
                    label="Notes"
                    placeholderText="Enter Notes"
                    fieldwidth="80"
                    type="textarea"
                    rows="5" cols="59" 
                    fieldIsArray={true}
                    calendarIcon={true}
                /></Col></Row>
                </Fragment>
                : null }
                </CardBody>
            </Card>
        </div>
    )

} //end functional component

export default ContactMasterDetail;