import React, {useState, useEffect} from 'react';
//import {timeDifference, activeOrigin, importsOrigin} from './tableFormats';     //importsOrigin


export const useFetchDetail = ({initId, currentCompany, resetCurrentCompany, env, setFetchTime, table="master", api, org}) => {
  const [id, setId] = useState(initId);

  const initialUrl = `/aiLead/api/${table}/${id}`;
  const { fetchedData, isLoaded, fetchError, fetchErrorDetail, setReadTable, setFetchUrl } 
    = useFetch({initialUrl, setFetchTime, env, initialFetch: false, api});  

  useEffect(()=>{
    if (currentCompany && currentCompany._id) setId(currentCompany._id.toString());
  }, [currentCompany]);

  useEffect(()=>{
    if (id && env && id !== 'new') {
      setFetchUrl(`/aiLead/api/master/${id}`);
      setReadTable(true);
    }
  }, [id,env]);
//   useEffect(()=>{
//     alert(env);
//     if (id && id !== 'new' && org && env && (!currentCompany || (currentCompany && currentCompany._id && currentCompany._id.toString()) !== id)) {
// //      alert(JSON.stringify(['useFetchDetail', id, env, org ? org.name : null, (currentCompany && currentCompany._id) ? currentCompany._id.toString() : null  ]))
//       //alert(`1 - setting fetch detail - /aiLead/api/master/${id}`);
//       setFetchUrl(`/aiLead/api/master/${id}`);
//       setReadTable(true);
//     }
//   },[id, org, currentCompany]);

  useEffect(()=>{
    if (fetchedData && fetchedData.data && fetchedData.data[0]) {
      setReadTable(false);
      //alert('4 - results of fetch = ' + JSON.stringify(fetchedData.data, null, 2));
      resetCurrentCompany(fetchedData.data[0], 0, "");
    }
  }, [fetchedData]);

  return {detailLoaded: isLoaded, detailFetchError: fetchError, detailFetchErrorDetail: fetchErrorDetail, setId, setReadTable};
}

export const useFetchTableData = ({origin, isFirstRender, setFetchTime, env, records, setRecords, 
    navTable, setNavTable, activeFilterCard, lastItems, setLastItems, filterCardArray, org,
    setFilterCardArray, IndustryColors, initialFetch, counts, setCounts, refetchCounts, setRefetchCounts, 
    setMessage, table="master", api}) =>{
  //custom hook for fetching and maintaining the data in the active/imports
  
  const {countsCached, loadingCounts} = useRecordCounts(table, counts, setCounts, setMessage, api, refetchCounts, setRefetchCounts);

  // useEffect(()=>{
  //     if (counts) {
  //         alert(JSON.stringify(counts, null, 2));
  //         alert(countsCached);
  //     }
  // },[counts])

// const [recordsRead, setRecordsRead] = useState(-1);
  let initialUrl = `/aiLead/api/${table}/table/${activeFilterCard ? activeFilterCard : table === "master" ? "ThumbsUp" : "Recent"}`;

//  alert(initialUrl);

  const {addToNavTable, writeNavTable, filteredRows, setFilteredRows, currentNavKey, setWrite} = useNavTable({origin, navTable, setNavTable, orgId: api.getOrgId()});
  const [prevCard, setPrevCard] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(0);
  const [numRecords, setNumRecords] = useState(0);

  const { fetchedData, isLoaded, fetchError, fetchErrorDetail, setFetchUrl, setReadTable, setIsLoaded } 
    = useFetch({initialUrl, setFetchTime, env, initialFetch: false, api});  
    
  useEffect(()=>{
    if (activeFilterCard && activeFilterCard !== prevCard) {
      setPage(1);
      setPrevCard(activeFilterCard)
    }
  }, [activeFilterCard]);

  // useEffect(()=>{alert(isLoaded)}, [isLoaded]);

  useEffect(()=>{
      //alert(JSON.stringify([activeFilterCard, records.length] ));
      if (org && activeFilterCard) {    // && activeFilterCard !== prevCard
          setIsLoaded(false);
          setFetchUrl(`${`/aiLead/api/${table}/table/${activeFilterCard}?page=${page}`}`);
          setReadTable(true);
          //alert(`${`/aiLead/api/${table}/table/${activeFilterCard}?page=${page}`}`);
          setRecords([]);
      }
  }, [activeFilterCard, page]);   //

  useEffect(() => {
    if (!isFirstRender.current && fetchedData && (table in counts)) {
        //alert(fetchedData.data.length);
        //setIsLoaded(false);
        const newRecords = fetchedData.data;
        setPage(fetchedData.page);
        setPageCount(fetchedData.numPages);
        setNumRecords(fetchedData.numRecords);
        setStart(fetchedData.start);
        setEnd(fetchedData.end);
        setFetchTime(new Date());
//        setPageIndex(fetchedData.page-1);

        newRecords.forEach((item, i) => {
            //first let's stuff industry with right info
            let short = '';
            if (item.NAICS) {
                let ndx = IndustryColors.findIndex(ind=>ind.Code.toString() === item.NAICS.toString().substring(0,2));
                //console.log('ndx = ' + ndx);
                if (ndx > -1) short = IndustryColors[ndx].IndustryShort;
            }
            item.Industry = item.NAICS ? (item.NAICS.toString().substring(0,2) + '-' + short) : '';
            item.CRMStatus_open = false;
        });

        setRecords([...newRecords]);
        setLastItems(prev=>({...prev, numberActives: records.length, activesWritten: new Date()}));

        //alert(JSON.stringify(counts[table], null, 2));
        filterCardArray.map(item=>item.Number = counts[table].counts[item.Label]);

        setFilterCardArray([...filterCardArray]);
        setReadTable(false);
        setWrite(true);  //this will fire a useEffect hook that will write the navTable to localStorage
        setIsLoaded(true);
      } //end if (!isFirstRender.current)
  }, [fetchedData, isFirstRender, counts]);  //readTable

  useEffect(()=> {
      if (filteredRows && filteredRows.length) {
        let prevId = null, nextId = null, key=null;
        filteredRows.forEach((item, i)=> {
          key = item.original._id.toString();
          if (i>0) prevId = filteredRows[i-1].original._id.toString()
          else prevId=null;
          if (i < filteredRows.length-1) nextId = filteredRows[i+1].original._id.toString()
          else nextId=null;
          addToNavTable(i, key, prevId, nextId, filteredRows.length);
        });
        setWrite(true);  //this will fire a useEffect hook that will write the navTable to localStorage
        setIsLoaded(true);
      }
  }, [filteredRows.length]);

  return {isLoaded, page, setPage, pageCount, start, end, numRecords, fetchError, fetchErrorDetail, currentNavKey, 
    setReadTable, setRefetchCounts, filteredRows, setFilteredRows}
} //end useFetchTableData

export const useFetch = ({initialUrl, setFetchTime, env, initialFetch=true, api}) => {     
    const [url, setFetchUrl] = useState(initialUrl);
    //const [env, setEnv] = useState(initialEnv);
    const [fullUrl, setFullUrl] = useState('');
    const [fetchedData, setFetchedData] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [fetchError, setFetchError] = React.useState(null)
    const [fetchErrorDetail, setFetchErrorDetail] = React.useState(null)
    const [readData, setReadData] = useState(true);
    const [doFetch, setDoFetch] = useState(initialFetch);
    const [prevFullUrl, setPrevFullUrl] = useState(null);

    useEffect(()=>{
      if (env && url) {
        const tempUrl = `${api.getBaseUrl(env)}${url}`;
        setFullUrl(tempUrl);
        //alert('setting url ' + tempUrl)
      }
    }, [env, url]);

    useEffect(()=>{
      if (fullUrl && readData) {
        setDoFetch(true);
        //alert(fullUrl === prevFullUrl);
        //alert('retrieving ' + fullUrl);
        setPrevFullUrl(fullUrl);
        setReadData(false);
      }
    }, [fullUrl, readData]);

    useEffect(() => {
      const fetchData = async () => {
        //setIsLoaded(false);
        setFetchTime && setFetchTime(null);
        setFetchErrorDetail(null);
        setFetchError(null);
        try {
            const response = await api.axiosGet(fullUrl);
            const retData = response.data;
            if (retData.error) {
                if (retData.message) setFetchError(retData.message.toString());
                else setFetchError('Fetch unsuccessful');
            } else if (!retData.results) {
                if (retData.message) setFetchError(retData.message.toString());
                else setFetchError('No Records were returned');
            } else if (!retData.error) {
              setFetchTime && setFetchTime(new Date());
              setFetchedData(retData.results);
              //alert(JSON.stringify(['data', doFetch, fullUrl, retData.results.data.length]));
              setDoFetch(false);
              setReadData(false);
              setPrevFullUrl(fullUrl);
            } //end if
            // setIsLoaded(true);
          } catch (err) {
              setFetchErrorDetail(JSON.stringify((err ? err : {details: "No details available"}), null, 2));
              if (err.response && err.response.status && err.response.status >=400) {
                  setFetchError(`Status: ${err.response.status} - ${err.response.statusText} ${(err.response.data.message !== undefined ? ' - ' + 
                      err.response.data.message : '')}`);
              } else {
                  setFetchError(`Fetch ${err.toString()}`);
              }
              // setIsLoaded(true)
          }
      }
      //if (fullUrl && (readData || (doFetch && fullUrl !== prevFullUrl))) fetchData();   // 
      if (doFetch && fullUrl) fetchData();   
    }, [doFetch]);

  return { fetchedData, isLoaded, fetchError, fetchErrorDetail, setFetchUrl, setReadTable: setReadData, setIsLoaded }
}

//custom hook for saving the table keys (Actives/Imports) so that the user may navigate through them
export const useNavTable = ({origin, navTable, setNavTable, orgId}) => {
  //  const [navTable, setLocalNavTable] = useState({});
    const [currentNavKey, setCurrentNavKey] = useState(`${orgId}_nav_${origin}`);
    const [filteredRows, setFilteredRows] = useState([]);
    const [saveOrigin, ] = useState(origin);
    const [write, setWrite] = useState(false);

    useEffect(()=>{
      if (write) writeNavTable();
    }, [write]);
  
    //this will "reset" the navTable when the origin or activeFilterCard changes
    useEffect(()=>{
      setNavTable({});
      setCurrentNavKey(`${orgId}_nav_${origin}`);
    }, []);
  
//    addToNavTable(i, key, prevId, nextId, filteredRows.length);
    const addToNavTable = (i, key, prevId, nextId, length) => {
        //the following is to capture the navigation info to use in the modal
        const contactRec = [prevId, nextId];
        setNavTable(prev=>({...prev, [key]: contactRec})); 
    }
  
    const writeNavTable = async ()=> {
      //alert('writing nav table ' + JSON.stringify(navTable));
        // const startTime = Date.now();
        //setNavTable && setNavTable(navTable);
        if (navTable.length) alert(JSON.stringify(navTable.slice(0,5), null, 0))
        localStorage.setItem(currentNavKey, JSON.stringify(navTable));
        setWrite(false);
        // const endTime = Date.now();
        //console.log(`Elapse time to write ${Object.keys(navTable).length} to localStorage is ${endTime - startTime}`);
        // getTotalLocalStorage();
    }
  
    return {addToNavTable, writeNavTable, filteredRows, setFilteredRows, currentNavKey, setWrite};
  }
  

  export const useCachedData = (items, setItems, lsKeyName, getItemsInit, itemName='items', hours=1) => {
    const [getItems, setGetItems] = useState(getItemsInit);

    useEffect(()=>{
      const getCachedItems = async () => {
        //need to make sure that we have the lead sources, country codes, naics codes, etc.
        //first check localStorage.  Requery if date older than # hours in param -- 1 - Lead Sources, 30 days - Countries
        let lsObj = {};
        const itemLS = localStorage.getItem(lsKeyName);
        if (itemLS) {
          lsObj = JSON.parse(itemLS);
        } else {      //otherwise we need to get the data from the API
          lsObj = await getItems(lsKeyName); 
        }
        // if (lsObj && lsObj.data) alert(lsKeyName + ' ' + new Date(lsObj.data.timestamp).toLocaleString());
        // if (lsObj && lsObj.data) alert(lsKeyName + ' ' + (Date.now() - lsObj.data.timestamp) + " " + 1000 * 60 * 60 * hours);
        if (lsObj && lsObj.data && (Date.now() - lsObj.data.timestamp) > 1000 * 60 * 60 * hours) {
            //need to fetch data again
            lsObj = await getItems(lsKeyName); 
        } 
        if (lsObj && lsObj.data && lsObj.data[itemName] && lsObj.data[itemName].length) {
          setItems(lsObj.data[itemName]);
        }
      } //end getCachedItems
      if (!items || !items.length) getCachedItems();
    }, []);
  }

  //custom hook for reading naics to/from localstorage and returning it to whatever app that needs it
  export const useNaicsCodes = (setMessage, naicsCodes, setNaicsCodes, api)=> {
    const [naicsCached, setNaicsCached] = useState(false);
    if (!naicsCodes || (naicsCodes && !naicsCodes.length)) {
      let naics = localStorage.getItem('naicsCodes');
      if (naics) naics = JSON.parse(naics);
      setNaicsCodes(naics);
    }
    
    useEffect(() => {
        const getNAICS = async () => {
          try {
            //alert('retrieving NaicsCodes ')
            const itemUrl = "/aiLead/api/naics/table";
            const response = await api.axiosGet(itemUrl);
            const retData = response.data;
            setNaicsCodes([...retData.results.data]);
            await localStorage.setItem('naicsCodes', JSON.stringify(retData.results.data));
            setNaicsCached(true);
          } catch(err) {
            setMessage('Could not fetch NAICS Codes');
          }
        }
        if (!naicsCached && (!naicsCodes)) getNAICS();
      }, [naicsCached, naicsCodes]);
    
    return {};
  }

  //custom hook for reading naics to/from localstorage and returning it to whatever app that needs it
  export const useRecordCounts = (coll, counts, setCounts, setMessage, api, refetch, setRefetch)=> {
    const [countsCached, setCountsCached] = useState(false);
    const [collection, ] = useState(coll);
    const [loadingCounts, setLoadingCounts] = useState(true);
    const [fetch, setFetch] = useState(true)
    // useEffect(()=>{
    //   if (api && !counts || Object.keys(counts).length === 0) {
    //     const lsKey = `${api.getOrgId()}_${collection}_counts`;
    //     let savedcounts = localStorage.getItem(lsKey);
    //     if (savedcounts) { savedcounts = JSON.parse(savedcounts);
    //       setCounts(savedcounts);
    //       setCountsCached(true);
    //     }
    //   }
    // }, []);
    useEffect(()=>{
      if (refetch) setFetch(true);
    },[refetch])

    useEffect(async () => {
        const getCounts = async () => {
          try {
            const url = `/aiLead/api/counts/${collection}`;
            //alert(`retrieving Counts ${url}`);
            const response = await api.axiosGet(url);
            //alert(JSON.stringify(response));
            const retData = response.data;
            const countsRec = {
              [collection]: {
                counts: {...retData.results.data.numbers[collection]},
                timestamp: retData.results.data.timestamp
              }
            }
            //alert(JSON.stringify(countsRec, null, 2));
            setCounts(countsRec);
            setLoadingCounts(false);
            //const lsKey = `${api.getOrgId()}_${collection}_counts`;
            //localStorage.setItem(lsKey, JSON.stringify(countsRec));
            //setCountsCached(true);
            setFetch(false);
            setRefetch(false);
          } catch(err) {
            setMessage('Could not fetch Record Counts');
            setFetch(false);
          }
        }
        if (fetch) getCounts();
      }, [fetch]);
    
    return {countsCached, loadingCounts};
  }

