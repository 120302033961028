import React, {useState} from 'react';
import {Alert, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import UploadFile from './UploadFile';
import DisplayFileSample from './DisplayFileSample';
import MapColumns from './MapColumns';
import PreviewUpload from './PreviewUpload';
import ConfirmImport from './ConfirmImport';
import {useAuth} from '../../auth/AuthContext';
import axios from 'axios'; 
//import {getImportData, confirmImport, cancelImport, axiosPost} from '../../common/ApiFunctions';
import {toDateString } from '../../common/tableFormats';

//import {CompanyContext} from '../../context/CompanyContext';

const ImportModal = (props) => {

    // const {
    //     org,
    // } = useContext(CompanyContext);        

    const api = props.api;
    const {org, currentUser, setRefreshOrg} = useAuth();
    const orgId = org._id.toString();

    const [alertTimeout, setAlertTimeout] = useState(null);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertColor, setAlertColor] = useState('info');
    const [uploading, setUploading] = useState(false);
    const [compVisible, setCompVisibile] = useState(true);
    const [fileUploaded, setFileUploaded] = useState(null);
    const [uploadFileName, setUploadFileName] = useState(null);
    const [columns, setColumns] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [uploadComplete, setUploadComplete] = useState(false)

    const [fileData, setFileData] = useState([]);
    const [readyToMap, setReadyToMap] = useState(false);
    const [step, setStep] = useState('upload');
    const [columnMap, setColumnMap] = useState([]);
    const [mappingInfo, setMappingInfo] = useState({});
    const [previewData, setPreviewData] = useState([]);
    const [templateModified, setTemplateModified] = useState(false);
    const [saveTemplate, setSaveTemplate] = useState(true);
    const [importedData, setImportedData] = useState([]);
    const [dataPageNo, setDataPageNo] = useState(1);
    const [limit, setLimit] = useState(100);
    const [importId, setImportId] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);

    //steps are 'upload','display','map','preview','confirm'

    const [message, setMessage] = useState(true);
    const dismissAlert = () => setAlertVisible(false);

    const normalizeFileName = name => {
        if (!name) return '';
        name = name.toLowerCase();
        let regex = /(\s|\-|\;)/g
        let newName = name.replace(regex, '.');
        regex = /[\.]+/g
        newName = newName.replace(regex, '.');

        regex = /\.v[\d]+$/
        if (newName.search(regex) === -1) {
            newName += '.v0';
            regex = /[\.]+/g
            newName = newName.replace(regex, '.');
        }
        else {
            let match = newName.match(regex);
            if (match.length) {
                let vString = match[0]
                let vNum = +vString.replace('.v', '')
                vNum++;
                vString = '.v' + vNum.toString();
                newName = newName.replace(match[0], vString);
            }
        }
        return newName;
    }
    
    const createPreview = (mappingInfo)=> {
        setMappingInfo({...mappingInfo});
        //take mappingInfo and apply it to the fileData to create previewData
        const mapArr = [...mappingInfo.columnMap];
        //let's transform the columnMap from an array to an object;
        const mapObj = {}
        columns.map((item, i)=>{
            mapObj[item] = mapArr[i];
        });
        const preview = fileData.map(row=>{
            let newRow = {}
            for (let col in row) {
                //get the dest column to map to
                const dest = mapObj[col];
                newRow[dest] = row[col];
            }
            return newRow;
        });
        setPreviewData([...preview]);
        //alert(JSON.stringify(preview, null, 2));
        setStep('preview');
        announcement('Review the preview and Stage Data if correct')
    }

    const getMoreImportRecords = async () => {
        const page = dataPageNo+1;
        setDataPageNo(prev=>prev+1);
        const newRecs = await api.getImportData(importId, page, limit, orgId);
        //alert(JSON.stringify(newRecs, null, 2))
        if (newRecs && newRecs.data) {
            const data = flattenContact(newRecs.data.results.data);
            //alert(data.length);
            setImportedData(prev=>[...prev, ...data]);
        }
    }

    const setTemplateName = (name) => {
        setMappingInfo(prev=>({...prev,template: name}))
    }

    const importData = async ()=> {
        setUploading(true);
        announcement(`You have successfully imported data from ${fileUploaded} into Imports`);
        //this is where we post all the import metadata to the server and the server does the import with optional save to the import template
        //transaction will return an importId so that we can cancel this import
        const importMeta = {
            //field mappings, existing template (if any), save?
            orgId: org._id.toString(),
            userId: currentUser.uid,
            source: 'Customer',
            sourceField: null,
            saveTemplate,
            importDesc:  'Description goes here -- need to add this to UI',
            fileInfo: {
                name: selectedFile.name,
                uploadFile: uploadFileName,
                type: selectedFile.type,
                size: selectedFile.size,
                lastModifiedDate: new Date(selectedFile.lastModifiedDate),
            },
            mappingInfo,
        }

        //alert(JSON.stringify(importMeta, null, 2));
        //need to move this to apiFunctions after getting it working
        const url = '/aiLead/api/importCSVFile';
        const res = await api.axiosPost(url, importMeta, orgId);
        if (!res) {
            announcement('Unable to import data from ' + url);
            return false;
        }
        if (!res.data || !res.data.results) {
            announcement(JSON.stringify(returnObj));
            return false;
        }
        setUploading(false);
        setRefreshOrg(prev=>!prev);
        const returnObj = res.data.results;
        setImportId(returnObj.importId);
        //alert(JSON.stringify(returnObj.importLogRec, null, 2));
        setTotalRecords(returnObj.importLogRec.noRecsInserted);
        const data = flattenContact(returnObj.data);
        setImportedData(data);
        setDataPageNo(1);
        announcement(`${templateModified === true ? 'Template has been saved. ':''}We have staged ${returnObj.importLogRec.noRecsInserted} records.  Please review the data and press confirm if correct.`);
        setTemplateModified(false);
        setStep('confirm');
    }

    const flattenContact = data => {
        if (!data) return null;
        const newData = [];
        data.map(row=>{
            if (Object.keys(row).map(col=>{
                if (col === '-- IGNORE --') delete row[col];
            }));
            if (row.Contact) {
                const contact = row.Contact;
                if (contact.First) row.First = contact.First;
                if (contact.Last) row.Last = contact.Last;
                if (contact.JobTitle) row.JobTitle = contact.JobTitle;
                if (contact.Email) row.Email = contact.Email;
                if (contact.LinkedInUrl) row.LinkedInUrl = contact.LinkedInUrl;
                if (contact.Phone) row.Phone = contact.Phone;
                if (contact.Notes) row.Notes = contact.Notes;
                // if (contact.ImportId) row.ImportId = contact.ImportId;
                if (contact.CRMContactId) row.CRMContactId = contact.CRMContactId;
                if (contact.PhoneExists) row.PhoneExists = contact.PhoneExists;
                if (contact.EmailExists) row.EmailExists = contact.EmailExists;
                if (contact.key) row.key = contact.key;
                delete row.Contact
            }
            delete row._id
            delete row.importId
        });
        return data;
    }
    
    const confirmImportAction = () => {
        //alert('confirming import! ' + importId);
        api.confirmImport(importId, orgId);
        // if (res.data.message === "OK" && res.data.code === 200) {
            setUploadComplete(true);
            //props.setReadTable(true);
            announcement(`Import Confirmation was queued Import ID: ${importId}`);
        // } else {
        //     announcement(`Error finalizing importId ${importId}: ${res.data.error}`);
        //     alert(JSON.stringify(res, null, 2));
        // }
    }
    
    const cancelImportAction = async () => {
        const res = await api.cancelImport(importId, orgId);
        if (res.error) announcement(`Error cancelling importId: ${importId}: ${res.error}`)
        //alert(JSON.stringify(res, null, 2));
        setFileUploaded(null);
        setUploadFileName(null);
        setColumns([]);
        setSelectedFile(null);
        setSelectedTemplate({});
        setImportedData([]);
        setMappingInfo({});
        setPreviewData([]);
        setTemplateName(null);
        announcement('Import was cancelled.  You may start over or else close this import dialog.')
        setStep('upload');
    }
    
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const formatCell = (cell, name) => {
        if (cell && (typeof cell === 'number' || name.toLowerCase().indexOf('revenue') > -1)) return numberWithCommas(cell);
        if (cell && name.toLowerCase().indexOf('date') > -1) return toDateString(cell, 2);
        if (cell && typeof cell === 'string') {
            if (cell.length > 35) return cell.substr(0, 35) + '...';
        }
        return cell;
    }
    
    const announcement = (msg, color="success") => {
        if (alertTimeout) clearTimeout(alertTimeout);
        setAlertVisible(true);
        if (!msg) {
            setMessage('');
            setAlertVisible(false);
                return;
        }
        setMessage(msg);
        setAlertColor(alertColor);
        const to = setTimeout(()=>{
            dismissAlert();
            setAlertTimeout(null);
        },30000);
        setAlertTimeout(to);
    }

	// On file upload (click the upload button) 
	const fileUpload = async (err) => { 
        try {
            // Create an object of formData 
            const formData = new FormData(); 
            
            console.log('in file upload');
            // Update the formData object 
            formData.append(
                "fileUpload", 
                selectedFile, 
                selectedFile.name 
            ); 
            
            formData.append(
                "filename",
                selectedFile.name 
            );
            setUploading(true);
            // Send formData object 
            const res = await api.axiosPost("/aiLead/api/uploadImportFiles", formData)
            if (res) {
                //setState(prev=>({...prev, uploading: false}));
                let dt = new Date();
                //alert(JSON.stringify(res.data.results, null, 2));
                const msg = res.data.results.origFilename + ' has been uploaded at ' 
                    + dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString();
                setFileUploaded(res.data.results.origFilename);
                setUploadFileName(res.data.results.newFilename);
                setColumns([...res.data.results.info.columns]);
                setFileData([...res.data.results.info.data]);
                announcement(msg);
                setUploading(false);
                setStep('display');
            }
            else throw new Error('Unable to upload file');
        } catch(error)  {
            announcement(error.toString());
            console.log(error.toString());
            //setState(prev=>({...prev, uploading: false}));
        };
	}; //end fileUpload()

    return (
        <Modal
            isOpen={props.modal} toggle={() => props.setModal(prev=>!prev)} 
            backdrop={"static"}
            size="lg"
            className="mb-1"
        >
            <ModalHeader className="m-2 p-2 mb-1">
                <h5 className="text-center mb-0 pb-0">Import Data from CSV File</h5>
            </ModalHeader>
            <ModalBody className="m-2 p-2 mt-1 pt-1">
                <Alert color={alertColor} isOpen={alertVisible} toggle={dismissAlert}>
                        {message}
                </Alert>
                {(step === 'upload') ? 
                <UploadFile 
                    setFileToUpload={setFileUploaded}
                    setFileData={setFileData}
                    selectedFile={selectedFile}
                    uploading={uploading}
                    setSelectedFile={setSelectedFile}
                    compVisible={compVisible}
                    announcement={announcement}
                    setColumns={setColumns}
                    setData={setFileData}
                    fileUpload={fileUpload}
                    numberWithCommas={numberWithCommas}
                    step={step}
                    setStep={setStep}
                />
                : (step === 'display') ?
                <DisplayFileSample
                    announcement={announcement}
                    uploading={uploading}
                    setUploading={setUploading}
                    importedData={importedData}
                    selectedFile={selectedFile}
                    fileData={fileData}
                    columns={columns}
                    fileName={fileUploaded}
                    setFileToUpload={setFileUploaded}
                    setReadyToMap={setReadyToMap}
                    numberWithCommas={numberWithCommas}
                    formatCell={formatCell}
                    step={step}
                    setStep={setStep}
                />
                : (step === 'map') ? 
                <MapColumns 
                    announcement={announcement}
                    uploading={uploading}
                    selectedFile={selectedFile}
                    columns={columns}
                    fileData={fileData}
                    fileName={fileUploaded}
                    setReadyToMap={setReadyToMap}
                    importTemplates={[...org.importTemplates]}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    templateModified={templateModified}
                    setTemplateModified={setTemplateModified}
                    columnMap={columnMap}
                    setColumnMap={setColumnMap}
                    mappingInfo={mappingInfo}
                    setMappingInfo={setMappingInfo}
                    createPreview={createPreview}
                    normalizeFileName={normalizeFileName}
                    step={step}
                    setStep={setStep}
                />
                : (step === 'preview') ? 
                <PreviewUpload 
                    announcement={announcement}
                    uploading={uploading}
                    fileName={fileUploaded}
                    selectedFile={selectedFile}
                    columnMap={columnMap}
                    previewData={previewData}
                    mappingInfo={mappingInfo}
                    normalizeFileName={normalizeFileName}
                    templateModified={templateModified}
                    setTemplateModified={setTemplateModified}
                    setTemplateName={setTemplateName}
                    importData={importData}
                    numberWithCommas={numberWithCommas}
                    formatCell={formatCell}
                    saveTemplate={saveTemplate}
                    setSaveTemplate={setSaveTemplate}
                    step={step}
                    setStep={setStep}
                />                    
                : (step === 'confirm') ? 
                <ConfirmImport 
                    announcement={announcement}
                    fileName={fileUploaded}
                    uploading={uploading}
                    columnMap={columnMap}
                    importedData={importedData}
                    cancelImport={cancelImportAction}
                    confirmImport={confirmImportAction}
                    numberWithCommas={numberWithCommas}
                    templateModified={templateModified}
                    setTemplateModified={setTemplateModified}
                    formatCell={formatCell}
                    dataPageNo={dataPageNo}
                    limit={limit}
                    totalRecords={totalRecords}
                    getMoreImportRecords={getMoreImportRecords}
                    uploadComplete={uploadComplete}
                    setUploadComplete={setUploadComplete}
                    closeModal={props.setModal}
                    step={step}
                    setStep={setStep}
                />                    
                : null}
            </ModalBody>
            <div className="text-center mt-0 mb-0">
                <Button color="primary" className="btn-round text-center mt-0" 
                    onClick={() => props.setModal(prev=>!prev)}>
                    Close
                </Button>
            </div>
            {/* <ModalFooter className="align-center mt-0 mb-0"> */}
                {/* <Row>
                    <Col>fileUploaded: {fileUploaded}</Col>
                    <Col>readyToMap: {readyToMap}</Col>
                    <Col>fileData: {JSON.stringify(fileData)}</Col>
                </Row> */}
            {/* </ModalFooter> */}
        </Modal>
    );

} //end component

export default ImportModal;