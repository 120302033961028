import React, {Fragment, useState, useEffect, useRef, useContext} from 'react';
import NotificationAlert from "react-notification-alert";
import {useParams,  useHistory} from 'react-router-dom';
import "react-notification-alert/dist/animate.css";
//import axios from 'axios';

import { Row, Col, Spinner,
    // Card, CardBody, CardTitle, Container, CardFooter, InputGroup, Input, Form, Button, Tooltip, Alert 
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import Table from '../../components/Table';
import ActionModal from '../modal/ActionModal';
import SimpleModal from '../modal/SimpleModal';
import GenericDropDown from '../../components/dropdowns/GenericDropdown';
import CRMStatusDropDown from '../../components/dropdowns/CRMStatusDropDown.jsx';

import IndustryColors from "../../assets/data/IndustryColorPalette.json";
import FetchErrorDisplay from '../../components/fetchError/FetchErrorDisplay';
import {useFetchTableData, useCachedData, } from '../../common/apiHooks';


import  {alignLeft, datetimeFormatter, LabelFilter, SelectColumnFilter, checkMarkDisplay, primaryColor,
        formatWebsite, useNotification, useFilterCards,             //getIconColorTable, getImportsFilterCardArray, 
        companyFormatter, stateFormatter, importsOrigin as origin} from '../../common/tableFormats';
import TableActions from '../../components/dropdowns/TableActions';
import ImportModal from './UploadModal';

function App({setFetchTime, reload, setReload}) {

    const {org, orgId, currentUser, setOrgAfterLogin, env} = useAuth();
    const { api, 
        imports, setImports,
        importsNavTable, setImportsNavTable,
        importsCounts, setImportsCounts,
        currentImportsFilter, setCurrentImportsFilter,
        setCurrentCompany, setCurrentContact,
        refetchCounts, setRefetchCounts,
        setPatchArray,
        //currentSort, setCurrentSort,
        setErrorLevel,
        lastItems, setLastItems,
        iconColorTable, 
        importsFilterCardArray, 
        filterCardArray, setFilterCardArray,
        filters, setFilters,
        naicsCodes, setNaicsCodes,
        tableOrigin, setTableOrigin,
        fetchTable, setFetchTable,
    } = useContext(CompanyContext);        

    useEffect(async ()=>{
        if (!org && currentUser) {
            await setOrgAfterLogin(currentUser.uid)
            setReadTable(true);
        };
    },[org]);

    const history = useHistory();
    const { urlFilter } = useParams();

    setTableOrigin('Imports');
    useEffect(()=>{
        if (urlFilter === ':urlFilter') {
            if (currentImportsFilter) history.push(`/admin/imports/${currentImportsFilter}`);
            else {
                let filter = 'Recent';
                setCurrentImportsFilter(filter);
                history.push(`/admin/imports/${filter}`);
            }
        } else {
            setCurrentImportsFilter(urlFilter);
        }

        // if (urlFilter === ':urlFilter') {
        //     let filter = 'Recent';
        //     // if (currentImportsFilter) {
        //     //     filter = currentImportsFilter;
        //     // }
        //     setCurrentImportsFilter(filter);
        //     history.push(`/admin/imports/${filter}`);
        // } else {
        //     setCurrentImportsFilter(urlFilter);
        // }
    }, []);

    //    const perfectScroll = React.useRef(null);
    const notifyElem = React.useRef(null);
    const isFirstRender = React.useRef(true);
    const [showActionModal, setShowActionModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showSimpleModal, setShowSimpleModal] = useState(false);
    const [simpleRowData, setSimpleRowData] = useState({});

//    const [pageSizeArray, setPageSizeArray] = useState([10]);
    const initialPageSize = 25;
    const [pageSize, setPageSize] = useState(initialPageSize);          //pageSize

    const [visibleColumns, setVisibleColumns] = useState([]);
    const setVC = vc => {
        if (vc.length && !visibleColumns.length) setVisibleColumns([...vc]);
    }

    const {setNotificationMsg} = useNotification(isFirstRender, notifyElem);

    const setMessage = (msg) => {
        setNotificationMsg(msg);
    };

    const {isLoaded, fetchError, fetchErrorDetail, currentNavKey, setReadTable, filteredRows, setFilteredRows,
        page, pageCount, start, end, numRecords, setPage,
    } 
        = useFetchTableData({origin: tableOrigin, isFirstRender, setFetchTime, env, records: imports, setRecords: setImports, 
            navTable: importsNavTable, setNavTable: setImportsNavTable, lastItems, setLastItems, 
            filterCardArray: importsFilterCardArray, activeFilterCard: currentImportsFilter, 
            setFilterCardArray, IndustryColors, initialFetch:false, counts: importsCounts, org,
            setCounts: setImportsCounts, refetchCounts, setRefetchCounts, setMessage, table: "staged", api});

    useEffect(()=>{
        if (reload) {
            setRefetchCounts(true);
            setReadTable(true);
            setReload(false);
            setMessage('Data will reload momentarily...');
        }
    },[reload]);

    useEffect(()=>{
        const addToNavTable = (key, prevId, nextId) => {
            //the following is to capture the navigation info to use in the modal
            const contactRec = [prevId, nextId];
            setImportsNavTable(prev=>({...prev, [key]: contactRec})); 
        }
        if (filteredRows.length) {
            setImportsNavTable({});
            const rows = [...filteredRows];
            let prevId = null, nextId = null, key=null;
            const filteredIds = rows.map((item, i)=>{
                //if (i===0) alert(JSON.stringify(item.original, null, 2));
                key = item.original._id.toString();
                if (i>0) prevId = filteredRows[i-1].original._id.toString()
                else prevId=null;
                if (i < filteredRows.length-1) 
                    nextId = filteredRows[i+1].original._id.toString()
                else nextId=null;
                addToNavTable(key, prevId, nextId);
                return item.original._id
            });
            setLastItems(prev=>({...prev, filteredIds, filteredCount: filteredRows.length}));
            //need to save filteredIds in localStorage in case user accesses detail from URL
            localStorage.setItem(`${api.getOrgId()}_filteredIds`, JSON.stringify(filteredIds));
            //need to reset the navTable
        }
    }, [filteredRows.length]);

    const syncContact = async(rowData)=> {
        //call the method to sync this contact because it has been updated
        await api.syncAction(rowData._id.toString(), {}, {...rowData}, setMessage, rowData.Name, 'OnePageCRM', setReadTable, origin);
    }

    const updateCompany = async (_id, doc, rowData, msg=null) => {
        console.log('in ImportFilterTable update company. ' + Object.keys(rowData));
        //setMessage(`Updating Database for ${Name}`);
        await api.updateCompany(_id, doc, rowData, msg, setMessage, null, null, null, null, setReadRecordSurrogate, null, syncContact, origin, setMessage);
    } //end updateCompany()  

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    useFilterCards(urlFilter === ':urlFilter' ? (currentImportsFilter ? currentImportsFilter : 'Recent') : urlFilter, 
        visibleColumns, 'column', importsFilterCardArray, importsCounts, 'staged', currentImportsFilter, setCurrentImportsFilter,
        filters, setFilters);

    const setRowCell = (row, rowIndex, field, newVal) => {
        row.data[rowIndex][field] = newVal;
    }

    const statusFormatter = (row) => {
        const rowIndex = row.cell.row.index;
        const rowData = row.data[rowIndex];
        return (
        <GenericDropDown 
            mouseover={false}
            mode={'CRMStatus'}
        >
            <CRMStatusDropDown  
                origin={'Table'}
                mode={'CRMStatus'}
                currentCompany={rowData}
                setRowData={(field, newVal)=>setRowCell(row, rowIndex, field, newVal)}
                setMessage={setMessage}
                setUpdateObject={()=>{}}
                updateCompany={updateCompany}
                setChangeMade={()=>{}}
            />
        </GenericDropDown>
        );
    }    

    const industryFormatter = row=> {
        if (!row.value || row.value === "") return <div>{row.value}</div>;
        const rowIndex = row.cell.row.index;
        const NAICS = row.data[rowIndex].NAICS && row.data[rowIndex].NAICS.toString();

        //let's lookup the NAICS Code to display it in the popover
        let code = row.value.toString().substring(0,2);
        let obj = IndustryColors.filter(c=>c.Code === parseInt(code))[0];
        if (!obj || !('color' in obj)) {
            code = "81"
            obj = IndustryColors.filter(c=>c.Code === parseInt(code))[0];
        }
        const color = obj.color; //indColors[code].color;
    //        const color = 'default'
        const icon = obj.Icon;
        const short = obj.IndustryShort;

        //        const desc = `${row.value}: ${obj['Industry Title']}`;
        let popover = `${NAICS}: ${obj['Industry Title']}`;
        let ndx = naicsCodes.findIndex(item=>item.value.toString() === NAICS);
        if (ndx > -1) popover = `${NAICS}: ${naicsCodes[ndx].display}`; 
    //        return (<Button className={"ml-4 mr-4 text-center btn btn-sm"} style={{color:'white', backgroundColor:color}} data-toggle="popover" title={desc} data-content={desc}>{row.value}</Button>)
        return (
            <div className={"badge badge-pill ml-2 mr-2 pl-1 pr-2 rounded-capsule badge-" + color} 
                style={{opacity: 0.7}}
                data-html={true} data-toggle="popover" title={popover} data-content="content">
                <FontAwesomeIcon icon={icon} transform="grow-2" className="ml-1" />&nbsp;
                <span style={{color:'white',opacity:1.0}}>{short}</span>
            </div>
        );
    }

    const handleCompanyClick = row => {
        const rowIndex = row.cell.row.index;
        const id = row.data[rowIndex]._id.toString();
        setPatchArray([]);
        setCurrentCompany({});
        setCurrentContact({});
        history.push('/admin/detail/' + id);
    }

    const [modalContent, setModalContent] = useState('');
    const [modalType, setModalType] = useState('contact');
    const [modalTitle, setModalTitle] = useState('Testing Title');
    const [buttonsType, setButtonsType] = useState('No:Yes');
    const [subtitle, setSubtitle] = useState('interesting subtitle');
    const [titleIcon, setTitleIcon] = useState(iconColorTable.ready.icon);
    const [iconColor, setIconColor] = useState('black');

    const [id, setId] = useState(null);
    const setReadTableSurrogate = (filters) => {
        setReadTable();
    }    
    const setReadRecordSurrogate = (id, newRowData, reread=false) => {    //this is fire when a single record is updated
        if (id) {
            // //alert('surrogate firing');
            // const fetched = {...fetchedData};
            // const ndx = fetched.data.findIndex(item=>item._id.toString() === id);
            // if (ndx > -1) {
            //     fetched.data[ndx] = {...newRowData.data};
            //     setFetchedData({...fetched});
            // }
        }
    }    

    const setShowActionModalSurrogate = () => {
        if (!showActionModal) setNotificationMsg(null);
        setShowActionModal(!showActionModal);
    }

    //register the useNotification custom hook so we can set the notification msg when necessary
    // const {setNotificationMsg} = useNotification(isFirstRender, notifyElem);
    // const setMessage = (msg) => {
    //     setNotificationMsg(msg);
    // };

    const setMoveAllToActive = () => {
        setModalType('moveAllToActive');
        setShowSimpleModal(true);
        setModalTitle('Move All To Active');
        setSubtitle('');
        setModalContent(`Are you sure you want to move these companies to Active?`)
    }
    const actionModal = 
        showActionModal ? 
        <ActionModal 
            origin={origin}
            modal={showActionModal} 
            id={id}
            setModal={setShowActionModal} 
            modalType={modalType}
            title={modalTitle} 
            subtitle={subtitle}
            titleIcon={titleIcon} 
            iconColor={iconColor}
            modalContent={modalContent}
            naicsCodes={naicsCodes}
            buttonsType={buttonsType}
            setTableMessage={setMessage}
            setFetchTime={setFetchTime}
            setReadTableParent={()=>setReadTableSurrogate(filters)}
            setFetchRecord={setReadRecordSurrogate}
            filters={filters}
            currentNavKey={currentNavKey} 
            api={api}
            env={env}
        /> : null;

    const simpleModal = (
        showSimpleModal &&  
        <SimpleModal 
            modal={showSimpleModal} 
            setModal={setShowSimpleModal} 
            id={id}
            filteredIds={[...lastItems.filteredIds]}
            rowData={simpleRowData}
            modalType={modalType}
            title={modalTitle} 
            subtitle={subtitle}
            titleIcon={`${modalType === 'moveToDeleted' ? 'trash-alt' :'exchange-alt'}`} 
            iconColor={'primary'}
            modalContent={modalContent}
            buttonsType={buttonsType}
            setMessage={setMessage}
            setReadTable={setReadTable}
            setRefetchCounts={setRefetchCounts}
            setErrorLevel={()=>{}}
        />);
    
    
    //const [actionTooltip, setActionTooltip] = useState(false);

    const tableActions = (row) => {
        const rowIndex = row.cell.row.index;
        const rowData = row.data[rowIndex];
        const id = rowData._id.toString();
        return (
            <TableActions 
                classFromParent="d-inline" 
                row={{...row}}
                origin={origin}
                NaicsCodes={naicsCodes}
                setMessage={setMessage}
                setErrorLevel={setErrorLevel}
                showActionModal={showActionModal}
                setSimpleRowData={setSimpleRowData}
                setShowActionModal={setShowActionModalSurrogate}
                setShowSimpleModal={setShowSimpleModal}
                setReadTable={setReadTableSurrogate}
                activeFilterCard={currentImportsFilter}
                setActiveFilterCard={setCurrentImportsFilter}
                setModalType={setModalType}
                setModalTitle={setModalTitle}
                setModalSubtitle={setSubtitle}
                setModalContent={setModalContent}
                setButtonsType={setButtonsType}
                setTitleIcon={setTitleIcon}
                setIconColor={setIconColor}
                _id={id}
                setId={setId}
                setFetchRecord={setReadRecordSurrogate}
                setRowCell={setRowCell}
            />
        )
    }

    const stateSelectRef = useRef(undefined);
    const statusSelectRef = useRef(undefined);
    const industrySelectRef = useRef(undefined);
    const stageSelectRef = useRef(undefined);

    const getDescription = async (id) => {
        return await api.getDescription(id);
    }

    const getWebtext = async (id) => {
        return await api.getWebtext(id);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'CRMStatus',
                Filter: SelectColumnFilter,
                Cell: statusFormatter,
                columnRef: statusSelectRef
            },
            {
                Header: 'Company',
                accessor: 'Name',
                Cell: (row)=>companyFormatter(row, handleCompanyClick, getDescription),
            },
            {
                Header: 'City',
                accessor: 'City',
                Cell: row=>alignLeft(row, 17, 0),
            },
            {
                Header: 'State',
                accessor: 'State',
                Filter: SelectColumnFilter,
                Cell: stateFormatter,
            },
            {
                Header: 'Industry',
                accessor: 'Industry',
                Filter: SelectColumnFilter,
                Cell: industryFormatter,
            },
            {
                Header: 'Website',
                accessor: 'Website',
                Cell: row=> {
                    if (!row.value) return <div></div>;
                    const widthToShow = 35;
                    const rowIndex = row.cell.row.index;
                    const company = row.data[rowIndex].Name;
                    const webText = (row.data[rowIndex].WebsiteInfo && row.data[rowIndex].WebsiteInfo.websiteText) || ''
                    return formatWebsite(row, row.value, company, widthToShow, 'divWithLink', getWebtext);
                },
            },
            {
                Header: 'Contacts',
                accessor: 'HasContacts',
                sortType: 'basic',
                Filter: SelectColumnFilter,
                Cell: checkMarkDisplay,
            },
            // {
            //     Header: 'Recent',
            //     accessor: 'RecentlyAdded',
            //     //sortType: 'basic',
            //     visible: false,
            //     isInvisible: true,
            //     Filter: SelectColumnFilter,
            //     Cell: checkMarkDisplay,
            // },            
            {
                Header: 'Ready',
                accessor: 'Ready',
                sortType: 'basic',
                Filter: SelectColumnFilter,
                Cell: checkMarkDisplay,
            },
            {
                Header: 'Date Updated',
                accessor: 'UpdateDate',
                sortType: 'basic',
                Cell: datetimeFormatter,
                disableFilters: true,
    //                Filter: DateColumnFilter,
            },
            {
                Header: 'Actions',
                id: 'actions',
                accessor: 'Label',
                disableSortBy: true,
                Filter: LabelFilter,
                Cell: tableActions,
            },            
        ],
    [iconColorTable]); //end columns

    //this is the "render part of the whole FilterTable Component"
    return (
        fetchError 
        ? <FetchErrorDisplay 
            fetchError={fetchError}
            fetchErrorDetail={fetchErrorDetail}
          /> 
        : !isLoaded 
        ? <span><Spinner color="primary" /> Loading...</span>
        : ( 
            <Fragment>
                <Row><Col sm={12}>
                <Table 
                        origin={origin}
                        columns={columns} 
                        data={imports} 
                        filterCardArray={importsFilterCardArray}
                        activeFilterCard={currentImportsFilter}
                        setActiveFilterCard={setCurrentImportsFilter}
                        setVisibleColumns={setVC}
                        setFilters={setFilters}
                        isMaster={false}
                        isReady={currentImportsFilter === 'Ready'}
                        setShowSimpleModal={setShowSimpleModal}
                        fetchError={fetchError}
                        setFilteredRows={setFilteredRows}
                        setShowImportModal={setShowImportModal}
                        setMoveAllToActive={setMoveAllToActive}
                        pageSize={pageSize}
                        setPS={setPageSize}
                        pageIndex={page-1}
                        setPageIndex={setPage}
                        pageCount={pageCount}
                        start={start}
                        end={end}
                        numRecords={numRecords}
                    />
                    {actionModal}
                    {simpleModal}
                    {showImportModal && <ImportModal 
                        modal={showImportModal}
                        setModal={setShowImportModal}
                        setReadTable={setReadTable}
                        api={api}
                    />}
                </Col></Row>
                {/* {sweetAlert} */}
                <NotificationAlert ref={notifyElem} />
                {env === 'local' ? <Row>
                        <Col><pre>{JSON.stringify([env, page, pageCount, start, end, numRecords], null, 2)}</pre></Col>
                        <Col><pre>{imports.length}</pre></Col>
                        <Col><pre>{JSON.stringify(importsNavTable, null, 2)}</pre></Col>
                        <Col><pre>{JSON.stringify(importsCounts, null, 2)}</pre></Col>
                        {/* <Col><pre>{JSON.stringify(importsNavTable, null, 2)}</pre></Col> */}
                        {/* <Col><pre>{currentImportsFilter}</pre></Col>
                        <Col><pre>{JSON.stringify(leadSources, null, 2)}</pre></Col>
                        <Col><pre>{JSON.stringify(lastItems, null, 2)}</pre></Col> */}
                    </Row>
                :null}

            </Fragment>
        )
    );    

} //end FilterTable;

export default App;