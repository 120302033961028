import React from 'react';
import {Card, CardBody, Row, Col} from 'reactstrap';

const CompanyDetailTab = ({text, icon, color, tabName, handleTabClick}) => {
    return (
        <Card className="card-stats border pt-0 mt-0 text-center" onClick={(e)=>handleTabClick(tabName, e)}>
            <CardBody className="pt-0 mt-0 pl-0 pr-0 mb-0 pb-0">
                <Row className="ml-1 mr-1">
                    <Col sm="12" xs="12" className=" text-center">
                        <div className={`pl-0 py-0 icon-big text-center icon-${color}`}>
                            <i className={`nc-icon nc-${icon} text-${color}`} />
                        </div>
                    </Col>
                </Row>
                <Row className="ml-1 mr-1 mt-0 mb-1">
                <Col sm="12" xs="12" className=" text-center">
                        <div className="numbers pt-0 text-center">
                        <p className="ml-0 mr-0 pl-0 pr-0 card-category">{text}</p>
                        {/* <CardTitle tag="p">150GB</CardTitle> */}
                        <p />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    )
}

export default CompanyDetailTab;