import React, {useState, createContext, useMemo, useEffect} from 'react';
import  {getIconColorTable, getActiveFilterCardArray, getImportsFilterCardArray} from '../common/tableFormats';
import {useAuth} from '../auth/AuthContext';
import {useAPI} from './APIProvider';
//import ApiFunctions from '../common/ApiFunctions'

export const CompanyContext = createContext();

export const CompanyProvider = (props) => {

    const {orgId} = useAuth();
    const {api} = useAPI();

    const getCachedItems = async (items, setItems, lsKeyName, getItems, itemName='items', hours=1, useOrg=false) => {
      //need to make sure that we have the lead sources, country codes, naics codes, etc.
      //first check localStorage.  Requery if date older than # hours in param -- 1 - Lead Sources, 30 days - Countries
      let lsObj = {};
      if (useOrg) lsKeyName = `${useOrg}_${lsKeyName}`;
      const itemLS = localStorage.getItem(lsKeyName);
      if (itemLS) {
        lsObj = JSON.parse(itemLS);
      } else {      //otherwise we need to get the data from the API
        lsObj = await getItems(lsKeyName); 
      }
      // if (lsObj && lsObj.data && (Date.now() - lsObj.data.timestamp) > 1000 * 60 * 60 * hours) {
      //     //need to fetch data again
      //     lsObj = await getItems(lsKeyName); 
      // } 
      if (lsObj && lsObj.data && lsObj.data[itemName] && lsObj.data[itemName].length) {
        setItems(lsObj.data[itemName]);
      }
    } //end getCachedItems

    useEffect(()=>{
      getCachedItems (naicsCodes, setNaicsCodes, 'naicsCodes', api.getNaicsCodes, 'items', 24*30);
      getCachedItems (countries, setCountries, 'countries', api.getCountryCodes, 'items', 24*90);
      getCachedItems (states, setStates , 'states', api.getStates, 'items', 24*90);
    },[]);

    useEffect(()=>{
      if (orgId) {
        getCachedItems (leadSources, setLeadSources, 'crmLeadSources', api.getLeadSources, 'items', 1, orgId);
      }
    },[orgId]);

    
    const initialLastItems = {
        _id: null,
        numberActives: -1,
        numberImports: -1,
        contactIndex: 0
    }
    const [tableOrigin, setTableOrigin] = useState(null);
    const [actives, setActives] = useState([]);
    const [imports, setImports] = useState([]);
    const [importsNavTable, setImportsNavTable] = useState({});
    const [activesNavTable, setActivesNavTable] = useState({});
    const [importsCounts, setImportsCounts] = useState({});
    const [activeCounts, setActiveCounts] = useState({});
    const [currentCompany, setCurrentCompany] = useState({});
    const [currentCompanySave, setCurrentCompanySave] = useState({});
    const [currentContact, setCurrentContact] = useState({});
    const [insertMode, setInsertMode] = useState(false);
    const [fetchTable, setFetchTable] = useState(false);
    const [startTime, setStartTime] = useState(null);

    const [naicsCodes, setNaicsCodes] = useState([]);
    const [leadSources, setLeadSources] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [currentActiveFilter, setCurrentActiveFilter] = useState(null);
    const [currentImportsFilter, setCurrentImportsFilter] = useState(null);
    const [refetchCounts, setRefetchCounts] = useState(false);
    const [currentSort, setCurrentSort] = useState([]);
    const [patchArray, setPatchArray] = useState([]);
    const [filterCardArray, setFilterCardArray] = React.useState([]);
    const [filters, setFilters] = useState([]);

    const [lastItems, setLastItems] = useState(initialLastItems);

    const [errorMessage, setError] = useState('');
    const [errorLevel, setErrorLevel] = useState('warning');
    const [alertVisible, setAlertVisible] = useState(false);

    const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);
    const activeFilterCardArray = useMemo(()=> [...getActiveFilterCardArray], []);
    const importsFilterCardArray = useMemo(()=> [...getImportsFilterCardArray], []);

    const [activeFilterCard, setActiveFilterCard] = useState('');

    const getUsStates = ()=> {
      return getStates('USA');
    }

    const getMexicanStates = ()=> {
      return getStates('MEX');
    }

    const getCanadianStates = ()=> {
      return getStates('CAN');
    }

    const getIntlStates = ()=> {
      return states.filter(item=>!['USA', 'CAN', 'MEX'].includes(item.Country));
    }

    const getStates = (country) => {
      if (country) return states.filter(item=>item.Country === country);
      //otherwise return all states
      return states;
    }
    const setErrorMessage = msg => {
        setError(msg);
        if (msg) {
            setAlertVisible(true);
        } else setAlertVisible(false);
    }

    const getOrigIndex = (item, i) => {
        console.log('origIndex type ' + typeof item.origIndex);
        if (item.origIndex === "-")
            return "-";
        return i.toString();
    }
    const resetCurrentCompany = (comp, contactIndex, msg="", level='warning')=> {
      if (comp) {
          setLastItems(prev=>({...prev, contactIndex: (contactIndex > -1) ? contactIndex : -1}));
          setCurrentCompany(prev=>{
            if (comp.Contacts) {
              const size = comp.Contacts.length;
              return {...comp, Contacts: [...comp.Contacts.map((item, i)=>({...item, 
                  syncContact: ('syncContact' in item) ? item.syncContact : true, 
                  defaultContact: size === 1 ? true : item.defaultContact ? true : false, 
                  origIndex: getOrigIndex(item, i)}))]}
            }
            return {...comp}
          });
          setCurrentCompanySave(prev=>{
              if (comp.Contacts) {
                const size = comp.Contacts.length;
                return {...comp, Contacts: [...comp.Contacts.map((item, i)=>({...item, 
                  syncContact: ('syncContact' in item) ? item.syncContact : true, 
                  defaultContact: size === 1 ? true : item.defaultContact ? true : false, 
                  origIndex: getOrigIndex(item, i)}))]}
              }
              return {...comp}
            });
          //alert('about to set ' + JSON.stringify(comp.Contacts[contactIndex], null, 2))
          if (comp.Contacts && comp.Contacts.length) 
            setCurrentContact({...comp.Contacts[contactIndex], 
              syncContact: (comp.Contacts[contactIndex] && 'syncContact' in comp.Contacts[contactIndex]) ? comp.Contacts[contactIndex].syncContact : true, 
              defaultContact: comp.Contacts.length === 1 ? true : (comp.Contacts[contactIndex] && comp.Contacts[contactIndex].defaultContact) ? true : false, 
              origIndex: contactIndex.toString()});
          if (msg) {
              setErrorMessage(msg);
              setAlertVisible(true);
              if (level) setErrorLevel(level);
            setTimeout(()=>{
                setAlertVisible(false);
            }, 5000);
          }
      }        
    }

    const setSingleField = (field, val, dontPatch=false, arrayName=null, fieldIsArray=false) => {
        const thisField = field;
    
        if (['Revenue', 'Founded', 'Employees'].includes(field) && field !== "")
          val = parseInt(val.toString().replace(/,/g, ""));
        console.dir(['setSingle...', field, val, arrayName]);
        if (arrayName === "Contacts") {
            let tempVal = val;
            if (fieldIsArray) tempVal = [val];
            setCurrentContact(prev => {
                return {...prev, [thisField]: tempVal}
            });
            const modifiedContact = {...currentContact};
            delete modifiedContact.origIndex;
            delete modifiedContact.newContact;
            addChangeToPatch(`Contacts/${currentContact.origIndex}`, {...modifiedContact, [field]: tempVal}, currentContact.newContact === true ? "add" : "replace", currentContact.key);

            // //now we need to update currentCompany
            // const newContacts = [...currentCompany.Contacts];
            // const ndx = newContacts.findIndex(item=>item.key === currentContact.key);
            // if (ndx > -1) {
            //     newContacts[ndx] = {...currentContact, [thisField]: val};
            //     setCurrentCompany(prev=>({...prev, Contacts: [...newContacts]}));
            // }            
        } else {
            //first we need to make sure that any changes to contacts are reflected here.
            setCurrentCompany(prev => {
                let tempVal = val;
                //alert(JSON.stringify(prev, null, 2));
                if (isChanged(tempVal, prev[field]) && !dontPatch) {
                    //alert(['adding change to patch', field, tempVal, prev[field]]);
                    addChangeToPatch(thisField, tempVal);
                }
                //alert(JSON.stringify(prev.Contacts, null, 2));
                const contacts = prev.Contacts ? [...prev.Contacts] : [];
                if (contacts.length === 0) {
                  return { ...prev, [thisField]: val }
                }
                else {
                  contacts[lastItems.contactIndex] = currentContact;
                  return { ...prev, Contacts: [...contacts], [thisField]: val }
                }
            });
        } //end if/else
      } //end setSingleField()
    
      const isChanged = (newVal, oldVal) => {
        if (typeof newVal === "object") return true;
        //alert(['isChanged', typeof newVal, newVal.toString(), oldVal.toString(), (newVal.toString() === oldVal.toString())]);
        if (typeof newVal === "object") return !(newVal.toString() === oldVal.toString())
        return !(newVal === oldVal);
      }
    
      const addChangeToPatch = (field, newVal, op="replace", key=null) => {
        const newPatch = {
          "op": op,
          "path": `/${field}`,
          "value": newVal,
        }
        const compkey = key;
        var excludeThisPatch = false;
        console.dir(newPatch);
        let excluded = [];
        //console.dir(['setSingleField', newPatch]);
        setPatchArray(prev=>{
          //we need to look for a prev "replace" patch and overwrite that one
          excluded = prev.filter(item=>{
            if (item.op === "replace" && item.path === `/${field}`) return false;
            if (op === "remove" && item.op === "add" && item.path === `/${field}` && compkey && item.value && item.value.key === compkey)
            {
              excludeThisPatch = true;
              console.dir(['removing newly added item', item, compkey])
              return false;
            }          
            if (item.op === "add" && item.path === `/${field}` && compkey && item.value && item.value.key === compkey)
            {
              console.dir(['replacing old item', field, item.value, compkey])
              return false;
            }
            return true;
          });
          if (excludeThisPatch) {
            //console.dir(['excluded', [...excluded]])
            return [...excluded];
          }
          console.dir(['newPatch', [...excluded, newPatch]])
          if (prev.findIndex(item=>item.op==="test") > -1) return [...excluded, newPatch, ];
          //alert(currentCompany.UpdateDate);
          return [{ "op": "test", "path": "/UpdateDate", "value": currentCompany.UpdateDate }, ...excluded, newPatch, ]
        });  // end setPatchArray();
        return [...excluded, newPatch];
    } //end addChangeToPatch()
    
    return (
        <CompanyContext.Provider 
            // value={{companies, setCompanies}}
            value={{
                api,
                actives, setActives,
                imports, setImports,
                naicsCodes, setNaicsCodes,
                importsNavTable, setImportsNavTable,
                activesNavTable, setActivesNavTable,
                importsCounts, setImportsCounts, 
                activeCounts, setActiveCounts, 
                resetCurrentCompany,
                currentCompany, setCurrentCompany,
                currentCompanySave, setCurrentCompanySave,
                currentContact, setCurrentContact,
                currentActiveFilter, setCurrentActiveFilter,
                currentImportsFilter, setCurrentImportsFilter,
                refetchCounts, setRefetchCounts,
                currentSort, setCurrentSort,
                insertMode, setInsertMode,
                patchArray, setPatchArray,
                lastItems, setLastItems,
                errorMessage, setErrorMessage, 
                errorLevel, setErrorLevel,
                alertVisible, setAlertVisible,
                iconColorTable, 
                activeFilterCardArray, importsFilterCardArray,
                filterCardArray, setFilterCardArray,
                activeFilterCard, setActiveFilterCard,
                filters, setFilters,
                setSingleField, addChangeToPatch,
                leadSources, setLeadSources,
                countries, setCountries,
                states, setStates,
                getUsStates, getMexicanStates, getCanadianStates,
                tableOrigin, setTableOrigin,
                fetchTable, setFetchTable,
                startTime, setStartTime,
            }}
        >
            {props.children}
        </CompanyContext.Provider>
    )
}
