import React, {Fragment, useEffect, useContext} from 'react';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination //, useAsyncDebounce, 
} from 'react-table';  
import {useHistory} from 'react-router-dom';
import {Table as RTable} from 'reactstrap';
import {Container, Row, Col, Button, Card} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useAuth} from '../auth/AuthContext';
import {CompanyContext} from '../context/CompanyContext';
import FilterCards from '../components/filter/FilterCards';
import FilterSection from '../components/filter/FilterSection';
import PaginationControls from './PaginationControls';
import {DefaultColumnFilter, fuzzyTextFilterFn} from '../common/tableFormats';

const CustomTable = ({origin, columns, data, filterCardArray, setFilteredRows, setPageIndex, pageIndex, pageSize, pageCount, start, end, numRecords,
    activeFilterCard, setActiveFilterCard, setVisibleColumns, setFilters, isMaster, isReady,
    setShowImportModal=null, setMoveAllToActive=null }) => {
    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = val => !val;
    
//    const clearFilters = () => setAllFilters([]);
    const {env} = useAuth();
    const {setCurrentCompany, setErrorMessage, setErrorLevel, setCurrentContact, setPatchArray} = useContext(CompanyContext);
    const history = useHistory();


    const filterTypes = React.useMemo(
        () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startsWith"
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
        },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
        // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        // canPreviousPage,
        // canNextPage,
//        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        setAllFilters,
        page, 
        //pageCount,
        // preGlobalFilteredRows,
        // setGlobalFilter,
        state: {
            //pageIndex,
//            pageSize,
            filters,
            pageSizeArray,
//            globalFilter,
            // sortBy,
            // groupBy,
            // expanded,
            // selectedRowIds,
        },
    } = useTable({
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageSize: pageSize, pageSizeArray: [10,25,50, 75, 100] },  //pageIndex: pageIndex, 
    }, 
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    useEffect(()=>{
        if (visibleColumns.length) setVisibleColumns([...visibleColumns]);
    }, [visibleColumns, setVisibleColumns])
//    setVisibleColumns([...visibleColumns]);

    useEffect(()=>{
        //console.log('calling set filters ' + filters.length);
        setFilters([...filters]);
    //    setSaveFilters([...filters]);
    }, [filters, setFilters]);

    useEffect(()=>{
        setFilteredRows([...rows]);
        //console.log('setting filteredRows ' + rows.length);
    }, [rows.length, setFilteredRows]);

    // Render the UI for your table
    return (
        <Fragment>
        <FilterCards 
            origin={origin}
            filterCardArray={filterCardArray}
            activeFilterCard={activeFilterCard}
            shadowClassshadowClass
            filters={filters}
            setActiveFilterCard={setActiveFilterCard}
//            selectContacts={selectContacts}
        />
        <Container fluid className="mt-0 ml-0 pl-0 w-100">
            <Card className="w-100">
            <Row className="w-100 h-100 mt-0 pt-0">
                <Col lg="4" md="6" sm="6">
                    <FilterSection
                        filters={filters}
                        visibleColumns={visibleColumns}
                        columns={columns}
                    />
                </Col>
                <Col lg="4" md="6" sm="3" className="h-100 align-middle text-center">
                    <br /><h6 className="h-100 align-middle">Displaying {start}-{end} of {numRecords} Companies</h6>
                </Col>
                <Col lg="4" md="6" sm="3" className="text-right">
                    {!isMaster ? <Button className="btn-round" color="primary"
                        onClick={()=>setShowImportModal(true)}
                        style={{opacity: .7}}>
                            Import File{isReady===true}
                    </Button> : null}
                    {!isMaster && isReady === true ? <Button className="btn-round" color="primary"
                        onClick={()=>{
                            setMoveAllToActive();
                        }}
                        style={{opacity: .7}}>
                            Move All To Active
                    </Button> : null}                    
                    <Button className="btn-round" color="primary"
                        style={{opacity: .7}}
                        onClick={()=>{
                            setErrorMessage('');
                            setErrorLevel('dark');
                            setCurrentCompany({});
                            setCurrentContact({});
                            setPatchArray([]);
                            history.push('/admin/detail/new')}
                        }
                    >
                        Add Company
                    </Button>
                    {isMaster ? <Button className="btn-round" color="success"
                    style={{opacity: .7}}>
                        Rerun The AI
                    </Button> : null}
                </Col>
                </Row></Card>
            {/* <GlobalFilter 
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />       */}
            <Row><Col>
            <div style={{width:"100%"}}>
            <RTable size="sm" striped hover {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="bg-light text-dark">
                    {headerGroup.headers.map((column, i) => {
                    let display = column.isInvisible ? 'none' :'';
                    return (
                    <th key={`th_${i}`} className='align-text-bottom text-center ml-0 mr-0 pl-0 pr-1' style={{display:display}}>
                        <div className='text-center align-text-top' 
                            style={{
                                whiteSpace: 'nowrap',
    //                            fontSize: '.9rem',
                            }}
                        >
                            <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {!column.disableSortBy ? 
                                    column.isSorted 
                                    ? column.isSortedDesc
                                        ? <FontAwesomeIcon icon='sort-down' transform="shrink-1" className="mr-1" />                   
                                        : <FontAwesomeIcon icon='sort-up' transform="shrink-1" className="mr-1" />                     
                                    :  <FontAwesomeIcon icon='sort' transform="shrink-1" className="mr-1" />  
                                : '' }  
                            {column.render('Header')}&nbsp;
                                {/* {column.canFilter ? (
                                    <span className="dropdown dropdown-toggle" id={"filter-dropdown-" + column.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={filterIcon} alt="filter" height="10" width="10" />
                                    </span>
                                )
                                : (<span>&nbsp;</span>) } */}
                                </span>
                        </div>
                        {column.canFilter ? <div>{column.render('Filter')}</div> : <div>&nbsp;</div>}
                        {/* <div className="dropdown-menu" aria-labelledby={"filter-dropdown-" + column.id}>
                            <p className="dropdown-item">testing dropdown filters</p>
                        </div> */}
                    </th>
                    )}
                    )}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} className="m-0 p-0 border">
                    {row.cells.map((cell, j) => {
                        let display = cell.column.isInvisible ? 'none' : '';
                        return (<td key={`row_${i}_cell_${j}`} {...cell.getCellProps()}  style={{display:display}}
                            ><div  
                                style={{
                                    whiteSpace: 'normal',
                                    fontSize: '.8rem',
                                }}
                                className="m-0 p-0 text-left" 
                            >{cell.render('Cell')}</div></td>
                        )
                    })}
                    </tr>
                )
                })}
            </tbody>
            </RTable></div>
            </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <PaginationControls 
                        pageSizeArray={pageSizeArray} 
                        pageSize={pageSize} 
                        setPageSize={setPageSize} 
                        pageCount={pageCount} 
                        pageIndex={pageIndex} 
                        previousPage={previousPage} 
                        nextPage={nextPage} 
                        // canPreviousPage={canPreviousPage} 
                        // canNextPage={canNextPage} 
                        gotoPage={setPageIndex} 
                    />
                </Col>
            </Row>
            {/* <Row><Col><button onClick={() => setAllFilters([])}>Reset</button></Col></Row> */}
            {env === 'local' ? <Row>
                <Col xs={12}>
                {/* <pre>
                 <code>
                    {JSON.stringify(
                        {
                            datalength: data.length,
                            pageIndex,
                            pageSize,
                            pageCount,
                            //   sortBy,
                            filters,
                            //pageSizeArray,
                //              visibleColumns: Object.keys(visibleColumns[3]).join(' '),
                            },
                            null,
                            2
                        )}
                    </code>
                </pre> */}
                </Col>
            </Row>: null}
            </Container>
        </Fragment>
    )

} //end Table()

export default CustomTable;
