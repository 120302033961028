import React, {Fragment, useState, useEffect, useContext} from "react";
import {animateScroll as scroll, } from 'react-scroll';
import { nanoid } from 'nanoid';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import '../../assets/css/cursorPointer.css';
//import socketClient from "socket.io-client";

// reactstrap components
import {
  Alert, Row, Col,
} from "reactstrap";

import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import {useFetchDetail, } from '../../common/apiHooks';  //useCachedData, 
        
import ContactMasterDetail from './ContactMasterDetail';
import DetailHeader from './DetailHeader'
import CompanyPane from './CompanyPane';
import DetailPane from './DetailPane';
import NotesPane from './NotesPane';
import SupplementalPane from './SupplementalPane';
import HistoryPane from './HistoryPane';
import WebsitePane from './WebsitePane';
import ConfirmationModal from './ConfirmationModal.jsx';
import SimpleModal from '../modal/SimpleModal';
//import IndustryColors from "../../assets/data/IndustryColorPalette.json";

import {activeOrigin as origin, toDateString} from '../../common/tableFormats';
//import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

const CompanyDetail = ()=> {

  const { org, orgId, currentUser, setOrgAfterLogin, env } = useAuth();
  const { api, 
    activesNavTable, //setActivesNavTable,
    importsNavTable, setImportsNavTable,
    resetCurrentCompany,
    currentActiveFilter, currentImportsFilter,
    //setCurrentActiveFilter, 
    setCurrentImportsFilter,
    setRefetchCounts, 
    currentCompany, setCurrentCompany, 
    currentCompanySave,
    setCurrentContact,
    lastItems, setLastItems,
    insertMode, setInsertMode,
    patchArray, setPatchArray, 
    errorMessage, setErrorMessage,
    errorLevel, setErrorLevel,
    alertVisible, setAlertVisible,
    tableOrigin, 
    //fetchTable, 
    setFetchTable,
  } = useContext(CompanyContext);

  useEffect(async ()=>{
    if (!org && currentUser) {
      await setOrgAfterLogin(currentUser.uid);
    }
  },[org]);

  let location = useLocation();      
  const history = useHistory();

  if (org === {} || !currentUser || !currentUser.email) history.push('/auth/login');
      
  const SERVER = "http://54.90.27.3:8080";

  const {id} = useParams();

  // const [masterEndpoint, setMasterEndpoint] = useState(`/aiLead/api/master/${id}`);
  // const [stagedEndpoint, setStagedEndpoint] = useState(`/aiLead/api/staged/${id}`);
  const [prevId, setPrevId] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const [prevButtonEnabled, setPrevButtonEnabled] = useState(true);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);
  const [currentNavTable, setCurrentNavTable] = useState({});
  const [navTableRecord, setNavTableRecord] = useState({});
  const [prevEndpoint, setPrevEndpoint] = useState(null);
  const [nextEndpoint, setNextEndpoint] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSimpleModal, setShowSimpleModal] = useState(false);
  const [modalType, setModalType] = useState(null);

//  const [fetched, setFetched] = useState(false);

  //const initialPageSize = 50;
  // const [pageSize, setPageSize] = useState(initialPageSize);
  // const [pageIndex, setPageIndex] = React.useState(0);

  //const io = socketClient (SERVER);

  const setFetchTime = ()=> new Date();

  const isFirstRender = React.useRef(true);

  useEffect(() => {
    // io.on('connection', () => {
    //   alert(`I'm connected with the back-end`);
    // });
    // io.on('sync-successful', (msg) => {
    //   alert(msg);
    // })
    isFirstRender.current = false;
  }, []);

  // useEffect(() => {
  //   if (io) {
  //     alert('here')
  //   }
  // }, [io]);

  const {detailLoaded, detailFetchError, detailFetchErrorDetail, setId, setReadTable} 
    = useFetchDetail({initId: id, currentCompany, resetCurrentCompany, env, setFetchTime, table: "master", api, org});

  useEffect(()=>{
    if (insertMode === true) {
      setErrorMessage('');
      setErrorLevel('dark');
      setCurrentCompany({});
      setCurrentContact({});
      setPatchArray([])
    }
  },[insertMode]);

  useEffect(()=>{
    let localId;
    //when the id changes, we need to fetch the new detail record
    setErrorMessage('');
    setErrorLevel('dark');
    if (id && id === 'new') {
      setImportsNavTable({});
      setCurrentImportsFilter('Recent');
      setLastItems(prev=>({...prev, filteredCount: 1}));
      addNewCompany();
    }
    else if (id && id !== ':id') {
      localId = id;
    } else if (lastItems._id) {
      localId = lastItems._id;
    } else {
      localId = localStorage.getItem('last_detail_id');
    }
//    if (localId.endsWith('?')) localId = localId.substr(0,localId.length-1);

    if (localId) {
      setId(localId);
      setPrevId(localId);
      setLastItems(prev=>({...prev, _id: localId}));
      if (id !== localId && !localId.endsWith('?')) {
        history.push('/admin/detail/' + localId);
      }
      localStorage.setItem('last_detail_id', localId);
      const idString = localId.toString();
      if (currentNavTable && currentNavTable[idString]) {
        const rec = currentNavTable[idString]
        //alert(JSON.stringify(rec))
        setNavTableRecord([...rec]);
      }    
    }
  }, [id, currentNavTable]);

  useEffect(()=>{
    //alert(JSON.stringify(['navSetup', tableOrigin, importsNavTable, activesNavTable]))
    const navSetup = () => {
      let nt = localStorage.getItem(`${orgId}_nav_${tableOrigin}`);
      let haveNavRecords = true;
      if (tableOrigin === 'Active') {
        //alert('in detail -- active ' + JSON.stringify(activesNavTable));
        setCurrentNavTable({...activesNavTable});
        if (!activesNavTable || !Object.keys(activesNavTable).length) haveNavRecords = false;
      }
      else {
        //alert('in detail -- imports ' + JSON.stringify(importsNavTable));
        setCurrentNavTable({...importsNavTable});
        if (!importsNavTable || !Object.keys(importsNavTable).length) haveNavRecords = false;
      }

      if (!haveNavRecords && nt && Object.keys(JSON.parse(nt)).length) {
        setCurrentNavTable(JSON.parse(nt));
      }
    }
    if (tableOrigin) navSetup();
  }, [tableOrigin]);

  useEffect(()=>{
    if (navTableRecord) {
      setPrevButtonEnabled((navTableRecord[0] === null || patchArray.length) ? false : true);
      setNextButtonEnabled((navTableRecord[1] === null  || patchArray.length) ? false : true);
      if (navTableRecord[0]) setPrevEndpoint(navTableRecord[0]);
      if (navTableRecord[1]) setNextEndpoint(navTableRecord[1]);

    }
  }, [navTableRecord, patchArray])

  //this is so that we can enable Ctrl-S to save
  const saveKey = (e) => {
    //console.dir(['ctrl-s key', e.which, patchArray.length]);
    if (e.ctrlKey && e.which == 83) {
      console.dir(['ctrl-s','saved called'])
      setSaveFlag(true);
      e.preventDefault();
      return false;
    }
  }

  useEffect(()=>{
    //console.log("ctrl-s add event");
    const pLength = patchArray.length
    document.addEventListener("keydown", saveKey);
    return ()=> {
      console.log("ctrl-s remove event")
      document.removeEventListener("keydown", saveKey);
    }
  }, []);

  useEffect(()=>{
    console.dir(['patchArray', patchArray]);
    //if there are changes then we need to enable to save and cancel buttons
    if (patchArray.length) {
      setSaveDisabled(false);
      setCancelDisabled(false);
    } else {
      setSaveDisabled(true);
      setCancelDisabled(true);
    }
  }, [patchArray]);

  const [saveFlag, setSaveFlag] = useState(false);
  useEffect(()=>{
    if (patchArray.length && saveFlag) {
      saveChanges();
    }
  }, [saveFlag]);

  const handleTabClick = (which, value) => {
    //alert(tabs[which] + ' ' + location.pathname);
    if (!tabs[which]) {
      setNumCols(prev=>({...prev, [which]: 1}));
        //setBookmark("#" + which + "_view");
        //alert(`${which} ${JSON.stringify(panelTops, null, 2)}`);
//        alert(`${which} ${panelTops[which]-238}`);
        //scroll.scrollTo(panelTops[which]-238);
      setTimeout(function() {window.scrollTo(0, panelTops[which]-238);},1);    
    }
    else {
      //window.location = location.pathname;
      //window.scrollTo(0,0);
      setNumCols(prev=>({...prev, [which]: initialPaneCols[which]}));
      setTimeout(function() {scroll.scrollToTop();},1);    
    }
    setTabs(prev=>({...prev, [which]:!prev[which]}));
  }

  const abandonChanges = ()=> {
    // const comp = actives.find(item=>item._id === currentCompany._id);
    // if (comp) {
      //alert(`${insertMode} - ${prevId}`);
      if (insertMode) {
        setPatchArray([]);
        setId(null);
        setId(prevId);
      } else {
      setPatchArray([]);
      resetCurrentCompany(currentCompanySave, 0, 'Changes abandoned.  Restored original company/contacts', 'dark');
      }
  } //end abandonChanges()

  const prevCompany = ()=> {
    setPatchArray([]);
    setId(prevEndpoint);
    history.push('/admin/detail/' + prevEndpoint);
  }

  const nextCompany = ()=> {
    setPatchArray([]);
    setId(nextEndpoint);
    history.push('/admin/detail/' + nextEndpoint);
  }

  const saveAndClose = async () => {
    //alert('saveAndClose');
    await saveChanges(); 
    //history.push(`/admin/${tableOrigin === 'Active' ? 'active/' + currentActiveFilter : 'imports/' + (insertMode ? 'Recent' : currentImportsFilter)}`);
    history.push(`/admin/${tableOrigin === 'Active' ? 'active/:urlFilter' : 'imports/:urlFilter'}`);
  }

  const saveAndPrev = async () => {
    alert('saveAndPrev');
    //await saveChanges();
  }

  const saveAndNext = async () => {
    alert('saveAndNext');
    //await saveChanges();
  }

  const saveChanges = async ()=> {
    if (!patchArray.length) return;
    //lets send the patchArray to the API endpoint.
//    console.dir(['saveChanges', currentCompany._id.toString(), patchArray]);
    //alert(JSON.stringify(patchArray, null, 2));

    //need to strip out rowKey from arrays
    let result;
    if (!insertMode) {
      let patchToSend = patchArray.filter(item=>{return true});
      let id = currentCompany && currentCompany._id ? currentCompany._id.toString() : null;
      if (id) result = await api.patchCompany(id, patchToSend);
    }
    else { //otherwise this is an insert
      result = await api.insertCompany(currentCompany);
      setInsertMode(false);
      newCompany = result.data.results.data;
      resetCurrentCompany(newCompany, lastItems.contactIndex, `${newCompany.Name} has been successfully added to Imports`, "info");
      setFetchTable(true);
      if (tableOrigin === 'Imports') {
        setCurrentImportsFilter('Recent');
        history.push('/admin/imports/Recent');
      }
    } 
    //this will return the updated record
    //alert(JSON.stringify(result, null, 2));

    let newCompany;
    if (result && result.data && result.data.message === "OK" && result.data.results) {
      // if (insertMode) {
      //   setInsertMode(false);
      //   newCompany = result.data.results.data[0];
      //   resetCurrentCompany(newCompany, lastItems.contactIndex, `${newCompany.Name} has been successfully added to Imports`, "info");
      //   setFetchTable(true);
      //   history.push('/admin/imports/Recent');
      // } else {
        newCompany = result.data.results.data;
        if (org.crmActive && newCompany.CRMSync && newCompany.CRMSync.syncToAILead === "Yes") {
          setErrorLevel('dark');
          setErrorMessage(`Syncing ${newCompany.Name} to ${'OnePageCRM'}...`);
          const sync = await api.syncFromDB(id, newCompany, 'OnePageCRM');
          newCompany = sync.data.results.result;
          setErrorMessage(`Successfully Synced ${newCompany.Name} to ${'OnePageCRM'} in ${origin}`);
        }
        setPatchArray([]);
        resetCurrentCompany(newCompany, lastItems.contactIndex, `${newCompany.Name} has been updated successfully`, "dark");
      //}
    }
    else if (result.error && result.error.message && result.error.message.indexOf('409') > -1) {
      setErrorMessage(`Company has been updated before trying to apply your changes.  Please reapply your changes and resubmit update.`);
      setErrorLevel('warning');
    }
    else if (result.error && result.error.message) {
      setErrorMessage(result.error.message);
      setErrorLevel('danger');
    }
  }

  const setValidationError = (msg, level='warning') => {
    if (msg) {
      setErrorMessage(msg);
      setAlertVisible(true);
      if (level) setErrorLevel(level);
      setTimeout(()=>{
          setAlertVisible(false);
      }, 5000);
    }
  }

  const cancelChanges = ()=> {
    //this should cause the record to read again.
    setShowConfirmationModal(true);
  }

  const initialTabs = {
    contacts: false,
    company: false,
    website: false,
    history: false,
    notes: false,
    supplemental: false
  }

  const initialPaneCols = {
    contacts: 2,
    company: 2,
    website: 3,
    history: 3,
    notes: 1,
    supplemental: 3
  }  
  const [tabs, setTabs] = useState({...initialTabs});
  const [numCols, setNumCols] = useState({...initialPaneCols});


  const [bookmark, setBookmark] = useState('');
  const [firstRender, setFirstRender] = useState(false);
  const [panelTops, setPanelTops] = useState([]);

  const testScroll = () => {
    //alert('testing scroll');
    setTimeout(function() {window.scrollTo(0, 100);},1);
  }

  useEffect(()=> {
    if ((bookmark || bookmark === "") && firstRender) 
        window.location = location.pathname + bookmark;
    // else 
    //     window.location = location.pathname;
  }, [bookmark]);

  useEffect(()=>{
    setFirstRender(true);
    //alert('scrolling to top');
    //scroll.scrollToTop();
  },[])

  const modifiedDateLine = (<Fragment>
    <p className="m-0 p-0" style={{
      whiteSpace: 'nowrap',
      fontSize: '.9rem',
    }}
    ><small>Last Modified: {toDateString(currentCompany.UpdateDate, 4)}</small></p>
  </Fragment>
  );

  const getBoundingTop = (panel, top) => {
    setPanelTops(prev=>({...prev, [panel]: top}));
  }

  const addNewContact = ()=> {
    const contact = {
      "CreateDate": new Date(),
      "UpdateDate": new Date(),
      "First": "",
      "Last": "",
      "JobTitle": "",
      "Email": "",
      "Phone": "",
      "LinkedInUrl": "",
      "key": nanoid(),
      "Notes": [],
//      "dontSyncContact": false,
      "syncContact": true,
      "defaultContact": false,
      "newContact": true,
      "origIndex": "-"
    }
    const newContacts = currentCompany.Contacts ? [contact, ...currentCompany.Contacts] : [contact];
    setLastItems(prev=>({...prev, contactIndex: 0}));
    setCurrentCompany(prev=>({...currentCompany, Contacts: [...newContacts]}));
    setCurrentContact({...contact});
  }

  const addNewCompany = ()=> {
    setInsertMode(true);
    const company = {
        "Address": "",
        "CRMStatus": "Prospect",
        "City": "",
        "Country": "",
        "DUNSNumber": "",
        "Description": "",
        "Employees": null,
        "Enriched": false,
        "Fax": null,
        "Founded": null,
        "History": [{
            "date": new Date(),
            "desc": "Created by customer"
        }],
        "Industry": "",
        Label: "AIQueue",
        "LinkedInUrl": null,
        "MSA": null,
        "NAICS": null,
        "Name": "",
        "Notes": null,
        "Phone": "",
        "Ready": false,
        "Revenue": null,
        "RiskRating": "",
        "SIC": null,
        "Source": null,
        "Stage": 0,
        "StagedDate": new Date(),
        "State": "",
        "Website": "",
        "Zipcode": "",
        "aiLeadSource": "Client Entered",
        "Confidence": -1,
        "ProspectStage": "0-No Contact",
        "CRMSync": {
            "syncToAILead": "No",
            "existsInCRM": "No",
            "handleConflict": "mostRecentWins"
        },
        "coll": "staged"
      }
      setCurrentContact({});
      setLastItems(prev=>({...prev, contactIndex: -1}));
      resetCurrentCompany({...company}, -1, null);
    }
  
    const simpleModal = (
      showSimpleModal &&  
      <SimpleModal 
          origin={origin}
          modal={showSimpleModal} 
          setModal={setShowSimpleModal} 
          id={id}
          rowData={currentCompany}
          modalType={modalType}
          title={`Move ${currentCompany.Name} to ${tableOrigin === 'Active' ? 'Imports' : 'Active'}`} 
          subtitle={''}
          titleIcon={`${modalType === 'moveToDeleted' ? 'trash-alt' :'exchange-alt'}`} 
          iconColor={'primary'}
          modalContent={`Are you sure you want to ${modalType === 'moveToDeleted' ? 'remove' :'move'} this company?`}
          setMessage={setErrorMessage}
          setReadTable={setReadTable}
          setRefetchCounts={setRefetchCounts}
          setErrorLevel={setErrorLevel}
      />);
  
    return (
        <div className="content" id="topDiv">
          {showSimpleModal && simpleModal}
          {showConfirmationModal ? 
          <ConfirmationModal 
              showConfirmationModal={showConfirmationModal} 
              setShowConfirmationModal={setShowConfirmationModal} 
              abandonChanges={abandonChanges}
          /> : null }    
          <DetailHeader 
            id={id}
            cancelChanges={cancelChanges} 
            saveChanges={saveChanges}
            saveAndClose={saveAndClose} 
            saveAndPrev={saveAndPrev} 
            saveAndNext={saveAndNext}
            cancelDisabled={cancelDisabled}
            saveDisabled={saveDisabled}
            prevCompany={prevCompany}  
            prevButtonEnabled={prevButtonEnabled}  
            nextCompany={nextCompany} 
            nextButtonEnabled={nextButtonEnabled}  
            handleTabClick={handleTabClick}
            setShowSimpleModal={setShowSimpleModal}
            setModalType={setModalType}
          />
          <Row className="p-0 m-0 mb-1 mt-1 pt-0">
            <Col lg="12" className="p-0 m-0">
              {errorMessage ? 
                <Alert 
                  isOpen={alertVisible}
                  toggle={()=>setAlertVisible(prev=>!prev)}
                  color={errorLevel === "error" ? "danger" : errorLevel} 
                  className="text-center text-dark p-1 m-0 pt-0 border">
                   <strong>{errorMessage}</strong>
                </Alert>
              : null }              
            </Col>
          </Row>
          <Row className="mb-1 pb-0">
            <DetailPane key="company_pane"
                  paneName={`${currentCompany.Name}`}
                  tabSelected={tabs.company}
                  tabName="company"
                  setTabSelected={(which, value)=>setTabs(prev=>({...prev, [which]:value}))}
                  numberCols={numCols.company}
                  handleTabClick={handleTabClick}
                  subText={modifiedDateLine}
                  getBoundingTop={getBoundingTop}
                  hasInsert={true}
                  addNewRecord={()=>history.push('/admin/detail/new')}
            >
              <CompanyPane 
                setValidationError={setValidationError}
              />
            </DetailPane>
            <DetailPane key="contacts_pane"
              paneName={`Contacts (${currentCompany.Contacts ? currentCompany.Contacts.length : 0})`}
              tabName="contacts"
              setTabSelected={(which, value)=>setTabs(prev=>({...prev, [which]:value}))}
              tabSelected={tabs.contacts}
              numberCols={numCols.contacts}
              handleTabClick={handleTabClick}
              getBoundingTop={getBoundingTop}
              hasInsert={true}
              addNewRecord={addNewContact}
            >
              <ContactMasterDetail />
            </DetailPane>
          </Row>
          <Row className="mb-1 pb-0">
            <DetailPane 
              paneName="Contact Notes"
              tabSelected={tabs.notes}
              tabName="notes"
              setTabSelected={(which, value)=>setTabs(prev=>({...prev, [which]:value}))}
              numberCols={numCols.notes}
              handleTabClick={handleTabClick}
              getBoundingTop={getBoundingTop}
            >
                <NotesPane 
                />
            </DetailPane>
            <DetailPane 
              paneName="Enrichment Info"
              tabSelected={tabs.supplemental}
              tabName="supplemental"
              setTabSelected={(which, value)=>setTabs(prev=>({...prev, [which]:value}))}
              numberCols={numCols.supplemental}
              handleTabClick={handleTabClick}
              getBoundingTop={getBoundingTop}
            >
                <SupplementalPane />
            </DetailPane>
            <DetailPane 
              paneName="Record History"
              tabSelected={tabs.history}
              tabName="history"
              setTabSelected={(which, value)=>setTabs(prev=>({...prev, [which]:value}))}
              numberCols={numCols.history}
              handleTabClick={handleTabClick}
              getBoundingTop={getBoundingTop}
            >
                <HistoryPane />
            </DetailPane>
            <DetailPane 
              paneName="Website Info"
              tabSelected={tabs.website}
              tabName="website"
              setTabSelected={(which, value)=>setTabs(prev=>({...prev, [which]:value}))}
              numberCols={numCols.website}
              handleTabClick={handleTabClick}
              getBoundingTop={getBoundingTop}
            >
                <WebsitePane />
            </DetailPane>
          </Row>
          {/* <Row><Col>
            <UncontrolledDropdown className=""
            >
                  <DropdownToggle
                    aria-haspopup={true}
                    //caret
//                    color="white"
                    data-toggle="dropdown"
                    className="border card bg-white"
                    id="testingDrop"
                    //style = {{ backgroundColor: "white", ":hover": { cursor: "pointer", backgroundColor: "white" } }}
                  ><span className=""
                  >
                    <i className="nc-icon nc-bell-55" />
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </span>
                  </DropdownToggle>
                  <DropdownMenu
                    persist
                    aria-labelledby="testingDrop"
                    //left
                  >
                    <DropdownItem className="bg-white"><Card>Testing Card</Card></DropdownItem>
                  </DropdownMenu>
              </UncontrolledDropdown>          
          </Col></Row> */}
          {env === 'local' ? 
          <Row>
            <Col><pre>{JSON.stringify(currentCompany, null, 2)}</pre></Col>
            <Col><pre>{JSON.stringify(activesNavTable, null, 2)}</pre></Col>
            {/* <Col><pre>{JSON.stringify(org, null, 2)}</pre></Col> */}
            <Col><pre>{JSON.stringify(patchArray, null, 2)}</pre></Col>
            {/* <Col><pre>{currentCompany.UpdateDate}</pre></Col>
            <Col><pre>{JSON.stringify(jsonPatch(patchArray), null, 2)}</pre></Col>
            <Col><pre>{JSON.stringify(Object.keys(currentCompany, null, 2))}</pre></Col> */}
            {/* <Col><pre>{JSON.stringify(filters, null, 2)}</pre></Col>
            <Col><pre>{JSON.stringify(currentCompany, null, 2)}</pre></Col>
            <Col><pre>{JSON.stringify({currentActiveFilter, currentImportsFilter, nextEndpoint, prevEndpoint}, null, 2)}</pre></Col> */}
            {/* <Col><pre>{JSON.stringify(lastItems, null, 2)}</pre></Col> */}
            {/* <Col><pre>{JSON.stringify(panelTops, null, 2)}</pre></Col> */}
          </Row>
          : null}
        </div>
    );
  }

export default CompanyDetail;
