import React, {Fragment, useState, useEffect, useRef, useContext} from 'react';
import NotificationAlert from "react-notification-alert";
import {useParams, useHistory} from 'react-router-dom';
import "react-notification-alert/dist/animate.css";
import '../../assets/css/pre.css';

import { Row, Col, Spinner,
    // Card, CardBody, CardTitle, Container, CardFooter, InputGroup, Input, Form, Button, Tooltip, Alert 
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import Table from '../../components/Table';
import TableActions from '../../components/dropdowns/TableActions';
import InfoDropDown from '../../components/dropdowns/InfoDropDown';
import GenericDropDown from '../../components/dropdowns/GenericDropdown';
import ProspectStageDropDown from '../../components/dropdowns/ProspectStageDropDown';
import CRMStatusDropDown from '../../components/dropdowns/CRMStatusDropDown.jsx';
import ActionModal from '../modal/ActionModal';
import SimpleModal from '../modal/SimpleModal';

import IndustryColors from "../../assets/data/IndustryColorPalette.json";
import FetchErrorDisplay from '../../components/fetchError/FetchErrorDisplay';
import {useFetchTableData, useCachedData, } from '../../common/apiHooks';
import  {datetimeFormatter, LabelFilter, SelectColumnFilter, useNotification, useFilterCards, //syncAction,      //getTotalLocalStorage, getIconColorTable, getActiveFilterCardArray, 
        companyFormatter, stateFormatter, formatWebsite, activeOrigin as origin, alignLeft} from '../../common/tableFormats';
import '../../assets/css/cursorPointer.css';

function App({setFetchTime, reload, setReload}) {

    const {org, orgId, currentUser, setOrgAfterLogin, env} = useAuth();
    const { api, 
        actives, setActives,
        activesNavTable, setActivesNavTable,
        activeCounts, setActiveCounts,
        currentActiveFilter, setCurrentActiveFilter,
        refetchCounts, setRefetchCounts,
        setCurrentCompany, setCurrentContact,
        setPatchArray,
        //currentSort, setCurrentSort,
        setErrorLevel,
        lastItems, setLastItems,
        iconColorTable, 
        activeFilterCardArray, 
        filterCardArray, setFilterCardArray,
        filters, setFilters,
        naicsCodes, setNaicsCodes,
        tableOrigin, setTableOrigin,
        fetchTable, setFetchTable, startTime, setStartTime,

    } = useContext(CompanyContext);

    useEffect(async ()=>{
        if (!org && currentUser) await setOrgAfterLogin(currentUser.uid);
    },[org]);

    const history = useHistory();
    const { urlFilter } = useParams();

    setTableOrigin('Active');
    if (urlFilter === ':urlFilter') {
        if (currentActiveFilter) history.push(`/admin/active/${currentActiveFilter}`);
        else {
            let filter = 'ThumbsUp';
            setCurrentActiveFilter(filter);
            history.push(`/admin/active/${filter}`);
        }
    } else {
        setCurrentActiveFilter(urlFilter);
    }

    //    const perfectScroll = React.useRef(null);
    const notifyElem = React.useRef(null);
    const isFirstRender = React.useRef(true);
    const [showActionModal, setShowActionModal] = useState(false);
    const [showSimpleModal, setShowSimpleModal] = useState(false);
    const [simpleRowData, setSimpleRowData] = useState({});

    const [modalContent, setModalContent] = useState('');
    const [modalType, setModalType] = useState('contact');
    const [modalTitle, setModalTitle] = useState('Testing Title');
    const [buttonsType, setButtonsType] = useState('No:Yes');
    const [subtitle, setSubtitle] = useState('interesting subtitle');
    const [titleIcon, setTitleIcon] = useState(iconColorTable.ready.icon);
    const [iconColor, setIconColor] = useState('black');

    const [id, setId] = useState(null);

    const initialPageSize = 25;
    const [pageSize, setPageSize] = useState(initialPageSize);          //pageSize

    const [visibleColumns, setVisibleColumns] = useState([]);
    const setVC = vc => {
        if (vc.length && !visibleColumns.length) setVisibleColumns([...vc]);
    }

    const {setNotificationMsg} = useNotification(isFirstRender, notifyElem);

    const setMessage = (msg) => {
        setNotificationMsg(msg);
    };

    const {isLoaded, fetchError, fetchErrorDetail, currentNavKey, setReadTable, filteredRows, setFilteredRows, 
        page, pageCount, start, end, numRecords, setPage,
    } = useFetchTableData({origin: tableOrigin, isFirstRender, setFetchTime, env, records: actives, setRecords: setActives, 
        navTable: activesNavTable, setNavTable: setActivesNavTable, lastItems, setLastItems, filterCardArray: activeFilterCardArray, 
        activeFilterCard: currentActiveFilter, setFilterCardArray, IndustryColors, initialFetch:false, org,
        counts: activeCounts, setCounts: setActiveCounts, refetchCounts, setRefetchCounts, setMessage, table: "master", api});

    // useEffect(()=>{
    //     alert(isLoaded);
    // },[isLoaded]);

    useEffect(()=>{
        if (reload) {
            setRefetchCounts(true);
            setReadTable(true);
            setReload(false);
            setMessage('Data will reload momentarily...');
        }
    },[reload]);

    useEffect(()=>{
        const datediff = (a,b)=> {
            if (!a) a = new Date();
            return (a-b);
        }
        const addToNavTable = (key, prevId, nextId) => {
            //the following is to capture the navigation info to use in the modal
            const contactRec = [prevId, nextId];
            setActivesNavTable(prev=>({...prev, [key]: contactRec})); 
        }
        if (filteredRows.length) {
            console.log(JSON.stringify(['render complete', datediff(new Date(), startTime)]));
            setActivesNavTable({});
            const rows = [...filteredRows];
            let prevId = null, nextId = null, key=null;
            const filteredIds = rows.map((item, i)=>{
                //if (i===0) alert(JSON.stringify(item, null, 2));
                key = item.original._id.toString();
                if (i>0) prevId = filteredRows[i-1].original._id.toString()
                else prevId=null;
                if (i < filteredRows.length-1) 
                    nextId = filteredRows[i+1].original._id.toString()
                else nextId=null;
                addToNavTable(key, prevId, nextId);
                return item.original._id
            });
            setLastItems(prev=>({...prev, filteredIds, filteredCount: filteredRows.length}));
            //need to save filteredIds in localStorage in case user accesses detail from URL
            localStorage.setItem(`${api.getOrgId()}_filteredIds`, JSON.stringify(filteredIds));
            //need to reset the navTable
        }
    }, [currentActiveFilter, filteredRows.length]);

    const syncContact = async(rowData)=> {
        //call the method to sync this contact because it has been updated
        await api.syncAction(rowData._id.toString(), {}, {...rowData}, setMessage, rowData.Name, 'OnePageCRM', setReadTable, origin);
    }

    const updateCompany = async (_id, doc, rowData, msg=null) => {
        console.log('in ActiveFilterTable update company. ' + Object.keys(rowData));
        //setMessage(`Updating Database for ${Name}`);
        await api.updateCompany(_id, doc, rowData, msg, setMessage, null, null, null, null, setReadRecordSurrogate, null, syncContact, origin, setMessage);
    } //end updateCompany()  

    //this need to be redone is done out of the useFetchTableData()

    useEffect(() => {
        isFirstRender.current = false;
        setStartTime(new Date());
    }, []); 

    useFilterCards(urlFilter === ':urlFilter' ? (currentActiveFilter ? currentActiveFilter : 'ThumbsUp') : urlFilter, 
        visibleColumns, 'column', activeFilterCardArray, activeCounts, 'master', currentActiveFilter, setCurrentActiveFilter,
        filters, setFilters);

    const setRowCell = (row, rowIndex, field, newVal) => {
        row.data[rowIndex][field] = newVal;
    }

    const stageFormatter = (row) => {
        const rowIndex = row.cell.row.index;
        const rowData = row.data[rowIndex];
        return (
        <GenericDropDown 
            //origin={origin}
            mouseover={false}
            mode={'ProspectStage'}
            updateCompany={updateCompany}
        >
            <ProspectStageDropDown 
                origin={'Table'}
                mode={'ProspectStage'}
                currentCompany={rowData}
                setRowData={(field, newVal)=>setRowCell(row, rowIndex, field, newVal)}
                setMessage={setMessage}
                //isOpen={ddOpen.ProspectStage[rowIndex]}
                //setIsOpen={(mode, val)=>{setIsOpenMode(mode, val, rowIndex)}}
                //handleToggle={(mode, val)=>{setIsOpenMode(mode, false, rowIndex)}}
                updateCompany={updateCompany}
                setChangeMade={()=>{}}
            />        
        </GenericDropDown>);
    }

    const statusFormatter = (row) => {
        const rowIndex = row.cell.row.index;
        const rowData = row.data[rowIndex];
        return (
        <GenericDropDown 
            //origin={origin}
            mouseover={false}
            mode={'CRMStatus'}
        >
            <CRMStatusDropDown  
                origin={'Table'}
                mode={'CRMStatus'}
                currentCompany={rowData}
                setRowData={(field, newVal)=>setRowCell(row, rowIndex, field, newVal)}
                setMessage={setMessage}
                setUpdateObject={()=>{}}
                updateCompany={updateCompany}
                setChangeMade={()=>{}}
            />
        </GenericDropDown>
        );
    }

    const industryFormatter = row=> {
        if (!row.value || row.value === "") return <div>{row.value}</div>;
        const rowIndex = row.cell.row.index;
        const NAICS = row.data[rowIndex].NAICS && row.data[rowIndex].NAICS.toString();

        //let's lookup the NAICS Code to display it in the popover
        let code = row.value.toString().substring(0,2);
        let obj = IndustryColors.filter(c=>c.Code === parseInt(code))[0];
        if (!obj || !('color' in obj)) {
            code = "81"
            obj = IndustryColors.filter(c=>c.Code === parseInt(code))[0];
        }
        const color = obj.color; //indColors[code].color;
    //        const color = 'default'
        const icon = obj.Icon;
        const short = obj.IndustryShort;

        //        const desc = `${row.value}: ${obj['Industry Title']}`;
        let popover = `${NAICS}: ${obj['Industry Title']}`;
        let ndx = naicsCodes.findIndex(item=>item.value.toString() === NAICS);
        if (ndx > -1) popover = `${NAICS}: ${naicsCodes[ndx].display}`; 
    //        return (<Button className={"ml-4 mr-4 text-center btn btn-sm"} style={{color:'white', backgroundColor:color}} data-toggle="popover" title={desc} data-content={desc}>{row.value}</Button>)
        return (
            <div className="m-0 p-0 pt-1">
            <div className={"text-left badge badge-pill ml-2 mr-2 pl-1 pr-2 rounded-capsule badge-" + color} 
                style={{opacity: 0.7, width:'135px'}}
                data-html={true} data-toggle="popover" title={popover} data-content="content">
                <FontAwesomeIcon icon={icon} transform="grow-2" className="ml-1" />&nbsp;&nbsp;
                <span style={{color:'white',opacity:1.0}}>{short}</span>
            </div>
            </div>
        );
    }

    const handleCompanyClick = row => {
        const rowIndex = row.cell.row.index;
        const id = row.data[rowIndex]._id.toString();
        setPatchArray([]);
        setCurrentCompany({});
        setCurrentContact({});
        history.push('/admin/detail/' + id);
    }

    //const confidenceFormatter = row => {return (row.value ? <div className="text-right">{(parseFloat(row.value)*100).toFixed(2)}</div> : <div></div>)};

    const setReadTableSurrogate = (filters) => {
        setReadTable();
    }    
    const setReadRecordSurrogate = (id, newRowData, reread=false) => {    //this is fire when a single record is updated
        if (id) {
        }
    }    

    const setShowActionModalSurrogate = () => {
        if (!showActionModal) setNotificationMsg(null);
        setShowActionModal(!showActionModal);
    }

    const removeRecFromResults = (id)=> {
        //??? FLESH THIS OUT
    }

    const actionModal = (
        showActionModal && 
        <ActionModal 
            origin={origin}
            modal={showActionModal} 
            setModal={setShowActionModal} 
            id={id}
            modalType={modalType}
            title={modalTitle} 
            subtitle={subtitle}
            titleIcon={titleIcon} 
            iconColor={iconColor}
            modalContent={modalContent}
            naicsCodes={naicsCodes}
            buttonsType={buttonsType}
            setTableMessage={setMessage}
            setFetchTime={setFetchTime}
            setReadTableParent={()=>setReadTableSurrogate(filters)}
            setFetchRecord={setReadRecordSurrogate}
            filters={filters}
            currentNavKey={currentNavKey} 
            api={api}
            env={env}
        />);

    const simpleModal = (
        showSimpleModal &&  
        <SimpleModal 
            origin={origin}
            modal={showSimpleModal} 
            setModal={setShowSimpleModal} 
            id={id}
            rowData={simpleRowData}
            modalType={modalType}
            title={modalTitle} 
            subtitle={subtitle}
            titleIcon={`${modalType === 'moveToDeleted' ? 'trash-alt' :'exchange-alt'}`} 
            iconColor={iconColor}
            modalContent={modalContent}
            buttonsType={buttonsType}
            setMessage={setMessage}
            setReadTable={setReadTable}
            setRefetchCounts={setRefetchCounts}
            setErrorLevel={()=>{}}
        />);

    const tableActions = (row) => {
        const rowIndex = row.cell.row.index;
        const rowData = row.data[rowIndex];
        const id = rowData._id.toString();
        return (
            <TableActions 
                classFromParent="d-inline" 
                row={{...row}}
                origin={origin}
                NaicsCodes={naicsCodes}
                setMessage={setMessage}
                setErrorLevel={setErrorLevel}
                showActionModal={showActionModal}
                setSimpleRowData={setSimpleRowData}
                setShowActionModal={setShowActionModalSurrogate}
                setShowSimpleModal={setShowSimpleModal}
                setReadTable={setReadTableSurrogate}
                activeFilterCard={currentActiveFilter}
                setActiveFilterCard={setCurrentActiveFilter}
                setModalType={setModalType}
                setModalTitle={setModalTitle}
                setModalSubtitle={setSubtitle}
                setModalContent={setModalContent}
                setButtonsType={setButtonsType}
                setTitleIcon={setTitleIcon}
                setIconColor={setIconColor}
                _id={id}
                setId={setId}
                setFetchRecord={setReadRecordSurrogate}
                setRowCell={setRowCell}
            />
        );
    }

    const stateSelectRef = useRef(undefined);
    const statusSelectRef = useRef(undefined);
    const industrySelectRef = useRef(undefined);
    const stageSelectRef = useRef(undefined);

    const getDescription = async (id) => {
        return await api.getDescription(id);
    }
    
    const getWebtext = async (id) => {
        return await api.getWebtext(id);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'CRMStatus',
                Filter: SelectColumnFilter,
                Cell: statusFormatter,
                columnRef: statusSelectRef
            },
            {
                Header: 'Company',
                accessor: 'Name',
                Cell: (row)=>companyFormatter(row, handleCompanyClick, getDescription),
            },

            {
                Header: 'City',
                accessor: 'City',
                Cell: (row)=>alignLeft(row, 25, 0, {whiteSpace: 'pre-wrap'})
            },
            {
                Header: 'State',
                accessor: 'State',
                Filter: SelectColumnFilter,
                Cell: stateFormatter,
                columnRef: stateSelectRef
            },

            {
                Header: 'Industry',
                accessor: 'Industry',
                Filter: SelectColumnFilter,
                Cell: industryFormatter,
                columnRef: industrySelectRef
            },
            // {
            //     Header: 'Founded',
            //     sortType: 'basic',
            //     accessor: 'Founded',
            //     disableFilters: true,
            //     Cell: alignCenter,
            // },
            // {
            //     Header: 'Emps',
            //     accessor: 'Employees',
            //     sortType: 'basic',
            //     disableFilters: true,
            //     Cell: alignRight,
            // },
            // {
            //     Header: 'Rev(k)',
            //     accessor: 'Revenue',
            //     sortType: 'basic',
            //     disableFilters: true,
            //     Cell: currencyFormatter,
            // },

            {
                Header: 'Website',
                accessor: 'Website',
                Cell: row=> {
                    if (!row.value) return <div></div>;
                    const widthToShow = 35;
                    const rowIndex = row.cell.row.index;
                    const company = row.data[rowIndex].Name;
                    const webText = (row.data[rowIndex].WebsiteInfo && row.data[rowIndex].WebsiteInfo.websiteText) || '';
                    return formatWebsite(row, row.value, company, widthToShow, 'divWithLink', getWebtext);
                },
            },
            {
                Header: 'Stage',
                accessor: 'ProspectStage',
                Filter: SelectColumnFilter,
                Cell: stageFormatter,
                columnRef: stageSelectRef
            },
            // {
            //     Header: 'Conf%',
            //     accessor: 'Confidence',
            //     sortType: 'basic',
            //     disableFilters: true,
            //     Cell: confidenceFormatter,
            // },
            {
                Header: 'Date Updated',
                accessor: 'UpdateDate',
                sortType: 'basic',
                Cell: datetimeFormatter,
                disableFilters: true,
    //                Filter: DateColumnFilter,
            },
            {
                Header: 'Actions',
                id: 'Actions',
                accessor: 'Label',
                disableSortBy: true,
                Filter: LabelFilter,
                Cell: tableActions,
            },     
       
        ],
    [iconColorTable, currentActiveFilter, industryFormatter]); //end columns

    //this is the "render part of the whole FilterTable Component"
    return (
        fetchError 
        ? <FetchErrorDisplay 
            fetchError={fetchError}
            fetchErrorDetail={fetchErrorDetail}
          /> 
        : !isLoaded 
        ? <span><Spinner color="primary" /> Loading...</span>
        : 
            (
                <Fragment>
                    <Row><Col sm={12} key="table_mainCol">
                        <Table 
                            origin={origin}
                            columns={columns} 
                            data={actives} 
                            filterCardArray={activeFilterCardArray}
                            activeFilterCard={currentActiveFilter}
                            setActiveFilterCard={setCurrentActiveFilter}
                            setVisibleColumns={setVC}
                            setFilters={setFilters}
                            isMaster={true}
                            fetchError={fetchError}
                            setFilteredRows={setFilteredRows}
                            pageSize={pageSize}
                            setPS={setPageSize}
                            pageIndex={page-1}
                            setPageIndex={setPage}
                            pageCount={pageCount}
                            start={start}
                            end={end}
                            numRecords={numRecords}
    
                        />
                        {actionModal}
                        {simpleModal}
                    </Col></Row>
                    {env === 'local' ? <Row>
                        {/* <Col><pre>{JSON.stringify([page, pageCount, start, end, numRecords], null, 2)}</pre></Col> */}
                        <Col><pre>{actives.length}</pre></Col>
                        <Col><pre>{JSON.stringify(activesNavTable, null, 2)}</pre></Col>

                        {/* <Col><pre>{Object.keys(simpleRowData)}</pre></Col>
                        <Col><pre>{actives.length}</pre></Col> */}

                        {/* <Col><pre>{JSON.stringify(org, null, 2)}</pre></Col>
                        <Col><pre>{JSON.stringify(currentUser, null, 2)}</pre></Col>
                        <Col><pre>{activeFilterCard}</pre></Col>
                        <Col><pre>{JSON.stringify(filters, null, 2)}</pre></Col> */}

                        {/* <Col><pre>{JSON.stringify(lastItems, null, 2)}</pre></Col> */}
                    </Row>
                    : null}
                    {/* <pre>
                        {JSON.stringify(data.filter(item=>item.Contacts).slice(0,20).map(item=>{
                            const contacts = item.Contacts.map(contact=>({id: contact.CRMContactId ? contact.CRMContactId : uuid(),  first: contact.First, last: contact.Last, title: contact.JobTitle}));
                            return ({
                                _id: item._id, name: item.Name, address: item.Address, city: item.City, state: item.State, postal: item.Zipcode, phone: item.Phone, website: item.Website, contacts
                            });
                        }), null, 2)}
                    </pre> */}
                    <NotificationAlert ref={notifyElem} />
                </Fragment>
            )
    );    

} //end FilterTable;

export default App;