//import ApiFunctions from './common/ApiFunctions';
import React, {useState, useEffect} from 'react';
// import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
//import {iconColorTable, } from '../tableFormats';

// import ListGroup from 'reactstrap/es/ListGroup';
// import ListGroupItem from 'reactstrap/es/ListGroupItem';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoDropDown = ({type, cellContent, rowData, getDescription, getWebtext}) => {
    const [company, setCompany] = useState(rowData.Name);
    const [description, setDescription] = useState(null);
    const [webText, setWebText] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        if (rowData) {
            setCompany(rowData.Name);
            //setDescription(rowData.Description || 'No description available currently');
            //setWebText((rowData.WebsiteInfo && rowData.WebsiteInfo.websiteText) || '');
        } //end if
    }, [rowData]);

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp, false);
        // returned function will be called on component unmount 
        return () => {
            window.removeEventListener('keyup', handleKeyUp, false);
        }
      }, [])    
  
    // Handler
    const handleToggle = async e => {
        e.preventDefault();
        if (type==='website' && getWebtext) {
            //need to fetch the company description
            const res = await getWebtext(rowData._id);
            //setWebText(res && res.webtext ? res.webtext.replace(/\n/g, "<br />") : 'Website text not available');
            setWebText(res && res.webtext ? res.webtext : 'Website text not available');
        }
        else if (getDescription) {
            //need to fetch the company description
            const res = await getDescription(rowData._id.toString());
            setDescription(res && res.description ? res.description : 'No description available');
        }
        setIsOpen(!isOpen);
    };

    const handleKeyUp = e => {
        const keys = {
          27: () => {
            e.preventDefault();
            setIsOpen(false);
          },
        };
      
        if (keys[e.keyCode]) { keys[e.keyCode](); }
    }


    //we need to build the content that the dropdown needs
    return (
        <Dropdown
            // nav
            // inNavbar
            direction="down"
            className="d-inline" 
            isOpen={isOpen}
            toggle={handleToggle}

            onClick={() => {
                let windowWidth = window.innerWidth;
                windowWidth > 992 && setIsOpen(!isOpen);
            }}
            // onMouseLeave={() => {
            //     let windowWidth = window.innerWidth;
            //     windowWidth > 992 && setIsOpen(false);
            // }}
        ><DropdownToggle 
                // nav
                tag="span"
                className={'px-0 dropdown'}
                // onClick={e=>{
                //     e.stopPropagation();
                //     setIsOpen(!isOpen); 
                // }}                
                // className={classNames('px-0', {
                //     'notification-indicator notification-indicator-primary': !isAllRead
                // })}
            >{cellContent}
                {/* <img src={statusIcon} alt="filter" height="10" width="10" /> */}
            </DropdownToggle>
            <DropdownMenu down={1} className="mt-0 pt-0" >
            <Card className={"pt-1 mt-1 card-notification shadow-none"} style={{ width: 600, minHeight: 150, maxHeight: 325 }}>
                <CardHeader className="card-header"  titletag="h6" light="false">  {/* d-inline */}

                    <button type="button" className="close d-inline" aria-label="Close" onClick={handleToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>{`${type === 'website' ? 'Website Text' : 'Description'} for: `}</div>
                    <div><strong>{`${company}`}</strong></div>

                </CardHeader>
                    <Row>
                        <Col>
                            <CardBody>
                                {/* <Card> */}
                                    <div className="p-1" style={{whiteSpace: 'pre-wrap', width: 580, minHeight: 100, maxHeight: 250, overflowY: 'auto' }}
                                        // onClick={async e=>{
                                        //     //alert(contactId + ' - ' + JSON.stringify(buildUpdateDoc(status), null, 2));
                                        //     await updateContact(contactId, buildUpdateDoc(status));
                                        //     e.stopPropagation();
                                        //     handleToggle(e);  //now close the dropdown after we are done
                                        // }}
                                    >{type==='website' ? webText : description}
                                    </div>
                                {/* </Card> */}
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
            </DropdownMenu>
        </Dropdown>
    );
}

export default InfoDropDown;