import React, {Fragment, useEffect, useState} from "react";
import classnames from "classnames";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import SmartSelect from '../../components/smartsearch/SmartSelect';
import {useHistory} from 'react-router-dom';
import {useAuth} from '../../auth/AuthContext';
//import avatar from "../../assets/img/faces/rick-geyer.jpg";
//import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";

const AdminNavbar = (props) => {

  const history = useHistory();

  const initialState = {
    collapseOpen: false,
    color: "navbar-transparent",
    backgroundColor: "navy",
    activeColor: "warning",
    sidebarMini: false,
  };

  const [stateInfo, setStateInfo] = useState(initialState);
  const {org, currentUser, logout, resetPassword} = useAuth();

  const [userEnrich, setUserEnrich] = useState({});

  useEffect(()=>{
//    alert(JSON.stringify(org, null, 2));
    //now let's get the image and name from the orgs collection
    if (org && currentUser) {
      const ndx = org.users.findIndex(item=>item.email.toLowerCase() === currentUser.email.toLowerCase());
      if (ndx > -1) {
          const orgUser = {...org.users[ndx]};
          const completeUrl = `${org.imageDir}/${orgUser.imageUrl}`;
          //alert(completeUrl);
          setUserEnrich(prev=>({...prev, name: orgUser.name, imageUrl: completeUrl}));
      }    
    }
  },[currentUser, org])

  useEffect(()=> {
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
      if (window.innerWidth < 993 && stateInfo.collapseOpen) {
        setStateInfo(prev=>({...prev, 
          color: "bg-white",
        }));
      } else {
        setStateInfo(prev=>({...prev, 
          color: "navbar-transparent",
        }));
      }
    };
    window.addEventListener("resize", updateColor);
    const unreg = ()=> window.removeEventListener("resize", updateColor);
    return unreg;
  }, [])
  
  useEffect(()=> {  //runs every time the comp rerenders
    if (
      window.outerWidth < 993 &&
      //e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  });
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    let newState = {
      collapseOpen: !stateInfo.collapseOpen,
    };
    if (!stateInfo.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    setStateInfo(prev=>({...prev, ...newState}));
  };

  const handleActiveClick = (color) => {
    setStateInfo(prev=>({...prev,  activeColor: color }));
  };
  const handleBgClick = (color) => {
    setStateInfo(prev=>({...prev,  backgroundColor: color }));
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setStateInfo(prev=>({...prev,  sidebarMini: false }));
    } else {
      setStateInfo(prev=>({...prev,  sidebarMini: true }));
    }
    document.body.classList.toggle("sidebar-mini");
  };

    return (
      <Fragment>
        <Navbar
          className={classnames("navbar-absolute fixed-top", stateInfo.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper sticky">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: stateInfo.sidebarOpen,
                })}

              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand onClick={(e) => e.preventDefault()}>
                <span className="d-none d-md-block">
                  <strong>{org ? org.name : null}</strong> - <small>Find Your Bluebirds With AI</small>
                </span>
                <span className="d-block d-md-none">PureBlue</span>
              </NavbarBrand>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={stateInfo.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={stateInfo.collapseOpen}
            >
              {/* <Form>
                <InputGroup className="no-border">
                  <Input defaultValue="" placeholder="Search..." type="text" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form> */}
              <SmartSelect />
              <Nav navbar className="mt-0 pt-1">
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    //href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-layout-11" />
                    {/* <p>
                      <span className="d-lg-none d-md-block">Stats</span>
                    </p> */}
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown 
                    className="btn-rotate" nav>
                  <DropdownToggle
                    tag="span"
                    aria-haspopup={true}
                    caret
                    color="transparent"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                    className="ml-0 pl-0"
                    style={{backgroundColor: 'transparent'}} 
                  >
                    <i className="nc-icon nc-bell-55" />
                    {/* <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p> */}
                  </DropdownToggle>
                  <DropdownMenu
                    tag="span"
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  >
                    <DropdownItem
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
//                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
//                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else here
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown 
                    tag="span"
                    className="mt-0 pt-0" 
                nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    tag="span"
                    //caret
                    //color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                  <div className="mt-0 pt-0"  style={{width: 35, height: 35}} 
                      data-html={true} data-toggle="popover" title={currentUser ? ' Logged In As: ' + currentUser.email : 'No User Logged In'} data-content="content"                  
                  >{org && userEnrich.imageUrl ?
                    <img src={`${userEnrich.imageUrl}`} alt={`${userEnrich.name}`} />
                    : <span>IN</span>}
                  </div>
                    {/* <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p> */}
                  </DropdownToggle>
                  <DropdownMenu
                    tag="span"
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  > 
                    <DropdownItem
                    >
                      <strong>{currentUser ? <div>{userEnrich.name}<br />{currentUser.email}</div> : 'Please Log In'}</strong>
                    </DropdownItem>
                    {currentUser && <Fragment>
                    <DropdownItem divider/>
                    <DropdownItem
                      href=""
                      onClick={(e) => resetPassword()}
                    >
                      Reset Password
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => alert('Profile Page Coming Soon!')}
                    >
                      Profile
                    </DropdownItem>
                    <DropdownItem
                      //href="#pablo"
                      onClick={(e) =>{ 
                        logout();
                        history.push('/auth/login');
                      }}
                    >
                      Log Out
                    </DropdownItem>
                    </Fragment>}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                {/* <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="fixedPlugInMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-settings-gear-65" />
                    <FixedPlugin
                      bgColor={stateInfo.backgroundColor}
                      activeColor={stateInfo.activeColor}
                      sidebarMini={stateInfo.sidebarMini}
                      handleActiveClick={handleActiveClick}
                      handleBgClick={handleBgClick}
                      handleMiniClick={handleMiniClick}
                    />                
                  </DropdownToggle>
                  </UncontrolledDropdown> */}
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </Fragment>
    );
  }

export default AdminNavbar;
