import React, { useEffect, useState } from 'react';
import {Table, Input} from 'reactstrap';
import ContactDetails from './ContactDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {dangerColor, warningColor, infoColor, primaryColor} from '../../common/tableFormats';

const ContactsModal = ({data, setRowData, setUpdateObject, newIndex, setNewIndex, deleteContact, setEnableSync, editIndex, setEditIndex, 
    renderSaveCancel, setRenderSaveCancel, setYes, changeMade, setChangeMade }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');

  const [sortedArray, setSortedArray] = useState([]);
  const [collapseArray, setCollapseArray] = useState([]);
  
  //first we must see if there is a CRMSync object in the data.  If so, get values.

  useEffect(()=>{
    //alert('useEffect data fired ' + editIndex);
    if (data && 'Contacts' in data) {
      const ContactsArray = [...data.Contacts];
      const tmpArray = ContactsArray.sort((a,b)=> a.Last < b.Last);
      setCollapseArray([...tmpArray.map((item,i)=>{if (editIndex !== -1 && editIndex === i) return "collapse.show"; else return "collapse"})]);
      setSortedArray([...tmpArray]);
      if (newIndex !== -1) {
        setEditIndex(newIndex);
        setNewIndex(-1);
      }
    }
  }, [data, newIndex, editIndex, data.Contacts, setEditIndex, setNewIndex]);

  const deleteContactEvent = (e, i) => {
    e.stopPropagation();
    const contact = sortedArray[i];
    const first = contact.First;
    const last = contact.Last;
    let answer = prompt(`Enter 'yes' to delete ${i}: ${first} ${last}?`);
    if (answer) answer = answer.toLowerCase();
    if (['y', 'yes'].includes(answer)) deleteContact(i);
  }

  let selectedIndex = -1;
  const toggleContactOpen = (e, i, mode='toggle') => {
    e && e.stopPropagation();
    selectedIndex = i;
    let tmpArray = [...collapseArray];
    if (mode === 'open') tmpArray[selectedIndex] = "collapse.show";
    else if (mode === 'close') tmpArray[selectedIndex] = "collapse";
    else tmpArray[selectedIndex] = tmpArray[selectedIndex] === "collapse" ? "collapse.show" : "collapse";
    setCollapseArray(tmpArray);
  }

  useEffect(()=>{
    // alert('calling useEffect editMode with ' + tmpArray.length);
    if (data && 'Contacts' in data) {
      if (editIndex > -1) {
        //let's populate the state fields with the contact in edit mode
        setEnableSync(false);
        let contact = data.Contacts[editIndex];
        setFirstName(contact.First || '');
        setLastName(contact.Last || '');
        setEmail(contact.Email || '');
        setTitle(contact.JobTitle || '');
        setPhone(contact.Phone || '');
  
        toggleContactOpen(null, editIndex, 'open');
      } else if (changeMade) { //otherwise we are turning edit mode off for this contact
        setUpdateObject({Contacts: [...sortedArray] });
      } else if (editIndex === -1) setEnableSync(true);
    }
  }, [editIndex]);  //editMode

  const toggleEditMode = (e, i) => {
    e && e.stopPropagation();
    if (editIndex !== i)
      setEditIndex(i);
    else 
      setEditIndex(-1);
  }

  useEffect(()=>{
    if (changeMade === true && !renderSaveCancel) {
      //alert('change made firing'); 
      setRenderSaveCancel(true); 
      setYes('Save');
    }
  }, [changeMade]);

  const fieldChanged = (e, i, fieldName, fieldValue, setField, insertedValue=null) => {
    let newValue = insertedValue || e.target.value || null;
    if (!fieldValue || (newValue !== fieldValue.toString())) {
      console.log('old val: ' + fieldValue);
      //console.log('new val: ' + newValue);
      let contacts = [...data.Contacts];
      if (fieldName === 'Notes') {
        if (contacts[i].Notes && contacts[i].Notes.length)
          contacts[i].Notes[0] = newValue;
        else
          contacts[i].Notes = [newValue];
      }
      else contacts[i][fieldName] = newValue;
      contacts[i].CreateDate = contacts[i].CreateDate || contacts[i].UpdateDate;
      contacts[i].UpdateDate = new Date();
      //setRowData({...data, Contacts: [...contacts]});
      setChangeMade(true);
      setUpdateObject({Contacts: [...contacts] });
      setField(newValue);
    }
  }

  const fieldBlur = e=>{
    e.stopPropagation();
    // if (changeMade) setUpdateObject({Contacts: [...sortedArray] });
  }

  if (!sortedArray || (sortedArray && !sortedArray.length))
    return <div className="text-center">No Contacts</div>
    else return (
  <div style={{minWidth: 400, maxHeight:400, overflowX: 'auto'}}>
  <Table striped>
    <thead>
      <tr key='contacts_table_thead_tr'>
          <th key='th_blank'>{' '}</th>
          <th key='th_first'>First</th>
          <th key='th_last'>Last</th>
          <th key='th_title'>Job Title</th>
          <th key='th_email'>Email</th>
          <th key='th_phone'>Direct Phone</th>
          <th key='th_actions' colSpan="2">Actions</th>
      </tr>
    </thead>
    <tbody>
      {sortedArray.map((row, i)=>{ 
        return ( 
        <React.Fragment key={`${row.key}_fragment`}>
        <tr key={row.key + "_tr"}>
            <td key={`${row.key}_chevron_td`}>
              <FontAwesomeIcon 
                  key={`${row.key}_chevron_icon`}
                  icon={collapseArray[i] === "collapse" ? 'chevron-right' : 'chevron-down'} transform="grow-2" 
                  className="ml-1 mr-0" style={{color:primaryColor}} 
                  onClick={(e)=>toggleContactOpen(e, i)}
              />
            </td>
            {editIndex === i ? (    //render editable fields
            <React.Fragment>
              <td className="ml-0 pl-0 pr-0 mr-0" key={`${row.key}_firstName_td`}>
                <div style={{ //whiteSpace: 'nowrap',
                      fontSize: '.8rem',}}>
                        <Input type="text" placeholder="First" size="8" value={firstName}
                          key={`${row.key}_firstName_input`}
                          onChange={(e)=>fieldChanged(e, i, 'First', firstName, setFirstName)}
                   />
                </div>
              </td>
              <td className="ml-0 pl-0 pr-0 mr-0" key={`${row.key}_lastName_td`}>
                <div style={{fontSize: '.8rem',}}>
                  <Input type="text" placeholder="Last" size="8" value={lastName}
                        key={`${row.key}_lastName_input`}
                        onChange={(e)=>fieldChanged(e, i, 'Last', lastName, setLastName)}
                  />
                </div>
              </td>
              <td className="ml-0 pl-0 pr-0 mr-0" key={`${row.key}_title_td`}>
                <div style={{fontSize: '.8rem',}}>
                  <Input type="text" placeholder="Job Title" size="15" value={title}
                        key={`${row.key}_title_input`}
                        onChange={(e)=>fieldChanged(e, i, 'JobTitle', title, setTitle)}
                  />
                </div>
              </td>
              <td className="ml-0 pl-0 pr-0 mr-0" key={`${row.key}_email_td`}>
                <div style={{fontSize: '.8rem',}}>
                  <Input type="text" placeholder="email@example.com" size="22" value={email}
                      key={`${row.key}_email_input`}
                      onChange={(e)=>fieldChanged(e, i, 'Email', email, setEmail)}
                  />
                </div>
              </td>
              <td className="ml-0 pl-0 pr-0 mr-0" key={`${row.key}_phone_td`}>
                <div style={{ //whiteSpace: 'nowrap',
                fontSize: '.8rem',}}></div>
                <div style={{fontSize: '.8rem',}}>
                  <Input type="text" placeholder="nnn-nnn-nnnn" size="14" value={phone}
                      key={`${row.key}_phone_input`}
                      onChange={(e)=>fieldChanged(e, i, 'Phone', phone, setPhone)}
                  />
                </div>
              </td>
            </React.Fragment>
            ) :                               //otherwise render display only fields
            <React.Fragment>
              <td key={`${row.key}_first_disp_td`}><div className="text-left" style={{ //whiteSpace: 'nowrap',
                fontSize: '.8rem',}}>{row.First}</div>
              </td>
              <td key={`${row.key}_last_disp_td`}><div className="text-left"  style={{ //whiteSpace: 'nowrap',
                fontSize: '.8rem',}}>{row.Last}</div>
              </td>
              <td key={`${row.key}_jobtitle_disp_td`}><div className="text-left"  style={{ //whiteSpace: 'nowrap',
                fontSize: '.8rem',}}>{row.JobTitle}</div>
              </td>
              <td key={`${row.key}_email_disp_td`}><div className="text-left"  style={{ //whiteSpace: 'nowrap',
                fontSize: '.8rem',}}><a key={`${row.key}_email_a`} href={`mailto:${row.Email}`}>{row.Email}</a></div>
              </td>
              <td key={`${row.key}_phone_disp_td`}><div className="text-left"  style={{ //whiteSpace: 'nowrap',
                fontSize: '.8rem',}}><a key={`${row.key}_phone_a`} href={`tel:${row.Phone}`}>{row.Phone}</a>
              </div></td>
            </React.Fragment>
            }
            <td key={`${row.key}_edit_icon_td`}>
              <FontAwesomeIcon 
                  key={`${row.key}_edit_icon`} 
                  icon={(editIndex === i ? 'check' : 'pencil-alt')} transform="grow-2" 
                  className="ml-2 mr-2" style={{color:editIndex === i ? warningColor : infoColor}} 
                  onClick={(e)=>toggleEditMode(e, i)}
              />
            </td>
            <td key={`${row.key}_trash_icon_td`}>
              <FontAwesomeIcon 
                  key={`${row.key}_trash_icon`} 
                  icon={'trash-alt'} transform="grow-2" 
                  className="ml-2 mr-2" style={{color:dangerColor}} 
                  onClick={(e)=>deleteContactEvent(e, i)}
              />
            </td>
        </tr>
        <tr className={"w-100 " + collapseArray[i]} id={`ContactDetails_${i}`} key={`${row.key}_contact_details_tr`}>
            <td colSpan={8} key={`ccontact_details_td`}>
              <ContactDetails
                key={`${row.key}_ContactDetails`}
                contact={row}
                i={i}
                fieldChanged={fieldChanged}
                editMode={editIndex === i}
                fieldBlur={fieldBlur}
              />
            </td>
        </tr>
        <tr style={{maxHeight: '1px', display: 'none'}} key={`${row.key}_blank_line_tr`}>
          <td colSpan="6" key={`${row.key}_blank_line_td`}></td>
        </tr>
        </React.Fragment>
        )     
      })}
    </tbody>          
  </Table>
  </div>
)

}

export default ContactsModal;