import React, {useState} from 'react';
import {
  ModalHeader,
  Row, //Input,
  Col, Card, Button
} from 'reactstrap';
//import GenericDropDown from '../../components/dropdowns/GenericDropdown';

// import Background from './Background';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import is from 'is_js';
import {formatWebsite, toDateString, //getCRMStatusValueArray, getCountryValueArray, getStateValueArray, 
        //getIconColorTable, warningColor, 
        primaryColor, //secondaryColor, setHistory, formatObj, successColor,
      } from '../../common/tableFormats';

// const selectFieldsFull = { 
//   'CRMStatus': {
//     valueArray: getCRMStatusValueArray()
//   },
//   'Country' : {
//     valueArray: getCountryValueArray()
//   },
//   'State' : {
//     valueArray: getStateValueArray()
//   }
// };


const SimpleModalHeader = ({rowData, titleIcon, iconColor, title, toggle, moveAll=false}) => {
                            //modalType, toggle, setRenderSaveCancel, changed, setChanged, 

//  const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);

  const [Name, setName] = useState(rowData.Name);
  const [Address, setAddress] = useState(rowData.Address || '');
  const [City, setCity] = useState(rowData.City);
  const [State, setState] = useState(rowData.State);
  const [Zipcode, setZipcode] = useState(rowData.Zipcode);
  const [Phone, setPhone] = useState(rowData.Phone);
  const [Website, setWebsite] = useState(rowData.Website);

  const WebText = rowData.WebsiteInfo ? rowData.WebsiteInfo.websiteText : null;

  const UpdateDate = rowData.UpdateDate;
  const bullet = '\u2022';

  const shadowClass = 'shadow-lg';

  const modifiedDateLine = (<React.Fragment>
      <p style={{
        whiteSpace: 'nowrap',
        fontSize: '.8rem',
      }}
      ><small>Modified: {toDateString(UpdateDate, 4)}</small></p>
    </React.Fragment>
  );

  return (
      <ModalHeader className="bg-light mb-1 pb-1">
         <Row className="m-2 mb-0 pb=0 rounded-soft-top px-2 py-2 pb-0 pt-2 mb-0 mt-0">
          <Col sm="12" className="pl-0 pt-1 pb-0 mb-0 ml-1 mt-2 mr-2 d-inline justify-content-left text-left">
            <Row className="mt-0 pt-0"><Col>
              <h4 className="m-1 d-inline card-category text-left fs++2 icon-big">
                    <FontAwesomeIcon 
                      id="edit" icon={titleIcon} transform="grow-5" 
                      className="ml-0 pl-0 mr-3" style={{color:primaryColor}} 
                    />
                  {title}
              </h4>
            </Col></Row>
          </Col>
        </Row>
        {!moveAll && <Row className="mb-0 pb-0 pr-4">
          <Col sm="12" className="text-left mb-0 pb-0 ml-2 mr-2">
            <Card className="p-2">
            <Row><Col sm="12">

              <React.Fragment>
                    <Row><Col sm="12">
                    <div><strong>{Name}</strong></div>
                    </Col></Row>
                    <Row><Col sm="9">
                    <div className="fs--1"
                        style={{
                            fontSize: '.9rem',
                        }}
                    >{Address}</div>
                    <div className="fs--1"
                        style={{
                            fontSize: '.9rem',
                        }}
                    >{City}{City ? ', ' : ' '} {State} {Zipcode}</div>
                    <div className="fs--2"
                        style={{
                        whiteSpace: 'nowrap',
                                      fontSize: '.9rem',
                                  }}
                    >{Website ? formatWebsite(rowData, Website, Name, 50, 'divWithLink') : ''} {Website && Phone ? ' ' + bullet + ' ' : ''} {Phone}</div>
                    <div>{modifiedDateLine}</div>
                    </Col>
                    </Row>
                </React.Fragment>
              </Col>
            </Row>
          </Card>
          </Col>
          </Row>}
          <Row className="position-absolute t-0 r-0 z-index-1"><Col>
          <Button
              size="sm"
              className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
              onClick={() => toggle()}
          >
              <FontAwesomeIcon icon="times" transform="shrink-6 right-0.3 down-0.3" />
          </Button>
        </Col></Row>

        </ModalHeader>
    )
};  //end component

export default SimpleModalHeader;
