import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeAgo from 'react-timeago';
import {infoColor} from '../common/tableFormats';

const UpdatedClock = ({fetchTime})=> {

    return (<div className="stats">
                <FontAwesomeIcon 
                    icon={'history'} transform="grow-12" 
                    className="mr-2" style={{color:infoColor}} 
                /> Updated <TimeAgo date={fetchTime} />
            </div>
    );

}

export default UpdatedClock;


