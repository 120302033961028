import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import {AuthProvider} from './auth/AuthContext.js'
import {APIProvider} from './context/APIProvider';
import {CompanyProvider} from './context/CompanyContext.js';

import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import './initFA';

const hist = createBrowserHistory();

ReactDOM.render(
  <AuthProvider>
    <APIProvider>
      <CompanyProvider>
        <Router history={hist}>
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect to="/admin/dashboard" />
          </Switch>
        </Router>
    </CompanyProvider>
    </APIProvider>
  </AuthProvider>
  ,
  document.getElementById("root")
);
