import React, {Fragment, useState, useEffect, useContext, useRef, useMemo} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {CompanyContext} from '../../context/CompanyContext';
import {primaryColor, } from '../../common/tableFormats';

const DetailPane = ({children, paneName, tabName, numberCols=2, tabSelected, setTabSelected, editable=true, handleTabClick, subText, getBoundingTop, hasInsert=false, addNewRecord=null}) =>{

  const {
    insertMode, activeFilterCard, activesNavTable, lastItems,
    } = useContext(CompanyContext);

    // const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);

    const [expand, setExpand] = useState(tabSelected ? true : false);
    const [numLg, setNumLg] = useState("6");
    const [numSm, setNumSm] = useState("6");
    const [expandIcon, setExpandIcon] = useState('expand');

    const paneRef = useRef(null);

    useEffect(() => {
      if (numberCols === 1) {
          setNumLg("12")
          setNumSm("12")
          setExpandIcon("compress")
          //alert(paneRef.current.getBoundingClientRect().top)
          getBoundingTop && getBoundingTop(tabName, paneRef.current.getBoundingClientRect().top);
          //paneRef.current.scrollIntoView();
        } else {
          setNumLg(Math.round(12/numberCols));
          setNumSm(Math.round(12/numberCols));
          setExpandIcon("expand");
          setTabSelected && setTabSelected(tabName, false); 
      }
    }, [expand, numberCols]);

    useEffect(() => {
      if (tabSelected) {
          setExpand(true);
      } else {
          setExpand(false);
      }
    }, [tabSelected]);

    // useEffect(()=>{
    //   getBoundingTop && getBoundingTop(tabName, paneRef.current.getBoundingClientRect().top);
    //   //if (paneRef) alert(paneRef.current.getBoundingClientRect().top)
    // },[]);

    const toggleExpand = (tabName)=> {
      setExpandIcon(expand ? "compress" : "expand")
      setExpand(prev=>!prev);
      handleTabClick(tabName);
    }

    return (
        <Col className="m-0 p-1 mb-0 pb-0" lg={numLg} sm={numSm}>
        <div id={`${tabName}_view`}  ref={paneRef}>
        <Card key={`${tabName}_view`} className="ml-0 pl-0 mr-0 pr-0 mb-2 pb-0">
        <CardHeader className="pt-2 pb-0 mb-0 mt-0">
          <Row className="m-0 p-0 ml-0 mr-0 pb-0">
            <Col sm={"10"} className="mb-0 pb-0">
              <h5 className="text-left mb-0 pb-0">{paneName}</h5>
              {subText ? subText
              : null}
            </Col>
              <Col sm="2" className="text-right p-0 m-0 pull-right">
                {hasInsert ? <Fragment>
                  <FontAwesomeIcon icon={'plus'} color={primaryColor} transform="grow-9" className="m-0 p-0 pr-1" onClick={addNewRecord}
                    disabled={insertMode}
                  />
                  <span>&nbsp;&nbsp;</span>
                </Fragment> : null }
                <FontAwesomeIcon icon={expandIcon} transform="grow-5" className="m-0 p-0" onClick={()=>toggleExpand(tabName)}/>
              </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {children}
        </CardBody>
        {/* <CardFooter>
          <hr />
          <Row>
            <Col sm="7">
              <div className="footer-title">Contact Form on Top, List on the Bottom</div>
            </Col>
            <Col sm="5">
              <div className="pull-right">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                >
                  <i className="nc-icon nc-simple-add" />
                </Button>
              </div>
            </Col>
          </Row>
        </CardFooter> */}
      </Card>
      </div>
      </Col>
)


} //end functional component

export default DetailPane;