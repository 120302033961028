import React, {Fragment, useState, useEffect, useRef, useContext} from 'react';
import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import {Row, Col, Input, Form, InputGroup, InputGroupAddon, InputGroupText, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {useHistory} from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import SelectSearch, { useSelect } from 'react-select-search';
import './selectStyle.css';
//import fuzzySearch from './fuzzySearch.js';
//import {dangerColor, fuzzyTextFilterFn} from '../../common/tableFormats';
import './customSelectSearchStyles.css';
//import { sortedLastIndex } from "lodash-es";
//import './materialUIText.css'

// const UP_ARROW = 38;
// const DOWN_ARROW = 40;

const SmartSelect = ({fieldName, fieldValue, toggleEdit, error=false, optionsFunction, dbAsFilter=false, inputKey, addRow=null})=> {
  const history = useHistory();

  const {org, orgId, currentUser, setOrgAfterLogin, env} = useAuth();
  const { api, setImportsNavTable, setActivesNavTable, setTableOrigin,
    setCurrentActiveFilter, setCurrentImportsFilter
  } = useContext(CompanyContext);      

  useEffect(async ()=>{
    if (!org && currentUser) {
      await setOrgAfterLogin(currentUser.uid);
    }
  },[org]);

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(fieldValue ? fieldValue : "");
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState(false);
  const [optionIndex, setOptionIndex] = useState(-1);
  //const [originalValue, setOriginalValue] = useState(value);
  const ref = useRef(null);

  useEffect(()=>{
    document.addEventListener("keydown", handlePlusKey);
    return ()=> document.removeEventListener("keydown", handlePlusKey);
  }, []);

  useEffect(()=>{
    const retrieveOptions = async () => {
            console.log('about to retrieve Options ' + query);
      setLoading(true);
      const tempOptions = await api.globalSearch(query || "A  ");
//      console.log('got async options')
      setLoading(false);
      const opts = tempOptions.data;
      //alert(JSON.stringify(opts));
      setOptions(opts);
    }
    if (!loading && query && query.length && query.length >= 3) retrieveOptions();
  }, [query]);
  
  function handlePlusKey(key, e) {
    if (key === '+') {
      //close(e);
      alert('Plus Key');
      return;
    }      
  }

  useEffect(()=>{
    if (options.length && !select) {
//        console.log('setting open');
      if (options.length > 0) {
        setIsOpen(true);
        setOptionIndex(0);
      } else setOptionIndex(-1);
    }
  },[options, select]);

  useEffect(()=>{
//    console.log('value is', value);
    setQuery(value || "");
  }, [value])

  useEffect(()=>{
    document.addEventListener("click", close);
    return ()=> document.removeEventListener("click", close);
  }, []);

  function close(e) {
    console.log('SmartSelect', '6-IN CLOSE FN', (e && e.target === ref.current))
    const keepOpen = (e && e.target === ref.current);
    setIsOpen(keepOpen);
  }

  function handleKeypress({key, e}) {
    if (key === 'Escape') {
      //if user enters a date and then presses enter or tab, then if valid date, we need to SET the value
      //console.dir(['handleKeyDown', originalValue]);
      //setValue(originalValue);
      close(e);
      return false;
    }
    if (key === 'Tab') {
      close(e);
      setValue('')
      console.log(`calling close()`)
      return false;
    }
    // if (key === '+') {
    //   addRow();
    //   return false;
    // }
    if(options.length < 1) return;

    switch(key) {
      case "Enter":
        //alert('SmartSelect Search 3.1',options.length, optionIndex);
        if ( optionIndex < 0) return 
          //setSelect(true);
          setIsOpen(false);
          handleOptionSelected(options[optionIndex], e);
          //setSelect(false);
          break;

      case 'ArrowDown':
        if (isOpen) setOptionIndex(prev => prev+1);
        else {
          setIsOpen(true);
          setOptionIndex(0);
        }
        console.log(`set option index to ${optionIndex+1}`)
        break;

      case 'ArrowUp':
        if (optionIndex < 1) return
        setOptionIndex(prev => prev-1);
        console.log(`set option index to ${optionIndex-1}`)
        break;

      default: return true;
    } //end switch
  }

  function handleOptionSelected (option, e) {
//    console.log('SmartSelect', 'in handleOptionSelected', option);
    if (!option) return;
    //e && e.stopPropagation();
    //alert(JSON.stringify(option));
    setIsOpen(false);
    //setOptionIndex(-1);
    setQuery('');
    setValue('');
    const singleNav = {
      [option._id.toString()]: [null, null]
    }
    if (option.coll === "master") {
      setTableOrigin('Active');
      setActivesNavTable({...singleNav});
      setCurrentActiveFilter('ThumbsUp');
    } else {
      setTableOrigin('Imports');
      setImportsNavTable({...singleNav});
      setCurrentImportsFilter('Recent');
    }
    history.push(`/admin/detail/${option._id.toString()}`);
  }

  return (
    <Row className="text-center">
      <Col sm="auto" className="pr-0 mr-2" style={{width:300}}>
        <Dropdown isOpen={isOpen} toggle={()=>setIsOpen(prev=>!prev)}>
            <DropdownToggle tag="span" color="link" size="sm" className="text-600 btn-reveal" direction='left'
            >
            <Form>
                <InputGroup className="no-border control">
                  <Input type="text" 
                    ref={ref}
                    key={`input_${inputKey}`}
                    placeholder={'Search Company/Contact'}
                    value={query}
                    onKeyDown={handleKeypress}
                    onChange={e => {
                        setSelect(false);
                        setQuery(e.target.value || "");
                        //alert(e.target.value ? e.target.value.toLowerCase() : "");
                        setValue(e.target.value ? e.target.value.toLowerCase() : "");
                        setIsOpen(false);
                    }}
                    />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
            </DropdownToggle>
            <DropdownMenu style={{width: 500}}> 
              <DropdownItem header>
                <Row>
                  <Col sm="1" className="text-left">A/I</Col>
                  <Col sm="5" className="text-left">Company</Col>
                  <Col sm="4" className="text-left">City</Col>
                  <Col sm="2" className="text-left">State</Col>
                </Row>
              </DropdownItem>
              <DropdownItem divider />
              {options.map((comp, i)=>{ 
                if (comp.Name.toLowerCase().indexOf(query) > -1) {
                  return (
                  <DropdownItem className={`text-primary select-search__option ${i === optionIndex ? 'is-highlighted' : ''}`} key={`dropdown_${i}`}
                    onClick={(e)=>{
                      setIsOpen(false);
                      handleOptionSelected(comp, e);
                      // setQuery('');
                      // setValue('');
                      // history.push(`/admin/detail/${comp._id.toString()}`);
                    }}
                  >
                    <Row>
                      <Col sm="1">{comp.coll === 'master' ? 'A' : 'I'}</Col>
                      <Col sm="5">{`${comp.Name.substr(0,20)}${comp.Name.length > 20 ? '...' : ''}`}</Col>
                      <Col sm="4">{comp.City}</Col>
                      <Col sm="2">{comp.State}</Col>
                    </Row>
                </DropdownItem>)
                }
            })}
              <DropdownItem divider />
              <DropdownItem header>
                <Row>
                  <Col sm="1">A/I</Col>
                  <Col sm="3">First</Col>
                  <Col sm="4">Last</Col>
                  <Col sm="4">Company</Col>
                </Row>
              </DropdownItem>
              <DropdownItem divider />
              {options.map((comp, i)=>{ 
                if (!comp.Contacts) comp.Contacts = [{First: '<no>', Last: '<contacts>'}];
                return comp.Contacts.map(contact=> {
                  if (contact.Last.toLowerCase().indexOf(query) > -1 ) return (
                  <DropdownItem className={`text-primary select-search__option ${i === optionIndex ? 'is-highlighted' : ''}`} key={`dropdown_${i}`}
                    onClick={(e)=>{
                      setIsOpen(false);
                      handleOptionSelected(comp, e);
                      // setQuery('');
                      // setValue('');
                      // history.push(`/admin/detail/${comp._id.toString()}`);
                    }}
                  >
                    <Row>
                      <Col sm="1">{comp.coll === 'master' ? 'A' : 'I'}</Col>
                      <Col sm="3">{contact.First}</Col>
                      <Col sm="4">{contact.Last}</Col>
                      <Col sm="4">{`${comp.Name.substr(0,20)}${comp.Name.length > 20 ? '...' : ''}`}</Col>
                    </Row>
                </DropdownItem>)
                }
            )})}
            </DropdownMenu>

        </Dropdown>
      </Col>
    </Row>
    )        
  }
  
export default SmartSelect;