import React, {useState} from 'react';
import UpdatedClock from '../../components/UpdatedClock';

import { Container, 
    // Dropdown, DropdownItem 
} from "react-bootstrap";
import { 
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col, 
    // InputGroup, Input, Form, Button, Tooltip, Alert 
  } from "reactstrap";

import '../../assets/css/pagination.css';

//import ReactBSAlert from "react-bootstrap-sweetalert";

import FilterTable from './ImportsFilterTable';
//import contactsFormat, {tableContent} from './contactsFormat';

const ImportsTable = ()=> {

  const [fetchTime, setFetchTime] = useState(new Date());
  const [reload, setReload] = useState(false);
    
    return (
        <div className="main-content">
        <Container fluid>
            <Row>
            <Col md={12}>
            <Card>
                <CardHeader className="mb-0">
                  <CardTitle tag="h5">Imports and Staged Companies    <strong><small className="text-primary hover-pointer"
                    onClick={()=>setReload(true)}><u>reload</u></small></strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <FilterTable 
                      setFetchTime={setFetchTime}
                      reload={reload}
                      setReload={setReload}
                    />
                </CardBody>
                <CardFooter>
                  <hr />
                  <UpdatedClock 
                    fetchTime={fetchTime} 
                  />
                </CardFooter>
              </Card>
            </Col>
            </Row>
         
        </Container>
        </div>
    );

//    return (<div>{data.length}</div>);
}

export default ImportsTable;


