import React, {useState, useEffect} from 'react';
import { Dropdown } from 'reactstrap';
import '../../assets/css/cursorPointer.css';

const GenericDropDown = ({mouseover=false, mode, rowData, setRowData, setMessage, isOpen, setIsOpen, //setReadTable, 
    setUpdateObject, setChangeMade, updateCompany, children}) => {
    // const company = rowData.Name;

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp, false);
        // returned function will be called on component unmount 
        return () => {
            window.removeEventListener('keyup', handleKeyUp, false);
        }
    }, [])    
  
    
    // Handler
    const handleToggle = e => {
        setIsOpen(mode, !isOpen);
    };

    const handleKeyUp = e => {
        const keys = {
          27: () => {
//            e.preventDefault();
                setIsOpen(mode, false);
            },
        };
      
        if (keys[e.keyCode]) { keys[e.keyCode](); }
    }

    const renderIndustryDropDown = (<div>Ind</div>);
    const renderCompanySummaryDropDown = (<div>Summary</div>);
    const renderWebTextDropDown = (<div>WebText</div>);
    
//    console.log('in GenericDropDown ', origin, mode);
    //okay we need to look at the mode and render the correct component to display inside the dropdown
    let dropdownComponent;

    return (
        <Dropdown 
            // nav
            // inNavbar
            direction={'left'}
            className="d-inline" 
            isOpen={isOpen}
            //toggle={handleToggle}

            // onMouseOver={() => {
            //     let windowWidth = window.innerWidth;
            //     windowWidth > 992 && mouseover && setIsOpen(prev=>({...prev, [mode]: true}));
            // }}
            // onMouseLeave={() => {
            //     let windowWidth = window.innerWidth;
            //     windowWidth > 992 && mouseover && setIsOpen(prev=>({...prev, [mode]: false}));
            // }}
        >  
            {children}
        </Dropdown>
    );
}

export default GenericDropDown;