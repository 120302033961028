import React , { Fragment, useEffect, useState } // , {useMemo} 
from 'react';
import NotificationAlert from "react-notification-alert";
import {
  Modal, 
  ModalBody, Spinner, 
  Row, //Input,  //FormGroup, Label, 
  Col, Card, CardBody,
} from 'reactstrap';
import { nanoid } from 'nanoid'

import ActionModalHeader from './ActionModalHeader';
import ModalButtons from './ModalButtons';
import ModalNavButtons from './ModalNavButtons';
import ConfirmationModal from './ConfirmationModal';

import CRMSyncModalForm from './CRMSyncModalForm';
import ViewHistoryModal from '../detail/HistoryPane';
import ContactsModal from './ContactsModal';
import NotesModal from './NotesModal';
import GenericContent from '../../components/GenericContent';
import FetchErrorDisplay from '../../components/fetchError/FetchErrorDisplay';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getCRMStatusValueArray, getCountryValueArray, getStateValueArray, useNotification} from '../../common/tableFormats';
import {useFetch} from '../../common/apiHooks';

//import { optionCSS } from 'react-select/src/components/Option';

// const excluded = ['_id', 'DUNSNumber', 'CRMCompanyId', 'Gender', 'AgeRange', 'Value Index', 'Unpredictable', '3-Year Commission', 'Close Date', 'CycleDays', 'First Contacted'];
// const crmFields = ['CRMCompanyId', 'Name', 'City', 'State', 'Country/Region', 'Zipcode', 'Website' ];
// const readonly = ['Confidence', 'UpdateDate', 'CreateDate', 'aiLeadSource',];
// const dateFields = [ 'UpdateDate', 'CreateDate'];
//const percentage = ['Confidence'];
const selectFieldsFull = { 
  'CRMStatus': {
    valueArray: getCRMStatusValueArray()
  },
  'Country' : {
    valueArray: getCountryValueArray()
  },
  'State' : {
    valueArray: getStateValueArray()
  }
};

const ActionModal = ({origin, modal, setModal, id, setId, modalContent, filters, modalType, title, subtitle, titleIcon, iconColor, buttonsType, naicsCodes, 
 setTableMessage, setFetchTime, setReadTableParent, setFetchRecord, className, currentNavKey, api, env}) => {

  const notifyElem = React.useRef(null);
  const isFirstRender = React.useRef(true);

  const [noInitial, yesInitial] = buttonsType.split(':');
  // const [saveTooltipOpen, setSaveTooltipOpen] = useState(false);
  // const [cancelTooltipOpen, setCancelTooltipOpen] = useState(false);
  // const [disabled] = useState(modalType === 'view' ? true : false);
//  const [mode, setMode ] = useState(modalType === 'contact' ? 'contact' : 'edit');
  // const [editing, setEditing] = useState( false);
  // const [dataEdit, setDataEdit] = useState(false);
  // const [data, setData] = useState( null);
  //const [buttonsType, ] = useState( buttonsType || 'Cancel' : 'OK'),
  const [no, ] = useState(noInitial);
  const [yes, setYes] = useState(yesInitial);
  const [changeMade, setChangeMade] = useState(false);
  const [changed, setChanged] = useState( {} );
  const [renderSaveCancel, setRenderSaveCancel] = useState( false );
//  const [changesMade, setChangesMade] = useState( false );
  const [newIndex, setNewIndex] = useState( -1 );
  const [company, setCompany] = useState(null);
  const [historyArray, setHistoryArray] = useState( [] );
  const [enableSync, setEnableSync] = useState( true );
  const [editMode, setEditMode] = useState( false );
  const [editIndex, setEditIndex] = useState( -1 );
  const [rowData, setRowData] = useState(null);
  const [contactId, setContactId] = useState(id);
  const [navTable, setNavTable] = useState([]);

  const [prevButtonEnabled, setPrevButtonEnabled] = useState(true);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);

  const [confirmationModal, showConfirmationModal] = useState(false);
//  const [navConfButtons, setNavConfButtons] = useState(false);

  const collection = origin === 'Active' ? 'master' : 'staged';

  const initialUrl = `/aiLead/api/${collection}/${id.toString()}`;
  const { fetchedData, isLoaded, fetchError, fetchErrorDetail, setFetchUrl, setReadTable, } = useFetch({initialUrl, setFetchTime, initialEnv: env, api});

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(()=>{
    if (contactId) {
      setFetchUrl(`/aiLead/api/${collection}/${contactId.toString()}`);
    }
  }, [contactId, collection, setFetchUrl])

  useEffect(()=>{
    if (fetchedData && fetchedData.data) {
      const data = {...fetchedData.data[0]};
      const ContactsArray = data.Contacts ? [...data.Contacts] : [];
      ContactsArray.forEach(item=>{
        item.key = nanoid();
      })
      data.Contacts = ContactsArray ? [...ContactsArray] : [];
      setRowData(data);
      setCompany(data.Name);
      setHistoryArray(data.History);
      const contactId = data._id.toString();
      const prevId = navTable[contactId][0];
      if (!prevId) setPrevButtonEnabled(false);
      else setPrevButtonEnabled(true);
      const nextId = navTable[contactId][1];
      if (!nextId) setNextButtonEnabled(false);    
      else setNextButtonEnabled(true);
    }
  }, [fetchedData, navTable]);

  useEffect(()=>{
    if (currentNavKey) {
      const tempTable = localStorage.getItem(currentNavKey);
      if (tempTable) {
        const navTableParsed = JSON.parse(tempTable)
        setNavTable(navTableParsed);
        const prevId = navTableParsed[contactId][0];
        if (!prevId) setPrevButtonEnabled(false);
        const nextId = navTableParsed[contactId][1];
        if (!nextId) setNextButtonEnabled(false);
      }
    }
  }, [currentNavKey, contactId]);

  //register the useNotification custom hook so we can set the notification msg when necessary
  const {setNotificationMsg} = useNotification(isFirstRender, notifyElem, 'tc');
  const setMessage = (msg) => {
    setNotificationMsg(msg);
  };

  selectFieldsFull['NAICS'] = {valueArray: naicsCodes};
  selectFieldsFull['NAICS'].valueArray = selectFieldsFull['NAICS'].valueArray.map(item=>{return {'value': item.value.toString(), 'display': + item.value + ' - ' + item.display}})

  // const selectFields = Object.keys(selectFieldsFull);

  const syncContact = async(rowData)=> {
    //call the method to sync this contact because it has been updated
    console.log('in syncContact')
    //console.dir(rowData);
    await api.syncAction(rowData._id.toString(), {}, {...rowData}, setMessage, rowData.Name, 'OnePageCRM', setReadTable, origin);
  }
  
    const updateCompany = async (id, doc, msg) => {
        console.log('in update company. Action modal ')
        setMessage(`Updating Database for ${company}`);
        //alert('about to update ' + JSON.stringify(doc, null, 2));
        await api.updateCompany(id, doc, rowData, msg, setMessage, setEditMode, setEditIndex, setNewIndex, setRowData, setFetchRecord, setRenderSaveCancel, syncContact, origin, setTableMessage);

        //updateCompany = async (id, doc, rowData, msg, setMessage, setEditMode, setEditIndex, setNewIndex, setRowData, setFetchRecord, setRenderSaveCancel, syncContact, origin="Active")        
        //await putCompany(doc, msg);
    } //end updateCompany()  

  // const renderDataEditor = (data) => {
  //   const renderWysiwgEditor = () => {return (
  //     <Input type="textarea" name="comment" id="comment" value={data} style={{backgroundColor:  'lightGray', borderColor: 'darkGray'}}
  //       onChange={(e)=>{
  //       setData(e.target.value);
  //       //this is where we need to PUT changes
  //     } } />
  //   )};
  //   return (
  //     <div style={{maxHeight:300, overflowX: 'auto'}} onClick={()=>{
  //       if (!editing) 
  //       {
  //           setDataEdit(!dataEdit);
  //           setEditing(true)
  //       }
  //     }}
  //       >
  //       {(dataEdit || editing) ? renderWysiwgEditor() : (data || 'Click to enter notes')}
  //     </div>
  //   )
  // }

  const deleteContact = (no) => {
    let contacts = [...rowData.Contacts];
    if (contacts && contacts.length >= no) {
      let contact = contacts[no];
      let msg = `${contact.First} ${contact.Last} Was Successfully Deleted`;
      contacts.splice(no,1);
      setUpdateObject({Contacts: [...contacts]});
      setRowDataChanged({Contacts: [...contacts]});
      setHistoryLine(msg);
      setMessage(msg);
      }
  }

  const setHistoryLine = (newHistory) => {
    setHistoryArray([...historyArray, newHistory]);
  }

  const addContact = ()=> {
    //alert('adding new contact');
    const newContact = {
      "CRMContactId": null,
      "First": "",
      "Last": "",
      "JobTitle": "",
      "Phone": "",
      "Cellphone": "",
      "Email": "",
      "LinkedInUrl": "",
      // "Address": null,
      // "City": null,
      // "State": null,
      // "Zipcode": null,
      // "Country": 'USA',
      "Notes": [],
      "CreateDate": new Date(),
      "UpdateDate": new Date()
    }
    if (rowData.Contacts) rowData.Contacts = [newContact, ...rowData.Contacts ];
    else rowData.Contacts = [newContact];
    
    setNewIndex(0);
  //  alert(JSON.stringify(rowData.Contacts, null, 2));
    setRowData({...rowData});
  }


  const setRowDataChanged = (changed) => {
    setRowData({...rowData, ...changed});
  }
    
  const toggle = (save) => {
    setModal(!modal);
  }

  // const toggleTooltipOpen = (save) => {
  //   if (save === 'save') setSaveTooltipOpen(!saveTooltipOpen);
  //   else setCancelTooltipOpen(!cancelTooltipOpen);
  // }

  // const renderSelectField = (field) => {
  //   const options = [];
  //   selectFieldsFull[field].valueArray.forEach(opt => {
  //     let selected = false;
  //     if (opt.value && opt.value.toString() === rowData[field].toString())
  //       selected = true;
  //     options.push(
  //      <option value={"'" + opt.value + "'"} selected={selected}>{opt.display}</option>
  //   )});
  //   return <Input type="select" id={field} name={field} className="form-control-sm" 
  //       onChange={e=>changeFieldValue(e, field)} 
  //       disabled={disabled}
  //     >
  //       {options}
  //     </Input>
  // }

  // const renderValue = (field, value) => {
  //   if (dateFields.includes(field)) {
  //     const inpDate = value;
  //     const date = new Date(inpDate);
  //     let hours = date.getHours();
  //     let minutes = date.getMinutes();
  //     const ampm = hours >= 12 ? 'pm' : 'am';
  //     hours = hours % 12;
  //     hours = hours ? hours : 12; // the hour '0' should be '12'
  //     minutes = minutes < 10 ? '0'+minutes : minutes;
  //     const strTime = hours + ':' + minutes + ampm;
  //     const formattedDate = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
  //     return formattedDate;
  //   } else return value;
  // };

  // const renderMoveToForm = (data) => {
  //   return <div>data goes here</div>
  // }

  const renderGenericContent = (data, content, mode) => {
    return (
      <GenericContent 
        data={data}
        content={content}
        mode={mode}
        setUpdateObject={setUpdateObject}
      />
    )
  }
  const renderHistoryTable = (data) =>{
    return (
    <ViewHistoryModal  
      data={data}
      mode={'table'}
//      setUpdateObject={setUpdateObject}
    />
    );
  }

  const renderNotesTable = (data) => {
    return (
    <NotesModal  
      data={data}
      mode={'table'}
      setUpdateObject={setUpdateObject}
    />
    );
  }

  const renderSyncForm = (data) => {
    return (
    <CRMSyncModalForm 
      data={data}
      mode={'form'}
      setUpdateObject={setUpdateObject}
    />
    );
  }

  // const renderContactEditorForm = (data) => {
  //   return (
  //     <Row>
  //       <Col sm="12">
  //           <Card>
  //           <CardBody style={{overflowX: 'auto', minWidth: 1000, height: 500}}>
  //             {Object.keys(data).filter(item=>!excluded.includes(item)).map((field, i) => (
  //             <FormGroup>
  //               <Row>
  //                 <Col md="3" className="w-100 text-right pr-1 h-100 align-middle">
  //                   <Label className="justify-content-end form-control-sm" for={field}>{field}</Label>
  //                   {crmFields.includes(field) 
  //                     ? <img className="pr-0" alt="Syncs with CRM" width={40} height={40} src={require("assets/img/opcLogo.png")} /> 
  //                   : null}
  //                 </Col>
  //                 <Col md="9" className="pl-1">
  //                   {selectFields.includes(field) 
  //                     ? renderSelectField(field)
  //                     : 
  //                     <Input id={field} name={field} type='text' size="input-sm" 
  //                       placeholder={'<Enter ' + field + '>'}
  //                       value={renderValue(field, rowData[field])} 
  //                       onChange={e=>changeFieldValue(e, field)}
  //                       disabled={disabled || readonly.includes(field)}
  //                     />
  //                   }
  //                 </Col>
  //               </Row>
  //               </FormGroup>
  //             ))}
  //           </CardBody>
  //       </Card>
  //       </Col>
  //     </Row>
  //   );
  // }

  const setUpdateObject = (obj) => {
    //this is called when one of the modal components has updated the data
    setChanged({...changed, ...obj});
    // setRenderSaveCancel(true)
    // setNo('Cancel')
    // setYes('Save');
  }

    const [navFunction, setNavFunction] = useState(null);

    const prevContact = (abandon=false)=> {
      if (!abandon && renderSaveCancel && changed) {
        setNavFunction("prev");
        showConfirmationModal(true);
      }  else {
        setPrevButton();
      }
    }

    const abandonChanges = () => {
      setRenderSaveCancel(false);
      setEditMode(false);
      setEditIndex(-1);
      setChanged({});
      setChangeMade(false);
      setYes("OK")
      showConfirmationModal(false);
  }
    const setPrevButton = ()=> {
      const prevId = navTable[contactId][0];
      if (prevId) {
        abandonChanges();
        setContactId(prevId);
      }
      else setPrevButtonEnabled(false);
    }

    const nextContact = (abandon=false)=> {
      //alert('next fired ' + abandon + ' ' + renderSaveCancel + ' ' + JSON.stringify(changed));
      if (!abandon && renderSaveCancel && changed) {
        setNavFunction("next");
        showConfirmationModal(true);
      }  else {
        setNextButton();
      }
    }
    
    const setNextButton = ()=> {
      const nextId = navTable[contactId][1];
      if (nextId) {
        abandonChanges();
        setContactId(nextId);
      }
      else setNextButtonEnabled(false);
    }

//     const showConfirmationModalSurrogate = (set) => {
//       //alert('showConfirmationModalSurrogate fired ' + set);
//       showConfirmationModal(false);
//       if (set === true) {
// //          showConfirmationModal(true);
//           // setPrevButtonEnabled(false);
//           // setNextButtonEnabled(false);
//       } else {
//         setNextButton();
//         setPrevButton();
//         // showConfirmationModal(false);
//       }
//   }    

  //render() {
    
    let contentToRender;
    if (modalType === 'history') {
      contentToRender = renderHistoryTable(rowData);
    } else if (modalType === 'notes') {
      contentToRender = renderNotesTable(rowData);
    } else if (modalType === 'contacts') {
        contentToRender = (    
          <ContactsModal 
            data={{...rowData}}
            setRowData={setRowData}
            setUpdateObject={setUpdateObject}
            changeMade={changeMade}
            setChangeMade={setChangeMade}
            newIndex={newIndex}
            setNewIndex={setNewIndex}
            deleteContact={deleteContact}
//            setHistoryLine={setHistoryLine}
            setEnableSync={setEnableSync}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            renderSaveCancel={renderSaveCancel}
            setRenderSaveCancel={setRenderSaveCancel} 
            setYes={setYes}
          />
        );
    } else if (modalType === 'moveToActive') {
      contentToRender = renderGenericContent(rowData, modalContent, 'Active');
    } else if (modalType === 'moveToImports') {
      contentToRender = renderGenericContent(rowData, modalContent, 'Imports');
    } else if (modalType === 'moveToDeleted') {
      contentToRender = renderGenericContent(rowData, modalContent, 'Deleted');
    } else if (modalType === 'sync') {
      contentToRender = renderSyncForm(rowData);
    } else if (modalType === 'remove') {
      contentToRender = modalContent;
    }    

    let subtitleArray = subtitle;
    if (subtitleArray && subtitleArray.search('\n')>0)
      subtitleArray = subtitleArray.split('\n');
    else
      subtitleArray = [subtitleArray];

    return (
    fetchError 
    ? <FetchErrorDisplay 
        fetchError={fetchError}
        fetchErrorDetail={fetchErrorDetail}
      /> 
    // : !isLoaded 
    // ? <span><Spinner color="primary" /> Loading...</span>
    : <Fragment>
      <Modal
        backdrop={"static"}
        isOpen={modal}
        toggle={toggle}
        className={`modal-lg mt-3 ${className ? className : ''}`}
        contentClassName="border-0"
        modalClassName="theme-modal"
        size="lg"
      >{isLoaded ?  
      <ActionModalHeader 
          rowData={rowData}
          setRowData={setRowData}
          toggle={toggle}
          naicsCodes={naicsCodes}
          modalType={modalType}
          titleIcon={titleIcon}
          iconColor={iconColor}
          title={title}
          setRenderSaveCancel={renderSaveCancel}
          setMessage={setMessage}
          setReadTable={setReadTable}
          changed={changed}
          setChanged={setChanged}
          changeMade={changeMade}
          setChangeMade={setChangeMade}
          addContact={addContact}
          setUpdateObject={setUpdateObject}
          enableSync={enableSync}
          setEnableSync={setEnableSync}
          editMode={editMode}
          setEditMode={setEditMode}
          nextContact={nextContact}
          prevContact={prevContact}
          updateCompany={updateCompany}
      /> 
      : <span><Spinner color="primary" /> Loading...</span>}
      <ModalBody className="p-0">
        <div className="p-1 pb-2 pt-0 mt-0">
          <ConfirmationModal 
                    key={"nextPrevConfirmation"}
                    confirmationModal={confirmationModal}
                    setRenderSaveCancel={setRenderSaveCancel} 
                    showConfirmationModal={showConfirmationModal} 
                    toggle={toggle}
                    navFunction={navFunction}
                    abandonChanges={abandonChanges}
                    next={nextContact}
                    prev={prevContact}
          />
          <Row>
            <Col sm="12">
                <Card>
                    <CardBody>{contentToRender}</CardBody>
                </Card>
            </Col>
          </Row>
          <Row>
          <Col sm="6" className="text-left">
                {isLoaded ?
                <ModalNavButtons
                    nextContact={nextContact} 
                    prevContact={prevContact} 
                    prevButtonEnabled={prevButtonEnabled} 
                    nextButtonEnabled={nextButtonEnabled}
                    setRenderSaveCancel={setRenderSaveCancel}
                    confirmationModal={confirmationModal}
                    showConfirmationModal={showConfirmationModal}
                    navFunction={navFunction}
                /> : <span><Spinner color="primary" /> Loading...</span>}
              </Col>
              <Col sm="6" className="text-right">
                {isLoaded ?
                <ModalButtons 
                    origin={origin}
                    modalType={modalType}
                    setMessage={setMessage}
                    no={no}
                    yes={yes}
                    toggle={toggle}
                    rowData={rowData}
                    setReadTable={setReadTable}
                    filters={filters}
                    changed={changed}
                    setRowData={setRowData}
                    renderSaveCancel={renderSaveCancel}
                    setRenderSaveCancel={setRenderSaveCancel} 
                    confirmationModal={confirmationModal}
                    showConfirmationModal={showConfirmationModal}
//                    historyArray={historyArray}
                    setEnableSync={setEnableSync}
                    // setEditMode={setEditMode}
                    // setEditIndex={setEditIndex}
                    // setNewIndex={setNewIndex}
                    // syncContact={syncContact}
                    updateCompany={updateCompany}
                    setYes={setYes}
                    setNavFunction={setNavFunction}
                    /> : <span><Spinner color="primary" /> Loading...</span>}
              </Col>
          </Row>

        </div>
      </ModalBody>
    </Modal>
    <NotificationAlert ref={notifyElem} zIndex={9999} />
    </Fragment>
  );  //end render()

} //end component

export default ActionModal;