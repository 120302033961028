import React, {useState, useEffect, useMemo} from 'react';
import {
  ModalHeader,
  Row, Input,
  Col, Card, Button
} from 'reactstrap';
import GenericDropDown from '../../components/dropdowns/GenericDropdown';

// import Background from './Background';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import is from 'is_js';
import {formatWebsite, toDateString, //getCRMStatusValueArray, getCountryValueArray, getStateValueArray, 
        getIconColorTable, warningColor, //primaryColor, secondaryColor, setHistory, formatObj
        successColor, } from '../../common/tableFormats';

// const selectFieldsFull = { 
//   'CRMStatus': {
//     valueArray: getCRMStatusValueArray()
//   },
//   'Country' : {
//     valueArray: getCountryValueArray()
//   },
//   'State' : {
//     valueArray: getStateValueArray()
//   }
// };


const ActionModalHeader = ({naicsCodes, rowData, setRowData, modalType, setMessage, setReadTable, titleIcon, iconColor, title, setUpdateObject,
                            addContact, enableSync, setEnableSync, editMode, setEditMode, toggle, updateCompany, changeMade, setChangeMade}) => {
                            //modalType, toggle, setRenderSaveCancel, changed, setChanged, 

    const iconColorTable = useMemo(()=>{ return {...getIconColorTable}}, []);
    const [changed, setChanged] = useState({});

    const [Name, setName] = useState(rowData.Name);
    const [Address, setAddress] = useState(rowData.Address || '');
    const [City, setCity] = useState(rowData.City);
    const [State, setState] = useState(rowData.State);
    const [Zipcode, setZipcode] = useState(rowData.Zipcode);
    const [Phone, setPhone] = useState(rowData.Phone);
    const [Website, setWebsite] = useState(rowData.Website);

    const WebText = rowData.WebsiteInfo ? rowData.WebsiteInfo.websiteText : null;

    const UpdateDate = rowData.UpdateDate;
    const bullet = '\u2022';

    const fieldChanged = (e, fieldName, fieldValue, setField) => {
      let newValue = e.target.value || null;
      if (!fieldValue || (newValue !== fieldValue.toString())) {
        setChangeMade(true);
        setField(newValue);
        let chg = {...changed};
        chg[fieldName] = newValue;
        setChanged({...chg})
        setUpdateObject({...chg});
      }
    }
  
  useEffect(()=>{
    if (rowData) {
      setName(rowData.Name);
      setAddress(rowData.Address || '');
      setCity(rowData.City);
      setState(rowData.State);
      setZipcode(rowData.Zipcode);
      setPhone(rowData.Phone);
      setWebsite(rowData.Website);
    }
  }, [rowData]);

  const shadowClass = 'shadow-lg';

  const editAction = (e => {
    e && e.stopPropagation();
    setEditMode(!editMode);
  })

  const editCard = <Card className={"justify-content-center m-1 p-1 mt-0 " + (editMode ? shadowClass : '')}
      onClick={(e)=>editAction(e, 'edit', true, 'pencil-alt', iconColorTable.edit.color)}
    >
    <div className="w-100 text-center p-1 mt-0">
    <FontAwesomeIcon 
      id={`edit`} 
      icon={'pencil-alt'} transform="grow-2" 
      className="pt-1 ml-2 mr-2" style={{color:editMode ? warningColor : iconColorTable.edit.color}} 
    />      
    </div>                    
    <div className="text-center">
      <small>{'Edit'}</small>
    </div>                    
    </Card>;

  const modifiedDateLine = (<React.Fragment>
      <p style={{
        whiteSpace: 'nowrap',
        fontSize: '.8rem',
      }}
      ><small>Modified: {toDateString(UpdateDate, 4)}</small></p>
    </React.Fragment>
  );


  return (
      <ModalHeader className="bg-light">
         <Row className="m-2 mb-0 pb=0 rounded-soft-top px-3 py-2 pb-0 pt-2 mb-0 mt-0">
          <Col sm="6" className="pl-2 pt-1 pb-0 mb-0 ml-0 mt-2 mr-0 d-inline justify-content-left text-left">
            <Row className="mt-0 pt-0"><Col>
              <h4 className="m-1 d-inline card-category text-left fs++2 icon-big">
                    <FontAwesomeIcon 
                      id="edit" icon={titleIcon} transform="grow-5" 
                      className="ml-1 mr-3" style={{color:iconColor}} 
                    />
                  {title}
              </h4>
            </Col></Row>
          </Col>
          <Col sm="2" className="text-center ml-0 mr-0 p-1 pt-2 ml-0 mr-0 pr-0 pl-0">
            {/* {rowData.ProspectStage ? ( */}
              <GenericDropDown 
                  origin={'Modal'}
                  mouseover={false}
                  mode={'CRMStatus'}
                  rowData={rowData}
                  setRowData={setRowData}
                  setReadTable={setReadTable}
                  setMessage={setMessage} 
                  setUpdateObject={setUpdateObject}
                  updateCompany={updateCompany}
                  setChangeMade={setChangeMade}
              />
            {/* ) : null } */}
          </Col>
          <Col sm="2" className="text-center ml-0 mr-0 p-1 pt-2 ml-0 mr-0 pr-0 pl-0">
            {rowData.Label ? (
              <GenericDropDown 
                  origin={'Modal'}
                  mouseover={false}
                  mode={'AIStatus'}
                  rowData={{...rowData}}
                  setRowData={setRowData}
                  setReadTable={setReadTable}
                  setMessage={setMessage} 
                  setUpdateObject={setUpdateObject}
                  updateCompany={updateCompany}
                  setChangeMade={setChangeMade}
              />
            ) : null }
          </Col>
          <Col sm="2" className="text-center ml-0 mr-0 p-1 pt-2 ml-0 mr-0 pr-0 pl-0">
            {rowData.ProspectStage ? (
              <GenericDropDown 
                  origin={'Modal'}
                  mouseover={false}
                  mode={'ProspectStage'}
                  rowData={{...rowData}}
                  setRowData={setRowData}
                  setReadTable={setReadTable}
                  setMessage={setMessage} 
                  setUpdateObject={setUpdateObject}
                  updateCompany={updateCompany}
                  setChangeMade={setChangeMade}
              /> 
            ) : null }
          </Col>
        </Row>
        <Row className="mb-0 pb-0">
          <Col sm="9" className="text-left mb-0 pb-0">
            <Card className="p-2">
            <Row><Col sm="12">

                {editMode === false  
                ? <React.Fragment>
                    <Row><Col sm="12">
                    <div><strong>{Name}</strong></div>
                    </Col></Row>
                    <Row><Col sm="9">
                    <div className="fs--1"
                        style={{
                            fontSize: '.9rem',
                        }}
                    >{Address}</div>
                    <div className="fs--1"
                        style={{
                            fontSize: '.9rem',
                        }}
                    >{City}{City ? ', ' : ' '} {State} {Zipcode}</div>
                    <div className="fs--2"
                        style={{
                        whiteSpace: 'nowrap',
                                      fontSize: '.9rem',
                                  }}
                    >{Website ? formatWebsite(rowData, Website, Name, 50, 'divWithLink') : ''} {Website && Phone ? ' ' + bullet + ' ' : ''} {Phone}</div>
                    <div>{modifiedDateLine}</div>
                    </Col>
                    <Col sm="3" className="pt-0 pb-0 mt-0 mb-0">
                      {editCard}
                    </Col>
                    </Row>
                </React.Fragment>

                : <React.Fragment>
                  <Row><Col sm="12">
                    <div><Input className="fs--1" type="text" placeholder="Enter Company Name" size="30" value={Name}
                          onChange={(e)=>fieldChanged(e, 'Name', Name, setName)}
                      />
                    </div>
                    </Col></Row>
                    <Row><Col sm="9">
                    <div><Input className="fs--1" type="text" placeholder="Enter Company Address" size="30" value={Address}
                          onChange={(e)=>fieldChanged(e, 'Address', Address, setAddress)}
                      />
                    </div>
                    <div className="d-inline">
                      <span>
                        <Input className="d-inline w-25 fs--1" type="text" placeholder="Enter City" value={City}
                            style={{maxWidth: 250, minWidth: 150}}
                            onChange={(e)=>fieldChanged(e, 'City', City, setCity)}
                        />{', '}
                        <Input className="d-inline w-25 fs--1" type="text" placeholder="ST" value={State}
                            onChange={(e)=>fieldChanged(e, 'State', State, setState)}
                        />{' '}
                        <Input className="d-inline fs--1 w-25" type="text" placeholder="Zipcode" value={Zipcode}
                            onChange={(e)=>fieldChanged(e, 'Zipcode', Zipcode, setZipcode)}
                        />
                      </span>
                    </div>        
                    <div><span className="d-inline">
                      <Input className="d-inline fs--1" type="text" placeholder="Enter Website" size="30" value={Website}
                          style={{maxWidth: 225, minWidth: 180}}
                          onChange={(e)=>fieldChanged(e, 'Website', Website, setWebsite)}
                      />{' ' + bullet + ' '}
                      <Input className="d-inline fs--1" type="text" placeholder="Enter Phone" size="30" value={Phone}
                          style={{maxWidth: 125, minWidth: 90}}
                          onChange={(e)=>fieldChanged(e, 'Phone', Phone, setPhone)}
                      />
                      </span>
                    </div>
                    <div>{modifiedDateLine}</div>
                    </Col>                    
                    <Col sm="3" className="pt-1">
                      {editCard}
                    </Col>    
                    </Row>
                  </React.Fragment>
                }
              </Col>
          
            </Row></Card>
          </Col>
          <Col sm="3" className="mt-0">
              <Row className="pb-0 mb-0 mt-0">
              <Col className="justify-content-center mt-0 mb-0">

              <GenericDropDown 
                  origin={'Modal'}
                  mouseover={false}
                  mode={'CRMSyncToggle'}
                  rowData={{...rowData}}
                  setRowData={setRowData}
                  setReadTable={setReadTable}
                  setMessage={setMessage} 
                  editMode={editMode}
                  enableSync={enableSync}
                  setUpdateObject={setUpdateObject}
                  updateCompany={updateCompany}
                  setChangeMade={setChangeMade}
              />

                </Col>
              </Row>   
              {modalType === 'contacts' ? 
              <Row className="">
                <Col className="w-100 justify-content-center mt-0">
                  <Card className="pt-2 pb-2"
                    onClick={(e=>addContact(e))}
                  >
                  <span>
                    <FontAwesomeIcon 
                        id={`add`} 
                        icon={'plus'} transform="grow-2" 
                        className="ml-2 mr-2" style={{color:successColor}} 
                      />      
                      <small>{'Add Contact'}</small>
                  </span>
                  </Card>
                </Col>
              </Row>
              : '' }
            </Col>
          </Row>
          <Row className="position-absolute t-0 r-0 z-index-1"><Col>
          <Button
              size="sm"
              className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
              onClick={() => toggle()}
          >
              <FontAwesomeIcon icon="times" transform="shrink-6 right-0.3 down-0.3" />
          </Button>
        </Col></Row>

        </ModalHeader>
    )
};  //end component

export default ActionModalHeader;
