import React, {useRef, useState, useEffect} from "react";
import {useAuth} from '../../auth/AuthContext';
import {useHistory} from 'react-router-dom';
import "../../assets/css/cursorPointer.css";

// reactstrap components
import {
  Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Col, Row, Alert} from "reactstrap";

const Login = ()=> {

  const {login, currentUser, resetPassword} = useAuth();

  const [error, setError] = useState('');
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const history = useHistory();
  const emailRef = useRef();
  const passwordRef = useRef();

  
  useEffect(()=> {
    document.body.classList.toggle("login-page");
    const toggleLoginOff = ()=> document.body.classList.toggle("login-page");
      return toggleLoginOff;
  }, []);

  const handleSubmit = async (e)=> {
    e.preventDefault();
    if (!email || !password) {
      return setError("Must enter email and password to log in");
    }
    try {
      setError('');
      setReset(false);  
      setLoading(true);
      await login(email, password);
      //alert('after login ' + JSON.stringify(currentUser));
    } catch(err) {
      setError(`Failed to Log In.  ${err.toString()}`)
    }
    setLoading(false);
    history.push('/admin/dashboard');
  }

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            {error && <Alert color={reset ? 'info' : "danger"}>{error}</Alert>}
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>

                <Form>
                <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        placeholder="Email" 
                        type="text" 
                        required
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        ref={emailRef} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        ref={passwordRef}
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        required
                      />
                    </InputGroup>
                      <div className="text-center text-primary hover-pointer" 
                        onClick={()=>{
                          if (email) {
                            resetPassword(email);
                            setReset(true);
                            setError(`Password reset email has been sent to ${email}`)
                          } else setError('Please enter your email')
                        }}
                      ><strong><u>reset my password</u></strong></div>
                    </CardBody>
                  <CardFooter>
                    <Button
                      block
                      disabled={loading}
                      type="submit"
                      className="btn-round mb-3"
                      color="warning"
                      href="#pablo"
                      onClick={handleSubmit}
                    >
                      Log In
                    </Button>
                  </CardFooter>
                  </Form>
                </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/austin.jpg")})`,
          }}
        />
        <div>{JSON.stringify(currentUser, null, 2)}</div>
      </div>
    );
  }

export default Login;

