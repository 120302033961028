import React, {useState} from "react";
import {
    Card, CardHeader,
    Button, 
    Row,
    Col,
  } from "reactstrap";
  import CompanyDetailTab from './CompanyDetailTab';

  const DetailTabBar = ({handleTabClick})=> {

    return (
        <div  className="sticky-top ml-0 mr-0 mt-1 pt-1 w-100">
            <Row id="top" key="tab_row">
                <Col lg="12">
                    <Row>
                <Col lg="2" md="3" sm="4" >
                    <CompanyDetailTab key="company_tab"
                    tabName="company"
                    text="Company"
                    handleTabClick={()=>handleTabClick("company")}
                    icon="bank"
                    color="primary"
                    />
                </Col>
                <Col lg="2" md="3" sm="4">
                    <CompanyDetailTab  key="contacts_tab"
                        tabName="contacts"
                        text="Contacts"
                        handleTabClick={()=>handleTabClick("contacts")}
                        icon="single-02"
                        color="primary"
                    />
                </Col>
                <Col lg="2" md="3" sm="4">
                    <CompanyDetailTab key="notes_tab"
                        tabName="notes"
                        text="Notes"
                        handleTabClick={()=>handleTabClick("notes")}
                        icon="paper"
                        color="primary"
                    />
                </Col>
                <Col lg="2" md="3" sm="4">
                    <CompanyDetailTab key="supplemental_tab"
                        tabName="supplemental"
                        text="Enrichment"
                        handleTabClick={()=>handleTabClick("supplemental")}
                        icon="alert-circle-i"
                        color="primary"
                    />
                </Col>
                <Col lg="2" md="3" sm="4">
                    <CompanyDetailTab key="history_tab"
                        tabName="history"
                        text="History"
                        handleTabClick={()=>handleTabClick("history")}
                        icon="watch-time"
                        color="primary"
                    />
                </Col>
                <Col lg="2" md="3" sm="4">
                    <CompanyDetailTab key="website_tab"
                        tabName="website"
                        text="Website"
                        handleTabClick={()=>handleTabClick("website")}
                        icon="world-2"
                        color="primary"
                    />
                </Col>            
                </Row>
                </Col>
            </Row>
        </div>
    );
}

export default DetailTabBar;