import React, { useEffect, useState, useRef } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useHistory } from "react-router-dom";

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import {AuthProvider, useAuth} from '../auth/AuthContext.js'
//import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";

import {CompanyProvider} from '../context/CompanyContext';

import routes from "../routes.js";

let ps;

const Admin = (props) => {

  const {currentUser} = useAuth();
  const history = useHistory();

  useEffect(()=>{
    //alert(currentUser && currentUser.email);
    if (!currentUser || !currentUser.email) history.push('/auth/login');
  },[currentUser]);

  const mainPanelRef = useRef();
  const initialState = {
    backgroundColor: "primary",
    activeColor: "warning",
    sidebarMini: false,
  };
  const [state, setState] = useState(initialState);

  useEffect(()=>{
    const unmount = ()=> {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    }
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
    }
    return unmount;
  },[]);

  useEffect(()=>{
    
    // if (history.action === "PUSH") {
    //   document.documentElement.scrollTop = 0;
    //   document.scrollingElement.scrollTop = 0;
    //   mainPanelRef.current.scrollTop = 0;
    // }
  })

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin" && currentUser) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            sensitive={false}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // handleActiveClick = (color) => {
  //   setState(prev=>({...prev, activeColor: color }));
  // };
  // handleBgClick = (color) => {
  //   setState(prev=>({...prev, backgroundColor: color }));
  // };
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setState(prev=>({...prev, sidebarMini: false }));
    } else {
      setState(prev=>({...prev, sidebarMini: true }));
    }
    document.body.classList.toggle("sidebar-mini");
  };

  //render section
  return (
      <div className="wrapper">
        <Sidebar
          {...props}
          routes={routes}
          bgColor={state.backgroundColor}
          activeColor={state.activeColor}
        />
        <div className="main-panel mt-0 pt-0" ref={mainPanelRef}>
          {/* <AuthProvider> */}
          {/* <CompanyProvider> */}
          <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
            <Switch>{getRoutes(routes)}</Switch>
            {
              // we don't want the Footer to be rendered on full screen maps page
              props.location.pathname.indexOf("full-screen-map") !== -1 
              ? null 
              : ( <Footer fluid /> )
            }
          {/* </CompanyProvider> */}
          {/* </AuthProvider> */}
        </div>
        {/* <FixedPlugin
          bgColor={state.backgroundColor}
          activeColor={state.activeColor}
          sidebarMini={state.sidebarMini}
          handleActiveClick={handleActiveClick}
          handleBgClick={handleBgClick}
          handleMiniClick={handleMiniClick}
        /> */}
      </div>
    );
  }

export default Admin;
