import React, {useEffect, useState, useContext} from 'react';
import { Card, CardHeader, Row, Col, Dropdown, DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useAuth} from '../../auth/AuthContext';
import {CompanyContext} from '../../context/CompanyContext';
import {dangerColor, successColor, warningColor, infoColor, primaryColor } from '../../common/tableFormats';
//import {patchCompany, syncFromDB} from '../../common/ApiFunctions';
import './dropdowns.css';

const CRMStatusDropDown = ({origin, currentCompany, mode, setIsOpen, isOpen, handleToggle, setChangeMade, setRowData, setMessage}) => {
    const {org, orgId, currentUser, setOrgAfterLogin, env} = useAuth();
    const { api,
        addChangeToPatch, setSingleField,
        setErrorMessage, setErrorLevel, patchArray
    } = useContext(CompanyContext);

    useEffect(async ()=>{
        if (!org && currentUser) {
            await setOrgAfterLogin(currentUser.uid);
        }
    },[org]);    

    const [currentCRMStatus, setCurrentCRMStatus] = useState(currentCompany.CRMStatus);
    const [company, setCompany] = useState(currentCompany.Name);
    const [companyId, setCompanyId] = useState(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);
    //const [selectedStatusObj, setSelectedStatusObj] = useState(getCRMStatusInfo(currentCompany.CRMStatus));

    useEffect(()=>{
        if (currentCompany) {
            setCurrentCRMStatus(currentCompany.CRMStatus);
            setCompany(currentCompany.Name);
            setCompanyId(currentCompany && currentCompany._id ? currentCompany._id.toString() : null);
            // setHistory(currentCompany.History);
        }
    }, [currentCompany]);

    // const dropdownStyle = dateColumn ? {minHeight:375, minWidth: 280} : {};
    // const justifyStyle = dateColumn ? "justify-content-left" : "justify-content-center";

    const crmStatusArray = org ? Object.keys(org.crmStatuses).filter(item=>!currentCRMStatus || item !== currentCRMStatus) : [];

    const getCRMStatusInfo = status => {
        //changing this to get CRMStatuses out of the org

        // if (!org || !'crmStatuses' in org) {
        //     const err = 'missing org.crmStatuses';
        //     console.error(err);
        // }

        if (org && status in org.crmStatuses) return {...org.crmStatuses[status], color: org.crmStatuses[status].color === 'success' ? successColor : primaryColor};

        return {
            color: dangerColor,
            badgeColor: 'danger',
            icon: 'question',
            text: status,
            popover: `Status ${status} is invalid`
        }
    }

    return (
        <UncontrolledDropdown className="d-inline">
            <DropdownToggle
                tag="span"
                key={`crmstatus_dd_${companyId}`}
                aria-haspopup={true}
                data-toggle="dropdown"
                id="CRMStatusDropdown"
                className={'p-0 m-0'}
                style={{valign: 'top', border: 1, 
                    //backgroundColor: 'transparent'
                }}
            >{origin === 'Modal' ? 
            <div className="p-2 pb-0 mb-0 card border" 
                style={{width: 138,  
                    backgroundColor: 'transparent', 
                    //zIndex: 10000, 
                    "&:hover": {backgroundColor: 'transparent'}}}
            >
                    <h6 className="ml-1 mr-1 mb-0 pb-0">
                        <div className="pb-2">CRM Status</div>
                        <FontAwesomeIcon 
                            id="crmstatus_icon" icon={getCRMStatusInfo(currentCompany.CRMStatus).icon} 
                            transform="grow-6"   //getStatusIcon(status)
                            style={{color:getCRMStatusInfo(currentCompany.CRMStatus).color}}
                        /> 
                        <div className="text-center mt-1 pt-2"
                            style={{
                                whiteSpace: 'nowrap',
                                fontSize: '.85rem',
                            }}
                        >
                            {getCRMStatusInfo(currentCompany.CRMStatus).text}                    
                        </div>
                    </h6> 
                </div>
                : //else this is from the detail line
                <span key={`crmstatus_div_${companyId}`} 
                    className={"hover-pointer text-left badge badge-pill m-0 p-1 rounded-capsule badge-" + getCRMStatusInfo(currentCompany.CRMStatus).badgeColor} 
                    data-html={true} data-toggle="popover" title={getCRMStatusInfo(currentCompany.CRMStatus).popover} data-content="content"
                    style={{opacity: 0.7, width: '85px'}}
                >
                    <FontAwesomeIcon key={`tableIcon_${companyId}`} icon={getCRMStatusInfo(currentCompany.CRMStatus).icon} transform="grow-2" 
                        className="ml-1 mr-1" 
                    />
                    {getCRMStatusInfo(currentCompany.CRMStatus).text}
                </span>
                }
            </DropdownToggle>
            <DropdownMenu 
                //persist - this causes the dropdown to not show up in FFF
                left={true}
                aria-labelledby="CRMStatusDropdown"
                className={`m-0 p-0 ${origin==='Modal' ? "mt-4" : ''}`}
                //className="mt-0"
                //tag="div" 
            >
            <DropdownItem className="m-0 p-0 pt-0 mt-0 pt-0 bg-white" tag="div" >
            <div className={"p-0 pt-3 mt-0 pb-0 mb-0 card"} 
                style={{ minWidth: 400, maxHeight: 150, 
                    // backgroundColor: 'white', 
                    zIndex: 10001, 
                    // "&:hover": {backgroundColor: 'white'}
                }}
                >
                <div className="d-inline card-header p-0 mx-2 px-2" titletag="h6" light="false">
                    <button type="button" className="close d-inline" aria-label="Close" 
                            //onClick={()=>{handleToggle(mode, false)}}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>{`Change CRM Status of ${currentCRMStatus} for: `}</div>
                    <div><strong>{`${company}`}</strong></div>
                </div>
                <div className="card-body p-0 mx-2 px-2 mb-0 pb-0">
                    <Row>
                    {crmStatusArray.map(status => (
                    <Col key={`Col_${status}_${companyId}`} className="justify-content-center"><span key={`span_${status}_${companyId}`} className="justify-content-center ml-2 mr-1">
                        <div key={`div_${status}_${companyId}`} className="w-100 text-center">
                            <FontAwesomeIcon 
                                key={`icon_${status}_${companyId}`}
                                id="edit" icon={getCRMStatusInfo(status).icon} transform="grow-4" 
                                className="ml-1 mr-1" style={{color:getCRMStatusInfo(status).color}}
                                onClick={async e=>{
                                    if (origin === 'Modal') { 
                                        setChangeMade(true);
                                        setSingleField('CRMStatus', status);
                                        if (status === "Prospect") setSingleField('ProspectStage', "0-No Contact");
                                        setSingleField('Label', getCRMStatusInfo(status).Label);
                                    }
                                    else { 
                                        const msg = `${company} Has Been Updated to CRM Status: ${status}`;
                                        let patch = addChangeToPatch('CRMStatus', status);
                                        patch = [...patch, ...addChangeToPatch('Label', getCRMStatusInfo(status).Label)];
                                        if (status === "Prospect") patch = [...patch, ...addChangeToPatch('ProspectStage', "0-No Contact")];
                                        await api.patchCompany(companyId, patch);
                                        setRowData('CRMStatus', status);
                                        setRowData('Label', getCRMStatusInfo(status).Label);
                                        if (status === "Prospect") setRowData('ProspectStage', "0-No Contact");
                                        setMessage(msg);
                                        //now we need to sync this change if syncCRM is turned on
                                        if (currentCompany.CRMSync && currentCompany.CRMSync.syncToAILead === "Yes") {
                                            setMessage(`Syncing ${currentCompany.Name} to ${'OnePageCRM'}...`);
                                            if (org.crmActive) await api.syncFromDB(companyId, currentCompany, 'OnePageCRM');
                                            setMessage(`Successfully Synced ${currentCompany.Name} to ${'OnePageCRM'} in ${origin}`);
                                        }
                                    }
                                    // e.stopPropagation();
                                    // handleToggle(mode, false);  //now close the dropdown after we are done
                                }}
                            />
                        </div>
                        <div key={`div2_${status}_${companyId}`} className="text-center">
                            <small>{getCRMStatusInfo(status).text}</small>
                        </div>
                    </span></Col>)
                )}
                    </Row>
                </div>
            </div>
            </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default CRMStatusDropDown;