import React, {Fragment, useState, useContext, useEffect} from 'react';
// import {Table} from 'reactstrap';
// import is from 'is_js';

import {CompanyContext} from '../../context/CompanyContext';
// import tableFormats from '../../common/tableFormats';

const SupplementalForm = () => {
  const {
    currentCompany, 
  } = useContext(CompanyContext);

  const [openArray, setOpenArray] = useState({});
  //first we must see if there is a CRMSync object in the data.  If so, get values.
  const open = {};

  useEffect(()=>{
    if (currentCompany.Supplemental && currentCompany.Supplemental.length) {
        currentCompany.Supplemental.forEach(item=>{
            const key = Object.keys(item)[0];
            open[key] = false;
        });
        setOpenArray({...open});
      }
  },[currentCompany])

  const handleOnclick = (key)=>setOpenArray(prev=>({...prev, [key]: !prev[key]}));

                    //   {/* <div style={{ //whiteSpace: 'nowrap',
                    //     fontSize: '.8rem',}}
                    //     className={"" + liClass}
                    // >{formatOutput(item[key])}
                    //     {formatOutput(item[key], null, 2)}</div> */}

  if (!currentCompany.Supplemental || !Object.keys(currentCompany.Supplemental).length)
    return <div className="text-center">No Supplemental Info Available
                {/* <pre>{JSON.stringify(openArray, null, 2)}</pre> */}
    </div>
    else { 
        return (
        <div style={{height:400, overflowY: 'scroll'}}>
            <div>
            {Object.keys(currentCompany.Supplemental).map(key=>{ 
                const liClass = openArray[key] === true ? "collapse.show" : "collapse";
                return (
                <Fragment key={`frag_${key}`}>
                    <div key={`div_${key}`}
                    className="btn btn-success btn-lg"
                    onClick={()=>handleOnclick(key)}
                ><strong key={`strong_${key}`}>{key}</strong></div><br key={`br_${key}`}></br>
                    <pre style={{ //whiteSpace: 'nowrap',
                        fontSize: '.8rem',}}
                        className={"" + liClass}
                        key={`pre_${key}`}
                    >{JSON.stringify(currentCompany.Supplemental[key], null, 2)}</pre>
                </Fragment>)     
            })}
            </div>
        </div>
        )};

}
                        
export default SupplementalForm;


