import React, {useContext} from 'react';
import {Label, Input, Row, Col} from 'reactstrap';

import {CompanyContext} from '../../context/CompanyContext';
import {toDateString} from '../../common/tableFormats';

const WebsitePane = () => {
  const {
    currentCompany, 
  } = useContext(CompanyContext);

  //const [webInfo, setWebInfo] = useState({...currentCompany.WebsiteInfo});
  //const [openArray, setOpenArray] = useState({});
  //first we must see if there is a CRMSync object in the data.  If so, get values.

//   useEffect(()=>{
//     if (currentCompany.WebsiteInfo) {
//         //setWebInfo({...currentCompany.WebsiteInfo})
//         alert(JSON.stringify(currentCompany.WebsiteInfo.textArray))
//     }
//   },[currentCompany])

  //const handleOnclick = (key)=>setOpenArray(prev=>({...prev, [key]: !prev[key]}));

  // overflowY: 'scroll', overflowX: 'hidden'

  if (!currentCompany.WebsiteInfo)
    return <div className="text-center">No Website Info Available
                {/* <pre>{JSON.stringify(openArray, null, 2)}</pre> */}
    </div>
    else { 
        return (
        <div style={{height:400}}>
            <Row><Col lg="12" className="m-0 p-0">
                <Row>
                <Col lg="5" className="m-0 p-2">
                    <Row className="mt-0 pt-0 mb-1">
                        <Col lg="1" className="text-left px-1">
                            &nbsp;
                        </Col>
                        <Col lg="11" className="text-left ml-0 pl-4">
                            <Label><strong>Domain:</strong></Label>
                            <div style={{fontSize: '.8rem',}}>
                                <Input className="pl-1" type="text" disabled placeholder="<no domain>" size="15" value={(currentCompany && currentCompany.WebsiteInfo) ? currentCompany.WebsiteInfo.domain : ''}
                                    key={`${currentCompany._id}_domain`}
    //                                    onChange={(e)=>fieldChanged(e, i, 'Cellphone', Cellphone, setCellphone)}

                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-0 pt-0 mb-1">
                        <Col lg="1" className="text-left px-1">&nbsp;</Col>
                        <Col lg="11" className="text-left ml-0 pl-4">
                            <Label><strong>Words Found:</strong></Label>
                            <div style={{fontSize: '.8rem',}}>
                                <Input className="pl-1" type="text" disabled placeholder="<no words>" size="15" value={(currentCompany && currentCompany.WebsiteInfo) ? currentCompany.WebsiteInfo.numberWords : ''}
                                    key={`${currentCompany._id}_numberword`}
    //                                    onChange={(e)=>fieldChanged(e, i, 'Cellphone', Cellphone, setCellphone)}

                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-0 pt-0 mb-1">
                        <Col lg="1" className="text-left px-1">&nbsp;</Col>
                        <Col lg="11" className="text-left ml-0 pl-4">
                            <Label><strong>Run Time (Secs):</strong></Label>
                            <div style={{fontSize: '.8rem',}}>
                                <Input className="pl-1" type="text" disabled placeholder="<no runtime>" size="15" value={(currentCompany && currentCompany.WebsiteInfo) ? currentCompany.WebsiteInfo.runtime : ''}
                                    key={`${currentCompany._id}_runtime`}
    //                                    onChange={(e)=>fieldChanged(e, i, 'Cellphone', Cellphone, setCellphone)}

                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-0 pt-0 mb-1">
                        <Col lg="1" className="text-left px-1">&nbsp;</Col>
                        <Col lg="11" className="text-left ml-0 pl-4">
                            <Label><strong>Previous Domain:</strong></Label>
                            <div style={{fontSize: '.8rem',}}>
                                <Input className="pl-1" type="text" disabled placeholder="<no prev domain>" size="15" value={(currentCompany && currentCompany.WebsiteInfo) ? currentCompany.WebsiteInfo.prevDomain : ''}
                                    key={`${currentCompany._id}_prevdomain`}
    //                                    onChange={(e)=>fieldChanged(e, i, 'Cellphone', Cellphone, setCellphone)}

                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-0 pt-0 mb-1">
                        <Col lg="1" className="text-left px-1">&nbsp;</Col>
                        <Col lg="11" className="text-left ml-0 pl-4">
                            <Label><strong>No Attempts:</strong></Label>
                            <div style={{fontSize: '.8rem',}}>
                                <Input className="pl-1" type="text" disabled placeholder="<no attempts>" size="15" value={(currentCompany && currentCompany.WebsiteInfo && currentCompany.WebsiteInfo.scrapeAttempts) ? currentCompany.WebsiteInfo.scrapeAttempts : '0'}
                                    key={`${currentCompany._id}_noattempts`}
    //                                    onChange={(e)=>fieldChanged(e, i, 'Cellphone', Cellphone, setCellphone)}

                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-0 pt-0 mb-1">
                        <Col lg="1" className="text-left px-1">&nbsp;</Col>
                        <Col lg="11" className="text-left ml-0 pl-4">
                            <Label><strong>Scraped On:</strong></Label>
                            <div style={{fontSize: '.8rem',}}>
                                <Input className="pl-1" type="text" disabled placeholder="<no date>" size="15" value={(currentCompany && currentCompany.WebsiteInfo && currentCompany.WebsiteInfo.updated) ? toDateString(currentCompany.WebsiteInfo.updated, 4) : ''}
                                    key={`${currentCompany._id}_updated`}
    //                                    onChange={(e)=>fieldChanged(e, i, 'Cellphone', Cellphone, setCellphone)}

                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg="7" className="m-0 p-0 pt-2">
                    <Row className="mt-0 pt-0 mb-1">
                        <Col className="text-left ml-2 pl-2">
                            <Label><strong>Website Text:</strong></Label>
                            <div style={{fontSize: '.8rem',}}>
                                <textarea className="pl-1 pt-0" type="textarea" placeholder="" size="80" disabled value={(currentCompany && currentCompany.WebsiteInfo) ? currentCompany.WebsiteInfo.websiteText : ''}
                                    rows="8" cols="28" 
                                    key={`${currentCompany._id}_webinfo_website_text`}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-0 pt-0 mb-1">
                        <Col className="text-left ml-2 pl-2">
                            <Label><strong>Website Words:</strong></Label>
                            <ul style={{fontSize: '.8rem',width:'224px',height:'175px', overflowY: 'scroll', overflowX: 'hidden'}}>
                                {(currentCompany && currentCompany.WebsiteInfo && currentCompany.WebsiteInfo.textArray && currentCompany.WebsiteInfo.textArray.map((word, i)=>{
                                    return (
                                        // style={{height:'10px'}}
                                        <li key={`${i}_${word}`} className="text-left">{word}</li>
                                    )
                                }) //end map()
                                )} 
                            </ul>
                        </Col>
                    </Row>
                </Col>
                </Row>
                </Col>
            </Row>
            {/* <pre>{JSON.stringify(webInfo, null, 2)}</pre> */}
        </div>
        )};

}

export default WebsitePane;