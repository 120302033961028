import React, { useContext, useState, useEffect } from 'react';
//import {CompanyContext} from '../context/CompanyContext';
import {useAuth} from '../auth/AuthContext';
import axios from 'axios';
import {timeDifference, activeOrigin} from '../common/tableFormats';     //importsOrigin

const APIContext = React.createContext();

export function useAPI() {
    return useContext(APIContext);
}

export function APIProvider({children}) {
    const { //org, currentUser, 
      env, orgId, uid,
      } = useAuth();

    // const [orgId, setOrgId] = useState(orgId);
    // const [uid, setUid] = useState(null);
    // const [env, setEnv] = useState(null);

    // useEffect(()=>{
    //     const apiSetup = (org, user, environ) =>{
    //         setOrgId(org);
    //         setUid(user);
    //         setEnv(environ);
    //     }
    //     if (org && currentUser && org.env ) {
    //         apiSetup(org._id.toString(), currentUser.uid, org.env);
    //     }
    // },[org, currentUser, env]);

    const getOrgId = () => orgId;
    
    const axiosGet = async (url)=> {
          try {
              const headers = orgId ?
              {
                'Accept': 'application/json',
                'X-orgId': orgId,
                'X-uid': uid
              } : {
                'Accept': 'application/json',
              }
    
              if (url.substr(0,4) !== 'http') {
                url = `${getBaseUrl(env)}${url}`;
              }
              //alert(JSON.stringify([env, url, headers], null, 2));
              return await axios.get(url, {headers,
              validateStatus: function (status) {
                  return status >= 200 && status < 300; // default
              } 
          });
        } catch(err) {
          console.error(err);
          return {error: err.toString()}
        }
      }
    
    const globalSearch = async(search) => {
      try {
        const itemUrl = `/aiLead/api/search/${encodeURI(search)}`;
        const response = await axiosGet(itemUrl);
        const retData = response.data;
        return {...retData.results};
      } catch(err) {
        return {error: 'Could not fetch ' + search};
      }
    }

    const axiosPut = async (fetchUrl, putDoc)=> {
        try {
          const headers = orgId ?
          {
            'X-orgId': orgId,
            'X-uid': uid
          } : {}
          if (fetchUrl.substr(0,4) !== 'http') {
              fetchUrl = `${getBaseUrl(env)}${fetchUrl}`;
          }
          const res = await axios.put(fetchUrl, putDoc, {
              'Content-Type': 'application/json',
              headers
            });
            console.log('in axiosPut ' + JSON.stringify(putDoc));
            return res;
          } catch (err) {
            console.log('in axiosPut error' );
            console.error(JSON.stringify(err, null, 2));
            return false;
          }
      }
    
    const axiosPost = async (fetchUrl, putDoc)=> {
          try {
            if (fetchUrl.substr(0,4) !== 'http') {
              fetchUrl = `${getBaseUrl(env)}${fetchUrl}`;
            }
            const res = await axios({
              method: 'post', //you can set what request you want to be
              url: fetchUrl,
              data: putDoc,
              headers: {
                'X-orgId': orgId,
                'X-uid': uid
              }
            })
      //    console.log('in axiosPost ' + JSON.stringify(putDoc));
            return res;
        } catch (err) {
          console.log('in axiosPost error' );
          console.error(JSON.stringify(err, null, 2));
          return false;
        }
      }
    
    const insertCompany = async(doc) => {
        const baseUrl = getBaseUrl(env);
        const insertUrl = `${baseUrl}/aiLead/api/staged`;
        const res = await axiosPost(insertUrl, doc);
        return res;
      }
    
    const patchCompany = async (id, patchArray) => {
        // const rowVersionOp = {
        //   "op": "test",
        //   "path": "/rowVersion",
        //   "value": rowVersion
        // };  
        const patchObject = [...patchArray];
        const baseUrl = getBaseUrl(env);
        const patchUrl = `${baseUrl}/aiLead/api/master/${id}/`;
        const config = {
          'Content-Type': 'application/json-patch+json',  
          headers: {
            'X-orgId': orgId,
            'X-uid': uid
          }
        }
        try {
          const res = await axios.patch(patchUrl, patchObject, {...config});
          return res;
        } catch (err) { 
          return {error: err}
        }
      }
    
    const getPostalCodeRecord = async (postal) => {
      try {
        const itemUrl = `/aiLead/api/postal/${encodeURI(postal)}`;
        const response = await axiosGet(itemUrl);
        const retData = response.data;
        return {...retData.results};
      } catch(err) {
        return {error: 'Could not fetch ' + postal};
      }
    }
    
    const getDescription = async (id) => {
      try {
        const itemUrl = `/aiLead/api/master/${id}/description`;
        const response = await axiosGet(itemUrl);
        const retData = response.data;
        return {...retData.results};
      } catch(err) {
        return {error: 'Could not fetch ' + id};
      }
    }
    
    const getWebtext = async (id) => {
      try {
        const itemUrl = `/aiLead/api/master/${id}/webtext`;
        const response = await axiosGet(itemUrl);
        const retData = response.data;
        return {...retData.results};
      } catch(err) {
        return {error: 'Could not fetch ' + id};
      }
    }
    
    const getImportData = async (importId, page=1, limit=100) => {
          try {
            //const itemUrl = `/aiLead/api/importdata/${importId}?page=${page}&limit=${limit}`;
            const itemUrl = `/aiLead/api/importdata/${importId}?page=${page}&limit=${limit}`;
            const response = await axiosGet(itemUrl);
            return response;
          } catch(err) {
            return {error: 'Could not fetch page ' + page + ': ' + err.toString()};
          }
        }
    
    const confirmImport = (importId, orgId) => {
          try {
            const itemUrl = `/aiLead/api/confirmimport/${importId}`;
            const response = axiosGet(itemUrl);
            return true;
          } catch(err) {
            console.error(err);
            return {error: err.toString()};
          }
        }
    
        //
    const cancelImport = async (importId) => {
          try {
            //const itemUrl = `/aiLead/api/cancelImport/${importId}`;
            const itemUrl = `/aiLead/api/cancelImport/${importId}`;
            const response = await axiosGet(itemUrl);
            return response;
          } catch(err) {
            console.error(err);
            return {error: err.toString()};
          }
        }
    
    
    const getLeadSources = async (lsKey) =>{
          try {
            const itemUrl = "/aiLead/api/leadsources/table";
            const response = await axiosGet(itemUrl);
            const retData = response.data;
            localStorage.setItem(lsKey, JSON.stringify(retData.results));
            return {...retData.results};
          } catch(err) {
            return {error: 'Could not fetch ' + lsKey};
          }
        }
    
    const getStates = async (lsKey) => {
          try {
            const itemUrl = "/aiLead/api/states/table";
            const response = await axiosGet(itemUrl);
            const retData = response.data;
            localStorage.setItem(lsKey, JSON.stringify(retData.results));
            return {...retData.results};
          } catch(err) {
            return {error: 'Could not fetch ' + lsKey};
          }
        }
    
        const getCountryCodes = async (lsKey) => {
          try {
            const itemUrl = "/aiLead/api/countries/table";
            const response = await axiosGet(itemUrl);
            const retData = response.data;
            localStorage.setItem(lsKey, JSON.stringify(retData.results));
            return {...retData.results};
          } catch(err) {
            return {error: 'Could not fetch ' + lsKey};
          }
        }
    
        const getNaicsCodes = async (lsKey) => {
          try {
            const itemUrl = "/aiLead/api/naics/table";
            const response = await axiosGet(itemUrl);
            const retData = response.data;
            localStorage.setItem(lsKey, JSON.stringify(retData.results));
            return {...retData.results};
          } catch(err) {
            return {error: 'Could not fetch ' + lsKey};
          }
        }
    
        const putRecord = async (fetchUrl, changes, successMessage, setMessage, setRowData) => {
            const putDoc = {...changes}
            console.log(JSON.stringify(putDoc));
    
            try {
                const res = await axiosPut(fetchUrl, putDoc);
                // console.log('after call');
                // console.dir(res);
                if (res && res.data && res.data.succeeded) {
                  setMessage(successMessage)
                  setRowData({...res.data.data});
                  return res;
                }
            } catch (error) {
              setMessage(error.message)        
              console.error('Error:', error);
              return null;
            }
            return {}
        }
    
        //this is only used by the Tables for now
        //???  need to combine this into one function for both Tables & Modal
    
        const updateCompany = async (id, doc, rowData, msg, setMessage, setEditMode, setEditIndex, setNewIndex, setRowData, setFetchRecord, setRenderSaveCancel, 
          syncContact, origin="Active", setTableMessage) => {
          console.log('in generic update company. ');
          setMessage(`Updating Database for ${rowData.Name}`);  
          let result  = await patchCompany(id, doc);
    
          if (result.modifiedCount === 1) setMessage(`${rowData.Name} has been successfully updated`);
        }
    
        const syncFromDB = async (id) => {
          const url = `${getBaseUrl()}/aiLead/api/crmSyncFromDB/${id}`;
          return await axiosGet(url);
        }
    
        const syncAction = async (id, putDoc, rowData, setMessage, company, crmName, setReadTable, origin='Active') => {
          //this is where we call the REST API in order to do first a one-way sync out
          //  then after that is working, a two-way sync back
        
          const syncDoc = {...rowData, ...putDoc};
          const baseUrl = getBaseUrl();
          const url = `${baseUrl}/aiLead/api/crmSync/${id}`;
          setMessage(`Syncing ${company} to ${crmName}...`);
          //const ret = 
          const res = await axiosPut(url, syncDoc);
          if (!res.error) {
            //setRowData({...changes});
            setMessage(`Successfully Synced ${company} to ${crmName} in ${origin}`);
            setReadTable();  //toggle the switch so that the table read will happen again
            return true;
          }
          setMessage(res.error.toString());
          return false;
        };

        const removeCompanyFromColl = async (origin, id, setErrorMessage, setErrorLevel) => {
          const result = await removeCompany(origin.toLowerCase(), id);
          if (result.data.message !== "ok") {
            setErrorMessage('Successfully removed!')
            setErrorLevel('dark')
          } else setErrorMessage('Move was successful');
      
        } //end updateCompany()  
    
        const moveToActiveImports = async (origin, id, setErrorMessage, setErrorLevel) => {
          const table = origin.toLowerCase();
          const result = await moveCompany(table.toLowerCase(), id);
          if (result.data.message !== "ok") {
            setErrorMessage('Successfully moved!')
            setErrorLevel('dark')
          } else setErrorMessage('Move was successful');
          return true;
        } //end updateCompany()  

        const moveAllToActive = async (origin, navTable, setErrorMessage, setErrorLevel) => {
          const table = origin.toLowerCase();

          //const idsToMove = navTable;
          //let's fetch all the ids that need to move
          const result = await moveAll(table.toLowerCase()); //, {ids: [...idsToMove]});
          if (result.data.message !== "ok") {
            setErrorMessage(result.data.error);
            setErrorLevel('danger');
          } else {
            setErrorMessage('Move was successful');
            setErrorLevel('dark');
          }
          return true;
        } //end updateCompany()  

        const moveAll = async (coll, ids) => {
          const url = `${getBaseUrl()}/aiLead/api/moveall/${coll}`;
          return await axiosGet(url);
        }
    
        const moveCompany = async (coll, id) => {
          const url = `${getBaseUrl()}/aiLead/api/move/${coll}/${id}`;
          return await axiosGet(url);
        }
    
        const removeCompany = async (coll, id) => {
          const url = `${getBaseUrl()}/aiLead/api/remove/${coll}/${id}`;
          return await axiosGet(url);
        }
    
        const getBaseUrl = ()=> {
            let baseUrl = 'https://api.pureblue.ai';
            if (env === 'local') baseUrl = 'http://localhost:5001';
            return baseUrl;
        } //end getBaseUrl(env)

        const value = {
            
            api: {
                getOrgId,
                axiosGet,
                axiosPost,
                axiosPut,
                getStates,
                getCountryCodes,
                getNaicsCodes,
                getLeadSources,
                getImportData,
                getPostalCodeRecord,
                getDescription,
                getWebtext,
                confirmImport,
                cancelImport,
                insertCompany,
                patchCompany,
                putRecord,
                syncFromDB,
                updateCompany,
                moveToActiveImports,
                moveAllToActive,
                removeCompanyFromColl,
                globalSearch,
                syncAction,
                getBaseUrl,

            }
        }
        return (
            <APIContext.Provider value={value}>
                {children}
            </APIContext.Provider>
        )
    }        
