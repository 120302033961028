import { library } from '@fortawesome/fontawesome-svg-core';

// Agriculture - tractor
// Mining - gas-pump
// Utilities - bolt
// Construction - hard hat
// Manufacturing - industry
// Wholesale - warehouse
// Retail - shopping-cart
// Transportation - truck-moving
// Information - database
// Finance - landmark
// Real Estate - sign
// Scientific/Tech - vial
// Management - user-tie
// Waste Mgmt - dumpster
// Education - university
// Healthcare - band aid
// Arts - palette
// Accommodation/Food - concierge-bell
// Public Service - gavel

import {
  faStoreAltSlash,
  faHistory,
  faCalendarDay,
  faClipboard,
  faClipboardList,
  faTractor,
  faSyncAlt,
  faAtom,
  faFaucet,
  faGasPump,
  faHardHat,
  faIndustry,
  faWarehouse,
  faExpand,
  faCompress,
  faTruckMoving,
  faDatabase,
  faLandmark,
  faSign,
  faUserTie,
  faUserCheck,
  faUserTimes,
  faDumpster,
  faGraduationCap,
  faBandAid,
  faFilm,
  faConciergeBell,
  faBusAlt,
  faGavel,
  faTruckLoading,
  faPlaneDeparture,
  faPlane,
  faUniversity,
  faSearchDollar,
  faQuestion,
  faUndoAlt,
  faLayerGroup,
  faGlasses,
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faAddressBook,
  faFileUpload,
  faFolderOpen,
  faCloudUploadAlt,
  faHammer,
  faWrench,
  faMoneyCheck,
  faRunning,
  faImages,
  faCreditCard,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faAngleLeft,
  faAngleRight,
  faEllipsisH,
  faSink,
  faBars,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortUp, 
  faSortDown, 
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faLockOpen,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faUserSlash,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArrowLeft,
  faArrowRight,
  faArchive,
  faEnvelope,
  faClock,
  faReply,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faRedo,
  faSlidersH,
  faTrashAlt,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faFileImport,
  faEye,
  faCaretUp,
  faCaretDown,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faUsers,
  faGlobeAmericas,
  faPlusSquare,
  faUserPlus,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faThumbsDown,
  faColumns,
  faComment,
  faComments,
  faCommentsDollar,
  faPhone,
  faPhoneSlash,
  faPhoneAlt,
  faInfo,
  faInfoCircle,
  faVideo,
  faPalette,
  faEdit,
  faShare,
  faSquare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faPercentage,
  faIcons,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faCommentAlt,
  faAt,
  faGreaterThan,
  faBinoculars,
  faGrinTears
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebook,
  faFacebookSquare,
  faGulp,
  faNodeJs,
  faSass,
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,
  faYoutube,
  faTrello
} from '@fortawesome/free-brands-svg-icons';

import {
  faHeart as farHeart,
  faLightbulb as farLightbulb,
  faObjectUngroup as farObjectUngroup,
  faPaperPlane as farPaperPlane,
  faStar as farStar,
  faImage as farImage,
  faFileArchive as farFileArchive,
  faFilePdf as farFilePdf,
  faFile as farFile,
  faFileVideo as farFileVideo,
  faFileAudio as farFileAudio,
  faFileImage as farFileImage,
  faFileCode as farFileCode,
  faClock as farClock,
  faQuestionCircle as farQuestionCircle,
  faDizzy as farDizzy,
  faLaughBeam as farLaughBeam,
  faSmileBeam as farSmileBeam,
  faCircle as farCircle,
  faCopy as farCopy,
  faComment as farComment
} from '@fortawesome/free-regular-svg-icons';

library.add(
  // Solid
  faStoreAltSlash,
  faHistory,
  faCalendarDay,
  faClipboard,
  faClipboardList,
  faSyncAlt,
  faTractor,
  faAtom,
  faFaucet,
  faGasPump,
  faHardHat,
  faIndustry,
  faWarehouse,
  faExpand,
  faCompress,
  faTruckMoving,
  faDatabase,
  faLandmark,
  faSign,
  faUserTie,
  faUserCheck,
  faUserTimes,
  faDumpster,
  faGraduationCap,
  faBandAid,
  faFilm,
  faConciergeBell,
  faBusAlt,
  faGavel,
  //added these above to support Industries

  faGreaterThan,
  faSearchDollar,
  faQuestion,
  faExclamationTriangle,
  faGrinTears,
  faBinoculars,
  faLayerGroup,
  faTruckLoading,
  faPlaneDeparture,
  faPlane,
  faUniversity,
  faUndoAlt,  
  faGlasses,
  faAddressBook,
  faFileUpload,
  faFolderOpen,
  faCloudUploadAlt,
  faHammer,
  faWrench,
  faMoneyCheck,
  faRunning,
  faImages,
  faCreditCard,
  faFileImport,
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faAngleRight,
  faAngleLeft,
  faEllipsisH,
  faSink,
  faBars,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortUp, 
  faSortDown, 
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faLockOpen,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,

  faUser,
  faUserSlash,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArchive,
  faEnvelope,
  faClock,
  faStar,
  faReply,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faRedo,
  faSlidersH,
  faTrashAlt,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faEye,
  faCaretUp,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faUsers,
  faGlobeAmericas,
  faPlusSquare,
  faCaretDown,
  faUserPlus,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faThumbsDown,
  faColumns,
  faComment,
  faComments,
  faCommentsDollar,
  faPalette,
  faEdit,
  faTrashAlt,
  faShare,
  faSquare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faPercentage,
  faIcons,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faCommentAlt,
  faAt,

  // Brand
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGulp,
  faNodeJs,
  faSass,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,
  faYoutube,
  faVideo,
  faInfo,
  faInfoCircle,
  faPhone,
  faPhoneSlash,
  faPhoneAlt,
    faTrello,

  // Regular
  farHeart,
  farLightbulb,
  farObjectUngroup,
  farPaperPlane,
  farStar,
  farImage,
  farFileArchive,
  farFilePdf,
  farFile,
  farFileVideo,
  farFileAudio,
  farFileImage,
  farFileCode,
  farClock,
  farQuestionCircle,
  farDizzy,
  farLaughBeam,
  farSmileBeam,
  farCircle,
  farCopy,
  farComment,
);
