import React from 'react';
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';

const ConfirmationModal = ({showConfirmationModal, setShowConfirmationModal, abandonChanges, title="Data Has Changed", okMessage="Click OK to abandon changes"}) => {

    const toggleConfirmation = ()=>{
        setShowConfirmationModal(prev=>!prev);
    }

    const className = '';

    return (
        <Modal
            backdrop={"static"}
            isOpen={showConfirmationModal}
            toggle={toggleConfirmation}
            className={`modal-md mt-2 ${className ? className : ''}`}
            contentClassName="border-0"
            modalClassName="theme-modal"
            size="md"
      >
            <ModalHeader>
                <h3 className=" text-danger text-center justify-content-center">{title}</h3>
            </ModalHeader>
            <ModalBody>
                <div className="text-center text-danger">{okMessage}</div>
                <div className="text-center">
                <Button id="abandon"
                    key="modal_abandon_button"
                    color="danger"
                    className="btn btn-sm m-1"
                    onClick={() => {
                        abandonChanges();
                        toggleConfirmation();
                    }
                }
                >OK</Button>
                <Button id="cancelAbandon"
                    key="modal_cancel_abandon_button"
                    color="secondary"
                    className="btn btn-sm m-1"
                    onClick={() => {
                        toggleConfirmation();
                    }
                }
                >Cancel</Button>
                </div>
            </ModalBody>
        </Modal>
    );
} //end component

export default ConfirmationModal